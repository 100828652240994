define("front-end/helpers/is-mobile", ["exports", "ember-is-mobile/helpers/is-mobile"], function (_exports, _isMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isMobile.default;
    }
  });
});