define("front-end/models/actions/update-planned-date-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    message: (0, _model.attr)('string'),
    computeEquivalent: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    newDate: (0, _model.attr)('string'),
    newDaysAmount: (0, _model.attr)('string'),
    oldDate: (0, _model.attr)('string'),
    oldDaysAmount: (0, _model.attr)('string'),
    targetResource: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    })
  });

  _exports.default = _default;
});