define("front-end/models/helpers/util", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readableDuration = readableDuration;
  _exports.computeAdditionalDays = computeAdditionalDays;

  function readableDuration(duration) {
    var days = parseInt(_moment.default.duration(duration).asDays());

    if (!duration) {
      return duration;
    } else {
      if (days === 0) {
        return _moment.default.duration(duration).humanize();
      } else if (days === 1) {
        return days.toString() + ' dia';
      } else if (!isNaN(days)) {
        return days.toString() + ' dias';
      }
    }
  }
  /**
   * This method aims to compute the manufacturerDeliveryDate for EstimateConfirmation
   * and JobOfferProcess. However, these dates are used only for visualization purposes only.
   * The real dates are computed and provided by the backend at the manufacturer's accepting time.
   * Check API ComputeDeliveryDate value object in the backend to see the calculation rules.
   * @param {string} initialDaysAmount the amount of days to be used as initial offset
   * @returns {Date} the computedDate
   */


  function computeAdditionalDays(initialDaysAmount, referenceDate) {
    var computedDate = referenceDate ? referenceDate : (0, _moment.default)();
    var hour = computedDate.hour();
    var daysAmount = parseInt(initialDaysAmount, 10);

    if (hour >= 13) {
      daysAmount += 1;
    } // afternoons -> + 1 day


    computedDate.add(daysAmount, 'd');
    var additionalDays = 0;

    if (computedDate.weekday() == 6) {
      additionalDays += 2;
    } // saturdays -> + 2 days


    if (computedDate.weekday() == 0) {
      additionalDays += 1;
    } // sundays -> + 1 day


    computedDate.add(additionalDays, 'd');
    return computedDate;
  }
});