define("front-end/components/estimate-confirmation-info", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showActionButtons: true,
    confirmationAccepted: Ember.computed.equal('estimateConfirmation.status', _constants.ESTIMATE_CONFIRMATION_STATUS.CONFIRMED),
    confirmationAvailable: Ember.computed.equal('estimateConfirmation.status', _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE),
    rawMaterialIncluded: Ember.computed('estimateSurvey.rawMaterialResponsible', function () {
      var responsible = this.estimateSurvey.get('rawMaterialResponsible');
      return responsible != 'manufacturer';
    }),
    material: Ember.computed('estimateConfirmation.estimateSurvey.material', function () {
      return this.estimateConfirmation.get('estimateSurvey.material') || 'Conforme desenho';
    }),
    mustConsiderFreight: Ember.computed('estimateConfirmation.estimate.fromManufacturerFreightResponsible', function () {
      var responsible = this.estimateConfirmation.get('estimate.fromManufacturerFreightResponsible');
      return responsible == 'manufacturer';
    }),
    shouldShowActionButtons: Ember.computed('showActionButtons', 'confirmationAccepted', function () {
      return this.showActionButtons && !this.confirmationAccepted;
    })
  });

  _exports.default = _default;
});