define("front-end/mirage/config/purchase-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.post('actions/formalize-purchase-order-actions', function (schema) {
      var attrs = this.normalizedRequestAttrs('actions/formalize-purchase-order-action');
      var purchaseOrder = schema.purchaseOrders.find(attrs.purchaseOrderId);
      purchaseOrder.update({
        creating: false
      });
      var action = schema.create('actions/formalize-purchase-order-action', {
        purchaseOrder: purchaseOrder
      });
      return this.serialize(action);
    });
    server.get('/files/purchase-orders-pdfs/:id');
    server.post('/files/purchase-order-pdfs', function (schema, request) {
      // Since Mirage does not 'understand' FormData
      // https://github.com/miragejs/ember-cli-mirage/issues/74
      var purchaseOrderId = request.requestBody.values().next().value;
      var purchaseOrder = schema.purchaseOrders.find(purchaseOrderId);
      var stored = true;
      var originalFilename = 'sample.pdf';
      var urlForFile = 'http://localhost:4200/assets/sample.pdf';
      var pdf = schema.create('files/purchase-order-pdf', {
        originalFilename: originalFilename,
        urlForFile: urlForFile,
        stored: stored,
        purchaseOrder: purchaseOrder
      });
      return this.serialize(pdf);
    });
    server.get('/purchase-orders/:id');
    server.del('/purchase-orders/:id', ['purchase-order', 'purchase-order-items']);
    server.patch('/purchase-orders/:id');
    server.get('/purchase-orders', function (schema) {
      var orders = schema.purchaseOrders.all();
      var json = this.serialize(orders);
      json.meta = {
        'page-count': 1,
        count: orders.length
      };
      return json;
    });
    server.del('/purchase-order-items/:id', function (schema, request) {
      var purchaseOrder = schema.purchaseOrderItems.find(request.params.id).purchaseOrder;
      var itemsOrder = purchaseOrder.itemsOrder.removeObject(request.params.id);
      purchaseOrder.update({
        itemsOrder: itemsOrder
      });
      schema.purchaseOrderItems.find(request.params.id).destroy();
    });
  }
});