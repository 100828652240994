define("front-end/templates/components/pagination-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E6pKM29E",
    "block": "{\"symbols\":[\"size\",\"page\"],\"statements\":[[10,\"div\"],[14,0,\"pagination-controls layout-row layout-align-start-start layout-wrap\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"Página:\"],[13],[2,\"\\n\"],[6,[37,4],null,[[\"selected\",\"options\",\"onChange\",\"data-test-change-page\"],[[35,3],[35,2],[30,[36,1],[[32,0],[35,0]],null],true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"Itens por página:\"],[13],[2,\"\\n\"],[6,[37,4],null,[[\"selected\",\"options\",\"onChange\",\"data-test-change-size\"],[[35,7],[35,6],[30,[36,1],[[32,0],[35,5]],null],true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout-row navigation-arrows\"],[12],[2,\"\\n      \"],[11,\"span\"],[24,\"data-test-page-down\",\"\"],[4,[38,1],[[32,0],[35,8]],null],[12],[1,[30,[36,9],[\"keyboard_arrow_left\"],null]],[13],[2,\"\\n      \"],[11,\"span\"],[24,\"data-test-page-up\",\"\"],[4,[38,1],[[32,0],[35,10]],null],[12],[1,[30,[36,9],[\"keyboard_arrow_right\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onChangePage\",\"action\",\"pageOptions\",\"page\",\"paper-select\",\"onChangeSize\",\"sizeOptions\",\"size\",\"onPageDown\",\"paper-icon\",\"onPageUp\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/pagination-controls.hbs"
    }
  });

  _exports.default = _default;
});