define("front-end/templates/components/competing-estimates-summary-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0D2fqHfg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"canvas\"],[14,1,\"estimate-chart\"],[15,\"width\",[34,0]],[15,\"height\",[34,1]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"width\",\"height\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/competing-estimates-summary-chart.hbs"
    }
  });

  _exports.default = _default;
});