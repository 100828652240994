define("front-end/templates/components/companies-autocomplete-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4PeKMJa3",
    "block": "{\"symbols\":[\"company\",\"autocomplete\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-start-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\"],[6,[37,13],null,[[\"required\",\"errorMessages\",\"disabled\",\"label\",\"loadingMessage\",\"noMatchesMessage\",\"options\",\"selected\",\"allowClear\",\"searchText\",\"searchField\",\"labelPath\",\"passThru\",\"onSelectionChange\"],[[35,12],[35,11],[30,[36,10],[[35,9,[\"isRunning\"]],[35,8]],null],[35,7],\"Buscando...\",\"Oops empresa não encontrada.\",[35,6],[35,5],true,[35,4],\"filterName\",\"displayName\",[30,[36,3],null,[[\"autocomplete\"],[\"off\"]]],[30,[36,2],[[32,0],\"setCompany\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"flags\",\"label\",\"searchText\",\"data-test-selected-company\"],[\"i\",[32,1,[\"displayName\"]],[32,2,[\"searchText\"]],true]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,14],[[35,9,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"diameter\"],[15]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paper-progress-circular\",\"paper-autocomplete-highlight\",\"action\",\"hash\",\"searchText\",\"selectedCompany\",\"companies\",\"label\",\"disabled\",\"_fetchCompanyNames\",\"or\",\"errorMessages\",\"required\",\"paper-autocomplete\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/companies-autocomplete-filter.hbs"
    }
  });

  _exports.default = _default;
});