define("front-end/components/is-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['layout-row', 'layout-align-start-center'],
    isSelected: Ember.computed('isDisabled', 'resource', 'resources.[]', function () {
      var resources = this.resources || [];
      return resources.includes(this.resource);
    }),
    isDisabled: Ember.computed('disabledFor.[]', 'resource', function () {
      if (!this.resource) {
        return true;
      }

      var disabledFor = this.disabledFor || [];
      return disabledFor.includes(this.resource);
    })
  });

  _exports.default = _default;
});