define("front-end/routes/admin/companies-groups/show", ["exports", "front-end/routes/helpers/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var group = this.store.findRecord('companies-group', params.group_id, {
        include: 'companies',
        fields: {
          'company': 'name, fantasy_name'
        }
      });
      var companies = group.then(function (group) {
        return group.get('companies');
      });
      return Ember.RSVP.hash({
        group: group,
        companies: companies
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('orderedCompanies', (0, _util.orderAlphabetically)(model.companies.toArray(), 'displayName'));
    },
    resetController: function resetController(controller) {
      controller._tearDown();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});