define("front-end/templates/admin/job-offer-processes/manufacturer-selection-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DZHCDEXU",
    "block": "{\"symbols\":[\"csv\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column layout-align-space-between\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[2,\"CSV seleção de fornecedores\"],[13],[2,\"\\n    \"],[8,\"generate-manufacturer-selection-csv-button\",[],[[\"@generateManufacturerSelectionCsv\"],[[30,[36,0],[[32,0],\"generateManufacturerSelectionCsv\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"paper-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"manufacturer-selection-csv-item\",[],[[\"@manufacturerSelectionCsv\"],[[32,1]]],null],[2,\"\\n    \"],[8,\"paper-divider\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"layout-row layout-align-end\"],[12],[2,\"\\n  \"],[1,[30,[36,7],null,[[\"page\",\"size\",\"pageOptions\",\"sizeOptions\",\"onPageUp\",\"onPageDown\",\"onChangePage\",\"onChangeSize\"],[[35,6],[35,5],[35,4],[35,3],[30,[36,0],[[32,0],\"pageUp\"],null],[30,[36,0],[[32,0],\"pageDown\"],null],[30,[36,0],[[32,0],\"changePage\"],null],[30,[36,0],[[32,0],\"changeSize\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\",\"sizeOptions\",\"pages\",\"size\",\"page\",\"pagination-controls\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/job-offer-processes/manufacturer-selection-csv.hbs"
    }
  });

  _exports.default = _default;
});