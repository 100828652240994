define("front-end/transforms/pt-currency", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized == 0 || deserialized) {
        deserialized = deserialized.toString();

        if (deserialized.indexOf(',') > -1) {
          deserialized = deserialized.replace('.', '');
          deserialized = deserialized.replace(',', '.');
        }

        return deserialized;
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});