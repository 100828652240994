define("front-end/components/quotation-tracking", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentStep: Ember.computed('quotation.status', 'batch.status', function () {
      switch (this.quotation.status) {
        case _constants.QUOTATION_STATUS.ANALYZING:
          return 0;

        case _constants.QUOTATION_STATUS.PRICING:
          return 1;

        case _constants.QUOTATION_STATUS.NEGOTIATION:
          return 2;

        case _constants.QUOTATION_STATUS.ACCEPTED:
          if (this.batch.get('status') != _constants.BATCH_STATUS.COMPLETED) {
            return 3;
          } else {
            return 4;
          }

        default:
          return -1;
      }
    })
  });

  _exports.default = _default;
});