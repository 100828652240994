define("front-end/templates/components/textbox-save/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QhOlBB3T",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,10],null,[[\"block\",\"label\",\"value\",\"textarea\",\"placeholder\",\"maxlength\",\"keyUp\",\"onChange\",\"required\",\"disabled\",\"errorMessages\",\"passThru\",\"data-test-textbox-save-textarea\"],[true,[35,9],[35,4],true,[35,8],[35,7],[30,[36,6],[[32,0],\"modelSave\"],null],[30,[36,6],[[32,0],[30,[36,5],[[35,4]],null]],null],[35,3],[35,2],[35,1],[30,[36,0],null,[[\"rows\",\"maxRows\",\"autocomplete\"],[2,2,\"off\"]]],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"errorMessages\",\"isDisabled\",\"isRequired\",\"value\",\"mut\",\"action\",\"maxlength\",\"placeholder\",\"label\",\"paper-input\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/textbox-save/textarea.hbs"
    }
  });

  _exports.default = _default;
});