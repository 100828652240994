define("front-end/components/estimate-chat-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.currentUser.load().then(function (user) {
        _this.set('user', user);

        var adminModelName = 'chats/admin-estimate-chat-message';
        var companyModelName = 'chats/company-estimate-chat-message';
        var modelName = _this.chatMessage.constructor.modelName;

        if (!user.get('isAdmin') && modelName == companyModelName || user.get('isAdmin') && modelName == adminModelName) {
          _this.set('sender', true);
        } else {
          _this.set('sender', false);
        }
      });
    },
    readIcon: Ember.computed('sender', 'chatMessage.readAt', function () {
      var chatMessage = this.chatMessage;

      if (this.sender) {
        if (chatMessage.get('readAt')) {
          return 'done-all';
        } else {
          return 'done';
        }
      }

      return null;
    }),
    customClassNames: Ember.computed('sender', function () {
      if (this.sender) {
        return 'sender layout-row layout-align-end';
      }

      return 'receiver layout-row layout-align-start';
    })
  });

  _exports.default = _default;
});