define("front-end/models/company", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    updateCnpjRequestedAt: (0, _model.attr)('date'),
    site: (0, _model.attr)('string'),
    pipefyLink: (0, _model.attr)('string'),
    cnpj: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    fantasyName: (0, _model.attr)('string'),
    formattedCnpj: (0, _model.attr)('string'),
    inbound: (0, _model.attr)('boolean'),
    completed: (0, _model.attr)('boolean'),
    // A/B testing
    experimental: (0, _model.attr)('boolean'),
    users: (0, _model.hasMany)('user'),
    address: (0, _model.attr)('string'),
    publicPlace: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    number: (0, _model.attr)('number'),
    city: (0, _model.attr)('string'),
    uf: (0, _model.attr)('string'),
    phoneNumber: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('number'),
    longitude: (0, _model.attr)('number'),
    availableLogistic: (0, _model.attr)('boolean'),
    taxProfileAnswered: (0, _model.attr)('boolean'),
    manufacturerCharacterization: (0, _model.belongsTo)('manufacturer-characterization', {
      inverse: 'company'
    }),
    taxProfile: (0, _model.belongsTo)('tax-profile', {
      inverse: 'company'
    }),
    configuration: (0, _model.belongsTo)('company-configuration', {
      inverse: 'company'
    }),
    companiesGroups: (0, _model.hasMany)('companies-group', {
      inverse: 'companies'
    }),
    clientFormalization: (0, _model.belongsTo)('client-formalization', {
      inverse: 'company'
    }),
    clientProfile: (0, _model.belongsTo)('client-profile', {
      inverse: 'company',
      async: false
    }),
    manufacturerProfile: (0, _model.belongsTo)('manufacturer-profile', {
      inverse: 'company',
      async: false
    }),
    route: function route() {
      var _this = this;

      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          return ['admin.companies.show', _this.id];
        } else {
          return ['dashboard', _this.id];
        }
      });
    },
    filterName: Ember.computed('name', 'fantasyName', 'displayName', function () {
      var stringToFilter = '';

      if (this.name) {
        stringToFilter += this.name;
      }

      if (this.fantasyName) {
        stringToFilter += this.fantasyName;
      }

      if (!stringToFilter) {
        stringToFilter = this.displayName;
      }

      return stringToFilter;
    }),
    displayName: Ember.computed('cnpj', 'fantasyName', 'name', function () {
      if (this.fantasyName) {
        var fantasyName = this.fantasyName.split(' ');

        if (fantasyName.length > 1) {
          return "".concat(fantasyName[0], " ").concat(fantasyName[1]);
        } else {
          return "".concat(fantasyName[0]);
        }
      } else if (this.name) {
        var name = this.name.split(' ');

        if (name.length > 1) {
          return "".concat(name[0], " ").concat(name[1]);
        } else {
          return "".concat(name[0]);
        }
      } else {
        return "Empresa com CNPJ: ".concat(this.cnpj);
      }
    }),
    newComer: Ember.computed('manufacturerProfile.isManufacturer', 'clientProfile.isClient', function () {
      return !this.manufacturerProfile.isManufacturer && !this.clientProfile.isClient;
    }),
    fullAddress: Ember.computed('zipCode', 'address', function () {
      var address = this.address,
          zipCode = this.zipCode;
      return zipCode ? "".concat(address, " - ").concat(zipCode) : address;
    })
  });

  _exports.default = _default;
});