define("front-end/routes/dashboard/estimate-chats", ["exports", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-websocket-toaster-message"], function (_exports, _routeWebsocketHandlerMixin, _routeWebsocketToasterMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, _routeWebsocketToasterMessage.default, {
    model: function model() {
      var company = this.modelFor('dashboard').company;
      var chatsCount = this.store.query('chats/estimate-chat', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          'company': company.get('id'),
          'has_unread_messages': 'admin'
        }
      }).then(function (chats) {
        return chats.get('meta.count');
      });
      var queryOptions = {
        filter: {
          'company': company.get('id'),
          'has_unread_messages': 'admin'
        },
        include: 'estimate',
        sort: '-created-at',
        fields: {
          'estimate': 'my_id',
          'estimate-chats': 'estimate'
        }
      };
      var chats = this.store.query('chats/estimate-chat', queryOptions);
      return Ember.RSVP.hash({
        chats: chats,
        chatsCount: chatsCount
      });
    },
    websocketResourceHandler: function websocketResourceHandler(record, type) {
      if (type == 'chats/admin-estimate-chat-messages') {
        var model = this.controller.model;
        var existingChat = model.chats.find(function (estimateChat) {
          return estimateChat.get('id') == record.get('estimateChat.id');
        });

        if (!existingChat) {
          // Action implemented by RouteWebsocketToasterMessage mixin
          this.send('showToasterForNewMessages', 'Você pode ter novos chats. Recarregue a página.');
        }
      }
    },
    actions: {
      websocketToasterRefresh: function websocketToasterRefresh() {
        this.send('refreshRouteMixin');
      }
    }
  });

  _exports.default = _default;
});