define("front-end/utils/query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParam;

  function queryParam(target, key)
  /*descriptor*/
  {
    return {
      get: function get() {
        var parentInstance = this.args.parentInstance;
        return Ember.get(parentInstance, key);
      },
      set: function set(value) {
        var parentInstance = this.args.parentInstance;
        Ember.set(parentInstance, key, value);
        parentInstance.afterApplyFilters();
      }
    };
  }
});