define("front-end/components/custom-modals/clone-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p>Ao clonar esta máquina, você está criando uma cópia idêntica, para os casos onde a sua fábrica possua máquinas com as mesmas informações como marca, modelo, etc.</p>
  <p>Aceitando clonar sua máquina você irá:</p>
  <ul>
  	<li>Criar nova máquina idêntica a solicitada para ser clonada</li>
  	<li>Você será redirecionado para a página de edição da máquina recém clonada para os casos onde você deseje editar alguma informação e salvar</li>
  	<li>Caso não queira modificar nenhuma informação da máquina, basta que você saia do formulário sem alterar as informações da máquina que foi clonada</li>
  </ul>
  
  <b>Você tem certeza que deseja clonar essa máquina?</b>
  */
  {
    id: "qozlPMRu",
    block: "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[2,\"Ao clonar esta m\xE1quina, voc\xEA est\xE1 criando uma c\xF3pia id\xEAntica, para os casos onde a sua f\xE1brica possua m\xE1quinas com as mesmas informa\xE7\xF5es como marca, modelo, etc.\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"Aceitando clonar sua m\xE1quina voc\xEA ir\xE1:\"],[13],[2,\"\\n\"],[10,\"ul\"],[12],[2,\"\\n\\t\"],[10,\"li\"],[12],[2,\"Criar nova m\xE1quina id\xEAntica a solicitada para ser clonada\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[12],[2,\"Voc\xEA ser\xE1 redirecionado para a p\xE1gina de edi\xE7\xE3o da m\xE1quina rec\xE9m clonada para os casos onde voc\xEA deseje editar alguma informa\xE7\xE3o e salvar\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[12],[2,\"Caso n\xE3o queira modificar nenhuma informa\xE7\xE3o da m\xE1quina, basta que voc\xEA saia do formul\xE1rio sem alterar as informa\xE7\xF5es da m\xE1quina que foi clonada\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"b\"],[12],[2,\"Voc\xEA tem certeza que deseja clonar essa m\xE1quina?\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "front-end/components/custom-modals/clone-machine.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});