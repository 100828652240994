define("front-end/templates/components/iexplorer-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HAvB0uF7",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[6,[37,2],null,[[\"class\",\"data-test-iexplorer-alert\"],[\"error\",true]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('front-end/templates/components/iexplorer-alert.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    Nossa plataforma pode \"],[10,\"b\"],[12],[2,\"não funcionar devidamente com esta versão do Internet Explorer.\"],[13],[2,\" Para melhorar sua experiência, use um navegador compatível: \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"a\"],[14,6,\"https://www.mozilla.org/pt-BR/firefox/new/\"],[14,\"target\",\"_blank\"],[12],[2,\"Firefox\"],[13],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"a\"],[14,6,\"https://www.google.com/intl/pt-BR/chrome/\"],[14,\"target\",\"_blank\"],[12],[2,\"Google Chrome\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"paper-card\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/iexplorer-alert.hbs"
    }
  });

  _exports.default = _default;
});