define("front-end/components/reopen-estimates-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      removeEstimate: function removeEstimate(estimate) {
        var estimatesToBeReopened = this.estimatesToBeReopened;
        estimatesToBeReopened.removeObject(estimate);
      }
    }
  });

  _exports.default = _default;
});