define("front-end/templates/components/project-sooner-deadline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cBle7rIt",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"value\",\"data-test-sooner-deadline\"],[[35,0],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"minDate\",\"show-table-item\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/project-sooner-deadline.hbs"
    }
  });

  _exports.default = _default;
});