define("front-end/models/bounding-box", ["exports", "@ember-data/model", "front-end/models/bounding-volume"], function (_exports, _model, _boundingVolume) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _boundingVolume.default.extend({
    icon: 'fit-screen',
    type: 'bounding-box',
    capitalizedType: 'BoundingBox',
    humanizedName: 'Caixa Delimitadora',
    width: (0, _model.attr)('number'),
    height: (0, _model.attr)('number'),
    length: (0, _model.attr)('number')
  });

  _exports.default = _default;
});