define("front-end/components/manufacturer-selection-csv-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperItem data-test-manufacturer-selection-item={{@manufacturerSelectionCsv.id}} @class="md-3-line">
    <div class="md-list-item-text">
      <p>Processos no CSV {{@manufacturerSelectionCsv.myId}}: </p>
      {{#each @manufacturerSelectionCsv.jobOfferProcesses as |process|}}
        <LinkTo data-test-link-process={{process.myId}} @route='admin.job-offer-processes.build' @model={{process.id}} @target="_blank">
          <span class="mr-1">{{process.myId}}</span>
        </LinkTo>
      {{/each}}
    </div>
    <div class="md-secondary-container">
      {{#if @manufacturerSelectionCsv.stored}}
        <a data-test-link-csv={{@manufacturerSelectionCsv.id}} href={{@manufacturerSelectionCsv.authUrlForFile}} target="_blank">
          Download CSV - {{@manufacturerSelectionCsv.formattedDate}}
          <PaperIcon @icon="file_download" />
        </a>
      {{else}}
        <div class="layout-row layout-align-center-center">
          <span>Download CSV - {{@manufacturerSelectionCsv.formattedDate}}</span>
          <PaperProgressCircular data-test-loader @diameter=25 />
        </div>
      {{/if}}
    </div>
  </PaperItem>
  */
  {
    id: "WWSVQNj+",
    block: "{\"symbols\":[\"process\",\"@manufacturerSelectionCsv\"],\"statements\":[[8,\"paper-item\",[[16,\"data-test-manufacturer-selection-item\",[32,2,[\"id\"]]]],[[\"@class\"],[\"md-3-line\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Processos no CSV \"],[1,[32,2,[\"myId\"]]],[2,\": \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"jobOfferProcesses\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[[16,\"data-test-link-process\",[32,1,[\"myId\"]]]],[[\"@route\",\"@model\",\"@target\"],[\"admin.job-offer-processes.build\",[32,1,[\"id\"]],\"_blank\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,0,\"mr-1\"],[12],[1,[32,1,[\"myId\"]]],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-secondary-container\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2,[\"stored\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[15,\"data-test-link-csv\",[32,2,[\"id\"]]],[15,6,[32,2,[\"authUrlForFile\"]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n        Download CSV - \"],[1,[32,2,[\"formattedDate\"]]],[2,\"\\n        \"],[8,\"paper-icon\",[],[[\"@icon\"],[\"file_download\"]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Download CSV - \"],[1,[32,2,[\"formattedDate\"]]],[13],[2,\"\\n        \"],[8,\"paper-progress-circular\",[[24,\"data-test-loader\",\"\"]],[[\"@diameter\"],[\"25\"]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    meta: {
      moduleName: "front-end/components/manufacturer-selection-csv-item.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});