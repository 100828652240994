define("front-end/components/date-input", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['layout-row'],
    init: function init() {
      this._super.apply(this, arguments);

      var attr = this.attr;
      Ember.defineProperty(this, 'dateValue', Ember.computed.alias("model.".concat(attr)));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.dateValue) {
        this.set('dateShow', (0, _moment.default)(this.dateValue).format('DD/MM/YYYY'));
      }
    },
    actions: {
      setValue: function setValue(dateString) {
        if (dateString) {
          this.set('dateShow', dateString);
          var dateValue = (0, _moment.default)(dateString, "DD/MM/YYYY").format();

          if (this.onChange) {
            this.onChange(this.attr, dateValue);
          } else {
            this.set('dateValue', dateValue);
          }
        }
      }
    }
  });

  _exports.default = _default;
});