define("front-end/models/user-activities/tour", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    name: (0, _model.attr)('string'),
    currentStep: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    totalSteps: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    skipped: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    concluded: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    displayTour: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});