define("front-end/components/paper-menu-content", ["exports", "ember-paper/components/paper-menu-content"], function (_exports, _paperMenuContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paperMenuContent.default;
    }
  });
});