define("front-end/mixins/component-sidenav-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    expandedItem: null,
    currentRouteName: Ember.computed.reads('router.currentRouteName'),
    actions: {
      toggleExpandedItem: function toggleExpandedItem(value) {
        if (this.expandedItem === value) {
          value = null;
        }

        this.set('expandedItem', value);
      }
    }
  });

  _exports.default = _default;
});