define("front-end/templates/components/horizontal-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LB3d8OmO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,1,[34,0]],[14,0,\"splide\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"splide__track\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"splide__list\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"sliderId\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/horizontal-slider.hbs"
    }
  });

  _exports.default = _default;
});