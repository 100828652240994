define("front-end/models/manufacturer-characterization", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    jotFormVersion: (0, _model.attr)('string'),
    shifts: (0, _model.attr)('number'),
    overtime: (0, _model.attr)('boolean'),
    numberOfEmployees: (0, _model.attr)('number'),
    erp: (0, _model.attr)('string'),
    metrologyRoom: (0, _model.attr)('boolean'),
    stockRawMaterial: (0, _model.attr)('boolean'),
    dimensionalTolerance: (0, _model.attr)('number'),
    minimumNumberOfItems: (0, _model.attr)('number'),
    historicalDescription: (0, _model.attr)('string'),
    qualityAssuranceSoftware: (0, _model.attr)('string'),
    answeredAt: (0, _model.attr)('string'),
    // social_contract_file, type: Array
    // quality_certificate_files, type: Array
    // calibration_procedure_file, type: Array
    anotherSpecialty: (0, _model.attr)('string'),
    anotherService: (0, _model.attr)('string'),
    anotherQualityCertificate: (0, _model.attr)('string'),
    anotherQuotationProcess: (0, _model.attr)('string'),
    otherRawMaterials: (0, _model.attr)('string'),
    anotherManufacturingProcess: (0, _model.attr)('string'),
    anotherQualityAssuranceProcess: (0, _model.attr)('string'),
    rawMaterialTypes: (0, _model.attr)('string'),
    otherMeasureInstruments: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company', {
      inverse: 'manufacturerCharacterization'
    }),
    answeredBy: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    jotformResponse: (0, _model.belongsTo)('jotform-response', {
      inverse: 'manufacturerCharacterization'
    }),
    manufacturerManufacturingWorkflow: (0, _model.belongsTo)('tags/manufacturer-manufacturing-workflow', {
      inverse: 'manufacturerCharacterizations'
    }),
    calibrationProcedure: (0, _model.belongsTo)('tags/calibration-procedure', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerPartSize: (0, _model.belongsTo)('tags/manufacturer-part-size', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerSpecialty: (0, _model.belongsTo)('tags/manufacturer-specialty', {
      inverse: 'manufacturerCharacterizations'
    }),
    stockManagementProcess: (0, _model.belongsTo)('tags/stock-management-process', {
      inverse: 'manufacturerCharacterizations'
    }),
    qualityAssuranceProcess: (0, _model.belongsTo)('tags/quality-assurance-process', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerQuotationProcess: (0, _model.belongsTo)('tags/manufacturer-quotation-process', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerServices: (0, _model.hasMany)('tags/manufacturer-service', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerPartTypes: (0, _model.hasMany)('tags/manufacturer-part-type', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerPartWeights: (0, _model.hasMany)('tags/manufacturer-part-weight', {
      inverse: 'manufacturerCharacterizations'
    }),
    manufacturerQualityCertificates: (0, _model.hasMany)('tags/manufacturer-quality-certificates', {
      inverse: 'manufacturerCharacterizations'
    }),
    measureInstruments: (0, _model.hasMany)('tags/measure-instruments', {
      inverse: 'manufacturerCharacterizations'
    }),
    recurringRawMaterials: (0, _model.hasMany)('tags/raw-material', {
      inverse: 'manufacturerCharacterizations'
    })
  });

  _exports.default = _default;
});