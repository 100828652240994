define("front-end/components/ui/loader-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperButton ...attributes
  @raised={{true}}
  @primary={{@primary}}
  @disabled={{or @isDisabled @submitTask.isRunning}}
  @class="loader-button"
  @onClick={{perform @submitTask}} >
    <div class="layout-row layout-align-center-center">
      <span>{{@submitLabel}}</span>
      {{#if @submitTask.isRunning}}
        <PaperProgressCircular data-test-loader @class="loader-button-circular" @diameter={{12}} />
      {{/if}}
    </div>
  </PaperButton>
  */
  {
    id: "azC1Eyqz",
    block: "{\"symbols\":[\"&attrs\",\"@primary\",\"@submitTask\",\"@isDisabled\",\"@submitLabel\"],\"statements\":[[8,\"paper-button\",[[17,1]],[[\"@raised\",\"@primary\",\"@disabled\",\"@class\",\"@onClick\"],[true,[32,2],[30,[36,0],[[32,4],[32,3,[\"isRunning\"]]],null],\"loader-button\",[30,[36,1],[[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,5]],[13],[2,\"\\n\"],[6,[37,2],[[32,3,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"paper-progress-circular\",[[24,\"data-test-loader\",\"\"]],[[\"@class\",\"@diameter\"],[\"loader-button-circular\",12]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"perform\",\"if\"]}",
    meta: {
      moduleName: "front-end/components/ui/loader-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});