define("front-end/components/pipeline/item-quotation", ["exports", "moment", "front-end/constants"], function (_exports, _moment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    selectable: true,
    answeredEstimates: Ember.computed.filterBy('estimateSurvey.estimates', 'status', _constants.ESTIMATE_STATUS.NEGOTIATION),
    awaitingEstimates: Ember.computed.filterBy('estimateSurvey.estimates', 'status', _constants.ESTIMATE_STATUS.NOT_ANSWERED),
    doubleClick: function doubleClick() {
      this.router.transitionTo('admin.projects.show.batch.quotation', this.quotation.get('project.id'), this.batch.get('id'));
    },
    className: Ember.computed('batch.{invisible,lost}', function () {
      var className = 'flex-grow';
      className = this.batch.get('lost') ? className + ' lost' : className;
      className = this.batch.get('invisible') ? className + ' invisible' : className;
      return className;
    }),
    deadlineClass: Ember.computed('quotation.{beforeNegotiation,deadline}', function () {
      var quotation = this.quotation;
      var deadline = (0, _moment.default)(quotation.get('deadline'));
      var now = (0, _moment.default)();

      if (quotation.get('beforeNegotiation')) {
        if (now > deadline) {
          return 'error-text';
        }
      }

      return 'info-text';
    })
  });

  _exports.default = _default;
});