define("front-end/models/actions/decline-quotation-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    message: (0, _model.attr)('string'),
    quotation: (0, _model.belongsTo)('quotation', {
      inverse: null
    }),
    declinedQuotationMessage: (0, _model.belongsTo)('tags/declined-quotation-message', {
      inverse: null
    })
  });

  _exports.default = _default;
});