define("front-end/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8INrez8k",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,1]],[2,\"\\n\"],[1,[34,2]],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"accent\",\"class\"],[true,\"application-loader\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paper-progress-linear\",\"paper-toaster\",\"modals-container\",\"showLoader\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "front-end/templates/application.hbs"
    }
  });

  _exports.default = _default;
});