define("front-end/routes/dashboard/jobs/index", ["exports", "front-end/constants", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-pagination-mixin"], function (_exports, _constants, _routeWebsocketHandlerMixin, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, _routeWebsocketHandlerMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        sortBy: {
          refreshModel: true
        },
        finishedJobs: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var manufacturer = this.modelFor('dashboard').company.get('id');
      var filterQueryOptions = {
        filter: {
          'context__manufacturer': manufacturer,
          status: [_constants.BATCH_STATUS.MANUFACTURING, _constants.BATCH_STATUS.TRANSITING]
        },
        sort: '-created-at',
        include: 'context.part-revision.edited-pdf-design,context.part-revision.edited-two-dimensional-design,context.part-revision.three-dimensional-design,context.part-revision.main-manufacturing-process,context.part-revision.secondary-manufacturing-processes,context.manufacturer-delivery,context.raw-material-delivery,context.part-revision.manufacturer-part-treatments',
        fields: {
          'batch': 'status',
          'part-revision': 'edited_pdf_design,edited_two_dimensional_design,material,three_dimensional_design,material_from_design,tightest_tolerance,tightest_tolerance_from_design,main_manufacturing_process,secondary_manufacturing_processes,manufacturer_part_treatments,require_calibrator_control,require_dimensional_report,finer_roughness',
          'edited-pdf-design': 'url_for_file,url_for_thumb',
          'edited-two-dimensional-design': 'url_for_file',
          'three-dimensional-design': 'url_for_file'
        }
      };

      if (params.sortBy) {
        filterQueryOptions['sort'] = params.sortBy;
      }

      if (params.finishedJobs) {
        filterQueryOptions.filter['status'] = _constants.BATCH_STATUS.COMPLETED;
      } // This filter must be placed after the params.batchStatus filter,
      // since it changes the batch__status value.


      if (params.myId) {
        filterQueryOptions.filter['status.in'] = [_constants.BATCH_STATUS.MANUFACTURING, _constants.BATCH_STATUS.TRANSITING, _constants.BATCH_STATUS.COMPLETED];
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var jobs = this.store.query('batch', queryOptions);
      var jobsCount = this.store.query('batch', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          'context__manufacturer': manufacturer,
          'status.in': [_constants.BATCH_STATUS.MANUFACTURING, _constants.BATCH_STATUS.TRANSITING, _constants.BATCH_STATUS.COMPLETED]
        }
      }).then(function (jobs) {
        return jobs.get('meta.count');
      });
      return Ember.RSVP.hash({
        jobs: jobs,
        jobsCount: jobsCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();
      }
    }
  });

  _exports.default = _default;
});