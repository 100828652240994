define("front-end/components/custom-paper-pikaday", ["exports", "moment", "ember-paper/templates/components/paper-input", "ember-paper-pikaday/components/paper-pikaday"], function (_exports, _moment, _paperInput, _paperPikaday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperPikaday.default.extend({
    layout: _paperInput.default,
    // Override method of PikadayMixin from ember-pikaday
    setPikadayDate: function setPikadayDate() {
      var format = this.format;
      var value = this.value;

      if (!value) {
        this.pikaday.setDate(value, true);
      } else {
        var date = this.useUTC ? _moment.default.utc((0, _moment.default)(value, format).format('YYYY-MM-DD')).toDate() : (0, _moment.default)(value, format).toDate();
        this.pikaday.setDate(date, true);
      }
    }
  });

  _exports.default = _default;
});