define("front-end/controllers/mail/recovery", ["exports", "front-end/utils/password-analyzer"], function (_exports, _passwordAnalyzer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    password: '',
    passwordConfirmation: '',
    toasterMessages: Ember.inject.service(),
    isValid: Ember.computed('password.length', 'passwordConfirmation', function () {
      if (this.password.length == 0) return false;
      if (this.password !== this.passwordConfirmation) return false;

      var data = _passwordAnalyzer.default.analyze(this.password);

      return !data.isInvalid;
    }),
    actions: {
      setNewPassword: function setNewPassword() {
        var _this = this;

        var password = this.password;
        var recoveryPasswordToken = this.model;
        var passwordConfirmation = this.passwordConfirmation;
        var action = this.store.createRecord('actions/set-new-password-action', {
          password: password,
          passwordConfirmation: passwordConfirmation,
          recoveryPasswordToken: recoveryPasswordToken
        });
        action.save().then(function () {
          _this.toasterMessages.showSuccess(['Sua senha foi redefinida com sucesso']);

          _this.transitionToRoute('index.signin');
        });
      },
      setProperty: function setProperty(property, value) {
        this.set(property, value);
      }
    }
  });

  _exports.default = _default;
});