define("front-end/routes/admin/estimates/index", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        company: {
          refreshModel: true
        },
        projectName: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        },
        manufacturer: {
          refreshModel: true
        },
        createdFinalLte: {
          refreshModel: true
        },
        answeredFinalLte: {
          refreshModel: true
        },
        createdInitialGte: {
          refreshModel: true
        },
        answeredInitialGte: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      var filterQueryOptions = {
        filter: {},
        sort: '-created-at',
        include: 'manufacturer,estimate-survey.project,estimate-survey.company',
        fields: {
          'projects': 'name',
          'estimate_surveys': 'status,project,company',
          'companies': 'name,fantasy_name,cnpj',
          'manufacturers': 'name,fantasy_name',
          'estimates': 'my_id,created_at,answered_at,answer_time,timeout_deadline,price,status,concluded_at,available_time,conclusion_time,estimate_survey,manufacturer'
        }
      };

      if (params.status) {
        filterQueryOptions.filter['status'] = params.status;
      }

      if (params.myId) {
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      if (params.company) {
        filterQueryOptions.filter['client__id'] = params.company;
      }

      if (params.manufacturer) {
        filterQueryOptions.filter['manufacturer__id'] = params.manufacturer;
      }

      if (params.projectName) {
        filterQueryOptions.filter['project__name.match'] = params.projectName;
      }

      if (params.createdFinalLte) {
        filterQueryOptions.filter['createdAt.lte'] = params.createdFinalLte;
      }

      if (params.answeredFinalLte) {
        filterQueryOptions.filter['answeredAt.lte'] = params.answeredFinalLte;
      }

      if (params.createdInitialGte) {
        filterQueryOptions.filter['createdAt.gte'] = params.createdInitialGte;
      }

      if (params.internalCode) {
        filterQueryOptions.filter['part__internalCode.match'] = params.internalCode;
      }

      if (params.answeredInitialGte) {
        filterQueryOptions.filter['answeredAt.gte'] = params.answeredInitialGte;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var estimates = this.store.query('estimate', queryOptions);
      var estimatesCount = this.store.query('estimate', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (estimates) {
        return estimates.get('meta.count');
      });
      return Ember.RSVP.hash({
        estimates: estimates,
        estimatesCount: estimatesCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
      var dummyEstimate = this.store.createRecord('estimate');
      var availableEstimateStatus = dummyEstimate.get('availableEstimateStatus');
      controller.set('availableEstimateStatus', availableEstimateStatus);
      dummyEstimate.deleteRecord();
      var statusSelected = availableEstimateStatus.find(function (option) {
        return option.value == controller.status;
      });
      controller.set('statusSelected', statusSelected);
      var dateFilters = controller.dateFilters;
      var dateFiltersNotEmpty = dateFilters.find(function (filter) {
        return filter != '';
      });

      if (dateFiltersNotEmpty) {
        controller.set('showAdvancedFilters', true);
      }
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller.set('showAdvancedFilters', false);
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});