define("front-end/templates/dashboard/estimates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2nZ1QR4L",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"estimateSurvey\",\"company\",\"data-test-competing-estimates-summary\"],[[35,0,[\"estimateSurvey\"]],[35,0,[\"company\"]],true]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"layout-column layout-gt-md-row\"],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"model\",\"partRevision\",\"class\",\"data-test-batch-information\"],[[35,0,[\"estimateSurvey\"]],[35,0,[\"estimateSurvey\",\"partRevision\"]],\"layout-column flex-gt-md-60\",true]]]],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"class\",\"closeAction\",\"estimate\"],[\"layout-column flex-gt-md-40\",[30,[36,2],[[32,0],[30,[36,3],[\"showChat\",[32,0]],null]],null],[35,0,[\"estimate\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"estimate-information-for-manufacturer\",[],[[\"@estimateSurvey\",\"@estimate\",\"@batch\",\"@company\",\"@toggleShowChatAction\",\"@afterAction\"],[[34,0,[\"estimateSurvey\"]],[34,0,[\"estimate\"]],[34,0,[\"batch\"]],[34,0,[\"company\"]],[30,[36,2],[[32,0],\"toggleShowChat\"],null],[30,[36,2],[[32,0],\"afterAction\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,0,[\"estimateSurvey\",\"videoAskUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"url\"],[[35,0,[\"estimateSurvey\",\"videoAskUrl\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"videoask-widget\",\"action\",\"toggle\",\"estimate-chat\",\"competing-estimates-summary\",\"batch-information-for-manufacturer\",\"showChat\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/dashboard/estimates/show.hbs"
    }
  });

  _exports.default = _default;
});