define("front-end/controllers/dashboard/projects/manage", ["exports", "front-end/controllers/dashboard/projects"], function (_exports, _projects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _projects.default.extend({
    transitionAfterSave: false
  });

  _exports.default = _default;
});