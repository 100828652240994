define("front-end/mirage/config", ["exports", "front-end/mirage/config/job-offer", "front-end/mirage/config/purchase-order"], function (_exports, _jobOffer, _purchaseOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var interceptJobOffer = false;
    var interceptPurchaseOrder = false;
    this.timming = 1000; // Passthrough local assets

    this.namespace = 'notifications/';
    this.passthrough(); // Passthrough local assets

    this.namespace = 'assets/';
    this.passthrough();
    this.namespace = 'core/v1';
    this.urlPrefix = 'http://localhost:8000';
    this.passthrough();

    if (interceptJobOffer) {
      (0, _jobOffer.default)(this);
    }

    if (interceptPurchaseOrder) {
      (0, _purchaseOrder.default)(this);
    }
  }
});