define("front-end/models/part", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    name: (0, _model.attr)('string'),
    internalCode: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    mounting: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    creator: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    partRevisions: (0, _model.hasMany)('part-revision'),
    currentPartRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    route: function route() {
      return Ember.RSVP.resolve(['admin.parts.show', this.id]);
    }
  });

  _exports.default = _default;
});