define("front-end/controllers/index/signup", ["exports", "front-end/utils/cnpj-validator", "front-end/utils/email-validator", "front-end/utils/password-analyzer"], function (_exports, _cnpjValidator, _emailValidator, _passwordAnalyzer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    cnpj: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    cnpjValidation: Ember.A([{
      message: 'Valor inválido. Por favor verifique o CNPJ fornecido',
      validate: function validate(inputValue) {
        return Ember.isEmpty(inputValue) ? true : (0, _cnpjValidator.cnpjIsValid)(inputValue);
      }
    }]),
    _cnpjIsValid: function _cnpjIsValid() {
      return (0, _cnpjValidator.cnpjIsValid)(this.cnpj);
    },
    _emailIsValid: function _emailIsValid() {
      return (0, _emailValidator.emailValidator)(this.email);
    },
    _passwordIsValid: function _passwordIsValid() {
      if (this.password.length === 0) return false;
      if (this.password !== this.passwordConfirmation) return false;

      var data = _passwordAnalyzer.default.analyze(this.password);

      return !data.isInvalid;
    },
    hasRequiredData: Ember.computed('cnpj', 'name', 'email', 'password', 'passwordConfirmation', function () {
      return !!this.cnpj && !!this.name && !!this.email && this._cnpjIsValid() && this._emailIsValid() && this._passwordIsValid();
    }),
    _resetValues: function _resetValues() {
      Ember.setProperties(this, {
        cnpj: "",
        name: "",
        email: "",
        phone: "",
        password: "",
        passwordConfirmation: ""
      });
    },
    actions: {
      doSignUp: function doSignUp() {
        var _this = this;

        var action = this.store.createRecord('actions/register-user-action', {
          cnpj: this.cnpj,
          name: this.name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation
        });
        action.save().then(function () {
          _this.toasterMessages.showSuccess(['Sucesso! Verifique sua caixa de email']);

          _this._resetValues();

          _this.transitionToRoute('index');
        }).catch(function () {}); // eslint-disable-line no-empty
      },
      setProperty: function setProperty(property, value) {
        this.set(property, value);
      }
    }
  });

  _exports.default = _default;
});