define("front-end/adapters/application", ["exports", "@ember-data/adapter/json-api", "front-end/config/environment", "ember-simple-auth-token/mixins/token-authorizer"], function (_exports, _jsonApi, _environment, _tokenAuthorizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_tokenAuthorizer.default, {
    toasterMessages: Ember.inject.service(),
    host: _environment.default.host,
    namespace: 'core/v1',
    headers: Ember.computed('session.data.authenticated.jwt', {
      get: function get() {
        var headers = {};
        headers['API_KEY'] = _environment.default.API_KEY;
        var authToken = this.session.data.authenticated.jwt;

        if (authToken) {
          headers['Authorization'] = "Bearer ".concat(authToken);
        }

        return headers;
      }
    }),
    createRecord: function createRecord(store, type) {
      var ajaxRequest = this._super.apply(this, arguments); // Workaround ember issue dealing with http responses containing
      // records that have already been pushed to the store by ws message


      ajaxRequest.then(function (response) {
        var id = response.data.id;

        var map = store._internalModelsFor(type.modelName);

        var internalModel = map.get(id);
        map.remove(internalModel, id);
      });
      return ajaxRequest;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status < 500) {
        if (payload && payload.errors) {
          var details = payload.errors.map(function (error) {
            return error.detail;
          });
          this.toasterMessages.showErrors(details);
        }
      } else {
        this.toasterMessages.showErrors(['Serviço indisponível. Tente novamente.']);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});