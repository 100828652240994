define("front-end/models/files/commercial-proposal-pdf", ["exports", "@ember-data/model", "front-end/models/files/application-file"], function (_exports, _model, _applicationFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationFile.default.extend({
    session: Ember.inject.service(),
    resource: (0, _model.belongsTo)('commercial-proposal', {
      inverse: 'commercialProposalPdf'
    })
  });

  _exports.default = _default;
});