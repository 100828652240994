define("front-end/models/tags/manufacturer-part-family", ["exports", "@ember-data/model", "front-end/models/tags/application-tag"], function (_exports, _model, _applicationTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationTag.default.extend({
    manufacturingProcessMainType: (0, _model.attr)('string'),
    tagImage: (0, _model.belongsTo)('files/tag-image', {
      inverse: 'resource'
    })
  });

  _exports.default = _default;
});