define("front-end/utils/cnpj-validator", ["exports", "cpf-cnpj-validator"], function (_exports, _cpfCnpjValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cnpjIsValid = cnpjIsValid;

  /**
  * This utility receives a cnpj string as param and use `cpf-cnpj-validator`
  * library to check if it is a valid cnpj or not.
  *
  * @param {String} cnpjString
  * @return {Boolean}
  */
  function cnpjIsValid(cnpjString) {
    return _cpfCnpjValidator.cnpj.isValid(cnpjString);
  }
});