define("front-end/models/client-formalization", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/constants"], function (_exports, _model, _defaultModel, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    myId: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company', {
      inverse: 'clientFormalization'
    }),
    batch: (0, _model.belongsTo)('batch', {
      inverse: 'clientFormalization'
    }),
    mechanism: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    }),
    purchaseOrderItem: (0, _model.belongsTo)('purchase-order-item', {
      inverse: 'clientFormalization'
    }),
    statusReference: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.CLIENT_FORMALIZATION_STATUS.UNDEFINED:
          return 'undefined';

        case _constants.CLIENT_FORMALIZATION_STATUS.DEFINING:
          return 'defining';

        case _constants.CLIENT_FORMALIZATION_STATUS.DEFINED:
          return 'defined';

        case _constants.CLIENT_FORMALIZATION_STATUS.CANCELED:
          return 'canceled';

        default:
          return '';
      }
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.CLIENT_FORMALIZATION_STATUS.UNDEFINED:
          return 'notifications_active';

        case _constants.CLIENT_FORMALIZATION_STATUS.DEFINING:
          return 'assignment';

        case _constants.CLIENT_FORMALIZATION_STATUS.DEFINED:
          return 'thumb_up';

        case _constants.CLIENT_FORMALIZATION_STATUS.CANCELED:
          return 'cancel';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.CLIENT_FORMALIZATION_STATUS.UNDEFINED:
          return 'warning';

        case _constants.CLIENT_FORMALIZATION_STATUS.DEFINING:
          return 'info';

        case _constants.CLIENT_FORMALIZATION_STATUS.DEFINED:
          return 'success';

        case _constants.CLIENT_FORMALIZATION_STATUS.CANCELED:
          return 'error';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});