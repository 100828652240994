define("front-end/templates/components/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L+FY6cIV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,9],null,[[\"href\",\"onClick\",\"class\",\"data-test-menu-item\"],[[35,8],[35,0],[30,[36,7],[\"menu-item\",[30,[36,4],[[35,6],\" active\"],null],[30,[36,4],[[35,5],\" expanded\"],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"span\"],[24,\"data-test-expand-submenu\",\"\"],[24,0,\"expand-submenu\"],[4,[38,1],[[32,0],[35,0]],null],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"expand-more\"],[[\"class\"],[\"expand-icon\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onClick\",\"action\",\"paper-icon\",\"canExpand\",\"if\",\"expanded\",\"active\",\"concat\",\"href\",\"paper-item\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/menu-item.hbs"
    }
  });

  _exports.default = _default;
});