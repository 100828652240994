define("front-end/controllers/admin/estimates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showChat: false,
    queryParams: ['showChat']
  });

  _exports.default = _default;
});