define("front-end/models/query-objects/companies-summary-query", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    groupBy: (0, _model.attr)('string'),
    lowerBound: (0, _model.attr)('string'),
    upperBound: (0, _model.attr)('string'),
    stats: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return [];
      }
    }) // Array

  });

  _exports.default = _default;
});