define("front-end/routes/logout", ["exports", "front-end/config/environment", "ember-local-storage"], function (_exports, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    websockets: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    cache: (0, _emberLocalStorage.storageFor)('session-cache'),
    tearDown: function tearDown(access_token) {
      this.cache.reset();

      if (access_token) {
        var url = _environment.default.ws + access_token;
        this.websockets.closeSocketFor(url);
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var access_token = this.session.data.authenticated.jwt;

      if (!this.session.isAuthenticated) {
        this.transitionTo('index.signin');
      } else {
        return this.currentUser.unload().then(function () {
          _this.tearDown(access_token);

          _this.store.unloadAll();

          _this.transitionTo('index.signin');
        });
      }
    }
  });

  _exports.default = _default;
});