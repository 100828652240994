define("front-end/components/batch-management-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hideUnitTargetPrice: false,
    providingRawMaterial: Ember.computed('model.rawMaterialResponsible', function () {
      var responsible = this.model.get('rawMaterialResponsible');
      return responsible == 'client' || responsible == 'peerdustry';
    })
  });

  _exports.default = _default;
});