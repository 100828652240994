define("front-end/routes/admin/purchase-orders/build", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var purchaseOrder = this.store.query('purchase-order', {
        filter: {
          id: params.purchase_order_id
        },
        include: 'company,purchaseOrderPdf,items.partRevision,items.batch.context.manufacturer'
      }).then(function (query) {
        return query.get('firstObject');
      });
      var batches = purchaseOrder.then(function (purchaseOrder) {
        return purchaseOrder.get('items').then(function (items) {
          return Ember.RSVP.map(items.toArray(), function (item) {
            return item.get('batch');
          });
        });
      });
      var partRevisions = purchaseOrder.then(function (purchaseOrder) {
        return purchaseOrder.get('items').then(function (items) {
          return Ember.RSVP.map(items.toArray(), function (item) {
            return item.get('partRevision');
          });
        });
      });
      return Ember.RSVP.hash({
        purchaseOrder: purchaseOrder,
        batches: batches,
        partRevisions: partRevisions
      });
    }
  });

  _exports.default = _default;
});