define("front-end/routes/admin", ["exports", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-error-handler", "front-end/mixins/route-filter-loader"], function (_exports, _routeWebsocketHandlerMixin, _routeErrorHandler, _routeFilterLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, _routeErrorHandler.default, _routeFilterLoader.default, {
    currentUser: Ember.inject.service(),
    parentRoute: 'admin',
    reconnectWebSocket: true,
    beforeModel: function beforeModel(transition) {
      if (!this.session.isAuthenticated) {
        var controller = this.controllerFor('index.signin', transition);
        controller.set('previousTransition', transition);
        this.transitionTo('index.signin');
      }
    },
    model: function model() {
      return this.currentUser.load();
    },
    afterModel: function afterModel(model) {
      return model.get('isAdmin') ? true : this.transitionTo('logout');
    }
  });

  _exports.default = _default;
});