define("front-end/components/company-icons", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    clientCategoryInfo: Ember.computed('company.clientProfile.clientCategory', function () {
      return _constants.COMPANY_CLIENT_CATEGORY_OPTIONS[this.company.get('clientProfile.clientCategory')];
    })
  });

  _exports.default = _default;
});