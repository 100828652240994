define("front-end/models/chats/admin-estimate-chat-message", ["exports", "front-end/models/chats/estimate-chat-message"], function (_exports, _estimateChatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _estimateChatMessage.default.extend({});

  _exports.default = _default;
});