define("front-end/routes/dashboard/tasks/index", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        status: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;
      var filterQueryOptions = {
        filter: {
          'target': company.id
        },
        include: 'requestor,closed-by',
        sort: '-created-at'
      };

      if (params.status) {
        filterQueryOptions.filter['status'] = params.status;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var tasks = this.store.query('tasks/task', queryOptions);
      var hasTasks = this.store.query('tasks/task', {
        filter: {
          target: company.id
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (tasks) {
        return tasks.get('meta.count') > 0;
      });
      return Ember.RSVP.hash({
        tasks: tasks,
        hasTasks: hasTasks,
        company: company
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});