define("front-end/models/tags/application-tag", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    slug: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    category: (0, _model.attr)('string'),
    official: (0, _model.attr)('boolean'),
    another: (0, _model.attr)('boolean'),
    namespace: (0, _model.attr)('string')
  });

  _exports.default = _default;
});