define("front-end/helpers/href-to", ["exports", "ember-href-to/helpers/href-to"], function (_exports, _hrefTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hrefTo.default;
    }
  });
  Object.defineProperty(_exports, "hrefTo", {
    enumerable: true,
    get: function get() {
      return _hrefTo.hrefTo;
    }
  });
});