define("front-end/routes/dashboard/estimates/index", ["exports", "front-end/constants", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-websocket-toaster-message"], function (_exports, _constants, _routeWebsocketHandlerMixin, _routeWebsocketToasterMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, _routeWebsocketToasterMessage.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        sortBy: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread({}, queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var controller = this.controllerFor('dashboard.estimates.index');
      var company = this.modelFor('dashboard').company;
      var filterQueryOptions = {
        filter: {
          status: _constants.ESTIMATE_STATUS.NOT_ANSWERED,
          'manufacturer': company.id
        },
        include: 'estimate-survey.batch,estimate-survey.partRevision.editedPdfDesign',
        fields: {
          'part_revision': 'edited_pdf_design,edited_two_dimensional_design',
          'estimate_survey': 'batch,part_revision',
          'estimates': 'my_id,timeout_deadline,price,status,estimate_survey,from_manufacturer_freight_responsible',
          'batches': 'quantity,raw_material_responsible_m,delivery_city_m,payment_method_m'
        }
      };

      if (params.sortBy) {
        filterQueryOptions['sort'] = params.sortBy;
      }

      if (params.status) {
        filterQueryOptions.filter['status'] = params.status;
      }

      if (params.myId) {
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      var queryOptions = _objectSpread({
        page: {
          size: controller.size,
          number: controller.page
        }
      }, filterQueryOptions);

      var estimates = this.store.query('estimate', queryOptions);
      var estimatesCount = this.store.query('estimate', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          'manufacturer': company.id,
          'status.in': [_constants.ESTIMATE_STATUS.NEGOTIATION, _constants.ESTIMATE_STATUS.NOT_ANSWERED]
        }
      }).then(function (estimates) {
        return estimates.get('meta.count');
      });
      var hasEstimates = this.store.query('estimate', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (estimates) {
        return estimates.get('meta.count') > 0;
      });
      return Ember.RSVP.hash({
        estimates: estimates,
        company: company,
        hasEstimates: hasEstimates,
        estimatesCount: estimatesCount
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('showLoader', false);
      controller.set('refreshedByFilter', false);
      model.estimates.forEach(function (estimate) {
        controller.estimatesArray.pushObject(estimate);
      });

      if (!controller.status) {
        controller.set('status', _constants.ESTIMATE_STATUS.NOT_ANSWERED);
      }

      if (!controller.sortBy) {
        controller.set('sortBy', 'timeout_deadline');
      }
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();

        controller.set('estimatesArray', []);
        controller.set('refreshedByFilter', false);
        controller.set('size', controller.size);
        controller.set('page', controller.defaultPage);
      }

      this._super.apply(this, arguments);
    },
    _toasterClickHandler: function _toasterClickHandler() {
      var controller = this.controller;
      Ember.setProperties(controller, {
        page: controller.defaultPage,
        size: controller.size,
        estimatesArray: []
      });
    },
    websocketResourceHandler: function websocketResourceHandler(record, type) {
      if (type == 'estimates') {
        var currentCompany = this.modelFor('dashboard').company;

        if (record.get('manufacturer.id') == currentCompany.get('id')) {
          // Action implemented by RouteWebsocketToasterMessage mixin
          this.send('showToasterForNewMessages', 'Você pode ter novos orçamentos. Recarregue a página.');
        }
      }
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      },
      websocketToasterRefresh: function websocketToasterRefresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});