define("front-end/components/input-filter-debounce", ["exports", "front-end/config/environment", "front-end/mixins/component-masked-input"], function (_exports, _environment, _componentMaskedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentMaskedInput.default, {
    init: function init() {
      this._super.apply(this, arguments);

      if (_environment.default.environment == 'test') {
        this.debounce = 150;
      } else {
        this.debounce = 1000;
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('inputValue', this.queryParam);
    },
    _callCustomAction: function _callCustomAction(value) {
      this.onChangeAction(value);
    },
    actions: {
      waitDebounceToFilter: function waitDebounceToFilter(value) {
        this.set('inputValue', value);
        Ember.run.debounce(this, '_callCustomAction', value, this.debounce);
      }
    }
  });

  _exports.default = _default;
});