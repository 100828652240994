define("front-end/routes/dashboard/notifications", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.queryParams = _objectSpread({}, this.pageQueryParams);
    },
    model: function model(params) {
      var notificationsCount = this.store.query('notification', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (notifications) {
        return notifications.get('meta.count');
      });
      var notReadNotificationsCount = this.store.query('notification', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          'readAt.eq': 'NUL'
        }
      }).then(function (notifications) {
        return notifications.get('meta.count');
      });

      var queryOptions = _objectSpread({
        sort: '-created-at',
        include: 'notifiable'
      }, this.getPageQueryParams(params));

      var notifications = this.store.query('notification', queryOptions);
      return Ember.RSVP.hash({
        notifications: notifications,
        notificationsCount: notificationsCount,
        notReadNotificationsCount: notReadNotificationsCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});