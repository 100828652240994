define("front-end/templates/components/general-file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uJ2u07x2",
    "block": "{\"symbols\":[\"queue\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-start-center\"],[12],[2,\"\\n\"],[6,[37,5],[[35,6,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n      \"],[1,[30,[36,11],null,[[\"diameter\",\"data-test-upload-loader\"],[15,true]]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"file-upload-loader\"],[12],[2,\"Upload do \"],[1,[34,0]],[2,\" em andamento...\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,10],null,[[\"disabled\",\"name\",\"onfileadd\",\"data-test-upload-general-file\"],[[35,9],[35,8],[30,[36,7],[[35,6]],null],true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[14,\"data-test-update-label\",\"\"],[14,0,\"md-button md-default-theme md-primary md-raised\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[2,\"Atualizar\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[2,\"Upload\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"label\",\"file\"],[[35,2,[\"label\"]],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"small\"],[14,\"data-test-empty-file\",\"\"],[12],[2,\"Faça um upload do \"],[1,[34,0]],[2,\" \"],[10,\"b\"],[14,\"data-test-file-extensions\",\"\"],[14,0,\"accent\"],[12],[2,\"(\"],[1,[34,1]],[2,\")\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"label\",\"allowedExtensions\",\"file\",\"display-file-name\",\"stored\",\"if\",\"uploadGeneralFile\",\"perform\",\"name\",\"disabled\",\"file-upload\",\"paper-progress-circular\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/general-file-uploader.hbs"
    }
  });

  _exports.default = _default;
});