define("front-end/models/manufacturing-job", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/constants"], function (_exports, _model, _defaultModel, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    myId: (0, _model.attr)('string'),
    manufacturer: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    project: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    batch: (0, _model.belongsTo)('batch', {
      inverse: 'manufacturingJob'
    }),
    context: (0, _model.belongsTo)('batch-context', {
      inverse: null
    }),
    definingMechanism: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    }),
    estimateConfirmations: (0, _model.hasMany)('estimate-confirmation', {
      inverse: 'manufacturingJob'
    }),
    jobOfferProcessItems: (0, _model.hasMany)('job-offer-process-item', {
      inverse: 'manufacturingJob'
    }),
    // Denormalized relationships
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    hasDefiningMechanism: Ember.computed('definingMechanism', function () {
      return this.belongsTo('definingMechanism').id();
    }),
    defined: Ember.computed('status', function () {
      return this.status == _constants.MANUFACTURING_JOB_STATUS.DEFINED;
    }),
    statusReference: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.MANUFACTURING_JOB_STATUS.UNDEFINED:
          return 'undefined';

        case _constants.MANUFACTURING_JOB_STATUS.DEFINING:
          return 'defining';

        case _constants.MANUFACTURING_JOB_STATUS.DEFINED:
          return 'defined';

        case _constants.MANUFACTURING_JOB_STATUS.CANCELED:
          return 'canceled';

        default:
          return '';
      }
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.MANUFACTURING_JOB_STATUS.UNDEFINED:
          return 'notifications_active';

        case _constants.MANUFACTURING_JOB_STATUS.DEFINING:
          return 'person_search';

        case _constants.MANUFACTURING_JOB_STATUS.DEFINED:
          return 'thumb_up';

        case _constants.MANUFACTURING_JOB_STATUS.CANCELED:
          return 'cancel';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.MANUFACTURING_JOB_STATUS.UNDEFINED:
          return 'warning';

        case _constants.MANUFACTURING_JOB_STATUS.DEFINING:
          return 'info';

        case _constants.MANUFACTURING_JOB_STATUS.DEFINED:
          return 'success';

        case _constants.MANUFACTURING_JOB_STATUS.CANCELED:
          return 'error';

        default:
          return '';
      }
    }),
    _translateOption: function _translateOption(option) {
      if (option == 'client') {
        return 'Cliente';
      }

      if (option == 'manufacturer') {
        return 'Fornecedor';
      }

      if (option == 'peerdustry') {
        return 'Peerdustry';
      }
    },
    route: function route() {
      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var projectId = this.belongsTo('project').id();
      var batchId = this.belongsTo('batch').id();
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          return Ember.RSVP.resolve(['admin.projects.show.batch.defining-manufacturer', projectId, batchId]);
        } else {
          // return not null elements of the below array
          return ['dashboard.jobs.show', companyId, batchId].filter(function (x) {
            return x;
          });
        }
      });
    }
  });

  _exports.default = _default;
});