define("front-end/templates/components/estimates-summaries-over-time-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jd1lJInV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"canvas\"],[14,\"data-test-estimates-summaries-over-time-chart\",\"\"],[14,1,\"estimates-summaries-over-time-chart\"],[15,\"height\",[34,0]],[14,\"width\",\"auto\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"height\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimates-summaries-over-time-chart.hbs"
    }
  });

  _exports.default = _default;
});