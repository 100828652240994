define("front-end/templates/components/batch-features-for-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nm5n2wrr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"batch-summary layout-row layout-align-space-around-center layout-wrap\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"data-test-payment-method\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"credit_card\"],null]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[35,3,[\"paymentMethod\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,\"data-test-delivery-city\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"place\"],null]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[35,3,[\"deliveryCity\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"data-test-raw-material-responsible\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"category\"],null]],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,2],[[35,1,[\"rawMaterialResponsible\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"referenceModel\",\"humanized-responsible\",\"batch\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/batch-features-for-admin.hbs"
    }
  });

  _exports.default = _default;
});