define("front-end/routes/admin/estimates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      // TODO: remove abortedEstimateAnswers from include in the future
      return this.store.query('estimate', {
        filter: {
          id: params.estimate_id
        },
        include: 'estimateSurvey.quotation,manufacturer,estimateSurvey.batch,estimateSurvey.project,estimateSurvey.company,estimateSurvey.part,estimateSurvey.partRevision.editedPdfDesign,estimateSurvey.partRevision.twoDimensionalDesign,estimateSurvey.partRevision.threeDimensionalDesign,estimateChat,abortedEstimateAnswers.company'
      }).then(function (estimates) {
        if (!estimates.length) {
          return Ember.RSVP.reject({
            errors: [{
              status: 404
            }]
          });
        }

        return Ember.RSVP.hash({
          estimates: estimates,
          estimate: estimates.get('firstObject'),
          survey: estimates.get('firstObject.estimateSurvey')
        });
      });
    },
    resetController: function resetController(controller) {
      controller.set('showChat', false);

      this._super.apply(this, arguments);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});