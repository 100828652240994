define("front-end/routes/dashboard/tasks/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var company = this.modelFor('dashboard').company;
      var task = this.store.findRecord('tasks/task', params.task_id);
      var closedBy = task.then(function (task) {
        return task.get('closedBy');
      });
      var requestor = task.then(function (task) {
        return task.get('requestor');
      });
      return Ember.RSVP.hash({
        company: company,
        task: task,
        requestor: requestor,
        closedBy: closedBy
      });
    }
  });

  _exports.default = _default;
});