define("front-end/models/batch-context", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    quantity: (0, _model.attr)('number'),
    material: (0, _model.attr)('string'),
    partName: (0, _model.attr)('string'),
    partMyId: (0, _model.attr)('string'),
    partInternalCode: (0, _model.attr)('string'),
    fee: (0, _model.attr)('number'),
    tax: (0, _model.attr)('number'),
    buyUnit: (0, _model.attr)('string'),
    price: (0, _model.attr)('pt-currency'),
    unitPrice: (0, _model.attr)('pt-currency'),
    clientPaymentMethod: (0, _model.attr)('string'),
    plannedDeliveryDate: (0, _model.attr)('string'),
    commercialDaysAmount: (0, _model.attr)('number'),
    rawMaterialCost: (0, _model.attr)('pt-currency'),
    rawMaterialUnitCost: (0, _model.attr)('pt-currency'),
    clientAddress: (0, _model.attr)('string'),
    manufacturerAddress: (0, _model.attr)('string'),
    noteFromClient: (0, _model.attr)('string'),
    noteForManufacturer: (0, _model.attr)('string'),
    deliveryCity: (0, _model.attr)('string'),
    logisticStrategy: (0, _model.attr)('string'),
    distributionCenterAddress: (0, _model.attr)('string'),
    toClientFreightResponsible: (0, _model.attr)('string'),
    fromManufacturerFreightResponsible: (0, _model.attr)('string'),
    rawMaterialFormat: (0, _model.attr)('string'),
    rawMaterialResponsible: (0, _model.attr)('string'),
    manufacturingCost: (0, _model.attr)('pt-currency'),
    manufacturingUnitCost: (0, _model.attr)('pt-currency'),
    manufacturerPaymentMethod: (0, _model.attr)('string'),
    clientCategory: (0, _model.attr)('number'),
    batch: (0, _model.belongsTo)('batch', {
      inverse: 'context'
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    manufacturer: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    rawMaterialDelivery: (0, _model.belongsTo)('raw-material-delivery', {
      inverse: 'context'
    }),
    manufacturerDelivery: (0, _model.belongsTo)('manufacturer-delivery', {
      inverse: 'context'
    }),
    humanizedMaterial: Ember.computed('material', function () {
      return this.material || "Conforme desenho";
    })
  });

  _exports.default = _default;
});