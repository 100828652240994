define("front-end/components/paper-pikaday", ["exports", "ember-paper-pikaday/components/paper-pikaday"], function (_exports, _paperPikaday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paperPikaday.default;
    }
  });
});