define("front-end/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: null,
    company: null,
    setCompany: function setCompany(company) {
      this.set('company', company);
    },
    unload: function unload() {
      var _this = this;

      return this.session.invalidate().then(function () {
        _this.set('user', null);

        _this.set('company', null);

        return;
      });
    },
    load: function load() {
      var _this2 = this;

      if (this.session.isAuthenticated) {
        if (this.user) {
          return Ember.RSVP.resolve(this.user);
        } else {
          return this.store.queryRecord('user', {
            me: true
          }).then(function (user) {
            _this2.set('user', user);

            return user;
          });
        }
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });

  _exports.default = _default;
});