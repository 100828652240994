define("front-end/routes/dashboard/quotations/history", ["exports", "front-end/constants", "front-end/mixins/route-pagination-mixin"], function (_exports, _constants, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        partName: {
          refreshModel: true
        },
        deadlineGte: {
          refreshModel: true
        },
        deadlineLte: {
          refreshModel: true
        },
        projectName: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;
      var filterQueryOptions = {
        filter: {
          company_id: company.get('id'),
          status: [_constants.QUOTATION_STATUS.ACCEPTED, _constants.QUOTATION_STATUS.DECLINED, _constants.QUOTATION_STATUS.CLOSED, _constants.QUOTATION_STATUS.TIMEOUT]
        },
        sort: '-created-at',
        include: 'selected-pricing-process,batch.partRevision.part,batch.project',
        fields: {
          'quotations': 'selected_pricing_process,my_id,unit_price,price,status,days_amount,batch',
          'batches': 'quantity,payment_method,quotation_deadline,project,part_revision',
          'projects': 'name',
          'part-revisions': 'part',
          'part': 'name,internal_code'
        }
      };

      if (params.myId) {
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      if (params.status) {
        filterQueryOptions.filter['status'] = params.status;
      }

      if (params.partName) {
        filterQueryOptions.filter['part__name.match'] = params.partName;
      }

      if (params.deadlineLte) {
        filterQueryOptions.filter['deadline.lte'] = params.deadlineLte;
      }

      if (params.deadlineGte) {
        filterQueryOptions.filter['deadline.gte'] = params.deadlineGte;
      }

      if (params.projectName) {
        filterQueryOptions.filter['project__name.match'] = params.projectName;
      }

      if (params.internalCode) {
        filterQueryOptions.filter['part__internalCode.match'] = params.internalCode;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var quotations = this.store.query('quotation', queryOptions);
      var quotationsCount = this.store.query('quotation', {
        filter: {
          company_id: company.get('id'),
          status: [_constants.QUOTATION_STATUS.ACCEPTED, _constants.QUOTATION_STATUS.DECLINED, _constants.QUOTATION_STATUS.CLOSED, _constants.QUOTATION_STATUS.TIMEOUT]
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (quotations) {
        return quotations.get('meta.count');
      });
      return Ember.RSVP.hash({
        quotations: quotations,
        quotationsCount: quotationsCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();

        controller.set('refreshedByFilter', false);
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});