define("front-end/routes/helpers/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orderAlphabetically = orderAlphabetically;

  function orderAlphabetically(array, propName) {
    return array.sort(function (a, b) {
      var firstString = a.get(propName).toLowerCase();
      var secondString = b.get(propName).toLowerCase();
      return firstString.localeCompare(secondString);
    });
  }
});