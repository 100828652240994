define("front-end/templates/dashboard/parts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/go6R8B",
    "block": "{\"symbols\":[],\"statements\":[[8,\"create-new-parts\",[],[[\"@onCancel\",\"@afterSave\"],[[30,[36,0],[[32,0],\"onCancelPart\"],null],[30,[36,0],[[32,0],\"afterSavePart\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "front-end/templates/dashboard/parts/new.hbs"
    }
  });

  _exports.default = _default;
});