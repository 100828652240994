define("front-end/components/ui/freight-strategy-help-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <HelpButton data-test-help-freight-strategy
  @side        = 'left'
  @mini        = {{false}}
  @helpFor     = 'moderate-freight-strategy'
  @tooltipText = 'Clique para entender melhor as opções de estratégia de frete'
  @title       = "Estratégia de frete">
    <div class="freight-strategy-tooltip">
      <p>Escolhendo a opção CIF o fornecedor será responsavel pelo frete da peça</p>
      <p>Escolhendo a opção FOB a Peerdustry será responsavel pelo frete da peça</p>
      <p>Escolhendo a opção CIF ou FOB o fornecedor poderá escolher o opção de frete que deseja, lembrando que para essa opção estar disponível precisamos ter logística disponivel para o parceiro escolhido (caso contrário CIF será escolhido automaticamente)</p>
    </div>
  </HelpButton>
  */
  {
    id: "+0Z5MwU6",
    block: "{\"symbols\":[],\"statements\":[[8,\"help-button\",[[24,\"data-test-help-freight-strategy\",\"\"]],[[\"@side\",\"@mini\",\"@helpFor\",\"@tooltipText\",\"@title\"],[\"left\",false,\"moderate-freight-strategy\",\"Clique para entender melhor as op\xE7\xF5es de estrat\xE9gia de frete\",\"Estrat\xE9gia de frete\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"freight-strategy-tooltip\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Escolhendo a op\xE7\xE3o CIF o fornecedor ser\xE1 responsavel pelo frete da pe\xE7a\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Escolhendo a op\xE7\xE3o FOB a Peerdustry ser\xE1 responsavel pelo frete da pe\xE7a\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Escolhendo a op\xE7\xE3o CIF ou FOB o fornecedor poder\xE1 escolher o op\xE7\xE3o de frete que deseja, lembrando que para essa op\xE7\xE3o estar dispon\xEDvel precisamos ter log\xEDstica disponivel para o parceiro escolhido (caso contr\xE1rio CIF ser\xE1 escolhido automaticamente)\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "front-end/components/ui/freight-strategy-help-message.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});