define("front-end/models/pricing-process", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/models/helpers/util"], function (_exports, _model, _defaultModel, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    myId: (0, _model.attr)('string'),
    status: (0, _model.attr)('number'),
    message: (0, _model.attr)('string'),
    statusName: (0, _model.attr)('string'),
    finalStatus: (0, _model.attr)('boolean'),
    statusMessage: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    price: (0, _model.attr)('pt-currency'),
    unitPrice: (0, _model.attr)('pt-currency'),
    rawMaterialCost: (0, _model.attr)('pt-currency'),
    rawMaterialUnitCost: (0, _model.attr)('pt-currency'),
    manufacturingCost: (0, _model.attr)('pt-currency'),
    rawMaterialResponsible: (0, _model.attr)('string'),
    manufacturingUnitCost: (0, _model.attr)('pt-currency'),
    material: (0, _model.attr)('string'),
    fee: (0, _model.attr)('number'),
    tax: (0, _model.attr)('number'),
    commercialDaysAmount: (0, _model.attr)('number'),
    manufacturingDaysAmount: (0, _model.attr)('number'),
    concludedAt: (0, _model.attr)('string'),
    conclusionTime: (0, _model.attr)('string'),
    // From Batch
    quantity: (0, _model.attr)('number'),
    // From Quotation
    deadline: (0, _model.attr)('string'),
    // Denormalized relationships
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    batch: (0, _model.belongsTo)('batch', {
      inverse: null
    }),
    project: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    readableConclusionTime: Ember.computed('conclusionTime', function () {
      var conclusionTime = this.conclusionTime;
      return (0, _util.readableDuration)(conclusionTime);
    }),
    rawMaterialFromPeerdustry: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return option == 'peerdustry';
    }),
    rawMaterialResponsibleToManufacturer: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;

      if (option == 'manufacturer') {
        return 'Fornecedor';
      }

      if (option == 'client' || option == 'peerdustry') {
        return 'Peerdustry';
      }

      return false;
    }),
    isResale: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return option == 'manufacturer';
    }),
    _translateOption: function _translateOption(option) {
      if (option == 'client') {
        return 'Cliente';
      }

      if (option == 'manufacturer') {
        return 'Fornecedor';
      }

      if (option == 'peerdustry') {
        return 'Peerdustry';
      }
    },
    route: function route() {
      var projectId = this.belongsTo('project').id();
      var batchId = this.belongsTo('batch').id();
      return Ember.RSVP.resolve(['admin.projects.show.batch.quotation', projectId, batchId]);
    }
  });

  _exports.default = _default;
});