define("front-end/mixins/route-error-handler", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toasterMessages: Ember.inject.service(),
    parentRoute: '',
    actions: {
      error: function error(_error) {
        try {
          var errorHandler = _error.errors[0];
          var status = parseInt(errorHandler.status); // Set 'no-title' for front-end injected error in show pages with store.query

          var title = errorHandler.title || 'no-title';
          var parentRoute = this.parentRoute ? "".concat(this.parentRoute, ".") : '';

          if (status === 403) {
            this.transitionTo("".concat(parentRoute, "forbidden"));
          } else if (status === 404) {
            if (title.includes('Company') && parentRoute.includes('dashboard')) {
              this.transitionTo('not-found', 404);
            } else {
              this.transitionTo("".concat(parentRoute, "not-found"), 404);
            }
          } else if (status === 401) {
            this.toasterMessages.showErrors(['Você foi desconectado. Faça login novamente']);
            this.transitionTo('logout');
          } else if (status >= 500) {
            this.transitionTo("service-unavailable");
          }
        } catch (e) {
          if (_environment.default.environment == 'development') {
            console.error('Entered catch block in route-error-handler: ', _error); // eslint-disable-line no-console
          }
        }
      }
    }
  });

  _exports.default = _default;
});