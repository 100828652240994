define("front-end/routes/admin/quotations/index", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    currentUser: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        sortBy: {
          refreshModel: true
        },
        quotationStatus: {
          refreshModel: true
        },
        batchStatus: {
          refreshModel: true
        },
        partName: {
          refreshModel: true
        },
        statusIn: {
          refreshModel: true
        },
        company: {
          refreshModel: true
        },
        createdAtLte: {
          refreshModel: true
        },
        createdAtGte: {
          refreshModel: true
        },
        sentToClientAtLte: {
          refreshModel: true
        },
        sentToClientAtGte: {
          refreshModel: true
        },
        concludedAtLte: {
          refreshModel: true
        },
        concludedAtGte: {
          refreshModel: true
        },
        deadlineLte: {
          refreshModel: true
        },
        deadlineGte: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        },
        clientCategory: {
          refreshModel: true
        },
        minAnsweredEstimates: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var user = this.currentUser.load();
      var filterQueryOptions = {
        filter: {},
        sort: '-created-at',
        include: 'company,supervised-quote,estimate-survey.estimates.manufacturer,batch.project,part,partRevision.pdfDesign,partRevision.editedPdfDesign,company,context,batch.purchase-order-item.purchase-order',
        fields: {
          'company': 'name,fantasy_name,cnpj',
          'estimate': 'status,status_name,status_message,estimate_survey,manufacturer',
          'part': 'name,my_id,internal_code',
          'part-revision': 'pdf_design,edited_pdf_design',
          'files/edited-pdf-design': 'url_for_file,url_for_thumb,stored,resource',
          'files/pdf-design': 'url_for_file,url_for_thumb,stored,resource',
          'project': 'name'
        }
      };

      if (params.sortBy) {
        filterQueryOptions.sort = params.sortBy;
      }

      if (params.myId) {
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      if (params.quotationStatus) {
        filterQueryOptions.filter['status'] = params.quotationStatus;
      }

      if (params.batchStatus) {
        filterQueryOptions.filter['batch__status'] = params.batchStatus;
      }

      if (params.statusIn) {
        var status = params.statusIn.split(',');
        filterQueryOptions.filter['status.in'] = status;
      }

      if (params.deadlineLte) {
        filterQueryOptions.filter['deadline.lte'] = params.deadlineLte;
      }

      if (params.deadlineGte) {
        filterQueryOptions.filter['deadline.gte'] = params.deadlineGte;
      }

      if (params.createdAtLte) {
        filterQueryOptions.filter['created_at.lte'] = params.createdAtLte;
      }

      if (params.createdAtGte) {
        filterQueryOptions.filter['created_at.gte'] = params.createdAtGte;
      }

      if (params.sentToClientAtLte) {
        filterQueryOptions.filter['sent_to_client_at.lte'] = params.sentToClientAtLte;
      }

      if (params.sentToClientAtGte) {
        filterQueryOptions.filter['sent_to_client_at.gte'] = params.sentToClientAtGte;
      }

      if (params.concludedAtLte) {
        filterQueryOptions.filter['concluded_at.lte'] = params.concludedAtLte;
      }

      if (params.concludedAtGte) {
        filterQueryOptions.filter['concluded_at.gte'] = params.concludedAtGte;
      }

      if (params.company) {
        filterQueryOptions.filter['company_id'] = params.company;
      }

      if (params.partName) {
        filterQueryOptions.filter['part__name.match'] = params.partName;
      }

      if (params.internalCode) {
        filterQueryOptions.filter['part__internalCode.match'] = params.internalCode;
      }

      if (params.clientCategory) {
        filterQueryOptions.filter['client_category'] = params.clientCategory;
      }

      if (params.minAnsweredEstimates) {
        filterQueryOptions.filter['min_answered_estimates'] = params.minAnsweredEstimates;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var quotations = this.store.query('quotation', queryOptions);
      var quotationsCount = this.store.query('quotation', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (quotations) {
        return quotations.get('meta.count');
      });
      return Ember.RSVP.hash({
        user: user,
        quotations: quotations,
        quotationsCount: quotationsCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
      var quotationStatusSelected = controller.quotationStatusOptions.find(function (option) {
        return controller.quotationStatus == option.value;
      });
      var batchStatusSelected = controller.batchStatusOptions.find(function (option) {
        return controller.batchStatus == option.value;
      });
      controller.set('quotationStatusSelected', quotationStatusSelected);
      controller.set('batchStatusSelected', batchStatusSelected);
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();

        controller.set('refreshedByFilter', false);
      }
    },
    actions: {
      refreshTable: function refreshTable() {
        this.controller.set('page', 1);
        this.set('refreshedByFilter', true);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});