define("front-end/models/tags/tax-regime", ["exports", "front-end/models/tags/application-tag"], function (_exports, _applicationTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationTag.default.extend({});

  _exports.default = _default;
});