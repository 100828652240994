define("front-end/models/estimate-confirmation", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/constants", "front-end/models/helpers/util"], function (_exports, _model, _defaultModel, _constants, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    type: 'estimate-confirmation',
    humanizedType: 'Confirmação de Orçamento',
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    availableAt: (0, _model.attr)('string'),
    concludedAt: (0, _model.attr)('string'),
    conclusionTime: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    message: (0, _model.attr)('string'),
    deadline: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    unitPrice: (0, _model.attr)('number'),
    plannedDeliveryDate: (0, _model.attr)('string'),
    fee: (0, _model.attr)('number'),
    tax: (0, _model.attr)('number'),
    rawMaterialCost: (0, _model.attr)('number'),
    rawMaterialUnitCost: (0, _model.attr)('number'),
    rawMaterialResponsible: (0, _model.attr)('string'),
    // raw material delivery info
    rawMaterialDeliveryDaysAmount: (0, _model.attr)('number'),
    rawMaterialDeliveryDate: (0, _model.attr)('string'),
    trackRawMaterialDelivery: (0, _model.attr)('boolean'),
    manufacturingCost: (0, _model.attr)('number'),
    manufacturingUnitCost: (0, _model.attr)('number'),
    manufacturingDaysAmount: (0, _model.attr)('number'),
    manufacturerDeliveryDate: (0, _model.attr)('deadline'),
    requireMessage: (0, _model.attr)('boolean'),
    allowCancel: (0, _model.attr)('boolean'),
    batch: (0, _model.belongsTo)('batch', {
      inverse: null
    }),
    context: (0, _model.belongsTo)('batch-context', {
      inverse: null
    }),
    project: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    manufacturingJob: (0, _model.belongsTo)('manufacturing-job', {
      inverse: 'estimateConfirmations'
    }),
    estimate: (0, _model.belongsTo)('estimate', {
      inverse: 'estimateConfirmation'
    }),
    estimateSurvey: (0, _model.belongsTo)('estimate-survey', {
      inverse: null
    }),
    manufacturer: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    isAvailable: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE;
    }),
    isCreating: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_CONFIRMATION_STATUS.CREATING;
    }),
    isResale: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return option == 'manufacturer';
    }),
    statusReference: Ember.computed('status', 'isDefined', function () {
      switch (this.status) {
        case _constants.ESTIMATE_CONFIRMATION_STATUS.CREATING:
          return 'creating';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE:
          return 'available';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CONFIRMED:
          return 'confirmed';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.REJECTED:
          return 'rejected';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.TIMEOUT:
          return 'timeout';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CANCELED:
          return 'canceled';

        default:
          return '';
      }
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_CONFIRMATION_STATUS.CREATING:
          return 'create';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE:
          return 'timer';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CONFIRMED:
          return 'thumb_up';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.REJECTED:
          return 'thumb_down';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.TIMEOUT:
          return 'timer_off';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CANCELED:
          return 'cancel';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_CONFIRMATION_STATUS.CREATING:
          return 'warning';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE:
          return 'info';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CONFIRMED:
          return 'success';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.REJECTED:
          return 'error';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.TIMEOUT:
          return 'error';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CANCELED:
          return 'error';

        default:
          return '';
      }
    }),
    readableStatus: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_CONFIRMATION_STATUS.CREATING:
          return 'Em criação';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE:
          return 'Disponível';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CONFIRMED:
          return 'Confirmado';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.REJECTED:
          return 'Declinado';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.TIMEOUT:
          return 'Expirado';

        case _constants.ESTIMATE_CONFIRMATION_STATUS.CANCELED:
          return 'Cancelado';

        default:
          return '';
      }
    }),
    computedManufacturerDeliveryDate: Ember.computed('manufacturingDaysAmount', 'manufacturerDeliveryDate', function () {
      if (this.manufacturerDeliveryDate) return this.manufacturerDeliveryDate;
      return (0, _util.computeAdditionalDays)(this.manufacturingDaysAmount);
    }),
    route: function route() {
      var _this = this;

      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          var projectId = _this.belongsTo('project').id();

          var batchId = _this.belongsTo('batch').id();

          return ['admin.projects.show.batch.defining-manufacturer', projectId, batchId].filter(function (x) {
            return x;
          });
        } else {
          if (_this.isAvailable) {
            return ['dashboard.job-offers', companyId].filter(function (x) {
              return x;
            });
          } else {
            var estimateId = _this.belongsTo('estimate').id();

            return ['dashboard.estimates.show', companyId, estimateId].filter(function (x) {
              return x;
            });
          }
        }
      });
    }
  });

  _exports.default = _default;
});