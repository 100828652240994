define("front-end/templates/components/notification-center-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SLdOqoPu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[35,0,[\"icon\"]]],null]],[2,\"\\n  \"],[10,\"p\"],[14,\"data-test-notification-message\",\"\"],[14,0,\"notification-message\"],[12],[2,\"\\n    \"],[1,[35,0,[\"subject\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"layout-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[13],[2,\"\\n  \"],[10,\"p\"],[14,\"data-test-notification-date\",\"\"],[12],[1,[30,[36,3],[[35,2],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"paper-icon\",\"notificationDate\",\"moment-format\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/notification-center-line.hbs"
    }
  });

  _exports.default = _default;
});