define("front-end/models/supervised-quote", ["exports", "@ember-data/model", "front-end/models/pricing-process", "front-end/constants"], function (_exports, _model, _pricingProcess, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pricingProcess.default.extend({
    currentUser: Ember.inject.service(),
    type: 'supervised-quote',
    typeName: 'Precificação Supervisionada',
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    // Must be implemented by a child of PricingProcess Class
    quotation: (0, _model.belongsTo)('quotation'),
    statusReference: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.SUPERVISED_QUOTE_STATUS.ESTIMATING:
          return 'estimating';

        case _constants.SUPERVISED_QUOTE_STATUS.COMPLETED:
          return 'completed';

        case _constants.SUPERVISED_QUOTE_STATUS.CLOSED:
          return 'closed';

        default:
          return 'negotiation';
      }
    }),
    isNegotiating: Ember.computed('status', function () {
      return this.status == _constants.SUPERVISED_QUOTE_STATUS.NEGOTIATION;
    }),
    isCompleted: Ember.computed('status', function () {
      return this.status == _constants.SUPERVISED_QUOTE_STATUS.COMPLETED;
    }),
    isClosed: Ember.computed('status', function () {
      return this.status == _constants.SUPERVISED_QUOTE_STATUS.CLOSED;
    }),
    beforeNegotiation: Ember.computed('status', function () {
      return this.status < _constants.SUPERVISED_QUOTE_STATUS.NEGOTIATION;
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.SUPERVISED_QUOTE_STATUS.ESTIMATING:
          return 'pending_actions';

        case _constants.SUPERVISED_QUOTE_STATUS.COMPLETED:
          return 'check_circle';

        case _constants.SUPERVISED_QUOTE_STATUS.CLOSED:
          return 'cancel';

        default:
          return 'attach_money';
      }
    }),
    level: Ember.computed('isDefined', 'status', function () {
      if (this.isDefined) {
        return 'info';
      } else {
        switch (this.status) {
          case _constants.SUPERVISED_QUOTE_STATUS.CLOSED:
            return 'error';

          default:
            return 'info';
        }
      }
    }),
    batchStatusMessage: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.SUPERVISED_QUOTE_STATUS.NEGOTIATION:
          return 'Em negociação';

        case _constants.SUPERVISED_QUOTE_STATUS.COMPLETED:
          return 'Completo';

        case _constants.SUPERVISED_QUOTE_STATUS.CLOSED:
          return 'Cancelado';

        default:
          return 'Em cotação';
      }
    })
  });

  _exports.default = _default;
});