define("front-end/mixins/route-filter-loader", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      loading: function loading(transition, originRoute) {
        var controller;
        controller = this.controllerFor(originRoute.get('fullRouteName'));

        if (controller.refreshedByFilter) {
          controller.set('loadingModel', true);
          transition.promise.finally(function () {
            controller.set('loadingModel', false);
            var elementToScroll = _environment.default.environment == 'test' ? document.querySelector('#ember-testing-container') : window;
            elementToScroll.scroll(0, 0);
          });
        } else {
          controller = this.controllerFor('application');
          controller.set('showLoader', true);
          transition.promise.finally(function () {
            controller.set('showLoader', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});