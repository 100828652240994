define("front-end/mixins/controller-pagination-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    paginationService: Ember.inject.service(),
    paginatedModelName: '',
    // To be implemented by caller
    defaultSizeValue: Ember.computed.reads('paginationService.defaultSizeValue'),
    pageQueryParams: ['page', 'size'],
    init: function init() {
      this._super.apply(this, arguments);

      this.page = 1;
      this.sizeOptions = [5, 10, 15, 20, 50, 100];
      this.size = this.paginationService.defaultSizeValue;
    },
    actions: {
      changePage: function changePage(page) {
        this.setProperties({
          page: page,
          refreshedByFilter: true
        });
      },
      changeSize: function changeSize(size) {
        this.set('paginationService.size', size);
        this.setProperties({
          size: size,
          page: 1,
          refreshedByFilter: true
        });
      },
      pageUp: function pageUp() {
        var page = this.page;
        var maxPage = this.get("".concat(this.paginatedModelName, ".meta.page-count"));

        if (page < maxPage) {
          this.incrementProperty('page');
          this.set('refreshedByFilter', true);
        }
      },
      pageDown: function pageDown() {
        if (this.page > 1) {
          this.decrementProperty('page');
          this.set('refreshedByFilter', true);
        }
      }
    }
  });

  _exports.default = _default;
});