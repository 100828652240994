define("front-end/controllers/admin/companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    size: 10,
    selectCompanies: false,
    init: function init() {
      this._super.apply(this, arguments);

      this._resetFilters();

      var queryParams = ['page', 'size', 'cnpj', 'sort', 'company', 'calibrationProceduresFilter', 'experimental', 'isClient', 'clientCategory', 'isManufacturer', 'inbound', 'availableLogistic', 'taxProfileAnswered', 'manufacturerPreHomologated', 'manufacturerFirstMachineRegistered', 'manufacturerProfileDisabled', 'manufacturerProfileHomologated', 'manufacturerPartSizesFilter', 'manufacturerManufacturingWorkflowsFilter', 'manufacturerSpecialtiesFilter', 'manufacturerProfileBlockedByAdmin', 'stockManagementProcessesFilter', 'qualityAssuranceProcessesFilter', 'manufacturerQuotationProcessesFilter', 'measureInstrumentsFilter', 'manufacturerServicesFilter', 'manufacturerPartTypesFilter', 'recurringRawMaterialsFilter', 'manufacturerPartWeightsFilter', 'manufacturerQualityCertificatesFilter', 'companiesGroupFilter', 'machineTypeFilter'];
      this.queryParams = [].concat(queryParams);
    },
    _resetFilters: function _resetFilters() {
      // belongsTo
      this.set('cnpj', '');
      this.set("sort", '');
      this.set("company", '');
      this.set("calibrationProceduresFilter", '');
      this.set("manufacturerPartSizesFilter", '');
      this.set("manufacturerManufacturingWorkflowsFilter", '');
      this.set("manufacturerSpecialtiesFilter", '');
      this.set("stockManagementProcessesFilter", '');
      this.set("qualityAssuranceProcessesFilter", '');
      this.set("manufacturerQuotationProcessesFilter", '');
      this.set("isClient", undefined);
      this.set("experimental", undefined);
      this.set("clientCategory", undefined);
      this.set("isManufacturer", undefined);
      this.set('inbound', undefined);
      this.set("availableLogistic", undefined);
      this.set("taxProfileAnswered", undefined);
      this.set("manufacturerProfileDisabled", undefined);
      this.set("manufacturerPreHomologated", undefined);
      this.set("manufacturerFirstMachineRegistered", undefined);
      this.set("manufacturerProfileHomologated", undefined); // hasMany

      this.set("measureInstrumentsFilter", []);
      this.set("manufacturerServicesFilter", []);
      this.set("manufacturerPartTypesFilter", []);
      this.set("recurringRawMaterialsFilter", []);
      this.set("manufacturerPartWeightsFilter", []);
      this.set("manufacturerQualityCertificatesFilter", []);
      this.set("companiesGroupFilter", []);
      this.set("machineTypeFilter", []);
    }
  });

  _exports.default = _default;
});