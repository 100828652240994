define("front-end/routes/admin/notifications", ["exports", "front-end/routes/dashboard/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notifications.default.extend({
    templateName: 'dashboard/notifications'
  });

  _exports.default = _default;
});