define("front-end/routes/admin/projects/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.queryParams = {
        cnpj: {
          refreshModel: true
        },
        searchText: {
          refreshModel: true
        }
      };
    },
    model: function model(params) {
      var project = this.store.query('project', {
        filter: {
          id: params.project_id
        },
        include: 'company,projectSummary,creator,status_name'
      }).then(function (projects) {
        if (!projects.length) {
          return Ember.RSVP.reject({
            errors: [{
              status: 404
            }]
          });
        }

        return projects.get('firstObject');
      });
      return Ember.RSVP.hash({
        project: project
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});