define("front-end/templates/components/textbox-save/date-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tTUfofz+",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,8],null,[[\"isTouched\",\"errorMessage\",\"minDate\",\"prop\",\"model\",\"isRequired\",\"placeholder\",\"afterClear\",\"onChangeAction\",\"data-test-textbox-save-date-hour\"],[[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"modelSave\"],null],[30,[36,0],[[32,0],\"modelSave\"],null],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"placeholder\",\"isRequired\",\"model\",\"prop\",\"minDate\",\"errorMessage\",\"isTouched\",\"date-hour-input\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/textbox-save/date-hour.hbs"
    }
  });

  _exports.default = _default;
});