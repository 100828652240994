define("front-end/components/pipeline/item-formalizing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var batch = this.batch;
      this.set('quotationId', batch.belongsTo('quotation').id());
    },
    isSuperAdmin: Ember.computed.reads('currentUser.user.isSuperAdmin'),
    className: Ember.computed('batch.invisible', function () {
      return this.batch.invisible ? 'invisible' : '';
    }),
    actions: {
      rollbackBatch: function rollbackBatch() {
        var _this = this;

        this.modalsManager.prompt({
          disallowEmpty: true,
          title: 'Reverter cotação aceita',
          bodyComponent: 'custom-modals/rollback-quotation-prompt-body',
          footerComponent: 'custom-modals/default-actions'
        }).then(function (message) {
          var batch = _this.batch;

          var action = _this.store.createRecord('actions/rollback-accepted-quotation-action', {
            batch: batch,
            message: message
          });

          action.save().then(function () {
            _this.toasterMessages.showSuccess(['A cotação foi revertida']);
          });
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});