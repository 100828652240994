define("front-end/components/projects-table-filters", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedMyId: Ember.computed.reads('myId'),
    selectedSearchText: Ember.computed.reads('searchText'),
    init: function init() {
      this._super.apply(this, arguments);

      this.statusOptions = _constants.PROJECT_OPTIONS;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.status) {
        var statusSelected = this.statusOptions.find(function (option) {
          return option.value.toString() === _this.status.toString();
        });
        this.set('statusSelected', statusSelected);
      }
    },
    actions: {
      selectStatus: function selectStatus(status) {
        this.set('statusSelected', status);
        this.send('callApplyFilters', 'status', status.value);
      },
      callApplyFilters: function callApplyFilters(propName, propValue) {
        this.set(propName, propValue);
        this.applyFilters(propName, propValue);
      }
    }
  });

  _exports.default = _default;
});