define("front-end/routes/mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var params = this.paramsFor('mail');

      if (params.type == 'notification.notify') {
        this.transitionTo('mail.notification', params.notification_id);
      } else if (params.type == 'resources.show') {
        this.transitionTo('mail.resource', params.resource_type, params.resource_id);
      } else if (params.type == 'user.recovery_password') {
        if (this.session.isAuthenticated) {
          this.transitionTo('dashboard');
        } else {
          this.transitionTo('mail.recovery', params.token);
        }
      } else if (params.type == 'user.registration_confirmation') {
        if (this.session.isAuthenticated) {
          this.transitionTo('index');
          this.toasterMessages.showWarnings(['Não foi possível ativar o seu usuário. Você está logado em outra conta, faça o logout e tente novamente']);
        } else {
          this.transitionTo('mail.confirmation', params.token);
        }
      }
    }
  });

  _exports.default = _default;
});