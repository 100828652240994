define("front-end/templates/dashboard/tasks/show/part-revision-approval-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtK1gJSP",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front-end/templates/dashboard/tasks/show/part-revision-approval-task.hbs"
    }
  });

  _exports.default = _default;
});