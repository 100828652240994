define("front-end/components/notification-feed", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['notification-feed-tile'],
    _goToNotifiableRoute: function _goToNotifiableRoute(notification) {
      var _this = this;

      notification.get('notifiable').then(function (notifiable) {
        notifiable.route().then(function (route) {
          var _this$router;

          (_this$router = _this.router).transitionTo.apply(_this$router, _toConsumableArray(route));
        });
      });
    },
    actions: {
      markAsRead: function markAsRead(notification) {
        var _this2 = this;

        // Only set readAt for the first time
        if (!notification.get('readAt')) {
          notification.set('readAt', (0, _moment.default)().format());
          notification.save().then(function () {
            _this2._goToNotifiableRoute(notification);
          });
        } else {
          this._goToNotifiableRoute(notification);
        }
      }
    }
  });

  _exports.default = _default;
});