define("front-end/mirage/config/job-offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.get('/job-offer/:id');
    server.get('/job-offers', function (schema, request) {
      var jobs;
      var status = request.queryParams ? request.queryParams['filter[status]'] : '';

      if (status) {
        jobs = schema.jobOffers.where({
          status: status
        });
      } else {
        jobs = schema.jobOffers.all();
      }

      var json = this.serialize(jobs);
      json.meta = {
        'page-count': 1,
        count: jobs.length
      };
      return json;
    });
  }
});