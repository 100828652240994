define("front-end/controllers/admin/machines/index", ["exports", "front-end/mixins/controller-pagination-mixin", "ember-inflector"], function (_exports, _controllerPaginationMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    loadingModel: false,
    // This property is being set by filter-loader-mixin implemented by this *route*
    myId: '',
    machineType: '',
    machineModel: '',
    company: '',
    brand: '',
    cncManufacturer: '',
    automationLevel: '',
    axisAmount: '',
    rectifyingType: '',
    electroType: '',
    xTrackGte: '',
    xTrackLte: '',
    yTrackGte: '',
    yTrackLte: '',
    zTrackGte: '',
    zTrackLte: '',
    aTrackGte: '',
    aTrackLte: '',
    diameterGte: '',
    diameterLte: '',
    passageBusDiameterLte: '',
    passageBusDiameterGte: '',
    passageCarDiameterLte: '',
    passageCarDiameterGte: '',
    maxDiameterGte: '',
    maxDiameterLte: '',
    maxDiameterBusLte: '',
    maxDiameterBusGte: '',
    maxDiameterCarLte: '',
    maxDiameterCarGte: '',
    maxWeightLte: '',
    maxWeightGte: '',
    passageDiameterLte: '',
    passageDiameterGte: '',
    endpoint: Ember.computed('machineType', function () {
      if (this.machineType) {
        return "machines/".concat((0, _emberInflector.pluralize)(this.machineType));
      } else {
        return 'machines/machines';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.machines';
      var queryParams = ['myId', 'machineType', 'machineModel', 'company', 'brand', 'cncManufacturer', 'automationLevel', 'axisAmount', 'rectifyingType', 'electroType', 'xTrackGte', 'xTrackLte', 'yTrackGte', 'yTrackLte', 'zTrackGte', 'zTrackLte', 'aTrackGte', 'aTrackLte', 'diameterGte', 'diameterLte', 'passageBusDiameterLte', 'passageBusDiameterGte', 'passageCarDiameterLte', 'passageCarDiameterGte', 'maxDiameterGte', 'maxDiameterLte', 'maxDiameterBusLte', 'maxDiameterBusGte', 'maxDiameterCarLte', 'maxDiameterCarGte', 'maxWeightLte', 'maxWeightGte', 'passageDiameterLte', 'passageDiameterGte'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
    },
    afterApplyFilters: function afterApplyFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: 10,
        refreshedByFilter: true
      });
    }
  });

  _exports.default = _default;
});