define("front-end/templates/components/custom-modals/warning-prompt-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DbBDxus9",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"class\"],[\"layout-row layout-align-space-between-center\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[14,\"data-test-warning-prompt-title\",\"\"],[14,0,\"md-title\"],[12],[2,\"\\n      \"],[1,[30,[36,0],null,[[\"icon\"],[\"warning\"]]]],[2,\"\\n      \"],[10,\"span\"],[12],[1,[35,1,[\"customTitle\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,4],null,[[\"class\",\"iconButton\",\"onClick\",\"data-test-close-warning-prompt-dialog\"],[\"close-dialog\",true,[30,[36,3],[[32,0],[35,2]],null],true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"icon\"],[\"close\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"options\",\"decline\",\"action\",\"paper-button\",\"paper-toolbar-tools\",\"paper-toolbar\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/custom-modals/warning-prompt-header.hbs"
    }
  });

  _exports.default = _default;
});