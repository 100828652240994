define("front-end/components/companies-autocomplete-filter", ["exports", "front-end/mixins/component-company-filters-mixin"], function (_exports, _componentCompanyFiltersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentCompanyFiltersMixin.default, {
    tagName: 'div',
    classNames: ['layout-column', 'companies-autocomplete'],
    label: '',
    searchText: '',
    queryParam: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedCompany = null;
    },
    // Using didUpdate since it must have fetched the
    // company names before setting the selected one
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      if (Ember.typeOf(this.queryParam) == 'array') {
        return;
      }

      var selectedCompany = this.companies.find(function (option) {
        return option.get('id') == _this.queryParam;
      });
      this.set('selectedCompany', selectedCompany);

      if (!selectedCompany) {
        this.set('searchText', '');
      }
    },
    didInsertElement: function didInsertElement() {
      if (!this.companies) {
        this._fetchCompanyNames.perform();
      }
    },
    actions: {
      setCompany: function setCompany(company) {
        this.filterAction(company);
      }
    }
  });

  _exports.default = _default;
});