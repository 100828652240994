define("front-end/routes/admin/companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();
      }
    }
  });

  _exports.default = _default;
});