define("front-end/models/actions/mark-chat-messages-as-read-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    estimateChat: (0, _model.belongsTo)('chats/estimate-chat', {
      inverse: null
    })
  });

  _exports.default = _default;
});