define("front-end/components/estimates-summaries-over-time-chart", ["exports", "chart.js", "moment"], function (_exports, _chart, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    height: '100',
    init: function init() {
      this._super.apply(this, arguments);

      var chartOptions = {
        data: {},
        type: 'line',
        options: {
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'DD/MM/YYYY'
                }
              },
              bounds: "data",
              distribution: 'linear'
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Engajamento dos Fornecedores (%)'
              },
              bounds: {
                min: 0,
                max: 100
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function label(tooltipItem) {
                var label = parseFloat(tooltipItem['yLabel']).toFixed(2);
                return label.toString() + '%';
              },
              title: function title(tooltipItem) {
                var time = (0, _moment.default)(tooltipItem[0]['xLabel'], _moment.default.ISO_8601);
                return time.format('DD/MM/YYYY');
              }
            }
          },
          responsive: true,
          legend: {
            display: false
          }
        }
      };
      this.set('chartOptions', chartOptions);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var summaries = this.summaries;
      var upperBound = this.upperBound;

      if (!upperBound) {
        upperBound = (0, _moment.default)().toISOString();
      }

      var data = [];
      summaries.forEach(function (entry) {
        var answerRate = Number((entry.get('answerRate') * 100).toFixed(2));
        var date = entry.get('createdAt');
        data.push({
          x: date,
          y: answerRate
        });
      }); // The backend api returns the most recent summary computed immediately
      // before the oldest summary returned so that we can add it to the chart.
      // This means that the chart will always start considering the previous
      // computed summary

      if (summaries.meta.previous) {
        var answerRate = Number((summaries.meta.previous['answer-rate'] * 100).toFixed(2));
        var date = summaries.meta.previous['created-at'];
        data.unshift({
          x: date,
          y: answerRate
        });
      } // If there is no registry related to the upper bound day, it means that
      // the data remained the same. Therefore, we add a fake data in this last
      // day displayed in the chart with the same answer rate of the last computed
      // summary


      if (data.length > 0) {
        var lastElement = data[data.length - 1];
        var lastDate = lastElement['y'];
        lastDate = (0, _moment.default)(lastDate);

        if (lastDate.format("DD/MM/YYYY") != (0, _moment.default)(upperBound, _moment.default.ISO_8601).format("DD/MM/YYYY")) {
          data.push({
            x: upperBound,
            y: lastElement['y']
          });
        }
      }

      var datasets = [{
        data: data,
        borderColor: '#4F8A10',
        type: 'line',
        pointRadius: 1,
        fill: false,
        lineTension: 0,
        borderWidth: 2
      }];
      var chartData = {
        datasets: datasets
      };
      this.set('chartOptions.data', chartData);
    },
    didRender: function didRender() {
      var ctx = document.getElementById("estimates-summaries-over-time-chart");
      this.set('chart', new _chart.default(ctx, this.chartOptions));
    }
  });

  _exports.default = _default;
});