define("front-end/models/computed-models/all-estimates-summary", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    notAnswered: (0, _model.attr)('number'),
    refused: (0, _model.attr)('number'),
    negotiation: (0, _model.attr)('number'),
    accepted: (0, _model.attr)('number'),
    declined: (0, _model.attr)('number'),
    delayed: (0, _model.attr)('number'),
    timeout: (0, _model.attr)('number'),
    canceled: (0, _model.attr)('number'),
    total: (0, _model.attr)('number'),
    answered: (0, _model.attr)('number'),
    expectedAnswered: (0, _model.attr)('number'),
    avgAnswerTime: (0, _model.attr)('number'),
    answerRate: (0, _model.attr)('number'),
    estimate: (0, _model.belongsTo)('estimate')
  });

  _exports.default = _default;
});