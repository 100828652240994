define("front-end/templates/components/go-back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T7oUJ+je",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"raised\",\"primary\",\"onClick\",\"data-test-back\"],[true,true,[30,[36,0],[[32,0],\"goBack\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"  Voltar\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"paper-button\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/go-back-button.hbs"
    }
  });

  _exports.default = _default;
});