define("front-end/templates/components/open-estimate-chat-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FcTfeC1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"unread-messages-wrapper\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"onClick\",\"iconButton\",\"warn\",\"accent\",\"raised\",\"data-test-open-estimate-chat\"],[[30,[36,6],[[32,0],[35,5]],null],true,[35,3],[30,[36,4],[[35,3]],null],true,true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[\"chat\"],[[\"size\"],[24]]]],[2,\"\\n\"],[6,[37,2],null,[[\"position\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"      Abrir \"],[10,\"i\"],[12],[2,\"Chat\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,8],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"data-test-unread-messages-count\",\"\"],[14,0,\"unread-messages-ballon\"],[12],[2,\"\\n      \"],[10,\"small\"],[14,0,\"count-unread-messages\"],[12],[2,\"\\n        \"],[1,[34,0]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"totalNotReadMessages\",\"paper-icon\",\"paper-tooltip\",\"hasUnreadMessages\",\"not\",\"openChatAction\",\"action\",\"paper-button\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/open-estimate-chat-button.hbs"
    }
  });

  _exports.default = _default;
});