define("front-end/routes/admin/analyses/estimates/refused", ["exports", "front-end/mixins/route-pagination-mixin", "front-end/constants"], function (_exports, _routePaginationMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        manufacturer: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        },
        refusedEstimateMessage: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      var filterQueryOptions = {
        filter: {
          status: _constants.ESTIMATE_STATUS.REFUSED
        },
        sort: '-created-at',
        include: 'manufacturer,estimate-survey.batch,estimate-survey.company,estimate-survey.part,estimate-survey.part-revision.edited-pdf-design,refused-estimate-message',
        fields: {
          'parts': 'name,internal_code',
          'estimate_surveys': 'batch,company,part_revision,part',
          'companies': 'cnpj,name,fantasy_name',
          'batches': 'raw_material_format,quantity',
          'estimates': 'my_id,created_at,timeout_deadline,answer_time,estimate_survey,manufacturer,refused_estimate_message,message',
          'part-revisions': 'edited_pdf_design,material_from_design,material,tightest_tolerance,tightest_tolerance_from_design'
        }
      };

      if (params.manufacturer) {
        filterQueryOptions.filter['manufacturer'] = params.manufacturer;
      }

      if (params.internalCode) {
        filterQueryOptions.filter['part__internal_code'] = params.internalCode;
      }

      if (params.refusedEstimateMessage) {
        filterQueryOptions.filter['refused_estimate_message'] = params.refusedEstimateMessage;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var estimates = this.store.query('estimate', queryOptions);
      var estimatesCount = this.store.query('estimate', {
        filter: {
          status: _constants.ESTIMATE_STATUS.REFUSED
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (estimates) {
        return estimates.get('meta.count');
      });
      var refusedEstimateMessages = this.store.query('tags/refused-estimate-message', {
        filter: {
          official: true
        },
        sort: 'another'
      });
      return Ember.RSVP.hash({
        estimates: estimates,
        estimatesCount: estimatesCount,
        refusedEstimateMessages: refusedEstimateMessages
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var refusedEstimateMessageSelected = model.refusedEstimateMessages.find(function (option) {
        return option.id === controller.refusedEstimateMessage;
      });
      controller.set('refusedEstimateMessageSelected', refusedEstimateMessageSelected);
      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});