define("front-end/utils/fuzzy-match", ["exports", "fuse.js"], function (_exports, _fuse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fuzzyMatch;

  function fuzzyMatch(list, keys, value) {
    var options = {
      keys: keys,
      threshold: 0.22,
      includeScore: true,
      findAllMatches: false
    };
    var fuse = new _fuse.default(list, options);
    return fuse.search(value);
  }
});