define("front-end/helpers/tomorrow-as-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tomorrowAsString = tomorrowAsString;
  _exports.default = void 0;

  function tomorrowAsString() {
    var today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString();
  }

  var _default = Ember.Helper.helper(tomorrowAsString);

  _exports.default = _default;
});