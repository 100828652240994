define("front-end/templates/components/tool-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kbIECfkM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"paper-toolbar\",[[24,\"data-test-toolbar\",\"\"],[24,0,\"top-bar\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row layout-align-space-between toolbar-wrapper\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"layout-row layout-align-center-center logo-wrapper\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,\"data-test-logo-full\",\"\"],[24,\"role\",\"button\"],[24,0,\"logo-completo\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"goHome\"],null]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"div\"],[24,\"role\",\"button\"],[16,0,[30,[36,2],[\"toggle md-button \",[32,0,[\"currentClass\"]]],null]],[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"toggleMenu\"],null]],null],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],[[32,0,[\"sidenav\",\"isOpened\"]],\"menu-open\",\"menu\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"div\"],[24,\"data-test-logo-symbol\",\"\"],[24,\"role\",\"button\"],[24,0,\"logo-simbolo\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"goHome\"],null]],null],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n      \"],[1,[34,5]],[2,\"\\n\\n\"],[6,[37,7],null,[[\"class\",\"href\",\"data-test-logout\"],[\"logout-button\",[30,[36,6],[\"logout\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"        Sair\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"on\",\"concat\",\"if\",\"paper-icon\",\"notification-center\",\"href-to\",\"paper-button\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/tool-bar.hbs"
    }
  });

  _exports.default = _default;
});