define("front-end/components/process-item-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@body.row data-test-item-row={{@item.myId}} @class={{concat "cursor-pointer" (if (is-equal @item @selectedItem) " info")}} @onClick={{action @setShowingItem @item}} as |row|>
    <row.cell>
      {{@item.myId}}
    </row.cell>
    <row.cell>
      <span data-test-row-quantity class="accent">
        {{@item.quantity}}
      </span>
    </row.cell>
    <row.cell>
      <span data-test-row-material class="accent">
        {{@item.material}}
      </span>
    </row.cell>
    <row.cell>
      <span data-test-row-raw-material-format class="accent">
        {{@item.rawMaterialFormat}}
      </span>
    </row.cell>
    <row.cell>
      <span data-test-row-days-amount class="accent">
        {{@item.daysAmount}} dias
      </span>
    </row.cell>
  </@body.row>
  */
  {
    id: "XPZ1Bey9",
    block: "{\"symbols\":[\"row\",\"@body\",\"@item\",\"@selectedItem\",\"@setShowingItem\"],\"statements\":[[8,[32,2,[\"row\"]],[[16,\"data-test-item-row\",[32,3,[\"myId\"]]]],[[\"@class\",\"@onClick\"],[[30,[36,2],[\"cursor-pointer\",[30,[36,1],[[30,[36,0],[[32,3],[32,4]],null],\" info\"],null]],null],[30,[36,3],[[32,0],[32,5],[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"cell\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,3,[\"myId\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"cell\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,\"data-test-row-quantity\",\"\"],[14,0,\"accent\"],[12],[2,\"\\n      \"],[1,[32,3,[\"quantity\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"cell\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,\"data-test-row-material\",\"\"],[14,0,\"accent\"],[12],[2,\"\\n      \"],[1,[32,3,[\"material\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"cell\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,\"data-test-row-raw-material-format\",\"\"],[14,0,\"accent\"],[12],[2,\"\\n      \"],[1,[32,3,[\"rawMaterialFormat\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"cell\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,\"data-test-row-days-amount\",\"\"],[14,0,\"accent\"],[12],[2,\"\\n      \"],[1,[32,3,[\"daysAmount\"]]],[2,\" dias\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"concat\",\"action\"]}",
    meta: {
      moduleName: "front-end/components/process-item-row.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});