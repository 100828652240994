define("front-end/models/user", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    company: (0, _model.belongsTo)('company'),
    role: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    accepted: (0, _model.attr)('boolean'),
    activatedAccount: (0, _model.attr)('boolean'),
    online: (0, _model.attr)('boolean'),
    connections: (0, _model.attr)('number'),
    password: (0, _model.attr)('string'),
    passwordConfirmation: (0, _model.attr)('string'),
    projects: (0, _model.hasMany)('project', {
      inverse: 'creator'
    }),
    requestedTasks: (0, _model.hasMany)('tasks/task', {
      inverse: 'requestor'
    }),
    closedTasks: (0, _model.hasMany)('tasks/task', {
      inverse: 'closedBy'
    }),
    isAdmin: Ember.computed('role', function () {
      var role = this.role;
      return role == 'superadmin' || role == 'admin';
    }),
    isSuperAdmin: Ember.computed('role', function () {
      var role = this.role;
      return role == 'superadmin';
    }),
    isCompanyAdmin: Ember.computed('role', function () {
      return this.role == 'company_admin';
    }),
    companyAdminLevel: Ember.computed.or('isAdmin', 'isCompanyAdmin'),
    isActive: Ember.computed('accepted', 'activatedAccount', 'isAdmin', function () {
      if (this.activatedAccount) {
        if (this.isAdmin || this.accepted) {
          return true;
        }
      }

      return false;
    }),
    level: Ember.computed('isActive', 'online', function () {
      if (this.isActive) {
        if (this.online) {
          return 'success';
        } else {
          return 'info';
        }
      } else {
        return 'error';
      }
    })
  });

  _exports.default = _default;
});