define("front-end/components/tax-profile-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toasterMessages: Ember.inject.service(),
    isTaxProfileDisabled: true,
    taxProfileButtonLabel: 'Editar',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var options = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Sim',
        value: true
      }, {
        display: 'Não',
        value: false
      }];
      var selectedOption = options.find(function (option) {
        return option.value == _this.taxProfile.taxSubstitution;
      });
      this.set('options', options);
      this.set('selectedOption', selectedOption);
      this.set('initializedTaxRegime', this.taxProfile.taxRegime);
    },
    actions: {
      selectSubstitution: function selectSubstitution(option) {
        this.set('selectedOption', option);
        this.set('taxProfile.taxSubstitution', option.value);
      },
      editTaxProfile: function editTaxProfile() {
        var _this2 = this;

        if (this.isTaxProfileDisabled) {
          this.set('isTaxProfileDisabled', false);
          this.set('taxProfileButtonLabel', 'Salvar');
        } else {
          this.set('isTaxProfileDisabled', true);
          this.set('taxProfileButtonLabel', 'Editar');
          this.taxProfile.save().then(function () {
            _this2.taxProfile.taxRegime.then(function (rel) {
              return _this2.set('initializedTaxRegime', rel);
            });

            _this2.toasterMessages.showSuccess(['Informações sobre impostos atualizadas com sucesso!']);
          });
        }
      },
      onCancel: function onCancel() {
        var _this3 = this;

        // Rollback relationship manually due ember-data restrictions
        // https://github.com/emberjs/rfcs/pull/21
        this.set('taxProfile.taxRegime', this.initializedTaxRegime);
        this.taxProfile.rollbackAttributes();
        var modelTaxSubstitution = this.options.find(function (option) {
          return option.value == _this3.taxProfile.taxSubstitution;
        });
        this.set('selectedOption', modelTaxSubstitution);
        this.set('isTaxProfileDisabled', true);
        this.set('taxProfileButtonLabel', 'Editar');

        if (this.cancelTaxEdit) {
          this.cancelTaxEdit();
        }
      }
    }
  });

  _exports.default = _default;
});