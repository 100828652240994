define("front-end/templates/components/average-ticket-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dTj21tjd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n  \"],[10,\"canvas\"],[14,\"data-test-ticket-chart\",\"\"],[14,1,\"data-test-ticket-chart\"],[14,\"width\",\"auto\"],[15,\"height\",[34,0]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"height\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/average-ticket-chart.hbs"
    }
  });

  _exports.default = _default;
});