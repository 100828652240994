define("front-end/components/date-range-filter", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['date-range-filter'],
    minDate: Ember.computed('startQueryParam', function () {
      var startQueryParam = this.startQueryParam;
      return startQueryParam ? (0, _moment.default)(startQueryParam) : null;
    })
  });

  _exports.default = _default;
});