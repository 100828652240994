define("front-end/components/selectable-companies-table", ["exports", "front-end/mixins/component-selectable-resource-table"], function (_exports, _componentSelectableResourceTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentSelectableResourceTable.default, {
    modelName: 'company',
    pinPointLat: '',
    pinPointLng: '',
    selectCompanies: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('queryParams', {
        sort: {},
        filter: {},
        include: 'manufacturer-characterization,tax-profile,machines,companies-groups'
      });
    },
    selectableResources: Ember.computed('disabledItems.[]', 'resources.[]', 'selectCompanies', function () {
      var _this = this;

      if (!this.selectCompanies) {
        this.resources;
      }

      return this.resources.filter(function (resource) {
        return !_this.disabledItems.includes(resource);
      });
    }),
    disabledItems: Ember.computed('disabledIds.[]', 'hasDisabledResources', 'resources.[]', function () {
      var _this2 = this;

      if (!this.hasDisabledResources) {
        return [];
      }

      return this.resources.filter(function (resource) {
        return _this2.disabledIds.includes(resource.get('id'));
      });
    }),
    unselectedResources: Ember.computed('selectableResources.[]', 'addedResources.[]', function () {
      var _this3 = this;

      return this.selectableResources.filter(function (resource) {
        return !_this3.addedResources.includes(resource);
      });
    }),
    emptySelectableResources: Ember.computed.empty('selectableResources'),
    allResourcesSelected: Ember.computed.empty('unselectedResources'),
    actions: {
      addFilteredCompanies: function addFilteredCompanies() {
        this.addedResources.addObjects(this.selectableResources);
      },
      onLoadMap: function onLoadMap(_ref) {
        var map = _ref.map;
        var bounds = new google.maps.LatLngBounds();
        this.resources.forEach(function (company) {
          if (company.get('latitude')) {
            var coords = new google.maps.LatLng(company.get('latitude'), company.get('longitude'));
            bounds.extend(coords);
          }
        });
        Ember.run.later(function () {
          if (map) {
            map.fitBounds(bounds);
            map.panToBounds(bounds);
          }
        }, 1500);
      },
      afterFilter: function afterFilter() {
        Ember.setProperties(this, {
          page: 1,
          size: this.size
        });

        this._fetchResources.perform();

        document.querySelector('.scroll-here').scrollIntoView();
      }
    }
  });

  _exports.default = _default;
});