define("front-end/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RAW_MATERIAL_LIKELY_FORMAT_DESCRIPTIONS = _exports.RAW_MATERIAL_LIKELY_FORMAT_OPTIONS = _exports.RAW_MATERIAL_PREPARING_PROCESS_OPTIONS = _exports.MINIMUM_MANUFACTURER_CLASSIFICATION_OPTIONS = _exports.FINER_ROUGHNESS_OPTIONS = _exports.BUY_UNIT_OPTIONS = _exports.PROJECT_OPTIONS = _exports.BATCH_OPTIONS = _exports.QUOTATION_OPTIONS = _exports.MACHINES = _exports.ENVIRONMENT_CONFIG_DEFAULT_VALUES = _exports.COMPANY_CLIENT_CATEGORY_OPTIONS = _exports.COMPANY_CLIENT_CATEGORY = _exports.AVAILABLE_CLASSIFICATION = _exports.PROVIDING_RESPONSIBLE_OPTIONS = _exports.RESPONSIBLE_OPTIONS_WITHOUT_CLIENT = _exports.RESPONSIBLE_OPTIONS = _exports.DEFAULT_PAYMENT_OPTIONS = _exports.MANUFACTURER_PAYMENT_OPTIONS = _exports.CLIENT_PAYMENT_OPTIONS = _exports.MATERIALS = _exports.FREIGHT_STRATEGY_OPTIONS = _exports.FREIGHT_STRATEGY = _exports.PROJECT_STATUS = _exports.BATCH_STATUS = _exports.ESTIMATE_CONFIRMATION_STATUS = _exports.ESTIMATE_STATUS = _exports.QUOTATION_STATUS = _exports.INTEREST_BASED_JOB_OFFER_STATUS = _exports.PROMPT_JOB_OFFER_STATUS = _exports.SUPERVISED_QUOTE_STATUS = _exports.ESTIMATE_SURVEY_STATUS = _exports.JOB_OFFER_PROCESS_ITEM_STATUS = _exports.MANUFACTURING_JOB_STATUS = _exports.CLIENT_FORMALIZATION_STATUS = void 0;
  // === Model Status Codes === //
  var CLIENT_FORMALIZATION_STATUS = {
    UNDEFINED: 0,
    DEFINING: 1,
    DEFINED: 2,
    CANCELED: 101
  };
  _exports.CLIENT_FORMALIZATION_STATUS = CLIENT_FORMALIZATION_STATUS;
  var MANUFACTURING_JOB_STATUS = {
    UNDEFINED: 0,
    DEFINING: 1,
    DEFINED: 2,
    CANCELED: 101
  };
  _exports.MANUFACTURING_JOB_STATUS = MANUFACTURING_JOB_STATUS;
  var JOB_OFFER_PROCESS_ITEM_STATUS = {
    MISSING_FILE: 0,
    READY_TO_OFFER: 1,
    AVAILABLE: 2,
    READY_TO_SELECT_WINNER: 3,
    COMPLETED: 4,
    FAILED: 5,
    CANCELED: 6
  };
  _exports.JOB_OFFER_PROCESS_ITEM_STATUS = JOB_OFFER_PROCESS_ITEM_STATUS;
  var ESTIMATE_SURVEY_STATUS = {
    MISSING_FILE: 0,
    READY_TO_ESTIMATE: 1,
    ESTIMATING: 2,
    COMPLETED: 3,
    CLOSED: 4
  };
  _exports.ESTIMATE_SURVEY_STATUS = ESTIMATE_SURVEY_STATUS;
  var SUPERVISED_QUOTE_STATUS = {
    ESTIMATING: 0,
    COMPLETED: 1,
    CLOSED: 2
  };
  _exports.SUPERVISED_QUOTE_STATUS = SUPERVISED_QUOTE_STATUS;
  var PROMPT_JOB_OFFER_STATUS = {
    AVAILABLE: 0,
    ACCEPTED: 1,
    REFUSED: 2,
    TAKEN: 3,
    CANCELED: 4,
    TIMEOUT: 5
  };
  _exports.PROMPT_JOB_OFFER_STATUS = PROMPT_JOB_OFFER_STATUS;
  var INTEREST_BASED_JOB_OFFER_STATUS = {
    AVAILABLE: 0,
    INTERESTED: 1,
    REFUSED: 2,
    ACCEPTED: 3,
    NOT_SELECTED: 4,
    CANCELED: 5,
    TIMEOUT: 6
  };
  _exports.INTEREST_BASED_JOB_OFFER_STATUS = INTEREST_BASED_JOB_OFFER_STATUS;
  var QUOTATION_STATUS = {
    ANALYZING: 0,
    PRICING: 1,
    NEGOTIATION: 2,
    ACCEPTED: 3,
    DECLINED: 4,
    CLOSED: 5,
    TIMEOUT: 6
  };
  _exports.QUOTATION_STATUS = QUOTATION_STATUS;
  var ESTIMATE_STATUS = {
    NOT_ANSWERED: 0,
    NEGOTIATION: 1,
    ACCEPTED: 2,
    REFUSED: 3,
    DECLINED: 4,
    DELAYED: 5,
    TIMEOUT: 6,
    CANCELED: 7,
    UNCONFIRMED: 110
  };
  _exports.ESTIMATE_STATUS = ESTIMATE_STATUS;
  var ESTIMATE_CONFIRMATION_STATUS = {
    CREATING: 0,
    AVAILABLE: 1,
    CONFIRMED: 2,
    REJECTED: 3,
    TIMEOUT: 4,
    CANCELED: 101
  };
  _exports.ESTIMATE_CONFIRMATION_STATUS = ESTIMATE_CONFIRMATION_STATUS;
  var BATCH_STATUS = {
    CREATING: 0,
    QUOTING: 1,
    FORMALIZING: 2,
    DEFINING_MANUFACTURER: 3,
    MANUFACTURING: 4,
    TRANSITING: 5,
    COMPLETED: 6,
    CANCELED: 101,
    DEPRECATED: 102,
    UNTRACKED: 103,
    DENIED: 104
  };
  _exports.BATCH_STATUS = BATCH_STATUS;
  var PROJECT_STATUS = {
    CREATING: 0,
    ANALYZING: 1,
    EXAMINED: 2,
    FINISHED: 3,
    CANCELED: 101,
    DENIED: 102
  }; // == Freight == //

  _exports.PROJECT_STATUS = PROJECT_STATUS;
  var FREIGHT_STRATEGY = {
    CIF: 0,
    FOB: 1,
    BOTH_CIF_FOB: 2
  };
  _exports.FREIGHT_STRATEGY = FREIGHT_STRATEGY;
  var FREIGHT_STRATEGY_OPTIONS = [{
    display: 'CIF',
    value: 0
  }, {
    display: 'FOB',
    value: 1
  }, {
    display: 'CIF ou FOB',
    value: 2
  }]; // == Materials == //

  _exports.FREIGHT_STRATEGY_OPTIONS = FREIGHT_STRATEGY_OPTIONS;
  var MATERIALS = [{
    display: 'SAE 1008',
    value: 'SAE 1008'
  }, {
    display: 'SAE 1020',
    value: 'SAE 1020'
  }, {
    display: 'SAE 1030',
    value: 'SAE 1030'
  }, {
    display: 'SAE 1045',
    value: 'SAE 1045'
  }, {
    display: 'SAE 1070',
    value: 'SAE 1070'
  }, {
    display: 'SAE 8620',
    value: 'SAE 8620'
  }, {
    display: 'SAE 8640',
    value: 'SAE 8640'
  }, {
    display: 'SAE 4140',
    value: 'SAE 4140'
  }, {
    display: 'SAE 4320',
    value: 'SAE 4320'
  }, {
    display: 'SAE 4340',
    value: 'SAE 4340'
  }, {
    display: 'SAE 1213',
    value: 'SAE 1213'
  }, {
    display: 'ASTM A36',
    value: 'ASTM A36'
  }, {
    display: 'AISI 304',
    value: 'AISI 304'
  }, {
    display: 'AISI 304L',
    value: 'AISI 304L'
  }, {
    display: 'AISI 316',
    value: 'AISI 316'
  }, {
    display: 'AISI 316L',
    value: 'AISI 316L'
  }, {
    display: 'AISI 310',
    value: 'AISI 310'
  }, {
    display: 'AISI 410 (VC140)',
    value: 'AISI 410 (VC140)'
  }, {
    display: 'AISI 420',
    value: 'AISI 420'
  }, {
    display: 'AISI D2',
    value: 'AISI D2'
  }, {
    display: 'AISI D6 (VC131)',
    value: 'AISI D6 (VC131)'
  }, {
    display: 'AISI H13',
    value: 'AISI H13'
  }, {
    display: 'AISI M2',
    value: 'AISI M2'
  }, {
    display: 'AISI O1 (VND)',
    value: 'AISI O1 (VND)'
  }, {
    display: 'AISI S1 (VW3)',
    value: 'AISI S1 (VW3)'
  }, {
    display: 'Alumínio Naval 5052F',
    value: 'Alumínio Naval 5052F'
  }, {
    display: 'Alumínio 5083',
    value: 'Alumínio 5083'
  }, {
    display: 'Alumínio 7075-T651',
    value: 'Alumínio 7075-T651'
  }, {
    display: 'Alumínio 7021',
    value: 'Alumínio 7021'
  }, {
    display: 'Alumínio 6061-T6',
    value: 'Alumínio 6061-T6'
  }, {
    display: 'Alumínio 6351-T6',
    value: 'Alumínio 6351-T6'
  }, {
    display: 'Alumínio 7028',
    value: 'Alumínio 7028'
  }, {
    display: 'Alumínio (Não especificado)',
    value: 'Alumínio (Não especificado)'
  }, {
    display: 'Bronze SAE 65',
    value: 'Bronze SAE 65'
  }, {
    display: 'Bronze SAE 40',
    value: 'Bronze SAE 40'
  }, {
    display: 'Bronze SAE 64',
    value: 'Bronze SAE 64'
  }, {
    display: 'Bronze TM23',
    value: 'Bronze TM23'
  }, {
    display: 'Bronze SAE 67',
    value: 'Bronze SAE 67'
  }, {
    display: 'Bronze (Não especificado)',
    value: 'Bronze (Não especificado)'
  }, {
    display: 'Latão',
    value: 'Latão'
  }, {
    display: 'UHMW',
    value: 'UHMW'
  }, {
    display: 'PU',
    value: 'PU'
  }, {
    display: 'Celeron',
    value: 'Celeron'
  }, {
    display: 'Nylon 6',
    value: 'Nylon 6'
  }, {
    display: 'Teflon',
    value: 'Teflon'
  }, {
    display: 'Outro',
    value: ''
  }]; // == Payment Conditions == //

  _exports.MATERIALS = MATERIALS;
  var CLIENT_PAYMENT_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'À vista',
    value: 'À vista'
  }, {
    display: '5 DDL',
    value: '5 DDL'
  }, {
    display: '15 DDL',
    value: '15 DDL'
  }, {
    display: '28 DDL',
    value: '28 DDL'
  }, {
    display: '30 DDL',
    value: '30 DDL'
  }, {
    display: '35 DDL',
    value: '35 DDL'
  }, {
    display: '45 DDL',
    value: '45 DDL'
  }, {
    display: '60 DDL',
    value: '60 DDL'
  }, {
    display: '90 DDL',
    value: '90 DDL'
  }, {
    display: '120 DDL',
    value: '120 DDL'
  }];
  _exports.CLIENT_PAYMENT_OPTIONS = CLIENT_PAYMENT_OPTIONS;
  var MANUFACTURER_PAYMENT_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'À vista',
    value: 'À vista'
  }, {
    display: '10 DDL',
    value: '10 DDL'
  }, {
    display: '20 DDL',
    value: '20 DDL'
  }, {
    display: '28 DDL',
    value: '28 DDL'
  }, {
    display: '30 DDL',
    value: '30 DDL'
  }, {
    display: '33 DDL',
    value: '33 DDL'
  }, {
    display: '35 DDL',
    value: '35 DDL'
  }, {
    display: '40 DDL',
    value: '40 DDL'
  }, {
    display: '45 DDL',
    value: '45 DDL'
  }, {
    display: '50 DDL',
    value: '50 DDL'
  }, {
    display: '60 DDL',
    value: '60 DDL'
  }, {
    display: '65 DDL',
    value: '65 DDL'
  }, {
    display: '90 DDL',
    value: '90 DDL'
  }, {
    display: '95 DDL',
    value: '95 DDL'
  }, {
    display: '125 DDL',
    value: '125 DDL'
  }];
  _exports.MANUFACTURER_PAYMENT_OPTIONS = MANUFACTURER_PAYMENT_OPTIONS;
  var DEFAULT_PAYMENT_OPTIONS = {
    value: null
  }; // == Responsible Options == //

  _exports.DEFAULT_PAYMENT_OPTIONS = DEFAULT_PAYMENT_OPTIONS;
  var RESPONSIBLE_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Cliente',
    value: 'client'
  }, {
    display: 'Peerdustry',
    value: 'peerdustry'
  }, {
    display: 'Fornecedor',
    value: 'manufacturer'
  }];
  _exports.RESPONSIBLE_OPTIONS = RESPONSIBLE_OPTIONS;
  var RESPONSIBLE_OPTIONS_WITHOUT_CLIENT = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Peerdustry',
    value: 'peerdustry'
  }, {
    display: 'Fornecedor',
    value: 'manufacturer'
  }];
  _exports.RESPONSIBLE_OPTIONS_WITHOUT_CLIENT = RESPONSIBLE_OPTIONS_WITHOUT_CLIENT;
  var PROVIDING_RESPONSIBLE_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: null
  }, {
    display: 'Vou fornecer',
    value: true
  }, {
    display: 'Não vou fornecer',
    value: false
  }]; // == Homologation options == //

  _exports.PROVIDING_RESPONSIBLE_OPTIONS = PROVIDING_RESPONSIBLE_OPTIONS;
  var AVAILABLE_CLASSIFICATION = ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-']; // == Client Category for == //

  _exports.AVAILABLE_CLASSIFICATION = AVAILABLE_CLASSIFICATION;
  var COMPANY_CLIENT_CATEGORY = {
    NOT_CATEGORIZED: 0,
    NEW_CLIENT: 1,
    RECURRING_CLIENT: 2
  };
  _exports.COMPANY_CLIENT_CATEGORY = COMPANY_CLIENT_CATEGORY;
  var COMPANY_CLIENT_CATEGORY_OPTIONS = [{
    display: 'Não Categorizado',
    icon: 'label_off',
    value: 0
  }, {
    display: 'Cliente Novo',
    icon: 'fiber_new',
    value: 1
  }, {
    display: 'Cliente Recorrente',
    icon: 'published_with_changes',
    value: 2
  }]; // == EnvironmentConfig Default Values for Test and Dev == //

  _exports.COMPANY_CLIENT_CATEGORY_OPTIONS = COMPANY_CLIENT_CATEGORY_OPTIONS;
  var ENVIRONMENT_CONFIG_DEFAULT_VALUES = {
    RESALE_TAX: 7.21,
    INDUSTRIALIZATION_TAX: 7.71
  }; // == Machines options == //

  _exports.ENVIRONMENT_CONFIG_DEFAULT_VALUES = ENVIRONMENT_CONFIG_DEFAULT_VALUES;
  var MACHINES = {
    TYPES: [{
      display: 'Limpar opção selecionada',
      value: ''
    }, {
      display: 'Centro de Torneamento',
      value: 'turning-center'
    }, {
      display: 'Centro de Usinagem',
      value: 'machining-center'
    }, {
      display: 'Eletroerosão',
      value: 'electroplating'
    }, {
      display: 'Fresadora',
      value: 'milling'
    }, {
      display: 'Mandrilhadora',
      value: 'boring'
    }, {
      display: 'Retífica',
      value: 'rectifying'
    }, {
      display: 'Torno',
      value: 'lathe'
    }, {
      display: 'Torno Vertical',
      value: 'vertical-lathe'
    }],
    BORING: {
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Mazak', 'SIEMENS', 'Outro'],
      AVAILABLE_AUTOMATION_LEVELS: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'CNC',
        value: 'cnc'
      }, {
        display: 'Convencional',
        value: 'conventional'
      }],
      AVAILABLE_BRANDS: ['Cincinnati', 'Clever', 'Diplomat', 'Doosan', 'Dorries', 'Eurostec', 'Mausa', 'Mazak', 'Pratt Whitney', 'Romi', 'Sinitron', 'Tos Varnsdorf', 'Wotan', 'Zocca', 'Outros']
    },
    ELECTROPLATING: {
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Mazak', 'SIEMENS', 'Outro'],
      AVAILABLE_ELECTRO_TYPES: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'A Fio',
        value: 'wire'
      }, {
        display: 'Penetração',
        value: 'penetration'
      }],
      AVAILABLE_BRANDS: ['Actspark', 'Agie', 'Charmilles', 'DMG', 'Electrocut', 'Eurostec', 'Fanuc', 'GF+', 'Makino', 'Mitsubishi', 'Sodick', 'Suprasonic', 'Outros']
    },
    LATHE: {
      AVAILABLE_COUNTER_POINTS: ['Hidráulico', 'Manual', 'Pneumático'],
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Fagor', 'GSK', 'Mazak', 'Romi', 'SIEMENS', 'Outro'],
      AVAILABLE_BRANDS: ['Clever', 'DMG', 'Diplomat', 'Doosan', 'Dorries', 'Ergomat', 'Eurostec', 'Hardinge', 'Index', 'Mazak', 'Nardini', 'Okuma', 'Promeca', 'Romi', 'Sinitron', 'Taunos', 'Tos', 'Traub', 'Travis', 'Veker', 'Wotan', 'Outros'],
      AVAILABLE_AUTOMATION_LEVELS: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'Automático',
        value: 'automatic'
      }, {
        display: 'CNC',
        value: 'cnc'
      }, {
        display: 'Convencional',
        value: 'conventional'
      }]
    },
    MACHINING_CENTER: {
      AVAILABLE_AXIS: [{
        amount: 3
      }, {
        amount: 5
      }],
      AVAILABLE_CNC_MANUFACTURERS: ['FANUC', 'SIEMENS', 'Outro'],
      AVAILABLE_TOOL_FIXATIONS: ['BT', 'HSK-A63', 'ISO 30', 'ISO 40', 'ISO 50', 'ISO 60'],
      AVAILABLE_BRANDS: ['AWEA', 'Brother', 'Cincinnati', 'DMG Mori', 'Diplomat', 'Doosan', 'Eurostec', 'Fanuc', 'Feeler', 'GF+', 'Grob', 'Haas', 'Heller', 'Hyundai-Kia', 'Mazak', 'Okuma', 'Panmachine', 'Pinnacle', 'Romi', 'Sinitron', 'Toyoda', 'Travis', 'Veker', 'Wotan', 'YCM', 'Outros']
    },
    MILLING: {
      AVAILABLE_TOOL_FIXATIONS: ['BT', 'ISO 30', 'ISO 40', 'ISO 60'],
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Mazak', 'SIEMENS', 'Outro'],
      AVAILABLE_BRANDS: ['Cincinnati', 'Clever', 'Diplomat', 'Dorries', 'Mazak', 'Natal', 'Romi', 'Sanches Blanes', 'Sinitron', 'Tos', 'Travis', 'Veker', 'Wotan', 'Outros'],
      AVAILABLE_AUTOMATION_LEVELS: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'CNC',
        value: 'cnc'
      }, {
        display: 'Ferramenteira',
        value: 'toolmaker'
      }]
    },
    RECTIFYING: {
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Mazak', 'SIEMENS', 'Outro'],
      AVAILABLE_AUTOMATION_LEVELS: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'CNC',
        value: 'cnc'
      }, {
        display: 'Convencional',
        value: 'conventional'
      }],
      AVAILABLE_BRANDS: ['Clever', 'Dorries', 'Eurostec', 'Ferdimat', 'Jhones Shipman', 'Mazak', 'Romi', 'Sinitron', 'Studer', 'Sulmecanica', 'Toyoda', 'Vigoreli', 'Wotan', 'Zema', 'Outros'],
      AVAILABLE_RECTIFYING_TYPES: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'Cilíndrica',
        value: 'cylindrical'
      }, {
        display: 'Perfil',
        value: 'profile'
      }, {
        display: 'Plana',
        value: 'flat'
      }]
    },
    TURNING_CENTER: {
      AVAILABLE_COUNTER_POINTS: ['Hidráulico', 'Manual', 'Pneumático'],
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Mazak', 'SIEMENS', 'Outro'],
      AVAILABLE_BRANDS: ['Clever', 'DMG Mori', 'Doosan', 'Dorries', 'Ergomat', 'Haas', 'Hyundai-Kia', 'Index Traub', 'Mazak', 'Romi', 'Sinitron', 'Outros']
    },
    VERTICAL_LATHE: {
      AVAILABLE_BRANDS: ['Clever', 'Dorries', 'Eurostec', 'Mazak', 'Romi', 'Sinitron', 'Tos', 'Wotan', 'Outros'],
      AVAILABLE_CNC_MANUFACTURERS: ['Fanuc', 'Fagor', 'GSK', 'Mazak', 'Romi', 'SIEMENS', 'Outro'],
      AVAILABLE_AUTOMATION_LEVELS: [{
        display: 'Limpar opção selecionada',
        value: ''
      }, {
        display: 'CNC',
        value: 'cnc'
      }, {
        display: 'Convencional',
        value: 'conventional'
      }]
    }
  }; // ==  Quotation Options == //

  _exports.MACHINES = MACHINES;
  var QUOTATION_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Em análise',
    value: QUOTATION_STATUS.ANALYZING
  }, {
    display: 'Em precificação',
    value: QUOTATION_STATUS.PRICING
  }, {
    display: 'Em negociação',
    value: QUOTATION_STATUS.NEGOTIATION
  }, {
    display: 'Aceita',
    value: QUOTATION_STATUS.ACCEPTED
  }, {
    display: 'Rejeitada',
    value: QUOTATION_STATUS.DECLINED
  }, {
    display: 'Rejeitada pelo Admin',
    value: QUOTATION_STATUS.CLOSED
  }, {
    display: 'Expiradas',
    value: QUOTATION_STATUS.TIMEOUT
  }]; // == Batch Options == //

  _exports.QUOTATION_OPTIONS = QUOTATION_OPTIONS;
  var BATCH_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Em criação',
    value: BATCH_STATUS.CREATING
  }, {
    display: 'Em cotação',
    value: BATCH_STATUS.QUOTING
  }, {
    display: 'Em formalização',
    value: BATCH_STATUS.FORMALIZING
  }, {
    display: 'Seleção de Fornecedores',
    value: BATCH_STATUS.DEFINING_MANUFACTURER
  }, {
    display: 'Em fabricação',
    value: BATCH_STATUS.MANUFACTURING
  }, {
    display: 'Em trânsito',
    value: BATCH_STATUS.TRANSITING
  }, {
    display: 'Finalizados',
    value: BATCH_STATUS.COMPLETED
  }]; // == Project Options == //

  _exports.BATCH_OPTIONS = BATCH_OPTIONS;
  var PROJECT_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Em criação',
    value: PROJECT_STATUS.CREATING
  }, {
    display: 'Em análise',
    value: PROJECT_STATUS.ANALYZING
  }, {
    display: 'Analisado',
    value: PROJECT_STATUS.EXAMINED
  }, {
    display: 'Declinado',
    value: PROJECT_STATUS.DENIED
  }]; // == Part Buy unit Options == //

  _exports.PROJECT_OPTIONS = PROJECT_OPTIONS;
  var BUY_UNIT_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Peça',
    value: 'PC'
  }, {
    display: 'Conjunto',
    value: 'CJ'
  }, {
    display: 'Unidade',
    value: 'UN'
  }]; // == PartRevision's finer roughness Options == //

  _exports.BUY_UNIT_OPTIONS = BUY_UNIT_OPTIONS;
  var FINER_ROUGHNESS_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'Não há requisito',
    value: 'Não há requisito'
  }, {
    display: 'Ra 50 µm',
    value: 'Ra 50 µm'
  }, {
    display: 'Ra 25 µm',
    value: 'Ra 25 µm'
  }, {
    display: 'Ra 12,5 µm',
    value: 'Ra 12,5 µm'
  }, {
    display: 'Ra 6,3 µm',
    value: 'Ra 6,3 µm'
  }, {
    display: 'Ra 3,2 µm',
    value: 'Ra 3,2 µm'
  }, {
    display: 'Ra 1,6 µm',
    value: 'Ra 1,6 µm'
  }, {
    display: 'Ra 0,8 µm',
    value: 'Ra 0,8 µm'
  }, {
    display: 'Ra 0,4 µm',
    value: 'Ra 0,4 µm'
  }, {
    display: 'Ra 0,2 µm',
    value: 'Ra 0,2 µm'
  }, {
    display: 'Ra 0,1 µm',
    value: 'Ra 0,1 µm'
  }, {
    display: 'Polido',
    value: 'Polido'
  }, {
    display: 'Outros',
    value: 'Outros'
  }]; // == PartRevision's minimum manufacturer classification options == //

  _exports.FINER_ROUGHNESS_OPTIONS = FINER_ROUGHNESS_OPTIONS;
  var MINIMUM_MANUFACTURER_CLASSIFICATION_OPTIONS = [{
    display: 'Selecione uma opção...',
    value: ''
  }, {
    display: 'A+',
    value: 'A+'
  }, {
    display: 'A',
    value: 'A'
  }, {
    display: 'A-',
    value: 'A-'
  }, {
    display: 'B+',
    value: 'B+'
  }, {
    display: 'B',
    value: 'B'
  }, {
    display: 'B-',
    value: 'B-'
  }, {
    display: 'C+',
    value: 'C+'
  }, {
    display: 'C',
    value: 'C'
  }, {
    display: 'C-',
    value: 'C-'
  }]; // == PartRevision's raw material preparing process options == //

  _exports.MINIMUM_MANUFACTURER_CLASSIFICATION_OPTIONS = MINIMUM_MANUFACTURER_CLASSIFICATION_OPTIONS;
  var RAW_MATERIAL_PREPARING_PROCESS_OPTIONS = [{
    display: 'Nenhum',
    value: null
  }, {
    display: 'Fresa',
    value: 'Fresa'
  }, {
    display: 'Oxicorte',
    value: 'Oxicorte'
  }, {
    display: 'Laser Plano',
    value: 'Laser Plano'
  }]; // == PartRevision's raw material likely format options == //

  _exports.RAW_MATERIAL_PREPARING_PROCESS_OPTIONS = RAW_MATERIAL_PREPARING_PROCESS_OPTIONS;
  var RAW_MATERIAL_LIKELY_FORMAT_OPTIONS = [{
    display: 'Nenhum',
    value: null
  }, {
    display: 'Redonda Laminada Blank Cortado',
    value: 'Redon Lam Cor'
  }, {
    display: 'Redonda Laminada em Barra',
    value: 'Redon Lam Bar'
  }, {
    display: 'Redonda Trefilado Blank Cortado',
    value: 'Redon Trf Cor'
  }, {
    display: 'Redonda Trefilado em Barra',
    value: 'Redon Trf Bar'
  }, {
    display: 'Redonda Retificada Tratada',
    value: 'Redon Rtt Bar'
  }, {
    display: 'Redonda Retificada sem Tratamento',
    value: 'Redon Rts Bar'
  }, {
    display: 'Retangular Laminada Blank Cortado',
    value: 'Retan Lam Cor'
  }, {
    display: 'Retangular Laminada em Barra',
    value: 'Retan Lam Bar'
  }, {
    display: 'Retangular Trefilada Blank Cortado',
    value: 'Retan Trf Cor'
  }, {
    display: 'Retangular Trefilada em Barra',
    value: 'Retan Trf Bar'
  }, {
    display: 'Sextavada Laminada Blank Cortado',
    value: 'Sexta Lam Cor'
  }, {
    display: 'Sextavada Laminada em Barra',
    value: 'Sexta Lam Bar'
  }, {
    display: 'Sextavada Trefilada Blank Cortado',
    value: 'Sexta Trf Cor'
  }, {
    display: 'Sextavada Trefilada em Barra',
    value: 'Sexta Trf Lam'
  }, {
    display: 'Chapa Oxicortada',
    value: 'Chapa Oxi Cor'
  }, {
    display: 'Chapa Cortada no Laser',
    value: 'Chapa Lsr Cor'
  }, {
    display: 'Chapa Oxicortada Fora do Padrão',
    value: 'Chapa Oxi For'
  }, {
    display: 'Chapa laminada cortada',
    value: 'Chapa Lam Cor'
  }, {
    display: 'Chapa cortada no Laser Fora do Padrão',
    value: 'Chapa Lsr For'
  }, {
    display: 'Item comercial',
    value: 'Comer Cmc Itm'
  }, {
    display: 'Outro',
    value: 'Outro'
  }];
  _exports.RAW_MATERIAL_LIKELY_FORMAT_OPTIONS = RAW_MATERIAL_LIKELY_FORMAT_OPTIONS;
  var RAW_MATERIAL_LIKELY_FORMAT_DESCRIPTIONS = {
    'Redon Lam Cor': 'Redonda Laminada Blank Cortado',
    'Redon Lam Bar': 'Redonda Laminada em Barra',
    'Redon Trf Cor': 'Redonda Trefilado Blank Cortado',
    'Redon Trf Bar': 'Redonda Trefilado em Barra',
    'Redon Rtt Bar': 'Redonda Retificada Tratada',
    'Redon Rts Bar': 'Redonda Retificada sem Tratamento',
    'Retan Lam Cor': 'Retangular Laminada Blank Cortado',
    'Retan Lam Bar': 'Retangular Laminada em Barra',
    'Retan Trf Cor': 'Retangular Trefilada Blank Cortado',
    'Retan Trf Bar': 'Retangular Trefilada em Barra',
    'Sexta Lam Cor': 'Sextavada Laminada Blank Cortado',
    'Sexta Lam Bar': 'Sextavada Laminada em Barra',
    'Sexta Trf Cor': 'Sextavada Trefilada Blank Cortado',
    'Sexta Trf Lam': 'Sextavada Trefilada em Barra',
    'Chapa Oxi Cor': 'Chapa Oxicortada',
    'Chapa Lsr Cor': 'Chapa Cortada no Laser',
    'Chapa Oxi For': 'Chapa Oxicortada Fora do Padrão',
    'Chapa Lsr For': 'Chapa Cortada no laser Fora do Padrão',
    'Chapa Lam Cor': 'Chapa laminada cortada',
    'Comer Cmc Itm': 'Item comercial',
    'Outro': 'Outro'
  };
  _exports.RAW_MATERIAL_LIKELY_FORMAT_DESCRIPTIONS = RAW_MATERIAL_LIKELY_FORMAT_DESCRIPTIONS;
});