define("front-end/mixins/route-query-param-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function basicArray(array) {
    if (Ember.typeOf(array) !== 'array') {
      return false;
    }

    return array.every(function (value) {
      return ['string', 'number'].includes(Ember.typeOf(value));
    });
  }

  var _default = Ember.Mixin.create({
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array' && basicArray(value)) {
        return value.slice();
      }

      return this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array' && basicArray(value)) {
        return value.slice();
      }

      return this._super(value, urlKey, defaultValueType);
    }
  });

  _exports.default = _default;
});