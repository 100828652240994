define("front-end/models/computed-models/competing-estimates-summary", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    stats: (0, _model.attr)(''),
    estimateSurvey: (0, _model.belongsTo)('estimate-survey')
  });

  _exports.default = _default;
});