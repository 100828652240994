define("front-end/components/filter-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LoadingTemplate class="flex-90" />
  
  */
  {
    id: "9b+yfaRH",
    block: "{\"symbols\":[],\"statements\":[[8,\"loading-template\",[[24,0,\"flex-90\"]],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "front-end/components/filter-loader.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});