define("front-end/models/part-automation-database-repository", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/config/environment"], function (_exports, _model, _defaultModel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    session: Ember.inject.service(),
    values: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Array
    pdfsQuantity: (0, _model.attr)('number'),
    threeDimensionalFilesQuantity: (0, _model.attr)('number'),
    hasBeenPerformed: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    outputFileUrl: (0, _model.attr)('string'),
    authUrlForOutputFile: Ember.computed('outputFileUrl', 'session.data.authenticated.jwt', 'updatedAt', function () {
      var file = this.outputFileUrl;
      var apiKey = _environment.default.API_KEY;
      var accessToken = this.session.data.authenticated.jwt;
      var url = "".concat(file, "?time=").concat(this.updatedAt, "&token=").concat(accessToken, "&client_token=").concat(apiKey);
      return file ? url : '';
    })
  });

  _exports.default = _default;
});