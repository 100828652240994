define("front-end/templates/components/sidebar-chat-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mtZlCdK9",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,\"data-test-menu-chats\",\"\"],[16,0,[30,[36,2],[\"menu-wrapper \",[32,0,[\"className\"]]],null]]],[[\"@route\"],[[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"chat\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"title\"],[12],[2,\"\\n      Chats\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,\"data-test-unread-chats-count\",\"\"],[12],[2,\"(\"],[1,[34,0]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"chatsCount\",\"routeName\",\"concat\",\"paper-icon\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/sidebar-chat-button.hbs"
    }
  });

  _exports.default = _default;
});