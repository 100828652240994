define("front-end/templates/components/custom-modals/cancel-estimate-confirmation-prompt-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BgUxGq+X",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-center\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"warn\"],[12],[10,\"b\"],[12],[2,\"Você está prestes a interromper um processo de definição de Fornecedor\"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"Você precisa informar o motivo do cancelamento da confirmação de orçamento.\"],[13],[2,\"\\n\"],[6,[37,5],null,[[\"class\"],[\"layout-column\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],[[30,[36,3],[[32,1,[\"input\"]],\"expected `form.input` to be a contextual component but found a string. Did you mean `(component form.input)`? ('front-end/templates/components/custom-modals/cancel-estimate-confirmation-prompt-body.hbs' @ L7:C4) \"],null]],[[\"required\",\"label\",\"value\",\"onChange\",\"errorMessages\",\"data-test-input-reason\"],[true,\"Motivo para o cancelamento\",[35,2],[35,1],[30,[36,0],null,[[\"required\"],[\"Por favor, insira o motivo para interromper a confirmação de orçamento\"]]],true]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"updatePromptValue\",\"promptValue\",\"-assert-implicit-component-helper-argument\",\"component\",\"paper-form\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/custom-modals/cancel-estimate-confirmation-prompt-body.hbs"
    }
  });

  _exports.default = _default;
});