define("front-end/templates/admin/projects/show/batch/quotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ayTSxCCV",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"quotation\",\"class\"],[[35,0],\"flex-100\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"quotation-management-panel\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/projects/show/batch/quotation.hbs"
    }
  });

  _exports.default = _default;
});