define("front-end/models/part-revision", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/constants"], function (_exports, _model, _defaultModel, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    name: (0, _model.attr)('string'),
    buyUnit: (0, _model.attr)('string'),
    material: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    number: (0, _model.attr)('number'),
    materialFromDesign: (0, _model.attr)('boolean'),
    tightestTolerance: (0, _model.attr)('pt-number'),
    tightestToleranceFromDesign: (0, _model.attr)('boolean'),
    finerRoughness: (0, _model.attr)('string'),
    minimumManufacturerClassification: (0, _model.attr)('string'),
    requireCalibratorControl: (0, _model.attr)('boolean'),
    requireDimensionalReport: (0, _model.attr)('boolean'),
    rawMaterialPreparingProcess: (0, _model.attr)('string'),
    rawMaterialLikelyFormat: (0, _model.attr)('string'),
    readyForProduction: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    hasBeenQuoted: (0, _model.attr)('boolean'),
    techConsiderations: (0, _model.attr)('string'),
    md5Hash: (0, _model.attr)('string'),
    mounting: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    batches: (0, _model.hasMany)('batch', {
      inverse: 'partRevision'
    }),
    part: (0, _model.belongsTo)('part'),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    pdfDesign: (0, _model.belongsTo)('files/pdf-design', {
      inverse: 'resource'
    }),
    editedPdfDesign: (0, _model.belongsTo)('files/edited-pdf-design', {
      inverse: 'resource'
    }),
    twoDimensionalDesign: (0, _model.belongsTo)('files/two-dimensional-design', {
      inverse: 'resource'
    }),
    editedTwoDimensionalDesign: (0, _model.belongsTo)('files/edited-two-dimensional-design', {
      inverse: 'resource'
    }),
    threeDimensionalDesign: (0, _model.belongsTo)('files/three-dimensional-design', {
      inverse: 'resource'
    }),
    boundingVolume: (0, _model.belongsTo)('bounding-volume'),
    manufacturerPartFamily: (0, _model.belongsTo)('tags/manufacturer-part-family', {
      inverse: null
    }),
    mainManufacturingProcess: (0, _model.belongsTo)('manufacturing-process', {
      inverse: null
    }),
    secondaryManufacturingProcesses: (0, _model.hasMany)('manufacturing-process', {
      inverse: null
    }),
    heatTreatments: (0, _model.hasMany)('tags/manufacturer-part-treatment', {
      inverse: null
    }),
    surfaceTreatments: (0, _model.hasMany)('tags/manufacturer-part-treatment', {
      inverse: null
    }),
    aestheticTreatments: (0, _model.hasMany)('tags/manufacturer-part-treatment', {
      inverse: null
    }),
    manufacturerPartTreatments: (0, _model.hasMany)('tags/manufacturer-part-treatment', {
      inverse: null
    }),
    completed: Ember.computed('boundingVolume.id', 'buyUnit', 'finerRoughness', 'mainManufacturingProcess', 'manufacturerPartFamily', 'material', 'minimumManufacturerClassification', 'myId', 'tightestTolerance', function () {
      var boundingVolumeId = this.belongsTo('boundingVolume').id();
      var manufacturerPartFamilyId = this.belongsTo('manufacturerPartFamily').id();
      var mainManufacturingProcessId = this.belongsTo('mainManufacturingProcess').id();
      var mainProperties = [boundingVolumeId, manufacturerPartFamilyId, mainManufacturingProcessId, this.tightestTolerance, this.material, this.buyUnit, this.minimumManufacturerClassification, this.finerRoughness];
      return mainProperties.every(function (prop) {
        return prop;
      });
    }),
    humanizedTightestTolerance: Ember.computed('tightestToleranceFromDesign', function () {
      return this._defineOption('tightestToleranceFromDesign', 'tightestTolerance');
    }),
    humanizedMaterial: Ember.computed('materialFromDesign', function () {
      return this._defineOption('materialFromDesign', 'material');
    }),
    buyUnitSelected: Ember.computed('buyUnit', function () {
      var _this = this;

      return _constants.BUY_UNIT_OPTIONS.find(function (option) {
        return option.value == _this.buyUnit;
      });
    }),
    finerRoughnessSelected: Ember.computed('finerRoughness', function () {
      var _this2 = this;

      return _constants.FINER_ROUGHNESS_OPTIONS.find(function (option) {
        return option.value == _this2.finerRoughness;
      });
    }),
    rawMaterialPreparingProcessSelected: Ember.computed('rawMaterialPreparingProcess', function () {
      var _this3 = this;

      return _constants.RAW_MATERIAL_PREPARING_PROCESS_OPTIONS.find(function (option) {
        return option.value == _this3.rawMaterialPreparingProcess;
      });
    }),
    rawMaterialLikelyFormatSelected: Ember.computed('rawMaterialLikelyFormat', function () {
      var _this4 = this;

      return _constants.RAW_MATERIAL_LIKELY_FORMAT_OPTIONS.find(function (option) {
        return option.value == _this4.rawMaterialLikelyFormat;
      });
    }),
    rawMaterialLikelyFormatDescription: Ember.computed('rawMaterialLikelyFormat', function () {
      if (this.rawMaterialLikelyFormat) {
        return _constants.RAW_MATERIAL_LIKELY_FORMAT_DESCRIPTIONS[this.rawMaterialLikelyFormat];
      }

      return null;
    }),
    minimumManufacturerClassificationSelected: Ember.computed('minimumManufacturerClassification', function () {
      var _this5 = this;

      return _constants.MINIMUM_MANUFACTURER_CLASSIFICATION_OPTIONS.find(function (option) {
        return option.value == _this5.minimumManufacturerClassification;
      });
    }),
    _defineOption: function _defineOption(fromDesignProperty, fallbackProperty) {
      var fromDesign = this.get(fromDesignProperty);

      if (fromDesign) {
        return "Conforme desenho";
      }

      return this.get(fallbackProperty);
    }
  });

  _exports.default = _default;
});