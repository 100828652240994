define("front-end/models/files/manufacturer-selection-csv", ["exports", "@ember-data/model", "front-end/config/environment", "front-end/models/files/application-file", "moment"], function (_exports, _model, _environment, _applicationFile, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationFile.default.extend({
    label: 'CSV escolha de fornecedores',
    urlForThumb: (0, _model.attr)('string'),
    fileProp: 'manufacturerSelectionCsv',
    endpoint: "".concat(_environment.default.host, "/core/v1/files/manufacturer-selection-csvs"),
    allowedExtensions: '.csv',
    showThumb: false,
    jobOfferProcesses: (0, _model.hasMany)('job-offer-process', {
      inverse: null
    }),
    formattedDate: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.createdAt).format('DD/MM/YYYY HH:mm');
    })
  });

  _exports.default = _default;
});