define("front-end/models/files/application-file", ["exports", "@ember-data/model", "front-end/config/environment", "front-end/models/default-model"], function (_exports, _model, _environment, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    session: Ember.inject.service(),
    stored: (0, _model.attr)('boolean'),
    urlForFile: (0, _model.attr)('string'),
    originalFilename: (0, _model.attr)('string'),
    fileExt: (0, _model.attr)('string'),
    uploader: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    resource: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    }),
    authUrlForFile: Ember.computed('urlForFile', 'session.data.authenticated.jwt', 'updatedAt', function () {
      var file = this.urlForFile;
      var apiKey = _environment.default.API_KEY;
      var accessToken = this.session.data.authenticated.jwt;
      var url = "".concat(file, "?time=").concat(this.updatedAt, "&token=").concat(accessToken, "&client_token=").concat(apiKey);
      return file ? url : '';
    }),
    authUrlForThumb: Ember.computed('urlForThumb', 'session.data.authenticated.jwt', 'updatedAt', function () {
      var thumb = this.urlForThumb;
      var apiKey = _environment.default.API_KEY;
      var accessToken = this.session.data.authenticated.jwt;
      var url = "".concat(thumb, "?time=").concat(this.updatedAt, "&token=").concat(accessToken, "&client_token=").concat(apiKey);
      return thumb ? url : '';
    })
  });

  _exports.default = _default;
});