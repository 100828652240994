define("front-end/helpers/five-days-from-now", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fiveDaysFromNow = fiveDaysFromNow;
  _exports.default = void 0;

  function fiveDaysFromNow(params
  /*, hash*/
  ) {
    var daysToExpire;

    if (params) {
      daysToExpire = params;
      daysToExpire.setDate(daysToExpire.getDate() + 5);
      return daysToExpire.toISOString();
    } else {
      daysToExpire = new Date(Date.now());
      daysToExpire.setDate(daysToExpire.getDate() + 5);
      return daysToExpire.toISOString();
    }
  }

  var _default = Ember.Helper.helper(fiveDaysFromNow);

  _exports.default = _default;
});