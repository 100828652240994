define("front-end/controllers/index/signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    previousTransition: null,
    password: "",
    email: "",
    _showInvalidLogin: function _showInvalidLogin(msg) {
      this.toasterMessages.showErrors([msg]);
    },
    actions: {
      recoveryPassword: function recoveryPassword() {
        var _this = this;

        var email = this.email;

        if (email) {
          var action = this.store.createRecord('actions/recovery-password-action', {
            email: email
          });
          action.save().then(function () {
            _this.toasterMessages.showSuccess(['Por favor, verifique sua caixa de email']);
          });
        } else {
          this.toasterMessages.showErrors(['Digite seu email na caixa de texto']);
        }
      },
      authenticate: function authenticate() {
        var _this2 = this;

        var authenticator = 'authenticator:jwt';
        var credentials = {
          email: this.email,
          password: this.password
        };
        this.session.authenticate(authenticator, credentials).then(function () {
          _this2.currentUser.load().then(function (user) {
            var previousTransition = _this2.previousTransition;

            if (previousTransition) {
              _this2.set('previousTransition', null);

              previousTransition.retry();
            } else {
              if (user.get('isAdmin')) {
                _this2.transitionToRoute('admin');
              } else {
                _this2.transitionToRoute('dashboard', user.get('company.id'));
              }
            }
          }).catch(function () {
            _this2._showInvalidLogin('Essa conta não está ativa');
          });
        }).catch(function (e) {
          if (e.status === 404) {
            _this2._showInvalidLogin('E-mail e/ou senha inválidos');
          } else if (e.status == 403) {
            _this2._showInvalidLogin('O perfil da empresa que você pertence já existe! Aguarde até que os administradores da empresa aprovem o seu cadastro.');
          } else if (e.status >= 500) {
            _this2.toasterMessages.showErrors(['Serviço temporariamente indisponível. Tente novamente mais tarde']);
          }
        });
      }
    }
  });

  _exports.default = _default;
});