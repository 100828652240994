define("front-end/controllers/admin/estimate-chats", ["exports", "front-end/mixins/controller-pagination-mixin"], function (_exports, _controllerPaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    loadingModel: false,
    hasUnreadMessages: true,
    partMyId: '',
    projectName: '',
    company: '',
    estimateMyId: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model';
      var queryParams = ['hasUnreadMessages', 'partMyId', 'projectName', 'company', 'estimateMyId'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
    },
    _clearFilters: function _clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: this.defaultSizeValue,
        hasUnreadMessages: true,
        partMyId: '',
        projectName: '',
        company: '',
        estimateMyId: ''
      });
    },
    actions: {
      clearFilters: function clearFilters() {
        this._clearFilters();
      },
      applyFilter: function applyFilter(propName, value) {
        Ember.setProperties(this, _defineProperty({
          page: 1,
          size: this.defaultSizeValue,
          refreshedByFilter: true
        }, propName, value));
      },
      toggleActiveChats: function toggleActiveChats() {
        var bool = !this.hasUnreadMessages;
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          hasUnreadMessages: bool,
          refreshedByFilter: true
        });
      },
      filterByCompany: function filterByCompany(company) {
        var id = company ? company.get('id') : '';
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          company: id,
          refreshedByFilter: true
        });
      }
    }
  });

  _exports.default = _default;
});