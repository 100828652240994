define("front-end/mixins/route-pagination-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    paginationService: Ember.inject.service(),
    pageQueryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
      controller.set('size', this.paginationService.size);
      controller.set('pages', this.pagesTotal(controller.get("".concat(controller.paginatedModelName))));
    },
    getPageQueryParams: function getPageQueryParams(params) {
      // This approach has a problem when we do a transitionTo a resource with the default pagination size value
      // There is a test case breaking on pagination-test in favor of this problem
      if (params.size && params.size != this.paginationService.size && params.size != this.paginationService.defaultSizeValue) {
        this.set('paginationService.size', params.size);
      }

      return {
        page: {
          number: params['page'] || 1,
          size: this.paginationService.size
        }
      };
    },
    pagesTotal: function pagesTotal(model) {
      var maxPages = parseInt(model.get('meta.page-count')); // Create an array with N elements where each value is equal to its index

      var pages = Array.from(Array(maxPages).keys(), function (x) {
        return x + 1;
      });
      return Ember.A(pages);
    },
    actions: {
      refreshTable: function refreshTable() {
        this.controller.set('page', 1);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});