define("front-end/models/actions/answer-estimate-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    daysAmount: (0, _model.attr)('number'),
    answerMessage: (0, _model.attr)('string'),
    unitPrice: (0, _model.attr)('pt-currency'),
    fromManufacturerFreightResponsible: (0, _model.attr)('string'),
    estimate: (0, _model.belongsTo)('estimate', {
      inverse: null
    })
  });

  _exports.default = _default;
});