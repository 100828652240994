define("front-end/mixins/component-block-page-scroll", ["exports", "front-end/mixins/custom-body-scroll-lock"], function (_exports, _customBodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_customBodyScrollLock.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.customDisableBodyScroll();
    },
    willDestroyElement: function willDestroyElement() {
      this.customEnableBodyScroll();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});