define("front-end/components/tasks-table-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var statusOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Pendente',
        value: 'active'
      }, {
        display: 'Cancelado',
        value: 'canceled'
      }, {
        display: 'Processado',
        value: 'processed'
      }];
      this.set('statusOptions', statusOptions);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var statusSelected = this.statusOptions.find(function (option) {
        return option.value == _this.status;
      });
      this.set('statusSelected', statusSelected);
    },
    actions: {
      callSelectStatus: function callSelectStatus(status) {
        this.set('statusSelected', status);
        this.selectStatus(status.value);
      }
    }
  });

  _exports.default = _default;
});