define("front-end/components/new-application-version-alert", ["exports", "ember-window-mock", "front-end/mixins/component-websocket-mixin"], function (_exports, _emberWindowMock, _componentWebsocketMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentWebsocketMixin.default, {
    paperToaster: Ember.inject.service(),
    applicationVersion: Ember.inject.service(),
    toaster: null,
    frontendVersion: Ember.computed.alias('applicationVersion.frontendVersion'),
    willDestroyElement: function willDestroyElement() {
      this._tearDownToaster();

      this._super.apply(this, arguments);
    },
    _tearDownToaster: function _tearDownToaster() {
      var toaster = this.toaster;

      if (toaster) {
        this.set('toaster', null);
        this.paperToaster.cancelToast(toaster);
      }
    },
    websocketMessageHandlerReady: function websocketMessageHandlerReady() {
      var channelObject = {
        channel: 'UsersChannel'
      };
      var data = {
        action: 'requesting_version'
      };
      this.sendCustomMessage(channelObject, data);
    },
    websocketRawHandler: function websocketRawHandler(payload, payloadType, payloadEvent) {
      if (payloadEvent == 'frontend_version_requested') {
        if (this.frontendVersion && this.frontendVersion != payload) {
          this.send('showToasterForNewMessages', 'Temos atualizações na plataforma, por favor recarregue a página.');
        }

        this.set('frontendVersion', payload);
      }

      if (payloadEvent == 'frontend_version_updated') {
        if (this.frontendVersion != payload) {
          this.set('frontendVersion', payload);
          this.send('showToasterForNewMessages', 'Temos atualizações na plataforma, por favor recarregue a página.');
        }
      }
    },
    actions: {
      showToasterForNewMessages: function showToasterForNewMessages(message) {
        var instance = this;

        if (!this.toaster) {
          this.set('toaster', this.paperToaster.show(message, {
            duration: false,
            position: 'bottom right',
            toastClass: 'custom-refresh-toaster',
            action: {
              accent: true,
              label: 'Recarregar',
              onClick: function onClick() {
                _emberWindowMock.default.scrollTo(0, 0);

                instance._tearDownToaster();

                _emberWindowMock.default.location.reload(true);
              }
            }
          }));
        }
      }
    }
  });

  _exports.default = _default;
});