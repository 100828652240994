define("front-end/routes/admin/statistics/quotations", ["exports", "front-end/mixins/route-query-param-bug"], function (_exports, _routeQueryParamBug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeQueryParamBug.default, {
    model: function model() {
      return this.store.query('company', {
        fields: {
          'companies': 'id'
        }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('groupByOptions', [{
        display: 'Acumulado',
        value: null
      }, {
        display: 'Ano',
        value: 'year'
      }, {
        display: 'Mês ao longo dos anos',
        value: 'month_by_year'
      }]);
      controller.set('groupBySelected', controller.groupByOptions[2]);
      controller.set('groupBy', controller.get('groupBySelected.value'));
      if (!Ember.isEmpty(controller.get('clients'))) controller.set('showClientFilters', true);
    }
  });

  _exports.default = _default;
});