define("front-end/controllers/dashboard/estimates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    showChat: false,
    queryParams: ['showChat'],
    actions: {
      toggleShowChat: function toggleShowChat() {
        this.toggleProperty('showChat');
      },
      afterAction: function afterAction() {
        this.send('refreshRoute');
      }
    }
  });

  _exports.default = _default;
});