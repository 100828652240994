define("front-end/components/pipeline/item-defining-manufacturer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    showEstimateConfirmationDialog: false,
    isSuperAdmin: Ember.computed.reads('currentUser.user.isSuperAdmin'),
    hasJobOfferProcess: Ember.computed('mechanism.type', function () {
      return this.mechanism && this.mechanism.type == 'job-offer-process-item';
    }),
    hasEstimateConfirmation: Ember.computed('mechanism.type', function () {
      return this.mechanism && this.mechanism.type == 'estimate-confirmation';
    }),
    offersCount: Ember.computed('mechanism.process.offers.[]', 'hasJobOfferProcess', function () {
      if (this.hasJobOfferProcess) {
        return this.mechanism.get('process.offers.length');
      } else {
        return 0;
      }
    }),
    hasMechanism: Ember.computed.bool('mechanism'),
    jobOfferProcessItemsCount: Ember.computed.reads('job.jobOfferProcessItems.length'),
    estimateConfirmationCount: Ember.computed.reads('job.estimateConfirmations.length'),
    interestedOffers: Ember.computed.filterBy('mechanism.process.offers', 'interested'),
    interestedCount: Ember.computed.reads('interestedOffers.length'),
    refusedOffers: Ember.computed.filterBy('mechanism.process.offers', 'refused'),
    refusedCount: Ember.computed.reads('refusedOffers.length'),
    className: Ember.computed('batch.invisible', function () {
      var className = '';
      className = this.batch.get('lost') ? className + ' lost' : className;
      className = this.batch.get('invisible') ? className + ' invisible' : className;
      return className;
    }),
    actions: {
      cancelEstimateConfirmation: function cancelEstimateConfirmation() {
        var _this = this;

        this.modalsManager.prompt({
          disallowEmpty: true,
          title: 'Interromper Confirmação de Orçamento',
          bodyComponent: 'custom-modals/cancel-estimate-confirmation-prompt-body',
          footerComponent: 'custom-modals/default-actions'
        }).then(function (message) {
          var estimateConfirmation = _this.mechanism;

          var action = _this.store.createRecord('actions/cancel-estimate-confirmation-action', {
            estimateConfirmation: estimateConfirmation,
            message: message
          });

          action.save().then(function () {
            _this.toasterMessages.showSuccess(['Confirmação de Orçamento cancelada']);
          });
        }).catch(function () {});
      },
      cancelJobOfferProcess: function cancelJobOfferProcess() {
        var _this2 = this;

        this.modalsManager.prompt({
          disallowEmpty: true,
          title: 'Interromper Job Bord',
          bodyComponent: 'custom-modals/cancel-job-offer-process-prompt-body',
          footerComponent: 'custom-modals/default-actions'
        }).then(function (message) {
          var jobOfferProcess = _this2.mechanism.process;

          var action = _this2.store.createRecord('actions/cancel-job-offer-process-action', {
            jobOfferProcess: jobOfferProcess,
            message: message
          });

          action.save().then(function () {
            _this2.toasterMessages.showSuccess(['Job Board interrompido']);
          });
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});