define("front-end/templates/admin/forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECwy2EEz",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[6,[37,0],null,[[\"data-test-card\"],[true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"error-page-logo layout-column layout-align-center-center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"data-test-img\",\"\"],[14,\"alt\",\"Peerdustry\"],[14,\"src\",\"/assets/logos/vertical.png\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,\"data-test-text\",\"\"],[14,0,\"layout-column layout-align-center-center error-page-text\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Página não encontrada\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Você está tentando acessar uma página não existente, por favor verifique se a URL esta correta.\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"paper-card\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/forbidden.hbs"
    }
  });

  _exports.default = _default;
});