define("front-end/components/custom-side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    show: false,
    classNames: ['custom-side-bar'],
    arrowIcon: Ember.computed('show', function () {
      return this.show ? 'keyboard_arrow_right' : 'keyboard_arrow_left';
    })
  });

  _exports.default = _default;
});