define("front-end/templates/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9yM9U89b",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-gt-sm-row layout-align-center-center flex-grow\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-gt-sm-nogrow flex-100 layout-column layout-align-center-center\"],[12],[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@class\",\"@icon\",\"@size\"],[\"info-text\",\"support-agent\",\"200\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"info-text flex-gt-sm-40 flex-100\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\" \\n      Identificamos que essa página não está agregando valor para os usuários da plataforma.\\n    \"],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n      Optamos por deixá-la mais limpa neste momento. Estamos trabalhando em uma nova proposta.\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front-end/templates/admin/index.hbs"
    }
  });

  _exports.default = _default;
});