define("front-end/models/files/tag-image", ["exports", "@ember-data/model", "front-end/models/files/application-file"], function (_exports, _model, _applicationFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationFile.default.extend({
    urlForThumb: (0, _model.attr)('string'),
    resource: (0, _model.belongsTo)('tags/application-tag', {
      inverse: 'tagImage'
    })
  });

  _exports.default = _default;
});