define("front-end/components/manufacturer-onboarding/manufacturer-terms-stage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperCard class='onboarding-stage fulfilled-stage' as |card|>
    <card.content class="layout-column layout-align-start-center">
      <span class="stage-order">Etapa {{@stageNumber}}</span>
      <div class="main-icon">
        <PaperIcon @icon="check-circle" class="success-text" @size={{48}}/>
      </div>
  
      <span class="stage-name">Termos de Uso de Parceiro de Manufatura</span>
      <small class="card-counter">Assinado em {{moment-format @company.manufacturerProfile.becameManufacturerAt "DD/MM/YYYY"}}</small>
    </card.content>
  </PaperCard>
  
  */
  {
    id: "rJ47Yh4V",
    block: "{\"symbols\":[\"card\",\"@stageNumber\",\"@company\"],\"statements\":[[8,\"paper-card\",[[24,0,\"onboarding-stage fulfilled-stage\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"content\"]],[[24,0,\"layout-column layout-align-start-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"stage-order\"],[12],[2,\"Etapa \"],[1,[32,2]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"main-icon\"],[12],[2,\"\\n      \"],[8,\"paper-icon\",[[24,0,\"success-text\"]],[[\"@icon\",\"@size\"],[\"check-circle\",48]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"stage-name\"],[12],[2,\"Termos de Uso de Parceiro de Manufatura\"],[13],[2,\"\\n    \"],[10,\"small\"],[14,0,\"card-counter\"],[12],[2,\"Assinado em \"],[1,[30,[36,0],[[32,3,[\"manufacturerProfile\",\"becameManufacturerAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\"]}",
    meta: {
      moduleName: "front-end/components/manufacturer-onboarding/manufacturer-terms-stage.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});