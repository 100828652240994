define("front-end/components/filters/toggle-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    isAccent: Ember.computed('value', function () {
      var value = this.value;
      return value === true || value === 'true';
    }),
    isWarn: Ember.computed('value', function () {
      var value = this.value;
      return value === false || value === 'false';
    })
  });

  _exports.default = _default;
});