define("front-end/components/thumb-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test-thumb class={{concat 'layout-column layout-align-center-center' @class}}>
    <a href={{@pdf}} class="thumb-image" target="_blank">
      {{#if @stored}}
        <img data-test-img src={{@url}} width={{@width}} height={{@height}}>
      {{else}}
        <PaperProgressCircular data-test-thumb-loader class="md-hue-2" @diameter={{25}} />
      {{/if}}
    </a>
  </div>
  
  */
  {
    id: "SUWKdkXy",
    block: "{\"symbols\":[\"@url\",\"@width\",\"@height\",\"@class\",\"@pdf\",\"@stored\"],\"statements\":[[10,\"div\"],[14,\"data-test-thumb\",\"\"],[15,0,[30,[36,0],[\"layout-column layout-align-center-center\",[32,4]],null]],[12],[2,\"\\n  \"],[10,\"a\"],[15,6,[32,5]],[14,0,\"thumb-image\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n\"],[6,[37,1],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,\"data-test-img\",\"\"],[15,\"src\",[32,1]],[15,\"width\",[32,2]],[15,\"height\",[32,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"paper-progress-circular\",[[24,\"data-test-thumb-loader\",\"\"],[24,0,\"md-hue-2\"]],[[\"@diameter\"],[25]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    meta: {
      moduleName: "front-end/components/thumb-image.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});