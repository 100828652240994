define("front-end/models/actions/merge-job-offer-processes-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    items: (0, _model.hasMany)('job-offer-process-item', {
      inverse: null
    }),
    processToKeep: (0, _model.belongsTo)('job-offer-process', {
      inverse: null
    })
  });

  _exports.default = _default;
});