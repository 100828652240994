define("front-end/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('create-batches-content-modal'), this.toValue(true), this.use('crossFade', {
      duration: 1000
    }), this.reverse('crossFade', {
      duration: 1000
    }));
    this.transition(this.hasClass('item-content-yield'), this.toValue(true), this.use('toLeft', {
      duration: 500
    }));
    this.transition(this.fromRoute('admin.companies-groups.index'), this.toRoute('admin.companies-groups.show'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('job-offer-processes-build'), this.fromRoute('admin.job-offer-processes.build.index'), this.toRoute('admin.job-offer-processes.build.item'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.hasClass('file-image-from-left'), this.use('toLeft'));
    this.transition(this.hasClass('file-image-from-right'), this.use('toRight'));
    this.transition(this.fromRoute('admin.companies.show.client'), this.toRoute('admin.companies.show.manufacturer'), this.use('toLeft'), this.reverse('toRight'));
  }
});