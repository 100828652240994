define("front-end/templates/components/videoask-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5X72Jhco",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"script\"],[15,\"src\",[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"src\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/videoask-widget.hbs"
    }
  });

  _exports.default = _default;
});