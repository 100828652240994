define("front-end/templates/components/estimates-summary-by-period-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QWo5/HLY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"canvas\"],[14,\"data-test-estimates-summary-by-period-chart\",\"\"],[14,1,\"estimates-summary-by-period-chart\"],[14,\"width\",\"auto\"],[15,\"height\",[34,0]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"height\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimates-summary-by-period-chart.hbs"
    }
  });

  _exports.default = _default;
});