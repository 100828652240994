define("front-end/models/actions/generate-commercial-proposal-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    mainContactName: (0, _model.attr)('string'),
    contactEmails: (0, _model.attr)(),
    // Array
    note: (0, _model.attr)('string'),
    project: (0, _model.belongsTo)('project', {
      inverse: null
    })
  });

  _exports.default = _default;
});