define("front-end/components/manufacturer-homologation-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    readyToHomologate: Ember.computed.and('company.manufacturerProfile.homologationClassification', 'company.manufacturerProfile.homologationDate'),
    actions: {
      selectClassification: function selectClassification(option) {
        var profile = this.company.manufacturerProfile;
        profile.set('homologationClassification', option);
      }
    }
  });

  _exports.default = _default;
});