define("front-end/controllers/dashboard/quotations/history", ["exports", "front-end/mixins/controller-pagination-mixin", "front-end/constants"], function (_exports, _controllerPaginationMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    myId: '',
    status: '',
    partName: '',
    deadlineGte: '',
    deadlineLte: '',
    projectName: '',
    internalCode: '',
    loadingModel: false,
    statusSelected: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.quotations';
      var queryParams = ['myId', 'status', 'projectName', 'internalCode', 'partName', 'deadlineGte', 'deadlineLte'];
      this.queryParams = [].concat(queryParams);
      this.statusOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Aceito',
        value: _constants.QUOTATION_STATUS.ACCEPTED
      }, {
        display: 'Expirado',
        value: _constants.QUOTATION_STATUS.TIMEOUT
      }, {
        display: 'Cancelado',
        value: _constants.QUOTATION_STATUS.CLOSED
      }, {
        display: 'Declinado',
        value: _constants.QUOTATION_STATUS.DECLINED
      }];
    },
    afterApplyFilters: function afterApplyFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: 10,
        refreshedByFilter: true
      });
    },
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: this.defaultSizeValue,
        refreshedByFilter: true
      });
    },
    actions: {
      clearFilters: function clearFilters() {
        this._resetFilters();
      }
    }
  });

  _exports.default = _default;
});