define("front-end/templates/components/toaster-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GB/rFT0X",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-space-between flex-grow\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,1]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center\"],[12],[2,\"\\n\"],[6,[37,4],null,[[\"class\",\"onClick\"],[\"clear-toaster-button\",[30,[36,3],[[32,0],\"clearToaster\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      Limpar\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"messages\",\"-track-array\",\"each\",\"action\",\"paper-button\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/toaster-text.hbs"
    }
  });

  _exports.default = _default;
});