define("front-end/templates/components/pricing-process-option-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "haGVC1bU",
    "block": "{\"symbols\":[\"controls\"],\"statements\":[[6,[37,6],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"class\",\"onClick\",\"data-test-initialized\"],[[30,[36,8],[[35,7,[\"level\"]],\" md-2-line mechanism-option option-initialized\"],null],[30,[36,3],[[32,0],[35,9]],null],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[35,7,[\"icon\"]]],[[\"class\"],[[30,[36,8],[[35,7,[\"level\"]],\"-text md-avatar-icon round-icon\"],null]]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[10,\"b\"],[12],[1,[34,1]],[13],[13],[2,\"\\n      \"],[10,\"p\"],[12],[1,[35,7,[\"statusMessage\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,6],[[35,5,[\"allowNewPricingProcesses\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"class\",\"onClick\",\"data-test-uninitialized\"],[\"unfocus-text md-2-line mechanism-option option-uninitialized\",[30,[36,3],[[32,0],[35,2]],null],true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"add-circle-outline\"],[[\"class\"],[\"unfocus-text\"]]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n        \"],[10,\"h3\"],[12],[10,\"b\"],[12],[1,[34,1]],[13],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Clique para iniciar\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"label\",\"initializeAction\",\"action\",\"paper-item\",\"quotation\",\"if\",\"pricingProcess\",\"concat\",\"showAction\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/pricing-process-option-card.hbs"
    }
  });

  _exports.default = _default;
});