define("front-end/models/notification", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    level: (0, _model.attr)('string'),
    readAt: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    recipient: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    notifiable: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    }),
    icon: Ember.computed('level', 'readAt', function () {
      var icon = '';

      switch (this.level) {
        case 'info':
          icon = 'info';
          break;

        case 'danger':
          icon = 'error';
          break;

        case 'warning':
          icon = 'warning';
          break;

        case 'success':
          icon = 'info';
          break;
      }

      return icon;
    }),
    notificationStatusClass: Ember.computed('readAt', function () {
      return this.readAt ? '' : 'error';
    })
  });

  _exports.default = _default;
});