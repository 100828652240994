define("front-end/models/companies-group", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    companies: (0, _model.hasMany)('company', {
      inverse: 'companiesGroups'
    })
  });

  _exports.default = _default;
});