define("front-end/templates/components/dynamic-page-header/sticked-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7jdJM5Tt",
    "block": "{\"symbols\":[\"card\",\"&default\"],\"statements\":[[6,[37,2],null,[[\"class\",\"data-test-sticked-header\"],[\"dynamic-header-sticked-header\",true]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('front-end/templates/components/dynamic-page-header/sticked-header.hbs' @ L2:C5) \"],null]],[[\"class\"],[\"layout-column layout-align-space-between\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"paper-card\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/dynamic-page-header/sticked-header.hbs"
    }
  });

  _exports.default = _default;
});