define("front-end/initializers/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // Keep this file in alphabetical order
  function initialize()
  /*application*/
  {
    var inflector = _emberInflector.default.inflector;
    inflector.irregular('dia', 'dias');
    inflector.irregular('Máquina Registrada', 'Máquinas Registradas');
  }

  var _default = {
    name: 'custom-inflector-rules',
    initialize: initialize
  };
  _exports.default = _default;
});