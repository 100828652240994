define("front-end/templates/components/companies-group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XsSsbeAJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,12],null,[[\"data-test-companies-group-item\",\"class\",\"onClick\",\"mouseEnter\",\"mouseLeave\"],[[35,2,[\"name\"]],[30,[36,11],[\"md-3-line companies-group-item \",[30,[36,7],[[35,10],\"active-group grey\",\"\"],null]],null],[30,[36,8],[[32,0],[35,9],[35,2,[\"id\"]]],null],[30,[36,8],[[32,0],\"hoverElement\"],null],[30,[36,8],[[32,0],\"hoverElement\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,\"data-test-companies-group-name\",\"\"],[14,0,\"companies-group-title\"],[12],[1,[35,2,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"h4\"],[14,\"data-test-group-creation-date\",\"\"],[12],[2,\"criado em: \"],[1,[30,[36,3],[[35,2,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[14,\"data-test-group-companies-counter\",\"\"],[12],[1,[30,[36,5],[[35,4],\"Empresa\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"md-secondary-container\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[35,0]],[[\"accent\",\"size\",\"data-test-arrow-item\"],[true,20,true]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,[34,13]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"arrowIcon\",\"paper-icon\",\"group\",\"moment-format\",\"groupCompaniesCount\",\"pluralize\",\"showItemArrow\",\"if\",\"action\",\"onClickAction\",\"groupRouteOpened\",\"concat\",\"paper-item\",\"paper-divider\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/companies-group-item.hbs"
    }
  });

  _exports.default = _default;
});