define("front-end/utils/download-file", ["exports", "file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = downloadFile;

  function downloadFile(file, filename) {
    return (0, _fileSaver.saveAs)(file, filename);
  }
});