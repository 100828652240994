define("front-end/components/company-list-filter", ["exports", "front-end/mixins/component-company-filters-mixin"], function (_exports, _componentCompanyFiltersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentCompanyFiltersMixin.default, {
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.companies = [];
    },
    didInsertElement: function didInsertElement() {
      this._fetchCompanyNames.perform();
    },
    shouldLoadChildComponent: Ember.computed.reads('companies.length'),
    addedCompanies: Ember.computed('arrayQueryParam.[]', function () {
      var _this = this;

      var companies = this.arrayQueryParam.map(function (id) {
        return _this.store.peekRecord('company', id);
      });
      return companies;
    })
  });

  _exports.default = _default;
});