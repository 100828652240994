define("front-end/mirage/factories/job-offer", ["exports", "ember-cli-mirage", "front-end/constants"], function (_exports, _emberCliMirage, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    company: (0, _emberCliMirage.association)(),
    available: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(jobOffer) {
        jobOffer.update({
          status: _constants.PROMPT_JOB_OFFER_STATUS.AVAILABLE
        });
      }
    }),
    accepted: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(jobOffer) {
        jobOffer.update({
          status: _constants.PROMPT_JOB_OFFER_STATUS.ACCEPTED
        });
      }
    })
  });

  _exports.default = _default;
});