define("front-end/templates/components/companies-group-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZcn1D2t",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[6,[37,3],null,[[\"readOnly\",\"removeItem\",\"addItem\",\"placeholder\",\"content\",\"options\",\"requireMatch\",\"searchField\",\"noMatchesMessage\",\"data-test-groups\"],[false,[30,[36,2],[[32,0],\"addOrRemoveGroup\"],null],[30,[36,2],[[32,0],\"addOrRemoveGroup\"],null],\"Filtrar por grupo\",[35,1],[35,0],true,\"name\",\"Não encontrado.\",true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"strong\"],[15,\"data-test-group-filter-name\",[32,1,[\"name\"]]],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"remainingGroups\",\"addedGroups\",\"action\",\"paper-chips\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/companies-group-filter.hbs"
    }
  });

  _exports.default = _default;
});