define("front-end/routes/admin/statistics/job-board", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    model: function model() {
      this._super.apply(this, arguments);

      var toBeReturned = {};
      var pageFilterOptions = {
        page: {
          size: 1,
          number: 1
        }
      }; // const pricingProcessesTotal = this.store.query('pricing-process', pageFilterOptions)
      //   .then(pricingProcesses => pricingProcesses.get('meta.count'));
      // JobOfferProcesses Queries

      var jobOfferProcessesTotal = this.store.query('job-offer-process-item', pageFilterOptions).then(function (jobOfferProcesses) {
        return jobOfferProcesses.get('meta.count');
      });
      toBeReturned['jobOfferProcessesTotal'] = jobOfferProcessesTotal;
      var completedJobOfferProcesses = this.store.query('job-offer-process-item', _objectSpread({
        filter: {
          status: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.COMPLETED
        }
      }, pageFilterOptions)).then(function (jobOfferProcesses) {
        return jobOfferProcesses.get('meta.count');
      });
      toBeReturned['completedJobOfferProcesses'] = completedJobOfferProcesses;
      var canceledJobOfferProcesses = this.store.query('job-offer-process-item', _objectSpread({
        filter: {
          status: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.CANCELED
        }
      }, pageFilterOptions)).then(function (jobOfferProcesses) {
        return jobOfferProcesses.get('meta.count');
      });
      toBeReturned['canceledJobOfferProcesses'] = canceledJobOfferProcesses;
      var activeJobOfferProcesses = this.store.query('job-offer-process-item', _objectSpread({
        filter: {
          'status.lte': _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE
        }
      }, pageFilterOptions)).then(function (jobOfferProcesses) {
        return jobOfferProcesses.get('meta.count');
      });
      toBeReturned['activeJobOfferProcesses'] = activeJobOfferProcesses; // SupervisedQuote Queries

      var supervisedQuotesTotal = this.store.query('quotation', _objectSpread({
        filter: {
          selected_pricing_process_type: 'supervised-quote'
        }
      }, pageFilterOptions)).then(function (supervisedQuotes) {
        return supervisedQuotes.get('meta.count');
      });
      toBeReturned['supervisedQuotesTotal'] = supervisedQuotesTotal;
      var acceptedSupervisedQuotes = this.store.query('quotation', _objectSpread({
        filter: {
          status: _constants.QUOTATION_STATUS.ACCEPTED,
          selected_pricing_process_type: 'supervised-quote'
        }
      }, pageFilterOptions)).then(function (supervisedQuotes) {
        return supervisedQuotes.get('meta.count');
      });
      toBeReturned['acceptedSupervisedQuotes'] = acceptedSupervisedQuotes;
      var refusedSupervisedQuotes = this.store.query('quotation', _objectSpread({
        filter: {
          status: _constants.QUOTATION_STATUS.DECLINED,
          selected_pricing_process_type: 'supervised-quote'
        }
      }, pageFilterOptions)).then(function (supervisedQuotes) {
        return supervisedQuotes.get('meta.count');
      });
      toBeReturned['refusedSupervisedQuotes'] = refusedSupervisedQuotes;
      var estimatesSurveyTotal = this.store.query('quotation', _objectSpread({
        filter: {
          selected_pricing_process_type: 'estimate-survey'
        }
      }, pageFilterOptions)).then(function (estimatesSurvey) {
        return estimatesSurvey.get('meta.count');
      });
      toBeReturned['estimatesSurveyTotal'] = estimatesSurveyTotal;
      return Ember.RSVP.hash(toBeReturned);
    }
  });

  _exports.default = _default;
});