define("front-end/models/files/three-dimensional-design", ["exports", "@ember-data/model", "front-end/config/environment", "front-end/models/files/application-file"], function (_exports, _model, _environment, _applicationFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationFile.default.extend({
    label: 'Modelo 3D',
    fileProp: 'threeDimensionalDesign',
    endpoint: "".concat(_environment.default.host, "/core/v1/files/three-dimensional-designs"),
    resource: (0, _model.belongsTo)('part-revision', {
      inverse: 'threeDimensionalDesign'
    }),
    allowedExtensions: '.step, .stp, .stl',
    showThumb: false
  });

  _exports.default = _default;
});