define("front-end/helpers/fix-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // This helper handles the lack of protocol in url's, which causes problems when
  // used as href params of anchor tags. Without the transformations applied by this helper,
  // when a user clicks in a link having the shape:
  //
  // - <a href="www.example.com" target="_blank">my site</a>
  //
  // Ember will prepend with the currentUrl like => http://localhost:4200/admin/companies/show/www.example.com
  //
  // When using this helper inside a template, you should provide a single string
  // as argument.
  // The output will be a string.

  /**
    @param {url} [String] The url to be handled
    @return {String} The handled url String.
  */
  var _default = Ember.Helper.helper(function fixUrl(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        url = _ref2[0];

    if (url.length == 0) return;
    var protocolPattern = new RegExp('^https?');

    if (protocolPattern.test(url)) {
      return url;
    }

    return "//".concat(url);
  });

  _exports.default = _default;
});