define("front-end/templates/components/pipeline/pipeline-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i/qoL6cT",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pipeline/project-info\",[],[[\"@project\"],[[32,0,[\"project\"]]]],null],[2,\"\\n\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"project\",\"zoomIn\",\"zoomOut\"],[[35,1],[30,[36,0],[[32,0],\"zoomIn\"],null],[30,[36,0],[[32,0],\"zoomOut\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"project-pipeline\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pipeline-stage-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"project\"],[[35,1]]]]],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"project\"],[[35,1]]]]],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"project\"],[[35,1]]]]],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"project\"],[[35,1]]]]],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"project\"],[[35,1]]]]],[2,\"\\n    \"],[1,[30,[36,9],null,[[\"project\"],[[35,1]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"project\",\"project-info\",\"custom-side-bar\",\"pipeline/stage-analysis\",\"pipeline/stage-quotation\",\"pipeline/stage-formalizing\",\"pipeline/stage-defining-manufacturer\",\"pipeline/stage-manufacturing\",\"pipeline/stage-completed\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/pipeline/pipeline-wrapper.hbs"
    }
  });

  _exports.default = _default;
});