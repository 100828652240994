define("front-end/routes/admin/machines/index", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        machineType: {
          refreshModel: true
        },
        machineModel: {
          refreshModel: true
        },
        company: {
          refreshModel: true
        },
        automationLevel: {
          refreshModel: true
        },
        cncManufacturer: {
          refreshModel: true
        },
        brand: {
          refreshModel: true
        },
        rectifyingType: {
          refreshModel: true
        },
        electroType: {
          refreshModel: true
        },
        axisAmount: {
          refreshModel: true
        },
        xTrackGte: {
          refreshModel: true
        },
        xTrackLte: {
          refreshModel: true
        },
        yTrackGte: {
          refreshModel: true
        },
        yTrackLte: {
          refreshModel: true
        },
        zTrackGte: {
          refreshModel: true
        },
        zTrackLte: {
          refreshModel: true
        },
        aTrackGte: {
          refreshModel: true
        },
        aTrackLte: {
          refreshModel: true
        },
        diameterLte: {
          refreshModel: true
        },
        diameterGte: {
          refreshModel: true
        },
        passageBusDiameterLte: {
          refreshModel: true
        },
        passageBusDiameterGte: {
          refreshModel: true
        },
        passageCarDiameterLte: {
          refreshModel: true
        },
        passageCarDiameterGte: {
          refreshModel: true
        },
        maxDiameterLte: {
          refreshModel: true
        },
        maxDiameterGte: {
          refreshModel: true
        },
        maxDiameterBusLte: {
          refreshModel: true
        },
        maxDiameterBusGte: {
          refreshModel: true
        },
        maxDiameterCarLte: {
          refreshModel: true
        },
        maxDiameterCarGte: {
          refreshModel: true
        },
        maxWeightLte: {
          refreshModel: true
        },
        maxWeightGte: {
          refreshModel: true
        },
        passageDiameterLte: {
          refreshModel: true
        },
        passageDiameterGte: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var sortBy = {
        sort: '-createdAt'
      };

      var queryOptions = _objectSpread(_objectSpread({
        filter: {},
        include: 'company'
      }, sortBy), this.getPageQueryParams(params));

      if (params.myId) {
        queryOptions.filter['myId.match'] = params.myId;
      }

      if (params.company) {
        queryOptions.filter['company_id'] = params.company;
      }

      if (params.brand) {
        queryOptions.filter['brand.match'] = params.brand;
      }

      if (params.machineModel) {
        queryOptions.filter['model.match'] = params.machineModel;
      }

      if (params.rectifyingType) {
        queryOptions.filter['rectifyingType.match'] = params.rectifyingType;
      }

      if (params.electroType) {
        queryOptions.filter['electroType.match'] = params.electroType;
      }

      if (params.axisAmount) {
        queryOptions.filter['axisNumber'] = params.axisAmount;
      }

      if (params.automationLevel) {
        queryOptions.filter['automationLevel'] = params.automationLevel;
      }

      if (params.cncManufacturer) {
        queryOptions.filter['cncManufacturer'] = params.cncManufacturer;
      }

      if (params.xTrackGte) {
        queryOptions.filter['xTrack.gte'] = params.xTrackGte;
      }

      if (params.xTrackLte) {
        queryOptions.filter['xTrack.lte'] = params.xTrackLte;
      }

      if (params.yTrackGte) {
        queryOptions.filter['yTrack.gte'] = params.yTrackGte;
      }

      if (params.yTrackLte) {
        queryOptions.filter['yTrack.lte'] = params.yTrackLte;
      }

      if (params.zTrackGte) {
        queryOptions.filter['zTrack.gte'] = params.zTrackGte;
      }

      if (params.zTrackLte) {
        queryOptions.filter['zTrack.lte'] = params.zTrackLte;
      }

      if (params.aTrackGte) {
        queryOptions.filter['aTrack.gte'] = params.aTrackGte;
      }

      if (params.aTrackLte) {
        queryOptions.filter['aTrack.lte'] = params.aTrackLte;
      }

      if (params.aTrackGte) {
        queryOptions.filter['aTrack.gte'] = params.aTrackGte;
      }

      if (params.diameterGte) {
        queryOptions.filter['diameter.gte'] = params.diameterGte;
      }

      if (params.diameterLte) {
        queryOptions.filter['diameter.lte'] = params.diameterLte;
      }

      if (params.passageBusDiameterGte) {
        queryOptions.filter['passageBusDiameter.gte'] = params.passageBusDiameterGte;
      }

      if (params.passageBusDiameterLte) {
        queryOptions.filter['passageBusDiameter.lte'] = params.passageBusDiameterLte;
      }

      if (params.passageCarDiameterGte) {
        queryOptions.filter['passageCarDiameter.gte'] = params.passageCarDiameterGte;
      }

      if (params.passageCarDiameterLte) {
        queryOptions.filter['passageCarDiameter.lte'] = params.passageCarDiameterLte;
      }

      if (params.maxDiameterGte) {
        queryOptions.filter['maxDiameter.gte'] = params.maxDiameterGte;
      }

      if (params.maxDiameterLte) {
        queryOptions.filter['maxDiameter.lte'] = params.maxDiameterLte;
      }

      if (params.maxDiameterCarGte) {
        queryOptions.filter['maxDiameterCar.gte'] = params.maxDiameterCarGte;
      }

      if (params.maxDiameterCarLte) {
        queryOptions.filter['maxDiameterCar.lte'] = params.maxDiameterCarLte;
      }

      if (params.maxDiameterBusGte) {
        queryOptions.filter['maxDiameterBus.gte'] = params.maxDiameterBusGte;
      }

      if (params.maxDiameterBusLte) {
        queryOptions.filter['maxDiameterBus.lte'] = params.maxDiameterBusLte;
      }

      if (params.maxWeightLte) {
        queryOptions.filter['maxWeight.lte'] = params.maxWeightLte;
      }

      if (params.maxWeightGte) {
        queryOptions.filter['maxWeight.gte'] = params.maxWeightGte;
      }

      if (params.passageDiameterLte) {
        queryOptions.filter['passageDiameter.lte'] = params.passageDiameterLte;
      }

      if (params.passageDiameterGte) {
        queryOptions.filter['passageDiameter.gte'] = params.passageDiameterGte;
      }

      var machinesCount = this.store.query('machines/machine', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (machines) {
        return machines.get('meta.count');
      });
      var machines;

      if (params.machineType) {
        machines = this.store.query("machines/".concat(params.machineType), queryOptions);
        return Ember.RSVP.hash({
          machines: machines,
          machinesCount: machinesCount,
          queryOptions: queryOptions
        });
      } else {
        machines = this.store.query('machines/machine', queryOptions);
        return Ember.RSVP.hash({
          machines: machines,
          machinesCount: machinesCount,
          queryOptions: queryOptions
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});