define("front-end/components/sort-input-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedOption: null,
    iconClass: Ember.computed('queryParam', function () {
      return this.queryParam[0] == '-' ? '' : 'inverted-icon';
    }),
    tooltipContent: Ember.computed('queryParam', function () {
      return this.queryParam[0] == '-' ? 'Ordem decrescente' : 'Ordem crescente';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.availableOptions = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var availableOptions = this.options.map(function (option) {
        var paramName = option.value.replace('-', '');

        if (paramName && _this.queryParam[0] == '-') {
          return {
            value: '-' + paramName,
            display: option.display
          };
        } else {
          return option;
        }
      });
      this.set('availableOptions', availableOptions);
      var selectedOption = availableOptions.find(function (option) {
        return option.value == _this.queryParam;
      });
      this.set('selectedOption', selectedOption);
    },
    actions: {
      callFilterAction: function callFilterAction(option) {
        this.set('selectedOption', option);
        this.filterAction(option);
      },
      toggleOrder: function toggleOrder() {
        var paramName = this.queryParam.replace('-', '');

        if (this.queryParam[0] == '-') {
          this.filterAction({
            value: paramName,
            display: this.selectedOption.display
          });
        } else {
          this.filterAction({
            value: '-' + paramName,
            display: this.selectedOption.display
          });
        }
      }
    }
  });

  _exports.default = _default;
});