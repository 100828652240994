define("front-end/routes/dashboard", ["exports", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-error-handler", "front-end/mixins/route-filter-loader"], function (_exports, _routeWebsocketHandlerMixin, _routeErrorHandler, _routeFilterLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, _routeErrorHandler.default, _routeFilterLoader.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    isIExplorer: false,
    parentRoute: 'dashboard',
    reconnectWebSocket: true,
    beforeModel: function beforeModel(transition) {
      if (!this.session.isAuthenticated) {
        var controller = this.controllerFor('index.signin', transition);
        controller.set('previousTransition', transition);
        this.transitionTo('index.signin');
      }

      var browser = window.navigator.userAgent.toLowerCase();
      var msie = browser.toLowerCase().includes('msie');

      if (msie) {
        this.set('isIExplorer', true);
      }
    },
    model: function model(params) {
      var _this = this;

      var user = this.currentUser.load();
      var company = this.store.findRecord('company', params.company_id).then(function (company) {
        _this.currentUser.setCompany(company);

        return company;
      });
      var activeTasksCount = this.store.query('tasks/task', {
        filter: {
          target: params.company_id,
          status: 'active'
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (tasks) {
        return tasks.get('meta.count');
      });
      return Ember.RSVP.hash({
        user: user,
        company: company,
        activeTasksCount: activeTasksCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('isIExplorer', this.isIExplorer);
    }
  });

  _exports.default = _default;
});