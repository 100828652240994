define("front-end/models/job-offer-process-item", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/constants", "front-end/models/helpers/util"], function (_exports, _model, _defaultModel, _constants, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    type: 'job-offer-process-item',
    humanizedType: 'Item do Processo de Oferta de Trabalho',
    note: (0, _model.attr)('string'),
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    concludedAt: (0, _model.attr)('string'),
    conclusionTime: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    videoAskUrl: (0, _model.attr)('string'),
    price: (0, _model.attr)('number'),
    unitPrice: (0, _model.attr)('number'),
    plannedDeliveryDate: (0, _model.attr)('string'),
    fee: (0, _model.attr)('number'),
    tax: (0, _model.attr)('number'),
    value: (0, _model.attr)('number'),
    unitValue: (0, _model.attr)('number'),
    daysAmount: (0, _model.attr)('number'),
    manufacturerDeliveryDate: (0, _model.attr)('deadline'),
    paymentMethod: (0, _model.attr)('string'),
    material: (0, _model.attr)('string'),
    rawMaterialFormat: (0, _model.attr)('string'),
    rawMaterialResponsible: (0, _model.attr)('string'),
    rawMaterialProvided: (0, _model.attr)('boolean'),
    rawMaterialUnitCost: (0, _model.attr)('number'),
    rawMaterialCost: (0, _model.attr)('number'),
    // raw material delivery info
    rawMaterialDeliveryDaysAmount: (0, _model.attr)('number'),
    rawMaterialDeliveryDate: (0, _model.attr)('string'),
    trackRawMaterialDelivery: (0, _model.attr)('boolean'),
    fromManufacturerFreightResponsible: (0, _model.attr)('string'),
    deliveryCity: (0, _model.attr)('string'),
    finalStatus: (0, _model.attr)('boolean'),
    requireMessage: (0, _model.attr)('boolean'),
    allowCancel: (0, _model.attr)('boolean'),
    allowNewOffers: (0, _model.attr)('boolean'),
    moderatedFieldsCompleted: (0, _model.attr)('boolean'),
    winnerOffer: (0, _model.belongsTo)('job-offer', {
      inverse: null,
      polymorphic: true
    }),
    winnerManufacturer: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    process: (0, _model.belongsTo)('job-offer-process', {
      inverse: 'items'
    }),
    manufacturingJob: (0, _model.belongsTo)('manufacturing-job', {
      inverse: 'jobOfferProcessItems'
    }),
    // Denormalized relationships
    batch: (0, _model.belongsTo)('batch', {
      inverse: null
    }),
    project: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    context: (0, _model.belongsTo)('batch-context', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    isAvailable: Ember.computed('status', function () {
      return this.status == _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE;
    }),
    isBeforeAvailable: Ember.computed('status', function () {
      return this.status < _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE;
    }),
    hasBeenAvailable: Ember.computed('status', function () {
      return this.status >= _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE;
    }),
    rawMaterialFromPeerdustry: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return option == 'peerdustry';
    }),
    rawMaterialFromClient: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return option == 'client';
    }),
    isResale: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return option == 'manufacturer';
    }),
    statusReference: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.MISSING_FILE:
          return 'missing_file';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_OFFER:
          return 'ready_to_estimate';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE:
          return 'estimating';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_SELECT_WINNER:
          return 'ready_to_select_winner';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.COMPLETED:
          return 'completed';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.FAILED:
          return 'failed';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.CANCELED:
          return 'closed';

        default:
          return '';
      }
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.MISSING_FILE:
          return 'cloud_upload';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_OFFER:
          return 'notifications_active';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE:
          return 'timer';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_SELECT_WINNER:
          return 'timer';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.COMPLETED:
          return 'check_circle';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.FAILED:
          return 'cancel';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.CANCELED:
          return 'cancel';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.MISSING_FILE:
          return 'warning';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_OFFER:
          return 'warning';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE:
          return 'info';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_SELECT_WINNER:
          return 'info';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.COMPLETED:
          return 'info';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.FAILED:
          return 'error';

        case _constants.JOB_OFFER_PROCESS_ITEM_STATUS.CANCELED:
          return 'error';

        default:
          return '';
      }
    }),
    humanizedRawMaterialResponsible: Ember.computed('rawMaterialResponsible', function () {
      switch (this.rawMaterialResponsible) {
        case 'peerdustry':
          return 'Peerdustry';

        case 'client':
          return 'Cliente';

        case 'manufacturer':
          return 'Fornecedor';

        default:
          return '';
      }
    }),
    computedManufacturerDeliveryDate: Ember.computed('daysAmount', 'manufacturerDeliveryDate', function () {
      if (!this.manufacturerDeliveryDate) return (0, _util.computeAdditionalDays)(this.daysAmount);
      return this.manufacturerDeliveryDate;
    })
  });

  _exports.default = _default;
});