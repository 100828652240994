define("front-end/models/purchase-order-item", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    type: 'purchase-order-item',
    customId: (0, _model.attr)('string'),
    creating: (0, _model.attr)('boolean'),
    // From part
    name: (0, _model.attr)('string'),
    internalCode: (0, _model.attr)('string'),
    partMyId: (0, _model.attr)('string'),
    // From part-revision
    buyUnit: (0, _model.attr)('string'),
    // From quotation
    unitPrice: (0, _model.attr)('number'),
    price: (0, _model.attr)('number'),
    commercialDaysAmount: (0, _model.attr)('number'),
    deliveryDate: (0, _model.attr)('deadline'),
    // From batch
    quantity: (0, _model.attr)('number'),
    batchMyId: (0, _model.attr)('string'),
    // raw material delivery info
    rawMaterialResponsible: (0, _model.attr)('string'),
    rawMaterialDeliveryDate: (0, _model.attr)('string'),
    trackRawMaterialDelivery: (0, _model.attr)('boolean'),
    zapierVersion: (0, _model.attr)('number'),
    purchaseOrder: (0, _model.belongsTo)('purchase-order', {
      inverse: 'items'
    }),
    batch: (0, _model.belongsTo)('batch', {
      inverse: 'purchaseOrderItem'
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    clientFormalization: (0, _model.belongsTo)('client-formalization', {
      inverse: 'purchaseOrderItem'
    }),
    humanizedRawMaterialResponsible: Ember.computed('rawMaterialResponsible', function () {
      var option = this.rawMaterialResponsible;
      return this._translateOption(option);
    }),
    _translateOption: function _translateOption(option) {
      return option == 'client' ? 'Cliente' : 'Fornecedor/Peerdustry';
    }
  });

  _exports.default = _default;
});