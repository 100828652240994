define("front-end/controllers/admin/companies-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    loadingModel: false,
    shouldShowCreateGroupDialog: false,
    groupName: '',
    showingGroupCompanies: Ember.computed.equal('router.currentRouteName', 'admin.companies-groups.show'),
    containerClass: Ember.computed('showingGroupCompanies', function () {
      return this.showingGroupCompanies ? 'layout-column flex-50' : 'layout-column flex-100';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.queryParams = ['groupName'];
    },
    orderedGroups: Ember.computed.sort('model.groups.@each.name', function (a, b) {
      if (!b.get('name')) {
        return 1;
      } // b should come first when has no name


      if (!a.get('name')) {
        return -1;
      } // a should come first when has no name
      // return localeCompare if both have names


      return a.get('name').localeCompare(b.get('name'));
    }),
    companiesGroupsCount: Ember.computed.reads('orderedGroups.length'),
    actions: {
      toggleSearchField: function toggleSearchField() {
        if (this.searchField && this.groupName) {
          this.send('clearFilter');
        }

        this.toggleProperty('searchField');
      },
      clearFilter: function clearFilter() {
        Ember.setProperties(this, {
          groupName: '',
          refreshedByFilter: true
        });
      },
      filterGroupName: function filterGroupName(groupName) {
        var currentRouteName = this.router.currentRouteName;

        if (currentRouteName == 'admin.companies-groups.show') {
          this.transitionToRoute('admin.companies-groups');
        }

        Ember.setProperties(this, {
          groupName: groupName,
          refreshedByFilter: true
        });
      },
      toggleGroup: function toggleGroup(groupId) {
        this.set('groupName', '');

        if (this.router.isActive('admin.companies-groups.show', groupId)) {
          this.transitionToRoute('admin.companies-groups');
        } else {
          this.transitionToRoute('admin.companies-groups.show', groupId);
        }
      },
      afterGroupCreation: function afterGroupCreation(group) {
        this.set('shouldShowCreateGroupDialog', false);
        this.transitionToRoute('admin.companies-groups.show', group.get('id'));
      }
    }
  });

  _exports.default = _default;
});