define("front-end/components/videoask-widget", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    url: null,
    text: "Precisa de ajuda?",
    widgetType: "VideoThumbnailSmall",
    backgroundColor: "#00437F",
    position: "bottom-right",
    init: function init() {
      this._super.apply(this, arguments);

      this.src = _environment.default.environment != 'test' ? "https://www.videoask.com/embed/embed.js" : '';
      window.VIDEOASK_EMBED_CONFIG = {
        "kind": "widget",
        "url": this.url,
        "options": {
          "widgetType": this.widgetType,
          "text": this.text,
          "backgroundColor": this.backgroundColor,
          "position": this.position
        }
      };
    }
  });

  _exports.default = _default;
});