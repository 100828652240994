define("front-end/models/raw-material-delivery", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    initialPlannedDate: (0, _model.attr)('string'),
    initialPlannedDaysAmount: (0, _model.attr)('number'),
    plannedDate: (0, _model.attr)('string'),
    plannedDaysAmount: (0, _model.attr)('number'),
    actualDate: (0, _model.attr)('string'),
    actualDaysAmount: (0, _model.attr)('number'),
    tracked: (0, _model.attr)('boolean'),
    batch: (0, _model.belongsTo)('batch'),
    context: (0, _model.belongsTo)('batch-context'),
    updatePlannedDateActions: (0, _model.hasMany)('actions/update-planned-date-action')
  });

  _exports.default = _default;
});