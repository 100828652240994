define("front-end/helpers/has-batch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasBatch = hasBatch;
  _exports.default = void 0;

  function hasBatch(params
  /*, hash*/
  ) {
    var partRevision = params[0];
    var batches = params[1] || [];
    return batches.filter(function (batch) {
      return batch.get('partRevision.id') == partRevision.get('id');
    }).length;
  }

  var _default = Ember.Helper.helper(hasBatch);

  _exports.default = _default;
});