define("front-end/components/loading-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class = {{@class}}>
    <div class="layout-row layout-align-center">
      <h3>Carregando...</h3>
    </div>
    <PaperProgressLinear />
  </div>
  */
  {
    id: "Q+uYb6db",
    block: "{\"symbols\":[\"@class\"],\"statements\":[[10,\"div\"],[15,0,[32,1]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row layout-align-center\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Carregando...\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"paper-progress-linear\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "front-end/components/loading-template.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});