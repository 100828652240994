define("front-end/models/client-profile", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    isClient: (0, _model.attr)('boolean'),
    clientCategory: (0, _model.attr)('number'),
    becameClientAt: (0, _model.attr)('string'),
    company: (0, _model.belongsTo)('company', {
      inverse: 'clientProfile'
    })
  });

  _exports.default = _default;
});