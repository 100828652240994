define("front-end/components/pipeline/item-completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var batch = this.batch;
      this.set('quotationId', batch.belongsTo('quotation').id());
      this.set('jobId', batch.belongsTo('manufacturingJob').id());
    },
    showPurchaseOrder: Ember.computed('orderItem.type', function () {
      return this.orderItem.type == 'purchase-order-item';
    }),
    className: Ember.computed('batch.invisible', function () {
      return this.batch.invisible ? 'invisible' : '';
    })
  });

  _exports.default = _default;
});