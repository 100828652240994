define("front-end/mixins/controller-estimates-summary-chart-filters-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    summariesUpperBound: null,
    summariesLowerBound: null,
    groupBy: 'month_by_year',
    init: function init() {
      this._super.apply(this, arguments);

      this.groupByOptions = [{
        display: 'Ano',
        value: 'year'
      }, {
        display: 'Mês agregado',
        value: 'month'
      }, {
        display: 'Mês ao longo dos anos',
        value: 'month_by_year'
      }, {
        display: 'Semana agregado',
        value: 'week'
      }, {
        display: 'Semana ao longo dos anos',
        value: 'week_by_year'
      }, {
        display: 'Dia da semana agregado',
        value: 'day_of_week'
      }, {
        display: 'Dia do mês',
        value: 'day_of_month'
      }, {
        display: 'Dia do mês ao longo dos meses',
        value: 'day_of_month_by_month'
      }];
      this.set('groupBySelected', this.groupByOptions[2]);
      this.queryParams = ['groupBy', 'summariesUpperBound', 'summariesLowerBound'];
    },
    actions: {
      filterByDate: function filterByDate(queryParamName, date) {
        this.set(queryParamName, date);
      },
      clearFilters: function clearFilters() {
        this.setProperties({
          groupBy: 'month_by_year',
          groupBySelected: this.groupByOptions[2],
          summariesUpperBound: null,
          summariesLowerBound: null
        });
      },
      selectGroupBy: function selectGroupBy(option) {
        this.setProperties({
          groupBy: option.value,
          groupBySelected: option
        });
      }
    }
  });

  _exports.default = _default;
});