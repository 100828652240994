define("front-end/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // mirage/scenarios/default.js
  function _default(server) {
    var company = server.create('company', {
      name: 'Peerdustry'
    });
    server.create('purchase-order', 'withItems', 'withPdf', {
      company: company
    });
    server.createList('job-offer', 5, 'available');
    server.createList('job-offer', 10, 'accepted');
    server.createList('purchase-order', 10, 'withItems');
    server.createList('purchase-order-item', 10, {
      company: company
    });
  }
});