define("front-end/controllers/admin/quotations/index", ["exports", "front-end/mixins/controller-pagination-mixin", "front-end/constants", "moment"], function (_exports, _controllerPaginationMixin, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    myId: '',
    quotationStatus: '',
    batchStatus: '',
    sortBy: '',
    partName: '',
    statusIn: '',
    company: '',
    deadlineLte: '',
    deadlineGte: '',
    createdAtLte: '',
    createdAtGte: '',
    sentToClientAtLte: '',
    sentToClientAtGte: '',
    concludedAtLte: '',
    concludedAtGte: '',
    internalCode: '',
    clientCategory: undefined,
    minAnsweredEstimates: null,
    advancedFilters: false,
    // Option to display through table or cards
    displayTable: false,
    loadingModel: false,
    // This property is being set by filter-loader-mixin implemented by this *route*
    quotationStatusSelected: null,
    batchStatusSelected: null,
    showQuotationProcessAutomationDialog: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.quotations';
      var queryParams = ['myId', 'quotationStatus', 'batchStatus', 'deadlineLte', 'deadlineGte', 'createdAtLte', 'createdAtGte', 'sentToClientAtLte', 'sentToClientAtGte', 'concludedAtLte', 'concludedAtGte', 'statusIn', 'company', 'internalCode', 'clientCategory', 'partName', 'sortBy', 'minAnsweredEstimates', 'displayTable'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
      this.quotationStatusOptions = _constants.QUOTATION_OPTIONS;
      this.batchStatusOptions = _constants.BATCH_OPTIONS;
      this.sortOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Data de criação',
        value: 'createdAt'
      }, {
        display: 'Data de expiração',
        value: 'deadline'
      }];
      this.csvQueryOptions = {
        filter: {
          'createdAt.lte': (0, _moment.default)().format(),
          'createdAt.gte': (0, _moment.default)().subtract(120, 'days').startOf('day').format()
        }
      };
    },
    afterApplyFilters: function afterApplyFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: 10,
        refreshedByFilter: true
      });
    },
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: this.defaultSizeValue,
        sortBy: '',
        displayTable: false
      });
    },
    actions: {
      closeQuotationProcessAutomationDialog: function closeQuotationProcessAutomationDialog() {
        this.set('showQuotationProcessAutomationDialog', false);
      },
      sortBy: function sortBy(option) {
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          sortBy: option.value,
          refreshedByFilter: true
        });
      },
      clearFilters: function clearFilters() {
        this._resetFilters();
      }
    }
  });

  _exports.default = _default;
});