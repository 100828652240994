define("front-end/mixins/route-websocket-toaster-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toaster: null,
    paperToaster: Ember.inject.service(),
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this._tearDownToaster();
    },
    _tearDownToaster: function _tearDownToaster() {
      var toaster = this.toaster;

      if (toaster) {
        this.set('toaster', null);
        this.paperToaster.cancelToast(toaster);
      }
    },
    // Will be implemented by the route
    _toasterClickHandler: function _toasterClickHandler() {},
    actions: {
      showToasterForNewMessages: function showToasterForNewMessages(message) {
        var instance = this;

        if (!this.toaster) {
          this.set('toaster', this.paperToaster.show(message, {
            duration: false,
            position: 'bottom right',
            toastClass: 'custom-refresh-toaster',
            action: {
              accent: true,
              label: 'Recarregar',
              onClick: function onClick() {
                window.scrollTo(0, 0);

                instance._tearDownToaster();

                instance._toasterClickHandler();

                instance.send('websocketToasterRefresh');
              }
            }
          }));
        }
      }
    }
  });

  _exports.default = _default;
});