define("front-end/components/ui/labeled-info-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test-labeled-info ...attributes class="layout-column card-item" align={{if @align @align "center"}}>
    <span data-test-label class="label">{{@label}}</span>
    <span data-test-value class={{concat "content " @valueClass}}>{{@value}}</span>
  </div>
  
  */
  {
    id: "8XFQLzVb",
    block: "{\"symbols\":[\"&attrs\",\"@align\",\"@label\",\"@valueClass\",\"@value\"],\"statements\":[[11,\"div\"],[24,\"data-test-labeled-info\",\"\"],[17,1],[24,0,\"layout-column card-item\"],[16,\"align\",[30,[36,0],[[32,2],[32,2],\"center\"],null]],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-label\",\"\"],[14,0,\"label\"],[12],[1,[32,3]],[13],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-value\",\"\"],[15,0,[30,[36,1],[\"content \",[32,4]],null]],[12],[1,[32,5]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    meta: {
      moduleName: "front-end/components/ui/labeled-info-column.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});