define("front-end/templates/components/display-file-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KJrfAevX",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[6,[37,5],[[35,1,[\"stored\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[14,\"data-test-file-link\",\"\"],[15,6,[31,[[34,2]]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"insert-drive-file\"],null]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,1]],[2,\" em \"],[1,[35,1,[\"fileExt\"]]],[13],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[35,1,[\"originalFilename\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"diameter\"],[14]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paper-progress-circular\",\"file\",\"authUrl\",\"paper-icon\",\"paper-tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/display-file-name.hbs"
    }
  });

  _exports.default = _default;
});