define("front-end/components/manufacturing-job-card", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isManufacturing: Ember.computed.equal('batch.status', _constants.BATCH_STATUS.MANUFACTURING),
    material: Ember.computed('job.material', function () {
      return this.job.get('material') || 'Conforme desenho';
    }),
    rawMaterialIncluded: Ember.computed('job.rawMaterialResponsible', function () {
      var responsible = this.job.get('rawMaterialResponsible');
      return responsible != 'manufacturer';
    }),
    rawMaterialDeliveryInfo: Ember.computed('rawMaterialIncluded', function () {
      if (this.rawMaterialIncluded) {
        return "após recebimento da MP";
      } else {
        return "após recebimento do pedido de compra";
      }
    }),
    rawMaterialFromManufacturer: Ember.computed.equal('job.rawMaterialResponsible', 'manufacturer'),
    mustConsiderFreight: Ember.computed('job.fromManufacturerFreightResponsible', function () {
      var responsible = this.job.get('fromManufacturerFreightResponsible');
      return responsible == 'manufacturer';
    })
  });

  _exports.default = _default;
});