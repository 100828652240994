define("front-end/models/tasks/task", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    icon: 'label',
    title: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    endDate: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    action: (0, _model.attr)('string'),
    requestor: (0, _model.belongsTo)('user', {
      inverse: 'requestedTasks'
    }),
    target: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    }),
    closedBy: (0, _model.belongsTo)('user', {
      inverse: 'closedTasks'
    }),
    statusMessage: Ember.computed('status', function () {
      switch (this.status) {
        case 'active':
          return 'Pendente';

        case 'canceled':
          return 'Cancelada';

        case 'processed':
          return 'Processada';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case 'active':
          return 'info';

        case 'canceled':
          return 'error';

        case 'processed':
          return 'success';

        default:
          return '';
      }
    }),
    active: Ember.computed('status', function () {
      return this.status == 'active';
    }),
    canceled: Ember.computed('status', function () {
      return this.status == 'canceled';
    }),
    processed: Ember.computed('status', function () {
      return this.status == 'processed';
    })
  });

  _exports.default = _default;
});