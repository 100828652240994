define("front-end/mirage/factories/company", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    cnpj: function cnpj() {
      return generateCNPJ();
    },
    user: (0, _emberCliMirage.association)()
  });

  _exports.default = _default;

  function generateCNPJ() {
    var digit = function digit(numbers) {
      var index = 2;
      var reverse = numbers.split('').reduce(function (buffer, number) {
        return [+number].concat(buffer);
      }, []);
      var sum = reverse.reduce(function (buffer, number) {
        buffer += number * index;
        index = index === 9 ? 2 : index + 1;
        return buffer;
      }, 0);
      var mod = sum % 11;
      return mod < 2 ? 0 : 11 - mod;
    };

    var cnpj = '';
    var i = 12;

    while (i--) {
      cnpj += Math.floor(Math.random() * 9);
    }

    cnpj += digit(cnpj);
    cnpj += digit(cnpj);
    return cnpj;
  }
});