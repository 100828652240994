define("front-end/templates/dashboard/jobs/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CECv6qD2",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"job\",\"batch\"],[[35,0,[\"context\"]],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"manufacturing-job-card\"]}",
    "meta": {
      "moduleName": "front-end/templates/dashboard/jobs/show.hbs"
    }
  });

  _exports.default = _default;
});