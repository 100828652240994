define("front-end/helpers/today-as-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.todayAsString = todayAsString;
  _exports.default = void 0;

  function todayAsString() {
    return new Date().toISOString();
  }

  var _default = Ember.Helper.helper(todayAsString);

  _exports.default = _default;
});