define("front-end/controllers/dashboard/estimates/history", ["exports", "front-end/constants", "front-end/mixins/controller-pagination-mixin"], function (_exports, _constants, _controllerPaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    loadingModel: false,
    myId: '',
    status: '',
    statusSelected: null,
    timedOutEstimates: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.estimates';
      var queryParams = ['myId', 'status', 'timedOutEstimates'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
      this.availableEstimateStatus = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Aceito',
        value: _constants.ESTIMATE_STATUS.ACCEPTED
      }, {
        display: 'Expirado',
        value: _constants.ESTIMATE_STATUS.TIMEOUT
      }, {
        display: 'Declinado pelo cliente',
        value: _constants.ESTIMATE_STATUS.DECLINED
      }, {
        display: 'Perdido devido demora',
        value: _constants.ESTIMATE_STATUS.DELAYED
      }, {
        display: 'Recusado pelo fornecedor',
        value: _constants.ESTIMATE_STATUS.REFUSED
      }];
    },
    actions: {
      selectStatus: function selectStatus(option) {
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          timedOutEstimates: false,
          status: option.value,
          statusSelected: option,
          refreshedByFilter: true
        });
      },
      toggleTimedOutOnly: function toggleTimedOutOnly() {
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          status: '',
          timedOutEstimates: !this.timedOutEstimates,
          statusSelected: null,
          refreshedByFilter: true
        });
      },
      applyFilters: function applyFilters(propName, propValue) {
        var _Ember$setProperties;

        Ember.setProperties(this, (_Ember$setProperties = {
          page: 1,
          size: this.defaultSizeValue
        }, _defineProperty(_Ember$setProperties, propName, propValue), _defineProperty(_Ember$setProperties, "refreshedByFilter", true), _Ember$setProperties));
      },
      clearFilters: function clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          status: '',
          myId: '',
          timedOutEstimates: false,
          statusSelected: null,
          refreshedByFilter: true
        });
      }
    }
  });

  _exports.default = _default;
});