define("front-end/services/toaster-messages", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    paperToaster: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.toasters = [];
      this.messages = [];
    },
    showSuccess: function showSuccess(msgs) {
      this.cancelAll();
      this.set('messages', msgs);
      this.toasters.pushObject(this.paperToaster.showComponent('toaster-text', {
        toastClass: 'success-toaster'
      }));
    },
    showErrors: function showErrors(msgs) {
      var _ENV$emberPaper$pape = _environment.default['ember-paper']['paper-toaster'],
          duration = _ENV$emberPaper$pape.duration,
          durationManyErrors = _ENV$emberPaper$pape.durationManyErrors;
      var toasterDuration = msgs.length > 1 ? durationManyErrors : duration;
      this.cancelAll();
      this.set('messages', msgs);
      this.toasters.pushObject(this.paperToaster.showComponent('toaster-text', {
        toastClass: 'error-toaster',
        duration: toasterDuration
      }));
    },
    showWarnings: function showWarnings(msgs) {
      this.cancelAll();
      this.set('messages', msgs);
      this.toasters.pushObject(this.paperToaster.showComponent('toaster-text', {
        toastClass: 'warning-toaster'
      }));
    },
    cancelAll: function cancelAll() {
      var _this = this;

      this.toasters.forEach(function (toaster) {
        return _this.paperToaster.cancelToast(toaster);
      });
      this.toasters.clear();
    }
  });

  _exports.default = _default;
});