define("front-end/routes/admin/analyses/job-offers/refused", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        manufacturer: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        },
        refusedJobOfferMessage: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      var filterQueryOptions = {
        filter: {
          is_refused: true
        },
        sort: '-created-at',
        include: 'manufacturer,job-offer-process.batch,job-offer-process.company,job-offer-process.part,job-offer-process.part-revision.edited-pdf-design,refused-job-offer-message',
        fields: {
          'parts': 'name,internal_code',
          'job_offer_processes': 'batch,company,part_revision,part',
          'companies': 'cnpj,name,fantasy_name',
          'batches': 'raw_material_format,quantity,company',
          'job-offers': 'my_id,created_at,timeout_deadline,conclusion_time,job_offer_process,manufacturer,refused_job_offer_message,message',
          'part-revisions': 'edited_pdf_design,material_from_design,material,tightest_tolerance,tightest_tolerance_from_design'
        }
      };

      if (params.manufacturer) {
        filterQueryOptions.filter['manufacturer'] = params.manufacturer;
      }

      if (params.internalCode) {
        filterQueryOptions.filter['part__internal_code'] = params.internalCode;
      }

      if (params.refusedJobOfferMessage) {
        filterQueryOptions.filter['refused_job_offer_message'] = params.refusedJobOfferMessage;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var jobOffers = this.store.query('job-offer', queryOptions);
      var jobOffersCount = this.store.query('job-offer', {
        filter: {
          is_refused: true
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (jobOffers) {
        return jobOffers.get('meta.count');
      });
      var refusedJobOfferMessages = this.store.query('tags/refused-job-offer-message', {
        filter: {
          official: true
        },
        sort: 'another'
      });
      return Ember.RSVP.hash({
        jobOffers: jobOffers,
        jobOffersCount: jobOffersCount,
        refusedJobOfferMessages: refusedJobOfferMessages
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var refusedJobOfferMessageSelected = model.refusedJobOfferMessages.find(function (option) {
        return option.id === controller.refusedJobOfferMessage;
      });
      controller.set('refusedJobOfferMessageSelected', refusedJobOfferMessageSelected);
      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});