define("front-end/instance-initializers/patch-paper-modals-manager", ["exports", "ember-paper-modals-manager/instance-initializers/patch-paper-modals-manager"], function (_exports, _patchPaperModalsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _patchPaperModalsManager.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _patchPaperModalsManager.initialize;
    }
  });
});