define("front-end/templates/components/part-design-for-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+J8a1k5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,0,[\"editedPdfDesign\",\"urlForFile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,\"data-test-edited-pdf-design-for-admin\",\"\"],[12],[2,\"\\n    \"],[8,\"thumb-image\",[],[[\"@width\",\"@stored\",\"@pdf\",\"@url\"],[\"100\",[34,0,[\"editedPdfDesign\",\"stored\"]],[34,0,[\"editedPdfDesign\",\"authUrlForFile\"]],[34,0,[\"editedPdfDesign\",\"authUrlForThumb\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-missing-file-label\",\"\"],[14,0,\"accent edited-file-label\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"check_circle\"],[[\"accent\"],[true]]]],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"PDF descaracterizado\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,\"data-test-pdf-design-for-admin\",\"\"],[12],[2,\"\\n    \"],[8,\"thumb-image\",[],[[\"@width\",\"@stored\",\"@pdf\",\"@url\"],[\"100\",[34,0,[\"pdfDesign\",\"stored\"]],[34,0,[\"pdfDesign\",\"authUrlForFile\"]],[34,0,[\"pdfDesign\",\"authUrlForThumb\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-missing-file-label\",\"\"],[14,0,\"warn edited-file-label\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"post_add\"],[[\"warn\"],[true]]]],[2,\"\\n    \"],[10,\"small\"],[12],[2,\"Falta PDF descaracterizado\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"partRevision\",\"paper-icon\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/part-design-for-admin.hbs"
    }
  });

  _exports.default = _default;
});