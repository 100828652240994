define("front-end/controllers/dashboard/estimates/index", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    page: 1,
    size: 10,
    myId: '',
    sortBy: '',
    status: '',
    showLoader: false,
    loadingModel: false,
    statusSelected: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.defaultPage = 1;
      var queryParams = ['myId', 'status', 'sortBy'];
      this.queryParams = [].concat(queryParams);
      this.estimatesArray = [];
      this.availableEstimateStatus = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Em negociação',
        value: _constants.ESTIMATE_STATUS.NEGOTIATION
      }, {
        display: 'Pendentes',
        value: _constants.ESTIMATE_STATUS.NOT_ANSWERED
      }];
      this.sortOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Data de criação',
        value: 'created_at'
      }, {
        display: 'Data de expiração',
        value: 'timeout_deadline'
      }];
    },
    noMoreEstimates: Ember.computed('page', 'model.estimates.meta.page-count', function () {
      return this.page >= this.model.estimates.meta['page-count'];
    }),
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        status: '',
        myId: '',
        sortBy: '',
        statusSelected: null,
        refreshedByFilter: true
      });
    },
    _clearInfiniteScroll: function _clearInfiniteScroll() {
      Ember.setProperties(this, {
        estimatesArray: [],
        size: this.size,
        page: this.defaultPage
      });
    },
    actions: {
      loadEstimates: function loadEstimates() {
        var page = this.page;
        var count = this.model.estimates.meta['page-count'];

        if (page < count) {
          this.set('page', page + 1);
          this.set('showLoader', true);
          this.send('refreshRoute');
        }
      },
      selectStatus: function selectStatus(option) {
        this._clearInfiniteScroll();

        Ember.setProperties(this, {
          status: option.value,
          statusSelected: option,
          refreshedByFilter: true
        });
      },
      sortBy: function sortBy(option) {
        this._clearInfiniteScroll();

        Ember.setProperties(this, {
          sortBy: option.value,
          refreshedByFilter: true
        });
      },
      applyFilters: function applyFilters(propName, propValue) {
        var _Ember$setProperties;

        this._clearInfiniteScroll();

        Ember.setProperties(this, (_Ember$setProperties = {}, _defineProperty(_Ember$setProperties, propName, propValue), _defineProperty(_Ember$setProperties, "refreshedByFilter", true), _Ember$setProperties));
      },
      clearFilters: function clearFilters() {
        this._resetFilters();

        this._clearInfiniteScroll();
      }
    }
  });

  _exports.default = _default;
});