define("front-end/components/emmi-modals-container/prompt-confirm", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/prompt-confirm"], function (_exports, _promptConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _promptConfirm.default;
    }
  });
});