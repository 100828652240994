define("front-end/components/handle-large-descriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    side: 'top',
    fullDescription: '',
    wordsLimit: 10,
    descriptionSplited: false,
    toggleFullDescription: false,
    openPopoverLabel: Ember.computed('toggleFullDescription', function () {
      return this.toggleFullDescription ? 'fechar' : 'ver mais...';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var wordsLimit = this.wordsLimit;
      var fullDescription = this.fullDescription;
      this.set('shortDescription', this.generateShortDescription(fullDescription, wordsLimit));
    },
    generateShortDescription: function generateShortDescription(fullDescription, wordsLimit) {
      var words = fullDescription.split(/[ \n]/);
      var numberOfWords = words.length;

      if (numberOfWords > wordsLimit) {
        this.set('descriptionSplited', true);
        return fullDescription.split(/[ \n]/).splice(0, wordsLimit).join(' ');
      } else {
        return fullDescription;
      }
    }
  });

  _exports.default = _default;
});