define("front-end/models/quotation", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/models/helpers/util", "front-end/constants"], function (_exports, _model, _defaultModel, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    myId: (0, _model.attr)('string'),
    deadline: (0, _model.attr)('string'),
    concludedAt: (0, _model.attr)('string'),
    conclusionTime: (0, _model.attr)('string'),
    sentToClientAt: (0, _model.attr)('string'),
    sentToClientTime: (0, _model.attr)('string'),
    clientAnswerTime: (0, _model.attr)('string'),
    clientCategory: (0, _model.attr)('number'),
    message: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    finalStatus: (0, _model.attr)('boolean'),
    allowRenegotiation: (0, _model.attr)('boolean'),
    allowSetPricingProcess: (0, _model.attr)('boolean'),
    allowNewPricingProcesses: (0, _model.attr)('boolean'),
    batch: (0, _model.belongsTo)('batch', {
      inverse: 'quotation'
    }),
    purchaseOrderItem: (0, _model.belongsTo)('purchase-order-item', {
      inverse: null
    }),
    declinedQuotationMessage: (0, _model.belongsTo)('tags/declined-quotation-message', {
      inverse: null
    }),
    selectedPricingProcess: (0, _model.belongsTo)('pricing-process', {
      polymorphic: true,
      inverse: null
    }),
    pricingProcesses: (0, _model.hasMany)('pricing-process', {
      polymorphic: true,
      inverse: null
    }),
    estimateSurvey: (0, _model.belongsTo)('estimate-survey', {
      inverse: 'quotation'
    }),
    supervisedQuote: (0, _model.belongsTo)('supervised-quote', {
      inverse: 'quotation'
    }),
    price: (0, _model.attr)('number'),
    unitPrice: (0, _model.attr)('number'),
    commercialDaysAmount: (0, _model.attr)('number'),
    // Denormalized relationships
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    project: (0, _model.belongsTo)('project', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: null
    }),
    context: (0, _model.belongsTo)('batch-context', {
      inverse: null
    }),
    previous: (0, _model.belongsTo)('quotation', {
      inverse: 'next'
    }),
    next: (0, _model.belongsTo)('quotation', {
      inverse: 'previous'
    }),
    statusReference: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.QUOTATION_STATUS.ANALYZING:
          return 'analyzing';

        case _constants.QUOTATION_STATUS.PRICING:
          return 'pricing';

        case _constants.QUOTATION_STATUS.NEGOTIATION:
          return 'negotiation';

        case _constants.QUOTATION_STATUS.ACCEPTED:
          return 'accepted';

        case _constants.QUOTATION_STATUS.DECLINED:
          return 'declined';

        case _constants.QUOTATION_STATUS.CLOSED:
          return 'closed';

        case _constants.QUOTATION_STATUS.TIMEOUT:
          return 'timeout';

        default:
          return '';
      }
    }),
    canBeSent: Ember.computed('selectedPricingProcess', 'status', function () {
      return this.belongsTo('selectedPricingProcess').id() !== null && this.status < _constants.QUOTATION_STATUS.NEGOTIATION;
    }),
    isNegotiating: Ember.computed('status', function () {
      return this.status == _constants.QUOTATION_STATUS.NEGOTIATION;
    }),
    isAccepted: Ember.computed('status', function () {
      return this.status == _constants.QUOTATION_STATUS.ACCEPTED;
    }),
    isClosed: Ember.computed('status', function () {
      return this.status == _constants.QUOTATION_STATUS.CLOSED;
    }),
    isDeclined: Ember.computed('status', function () {
      return this.status == _constants.QUOTATION_STATUS.DECLINED;
    }),
    beforeNegotiation: Ember.computed('status', function () {
      return this.status < _constants.QUOTATION_STATUS.NEGOTIATION;
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.QUOTATION_STATUS.ANALYZING:
          return 'architecture';

        case _constants.QUOTATION_STATUS.PRICING:
          return 'monetization_on';

        case _constants.QUOTATION_STATUS.NEGOTIATION:
          return 'forum';

        case _constants.QUOTATION_STATUS.ACCEPTED:
          return 'thumb_up';

        case _constants.QUOTATION_STATUS.DECLINED:
          return 'thumb_down';

        case _constants.QUOTATION_STATUS.CLOSED:
          return 'cancel';

        case _constants.QUOTATION_STATUS.TIMEOUT:
          return 'timer_off';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.QUOTATION_STATUS.ANALYZING:
          return 'warning';

        case _constants.QUOTATION_STATUS.PRICING:
          return 'info';

        case _constants.QUOTATION_STATUS.NEGOTIATION:
          return 'info';

        case _constants.QUOTATION_STATUS.ACCEPTED:
          return 'success';

        case _constants.QUOTATION_STATUS.DECLINED:
          return 'error';

        case _constants.QUOTATION_STATUS.CLOSED:
          return 'error';

        case _constants.QUOTATION_STATUS.TIMEOUT:
          return 'error';

        default:
          return '';
      }
    }),
    batchStatusMessage: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.QUOTATION_STATUS.ANALYZING:
          return 'Analisando';

        case _constants.QUOTATION_STATUS.PRICING:
          return 'Precificando';

        case _constants.QUOTATION_STATUS.NEGOTIATION:
          return 'Negociando';

        case _constants.QUOTATION_STATUS.ACCEPTED:
          return 'Aceita';

        case _constants.QUOTATION_STATUS.DECLINED:
          return 'Declinada';

        case _constants.QUOTATION_STATUS.CLOSED:
          return 'Fechada';

        case _constants.QUOTATION_STATUS.TIMEOUT:
          return 'Expirada';

        default:
          return '';
      }
    }),
    readableSentToClientTime: Ember.computed('sentToClientTime', function () {
      var sentToClientTime = this.sentToClientTime;
      return (0, _util.readableDuration)(sentToClientTime);
    }),
    readableClientAnswerTime: Ember.computed('clientAnswerTime', function () {
      var clientAnswerTime = this.clientAnswerTime;
      return (0, _util.readableDuration)(clientAnswerTime);
    }),
    readableConclusionTime: Ember.computed('conclusionTime', function () {
      var conclusionTime = this.conclusionTime;
      return (0, _util.readableDuration)(conclusionTime);
    }),
    route: function route() {
      var _this = this;

      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          var projectId = _this.belongsTo('project').id();

          var batchId = _this.belongsTo('batch').id();

          return ['admin.projects.show.batch.quotation', projectId, batchId];
        } else {
          return _this.batch.then(function (batch) {
            return batch.route(companyId);
          });
        }
      });
    }
  });

  _exports.default = _default;
});