define("front-end/templates/components/brazilian-states-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "41pJfg5T",
    "block": "{\"symbols\":[\"state\",\"autocomplete\"],\"statements\":[[6,[37,8],null,[[\"disabled\",\"label\",\"loadingMessage\",\"noMatchesMessage\",\"options\",\"selected\",\"allowClear\",\"searchText\",\"onSearchTextChange\",\"searchField\",\"labelPath\",\"passThru\",\"onSelectionChange\"],[[35,7],\"Estado...\",\"Buscando...\",\"Oops estado não encontrado.\",[35,6],[35,5],true,[30,[36,4],[[35,3]],null],[30,[36,1],[[32,0],\"changeSearchText\"],null],\"display\",\"display\",[30,[36,2],null,[[\"autocomplete\",\"autocomplete\"],[\"off\",\"chrome-off\"]]],[30,[36,1],[[32,0],\"setCompanyState\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"flags\",\"label\",\"searchText\",\"data-test-autocomplete-uf\"],[\"i\",[32,1,[\"display\"]],[32,2,[\"searchText\"]],true]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"paper-autocomplete-highlight\",\"action\",\"hash\",\"searchText\",\"readonly\",\"selectedBrazilianState\",\"brazilianStates\",\"disabled\",\"paper-autocomplete\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/brazilian-states-selector.hbs"
    }
  });

  _exports.default = _default;
});