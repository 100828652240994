define("front-end/models/chats/estimate-chat-message", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    readAt: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    estimateChat: (0, _model.belongsTo)('chats/estimate-chat', {
      inverse: 'estimateChatMessages'
    })
  });

  _exports.default = _default;
});