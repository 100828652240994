define("front-end/components/display-file-name", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    authUrl: Ember.computed('file.urlForFile', 'session.data.authenticated.jwt', function () {
      var apiKey = _environment.default.API_KEY;
      var accessToken = this.session.data.authenticated.jwt;
      return "".concat(this.file.get('urlForFile'), "?token=").concat(accessToken, "&client_token=").concat(apiKey);
    })
  });

  _exports.default = _default;
});