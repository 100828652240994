define("front-end/routes/admin/job-offer-processes/index", ["exports", "front-end/mixins/route-pagination-mixin"], function (_exports, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        companyId: {
          refreshModel: true
        },
        myId: {
          refreshModel: true
        },
        batchMyId: {
          refreshModel: true
        },
        partInternalCode: {
          refreshModel: true
        },
        jobOfferProcessItemStatus: {
          refreshModel: true
        },
        finished: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var queryOptions = _objectSpread({
        filter: {
          finished: params.finished
        },
        sort: '-created-at',
        include: 'companies,items.project,offers,batches'
      }, this.getPageQueryParams(params));

      if (params.companyId) {
        queryOptions.filter['company_ids.in'] = [params.companyId];
      }

      if (params.myId) {
        queryOptions.filter['myId.match'] = params.myId;
      }

      if (params.batchMyId) {
        queryOptions.filter['batch__myId.match'] = params.batchMyId;
      }

      if (params.partInternalCode) {
        queryOptions.filter['part__internalCode.match'] = params.partInternalCode;
      }

      if (params.jobOfferProcessItemStatus) {
        queryOptions.filter['finished'] = true;
        queryOptions.filter['job_offer_process_item__status'] = params.jobOfferProcessItemStatus;
      }

      var jobOfferProcesses = this.store.query('job-offer-process', queryOptions);
      var jobOfferProcessesCount = this.store.query('job-offer-process', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (processes) {
        return processes.get('meta.count');
      });
      return Ember.RSVP.hash({
        jobOfferProcesses: jobOfferProcesses,
        jobOfferProcessesCount: jobOfferProcessesCount
      });
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();

        controller.set('refreshedByFilter', false);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});