define("front-end/mixins/component-masked-input", ["exports", "inputmask"], function (_exports, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var currentMask;
      var mask = this.mask;
      var parentElement = this.element;
      var inputElement = parentElement.querySelector('input'); // Dispatching 'input' event on pasting values in the input to resolve the problem described on the issue below
      // https://github.com/RobinHerbots/Inputmask/issues/2431

      parentElement.onpaste = function () {
        return inputElement.dispatchEvent(new Event('input'));
      };

      if (mask) {
        if (mask == 'tolerance') {
          currentMask = new _inputmask.default('decimal', {
            digits: [1, 4],
            radixPoint: ',',
            rightAlign: false,
            decimalProtect: false
          });
          this.defaultValue = 0.00;
        } else if (mask == 'currency') {
          currentMask = new _inputmask.default('currency', {
            prefix: 'R$ ',
            radixPoint: ',',
            autoUnmask: true,
            rightAlign: false
          });
          this.defaultValue = 0.00;
        } else if (mask == 'cnpj') {
          currentMask = new _inputmask.default('99.999.999/9999-99', {
            autoUnmask: true,
            rightAlign: false
          });
          this.defaultValue = "";
        } else if (mask == 'cep') {
          currentMask = new _inputmask.default('99999-999', {
            autoUnmask: true,
            rightAlign: false
          });
          this.defaultValue = "";
        } else if (mask == 'phone') {
          currentMask = new _inputmask.default('(99) 99999999[9]', {
            autoUnmask: false,
            rightAlign: false
          });
          this.defaultValue = "";
        }

        currentMask.mask(inputElement);
        inputElement.value = this.value || this.defaultValue;
      }
    }
  });

  _exports.default = _default;
});