define("front-end/routes/dashboard/index", ["exports", "front-end/mixins/route-websocket-handler-mixin", "front-end/constants"], function (_exports, _routeWebsocketHandlerMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, {
    currentUser: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.websocketTypes = ['quotations', 'projects', 'estimates', 'job-offers', 'companies'];
    },
    model: function model() {
      var size = 1;
      var number = 1;
      var toBeReturned = {};
      toBeReturned['currentUser'] = this.currentUser.load();
      var user = this.modelFor('dashboard').user;
      var company = this.modelFor('dashboard').company;
      toBeReturned['user'] = user;
      toBeReturned['company'] = company;
      var notifications = this.store.query('notification', {
        page: {
          number: 1,
          size: 10
        },
        sort: '-created-at',
        include: 'notifiable'
      });
      toBeReturned['notifications'] = notifications;

      if (company.get('clientProfile.isClient')) {
        var quotationsInNegotiation = this.store.query('quotation', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'batch__company_id': company.get('id'),
            'status': _constants.QUOTATION_STATUS.NEGOTIATION
          }
        });
        var quotationsInNegotiationCount = quotationsInNegotiation.then(function (projects) {
          return projects.get('meta.count');
        });
        toBeReturned['quotationsInNegotiationCount'] = quotationsInNegotiationCount;
        var projectWithPendingQuotations = this.store.query('project', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'company': company.get('id'),
            'quotations__status': _constants.QUOTATION_STATUS.NEGOTIATION
          }
        });
        var projectWithPendingQuotationsCount = projectWithPendingQuotations.then(function (projects) {
          return projects.get('meta.count');
        });
        toBeReturned['projectWithPendingQuotationsCount'] = projectWithPendingQuotationsCount;
      }

      if (company.get('manufacturerProfile.isManufacturer')) {
        var notAnswered = this.store.query('estimate', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'status': _constants.ESTIMATE_STATUS.NOT_ANSWERED,
            'manufacturer': company.get('id')
          }
        });
        var notAnsweredCount = notAnswered.then(function (estimates) {
          return estimates.get('meta.count');
        });
        toBeReturned['notAnsweredCount'] = notAnsweredCount;
        var finishedJobs = this.store.query('batch', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'context__manufacturer': company.get('id'),
            status: _constants.BATCH_STATUS.COMPLETED
          }
        });
        var finishedJobsCount = finishedJobs.then(function (batches) {
          return batches.get('meta.count');
        });
        toBeReturned['finishedJobsCount'] = finishedJobsCount;
        var jobs = this.store.query('batch', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'context__manufacturer': company.get('id'),
            status: _constants.BATCH_STATUS.MANUFACTURING
          }
        });
        var jobsCount = jobs.then(function (batches) {
          return batches.get('meta.count');
        });
        toBeReturned['jobsCount'] = jobsCount;
        var declined = this.store.query('estimate', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'status': _constants.ESTIMATE_STATUS.DECLINED,
            'manufacturer': company.get('id')
          }
        });
        var declinedCount = declined.then(function (estimates) {
          return estimates.get('meta.count');
        });
        toBeReturned['declinedCount'] = declinedCount;
        var timeout = this.store.query('estimate', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'status': _constants.ESTIMATE_STATUS.TIMEOUT,
            'manufacturer': company.get('id')
          }
        });
        var timeoutCount = timeout.then(function (estimates) {
          return estimates.get('meta.count');
        });
        toBeReturned['timeoutCount'] = timeoutCount;
        var availableJobOffers = this.store.query('job-offer', {
          page: {
            number: number,
            size: size
          },
          filter: {
            'manufacturer': company.get('id'),
            is_available: true
          }
        });
        var availableJobOffersCount = availableJobOffers.then(function (offers) {
          return offers.get('meta.count');
        });
        toBeReturned['availableJobOffersCount'] = availableJobOffersCount;
        var estimateConfirmations = this.store.query('estimate-confirmation', {
          page: {
            number: number,
            size: size
          },
          filter: {
            manufacturer: company.get('id'),
            status: _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE
          }
        });
        var availableEstimateConfirmationsCount = estimateConfirmations.then(function (confirmations) {
          return confirmations.get('meta.count');
        });
        toBeReturned['availableEstimateConfirmationsCount'] = availableEstimateConfirmationsCount;
      }

      return Ember.RSVP.hash(toBeReturned);
    },
    websocketResourceHandler: function websocketResourceHandler(record, type) {
      if (this.websocketTypes.includes(type)) {
        this.send('refreshRouteMixin');
      }
    }
  });

  _exports.default = _default;
});