define("front-end/components/competing-estimates-summary-chart", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    stats: null,
    chart: null,
    company: null,
    chartData: null,
    width: Ember.computed('stats.[]', function () {
      var base = 500;
      var n = this.stats.length;
      return base + n * 20;
    }),
    height: Ember.computed('width', function () {
      var base = 200;
      var width = this.width;
      var ratio = 1 - base / width;
      return (1 + ratio) * base;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var chartOptions = {
        responsive: true,
        data: {},
        type: 'bar',
        options: {
          tooltips: {
            callbacks: {
              label: function label(tooltipItem, data) {
                var text = [];
                var name = data.datasets[0]['data'][tooltipItem.index]['name'];
                text.push("Fornecedor: ".concat(name));
                var days = data.datasets[1]['data'][tooltipItem.index]['y'];
                text.push("Prazo: ".concat(days, " dias"));
                var price = data.datasets[0]['data'][tooltipItem.index]['y'];
                price = price.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                });
                text.push("Valor: ".concat(price));
                var freightResponsible = data.datasets[0]['data'][tooltipItem.index]['freightResponsible'] == 'manufacturer' ? 'CIF' : 'FOB';
                text.push("Frete: ".concat(freightResponsible));
                return text;
              }
            }
          },
          legend: {
            display: false
          },
          title: {
            fontSize: 20,
            display: true,
            text: 'Cotação em tempo real'
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              type: 'linear',
              position: 'left',
              id: 'price-axis',
              gridLines: {
                drawTicks: true,
                drawOnChartArea: false
              },
              scaleLabel: {
                display: true,
                labelString: 'Preço por peça'
              },
              ticks: {
                beginAtZero: true,
                callback: function callback(tick) {
                  return tick.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  });
                }
              }
            }, {
              type: 'linear',
              position: 'right',
              beginAtZero: true,
              id: 'days-axis',
              scaleLabel: {
                display: true,
                labelString: 'Prazo (dias)'
              },
              gridLines: {
                drawTicks: true,
                drawOnChartArea: false
              },
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      };
      this.set('chartOptions', chartOptions);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var stats = this.stats;
      var company = this.company;

      if (stats) {
        var labels = [];
        var datasets = [{
          data: [],
          type: 'bar',
          backgroundColor: [],
          yAxisID: 'price-axis'
        }, {
          data: [],
          fill: false,
          type: 'line',
          pointRadius: 5,
          yAxisID: 'days-axis',
          borderColor: 'rgba(0, 0, 0, 0.7)',
          cubicInterpolationMode: true
        }]; // Sorting stats by price

        stats.sort(function (a, b) {
          return a['unit-price'] - b['unit-price'];
        }); // Populate the main chart datasets

        stats.forEach(function (entry) {
          var data = {};
          var daysAmountData = {};

          if (company && entry['manufacturer-id'] && company.get('id') == entry['manufacturer-id']) {
            datasets[0]['backgroundColor'].push('rgba(255, 99, 132, 0.5)');
          } else {
            datasets[0]['backgroundColor'].push('rgba(54, 162, 235, 0.5)');
          } // Fetch the manufacturer's display name if the response brings the id (admin level)
          // Otherwise, show a sequence of dashes instead of the name itself (manufacturer level)
          // There is a third scenario when the admin takes over, in this case the compnay record won't be in this store


          var manufacturer = entry['manufacturer-id'] ? _this.store.peekRecord('company', entry['manufacturer-id']) : null;
          var manufacturerName = manufacturer ? manufacturer.get('displayName') : '---';
          data['name'] = manufacturerName;
          data['y'] = entry['unit-price'];
          data['freightResponsible'] = entry['from-manufacturer-freight-responsible'];
          daysAmountData['name'] = manufacturerName;
          daysAmountData['y'] = entry['days-amount'];
          labels.push('');
          datasets[0]['data'].push(data);
          datasets[1]['data'].push(daysAmountData);
        });
        var chartData = {
          datasets: datasets,
          labels: labels
        };
        this.set('chartOptions.data', chartData);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var ctx = document.getElementById("estimate-chart");

      if (this.chart) {
        this.chart.update();
      } else {
        this.set('chart', new _chart.default(ctx, this.chartOptions));
      }
    }
  });

  _exports.default = _default;
});