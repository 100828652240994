define("front-end/routes/admin/statistics/estimates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var companies = this.store.query('company', {
        page: {
          number: 1,
          size: 1
        },
        filter: {
          'manufacturer_profile#is_manufacturer': true
        }
      });
      var companiesCount = companies.then(function (companies) {
        return companies.get('meta.count');
      });
      var experimentalCompanies = this.store.query('company', {
        page: {
          number: 1,
          size: 1
        },
        filter: {
          'experimental': true,
          'manufacturer_profile#is_manufacturer': true
        }
      });
      var experimentalCompaniesCount = experimentalCompanies.then(function (companies) {
        return companies.get('meta.count');
      });
      var disabledCompanies = this.store.query('company', {
        page: {
          number: 1,
          size: 1
        },
        filter: {
          'manufacturer_profile#disabled': true
        }
      });
      var disabledCompaniesCount = disabledCompanies.then(function (companies) {
        return companies.get('meta.count');
      });
      var latestSummary = this.store.query('computed-models/all-estimates-summary', {
        page: {
          number: 1,
          size: 1
        },
        sort: '-created-at'
      });
      latestSummary = latestSummary.then(function (summaries) {
        return summaries.get('firstObject');
      });
      return Ember.RSVP.hash({
        companiesCount: companiesCount,
        experimentalCompaniesCount: experimentalCompaniesCount,
        disabledCompaniesCount: disabledCompaniesCount,
        latestSummary: latestSummary
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var groupBySelected = controller.groupByOptions.find(function (option) {
        return controller.groupBy == option.value;
      });
      controller.set('groupBySelected', groupBySelected);
    }
  });

  _exports.default = _default;
});