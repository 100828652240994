define("front-end/templates/components/quotations-summary-by-period-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJnBzHlv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n\"],[6,[37,5],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"class\",\"diameter\"],[\"md-hue-2\",50]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"canvas\"],[14,\"data-test-quotations-summary-by-period-chart\",\"\"],[14,1,\"quotations-summary-by-period-chart\"],[14,\"width\",\"auto\"],[15,\"height\",[34,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[12],[2,\"Não há dados para mostrar\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,5],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"flex\"],[12],[13],[2,\"\\n\"],[6,[37,2],null,[[\"value\",\"onChange\"],[[35,1],[30,[36,0],[[32,0],\"toggleLegend\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      Legendas\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"showLegendValue\",\"paper-checkbox\",\"height\",\"hasData\",\"if\",\"paper-progress-circular\",\"showLoader\",\"showLegend\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/quotations-summary-by-period-chart.hbs"
    }
  });

  _exports.default = _default;
});