define("front-end/routes/admin/parts/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.query('part', {
        filter: {
          id: params.part_id
        },
        include: 'creator,company,current-part-revision.pdf-design,part-revisions'
      }).then(function (query) {
        if (!query.length) {
          return Ember.RSVP.reject({
            errors: [{
              status: 404
            }]
          });
        }

        return query.get('firstObject');
      });
    }
  });

  _exports.default = _default;
});