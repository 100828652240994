define("front-end/mixins/controller-quotation-management-panel-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toasterMessages: Ember.inject.service(),
    closeQuotationReason: '',
    actions: {
      defineEstimate: function defineEstimate(estimate) {
        var action = this.store.createRecord('actions/define-estimate-action', {
          estimate: estimate
        });
        action.save();
      },
      endEstimate: function endEstimate(estimate) {
        var action = this.store.createRecord('actions/cancel-estimate-action', {
          estimate: estimate
        });
        action.save();
      },
      reopenEstimate: function reopenEstimate(estimates, message, customDeadline) {
        var action = this.store.createRecord('actions/reopen-estimate-action', {
          estimates: estimates,
          message: message,
          customDeadline: customDeadline
        });
        action.save();
      },
      endQuotationAction: function endQuotationAction(quotation, message) {
        var action = this.store.createRecord('actions/close-quotation-action', {
          quotation: quotation,
          message: message
        });
        action.save();
      },
      rollbackQuotationAction: function rollbackQuotationAction(quotation, message) {
        var _this = this;

        var action = this.store.createRecord('actions/rollback-accepted-quotation-action', {
          quotation: quotation,
          message: message
        });
        action.save().then(function () {
          _this.toasterMessages.showSuccess(['A cotação foi revertida']);
        });
      }
    }
  });

  _exports.default = _default;
});