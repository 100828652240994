define("front-end/models/project", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/constants"], function (_exports, _model, _defaultModel, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    name: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    statusName: (0, _model.attr)('string'),
    batches: (0, _model.hasMany)('batch'),
    quotations: (0, _model.hasMany)('quotation'),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    creator: (0, _model.belongsTo)('user', {
      inverse: 'projects'
    }),
    commercialProposal: (0, _model.belongsTo)('commercial-proposal'),
    projectSummary: (0, _model.belongsTo)('computed-models/project-summary', {
      inverse: 'project'
    }),
    creating: Ember.computed('status', function () {
      return this.status == _constants.PROJECT_STATUS.CREATING;
    }),
    analyzing: Ember.computed('status', function () {
      return this.status == _constants.PROJECT_STATUS.ANALYZING;
    }),
    finished: Ember.computed('status', function () {
      return this.status == _constants.PROJECT_STATUS.FINISHED;
    }),
    canceled: Ember.computed('status', function () {
      return this.status == _constants.PROJECT_STATUS.CANCELED;
    }),
    denied: Ember.computed('status', function () {
      return this.status == _constants.PROJECT_STATUS.DENIED;
    }),
    analyzed: Ember.computed.gt('status', _constants.PROJECT_STATUS.ANALYZING),
    notFinished: Ember.computed.lt('status', _constants.PROJECT_STATUS.FINISHED),
    isQuoting: Ember.computed.and('analyzed', 'notFinished'),
    route: function route() {
      var _this = this;

      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          return ['admin.projects.show', _this.id];
        } else {
          // return not null elements of the below array
          return ['dashboard.projects.manage', companyId, _this.id].filter(function (x) {
            return x;
          });
        }
      });
    }
  });

  _exports.default = _default;
});