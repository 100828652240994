define("front-end/templates/components/experimental-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ai0HR2q2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0,[\"experimental\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"company\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/experimental-feature.hbs"
    }
  });

  _exports.default = _default;
});