define("front-end/routes/admin/analyses/quotations/declined", ["exports", "front-end/mixins/route-pagination-mixin", "front-end/constants"], function (_exports, _routePaginationMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        client: {
          refreshModel: true
        },
        partName: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        },
        declinedQuotationMessage: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      var filterQueryOptions = {
        filter: {
          status: _constants.QUOTATION_STATUS.DECLINED
        },
        sort: '-created-at',
        include: 'selected-pricing-process.manufacturer,batch,company,part,part-revision.edited-pdf-design,declined-quotation-message',
        fields: {
          'parts': 'name,internal_code',
          'companies': 'cnpj,name,fantasy_name',
          'batches': 'raw_material_format,quantity',
          'estimates': 'manufacturer,price,unit_price',
          'part-revisions': 'edited_pdf_design,material_from_design,material,tightest_tolerance,tightest_tolerance_from_design'
        }
      };

      if (params.client) {
        filterQueryOptions.filter['company_id'] = params.client;
      }

      if (params.partName) {
        filterQueryOptions.filter['part__name.match'] = params.partName;
      }

      if (params.internalCode) {
        filterQueryOptions.filter['part__internal_code.match'] = params.internalCode;
      }

      if (params.declinedQuotationMessage) {
        filterQueryOptions.filter['declined_quotation_message'] = params.declinedQuotationMessage;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var quotations = this.store.query('quotation', queryOptions);
      var quotationsCount = this.store.query('quotation', {
        filter: {
          status: _constants.QUOTATION_STATUS.DECLINED
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (quotations) {
        return quotations.get('meta.count');
      });
      var declinedQuotationMessages = this.store.query('tags/declined-quotation-message', {
        filter: {
          official: true
        },
        sort: 'another'
      });
      return Ember.RSVP.hash({
        quotations: quotations,
        quotationsCount: quotationsCount,
        declinedQuotationMessages: declinedQuotationMessages,
        queryOptions: queryOptions
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var declinedQuotationMessageSelected = model.declinedQuotationMessages.find(function (option) {
        return option.id === controller.declinedQuotationMessage;
      });
      controller.set('declinedQuotationMessageSelected', declinedQuotationMessageSelected);
      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});