define("front-end/utils/generate-external-link-for-resource", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateExternalLinkForResource;

  function generateExternalLinkForResource(type, id) {
    var url = "mail?type=resources.show&resource_type=".concat(type, "&resource_id=").concat(id);
    return "".concat(_environment.default['frontEndUrl']).concat(url);
  }
});