define("front-end/routes/dashboard/projects/build", ["exports", "front-end/mixins/route-websocket-handler-mixin", "front-end/constants"], function (_exports, _routeWebsocketHandlerMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, {
    model: function model(params) {
      var _this = this;

      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;
      var project = this.store.findRecord('project', params.project_id, {
        reload: true
      });
      var batches = project.then(function (project) {
        return _this.store.query('batch', {
          filter: {
            'project': project.id
          },
          include: 'partRevision.pdfDesign,part.currentPartRevision'
        });
      });
      return Ember.RSVP.hash({
        company: company,
        project: project,
        batches: batches
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('addedBatches', model.batches.toArray());
    },
    afterModel: function afterModel(model) {
      var project = model.project;

      if (project.get('status') > _constants.PROJECT_STATUS.CREATING) {
        this.transitionTo('dashboard.projects.manage', project.get('id'));
      }
    },
    resetController: function resetController(controller, isExiting) {
      // Set default values to avoid dirty forms for other projects' build page.
      if (isExiting) {
        controller._resetFormValues();

        controller.set('showErrors', false); // remove mounting parts

        controller.newParts.invoke('destroyRecord');
        controller.newParts.clear();
      }

      this._super.apply(this, arguments);
    },
    websocketResourceHandler: function websocketResourceHandler(record, type) {
      if (type == 'projects') {
        var model = this.controller.model;

        if (record.get('id') == model.project.get('id')) {
          if (record.get('status') > _constants.PROJECT_STATUS.CREATING) {
            this.send('refreshRoute');
          }
        }
      }
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});