define("front-end/mirage/factories/purchase-order", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    company: (0, _emberCliMirage.association)(),
    creating: true,
    identifier: function identifier() {
      return Math.random();
    },
    withPdf: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(purchaseOrder, server) {
        var pdf = server.create('files/purchase-order-pdf', 1, {
          purchaseOrder: purchaseOrder
        });
        purchaseOrder.update({
          purchaseOrderPdf: pdf
        });
      }
    }),
    withItems: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(purchaseOrder, server) {
        var items = server.createList('purchase-order-item', 25, {
          purchaseOrder: purchaseOrder,
          company: purchaseOrder.company
        });
        purchaseOrder.update({
          items: items,
          itemsOrder: items.map(function (item) {
            return item.id;
          })
        });
      }
    })
  });

  _exports.default = _default;
});