define("front-end/components/company-resource-summary/general-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperCard ...attributes data-test-general-summary @class="general-summary" as |card|>
    <card.header @class="layout-row layout-align-start-center info-text" as |header|>
      <header.avatar>
        <PaperIcon data-test-icon @class="info" @icon={{@resourceIcon}} @size={{20}} />
      </header.avatar>
  
      <header.text as |text|>
        <text.title data-test-title>{{@resourceTitle}}</text.title>
      </header.text>
    </card.header>
  
    {{#if @showLoader}}
      <div class="layout-column layout-align-center-center flex">
        <PaperProgressCircular data-test-loading-data @diameter={{30}} />
      </div>
    {{else}}
      <card.content data-test-resource-summary @class="flex summary-content">
        {{yield (hash content="blank-template")}}
      </card.content>
  
      <card.actions>
        {{yield (hash action="blank-template")}}
      </card.actions>
    {{/if}}
  </PaperCard>
  */
  {
    id: "kBmVO7gg",
    block: "{\"symbols\":[\"card\",\"header\",\"text\",\"&default\",\"&attrs\",\"@resourceIcon\",\"@resourceTitle\",\"@showLoader\"],\"statements\":[[8,\"paper-card\",[[17,5],[24,\"data-test-general-summary\",\"\"]],[[\"@class\"],[\"general-summary\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[\"@class\"],[\"layout-row layout-align-start-center info-text\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"avatar\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"paper-icon\",[[24,\"data-test-icon\",\"\"]],[[\"@class\",\"@icon\",\"@size\"],[\"info\",[32,6],20]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"text\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"title\"]],[[24,\"data-test-title\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,7]]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-center flex\"],[12],[2,\"\\n      \"],[8,\"paper-progress-circular\",[[24,\"data-test-loading-data\",\"\"]],[[\"@diameter\"],[30]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1,[\"content\"]],[[24,\"data-test-resource-summary\",\"\"]],[[\"@class\"],[\"flex summary-content\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,[[30,[36,0],null,[[\"content\"],[\"blank-template\"]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,[[30,[36,0],null,[[\"action\"],[\"blank-template\"]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    meta: {
      moduleName: "front-end/components/company-resource-summary/general-summary.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});