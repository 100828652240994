define("front-end/templates/components/custom-modals/default-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fg0UjIce",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"warn\",\"onClick\",\"data-test-cancel-confirm-dialog\"],[true,[30,[36,1],[[32,0],[35,0]],null],true]],[[\"default\"],[{\"statements\":[[2,\"  Cancelar\\n\"]],\"parameters\":[]}]]],[6,[37,2],null,[[\"raised\",\"primary\",\"disabled\",\"onClick\",\"data-test-submit-confirm-dialog\"],[true,true,[35,4],[30,[36,1],[[32,0],[35,3]],null],true]],[[\"default\"],[{\"statements\":[[2,\"  Confirmar\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"decline\",\"action\",\"paper-button\",\"confirm\",\"confirmDisabled\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/custom-modals/default-actions.hbs"
    }
  });

  _exports.default = _default;
});