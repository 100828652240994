define("front-end/templates/components/brazilian-cities-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vv5URvDl",
    "block": "{\"symbols\":[\"city\",\"autocomplete\"],\"statements\":[[10,\"div\"],[14,\"data-test-cities-selector\",\"\"],[12],[2,\"\\n\"],[6,[37,12],null,[[\"isTouched\",\"disabled\",\"required\",\"label\",\"loadingMessage\",\"noMatchesMessage\",\"options\",\"selected\",\"allowClear\",\"searchText\",\"onSearchTextChange\",\"searchField\",\"labelPath\",\"errorMessages\",\"passThru\",\"onSelectionChange\"],[[35,11],[35,10],[35,9],[35,8],\"Buscando...\",\"Oops cidade não encontrada.\",[35,7],[35,6],true,[30,[36,5],[[35,4]],null],[30,[36,1],[[32,0],\"changeSearchText\"],null],\"name\",\"name\",[35,3],[30,[36,2],null,[[\"autocomplete\",\"autocomplete\"],[\"off\",\"chrome-off\"]]],[30,[36,1],[[32,0],\"setCompanyCity\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"flags\",\"label\",\"searchText\",\"data-test-autocomplete-city\"],[\"i\",[32,1,[\"name\"]],[32,2,[\"searchText\"]],true]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paper-autocomplete-highlight\",\"action\",\"hash\",\"errorMessages\",\"searchText\",\"readonly\",\"selectedBrazilianCity\",\"brazilianCities\",\"label\",\"required\",\"disabled\",\"isTouched\",\"paper-autocomplete\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/brazilian-cities-selector.hbs"
    }
  });

  _exports.default = _default;
});