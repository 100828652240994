define("front-end/routes/dashboard/job-offers", ["exports", "front-end/constants", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-websocket-toaster-message"], function (_exports, _constants, _routeWebsocketHandlerMixin, _routeWebsocketToasterMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, _routeWebsocketToasterMessage.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        sortBy: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread({}, queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var manufacturer = this.modelFor('dashboard').company.id;
      var controller = this.controllerFor('dashboard.jobOffers');
      var jobOfferQueryOptions = {
        filter: {
          manufacturer: manufacturer,
          is_available: true
        },
        sort: '-timeout-deadline',
        include: 'jobOfferProcess.items.partRevision.mainManufacturingProcess,jobOfferProcess.items.partRevision.secondaryManufacturingProcesses,jobOfferProcess.items.partRevision.editedPdfDesign,jobOfferProcess.items.part,jobOfferProcess.items.context.rawMaterialDelivery',
        fields: {
          'part-revision': 'edited_pdf_design,edited_two_dimensional_design,three_dimensional_design,material_from_design,tightest_tolerance,tightest_tolerance_from_design,main_manufacturing_process,secondary_manufacturing_processes,manufacturer_part_treatments,require_calibrator_control,require_dimensional_report,finer_roughness',
          'edited-pdf-design': 'url_for_file,url_for_thumb',
          'three-dimensional-design': 'url_for_file',
          'raw-material-delivery': 'planned_date,tracked'
        },
        page: {
          size: controller.size,
          number: controller.pageOffers
        }
      };
      var estimateConfirmationQueryOptions = {
        filter: {
          manufacturer: manufacturer,
          status: _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE
        },
        sort: '-timeout-deadline',
        include: 'partRevision.editedPdfDesign,estimate,estimate-survey,context.rawMaterialDelivery',
        fields: {
          'part-revision': 'edited_pdf_design,edited_two_dimensional_design,material_from_design,tightest_tolerance,tightest_tolerance_from_design',
          'edited-pdf-design': 'url_for_file,url_for_thumb',
          'raw-material-delivery': 'planned_date,tracked'
        },
        page: {
          size: controller.size,
          number: controller.pageConfirmations
        }
      };

      if (params.sortBy) {
        jobOfferQueryOptions['sort'] = params.sortBy;
        estimateConfirmationQueryOptions['sort'] = params.sortBy;
      }

      if (params.myId) {
        jobOfferQueryOptions.filter['myId.match'] = params.myId;
        estimateConfirmationQueryOptions.filter['myId.match'] = params.myId;
      }

      var estimateConfirmations = this.store.query('estimate-confirmation', estimateConfirmationQueryOptions);
      var hasConfirmations = this.store.query('estimate-confirmation', {
        filter: {
          manufacturer: manufacturer,
          status: _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (confirmation) {
        return confirmation.get('meta.count') > 0;
      });
      var jobOffers = this.store.query('job-offer', jobOfferQueryOptions);
      return Ember.RSVP.hash({
        jobOffers: jobOffers,
        estimateConfirmations: estimateConfirmations,
        hasConfirmations: hasConfirmations
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (!controller.sortBy) {
        controller.set('sortBy', '-timeout_deadline');
      }

      if (controller.myId) {
        controller.set('reloadOffers', model.jobOffers.length > 0);
        controller.set('reloadConfirmations', model.estimateConfirmations.length > 0);
      }

      if (controller.reloadConfirmations) {
        model.estimateConfirmations.every(function (confirmation) {
          return controller.estimateConfirmationsArray.pushObject(confirmation);
        });
      }

      if (controller.reloadOffers) {
        model.jobOffers.every(function (offer) {
          return controller.jobOffersArray.pushObject(offer);
        });
      }

      controller.setProperties({
        reloadOffers: true,
        reloadConfirmations: true,
        refreshedByFilter: false
      });
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();

        Ember.setProperties(controller, {
          refreshedByFilter: false,
          reloadOffers: true,
          reloadConfirmations: true,
          estimateConfirmationsArray: [],
          jobOffersArray: [],
          size: controller.size,
          page: controller.defaultPage
        });
      }

      this._super.apply(this, arguments);
    },
    _toasterClickHandler: function _toasterClickHandler() {
      var controller = this.controller;
      Ember.setProperties(controller, {
        page: controller.defaultPage,
        size: controller.size,
        jobOffersArray: [],
        estimateConfirmationsArray: []
      });
    },
    websocketResourceHandler: function websocketResourceHandler(record, type, payloadEvent) {
      var correctType = type == 'job-offers' || type == 'estimate-confirmations';

      var checkEstimateConfirmation = correctType && record.get('status') != _constants.ESTIMATE_CONFIRMATION_STATUS.CONFIRMED && record.get('status') != _constants.ESTIMATE_CONFIRMATION_STATUS.REJECTED;

      var checkJobOffer = correctType && payloadEvent == 'resource_created';

      if (checkEstimateConfirmation || checkJobOffer) {
        var currentCompany = this.modelFor('dashboard').company;

        if (record.get('manufacturer.id') == currentCompany.get('id')) {
          // Action implemented by RouteWebsocketToasterMessage mixin
          this.send('showToasterForNewMessages', 'Você pode ter novas oportunidades. Recarregue a página.');
        }
      }
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      },
      websocketToasterRefresh: function websocketToasterRefresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});