define("front-end/models/actions/set-new-password-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    password: (0, _model.attr)('string'),
    passwordConfirmation: (0, _model.attr)('string'),
    recoveryPasswordToken: (0, _model.attr)('string')
  });

  _exports.default = _default;
});