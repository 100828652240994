define("front-end/controllers/dashboard/jobs/index", ["exports", "front-end/mixins/controller-pagination-mixin"], function (_exports, _controllerPaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    loadingModel: false,
    sortBy: '-created_at',
    myId: '',
    statusSelected: '',
    finishedJobs: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.jobs';
      var queryParams = ['myId', 'finishedJobs', 'sortBy'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
      this.sortOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Data de criação',
        value: 'created_at'
      }, {
        display: 'Última modificação',
        value: 'updated_at'
      }];
    },
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        sortBy: '-created_at',
        myId: '',
        statusSelected: '',
        page: 1,
        size: this.defaultSizeValue,
        refreshedByFilter: true
      });
    },
    actions: {
      toggleFinishedJobs: function toggleFinishedJobs() {
        this._resetFilters();

        Ember.setProperties(this, {
          finishedJobs: !this.finishedJobs,
          refreshedByFilter: true
        });
      },
      clearFilters: function clearFilters() {
        this.set('finishedJobs', false);

        this._resetFilters();
      },
      sortBy: function sortBy(option) {
        Ember.setProperties(this, {
          sortBy: option.value,
          refreshedByFilter: true
        });
      },
      filterByMyId: function filterByMyId(myId) {
        this._resetFilters();

        Ember.setProperties(this, {
          myId: myId,
          refreshedByFilter: true
        });
      }
    }
  });

  _exports.default = _default;
});