define("front-end/templates/components/date-filter-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gRQsBEHT",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],null,[[\"class\",\"label\",\"value\",\"minDate\",\"icon\",\"format\",\"errorMessages\",\"onChange\",\"yearRange\",\"passThru\",\"data-test-date-input\"],[\"flex-95\",[35,5],[35,4],[35,3],\"date_range\",\"DD/MM/YYYY\",[35,2],[30,[36,1],[[32,0],\"setDate\"],null],\"currentYear, 2050\",[30,[36,0],null,[[\"autocomplete\"],[\"off\"]]],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\",\"errorMessages\",\"minDateRaw\",\"dateShow\",\"label\",\"custom-paper-pikaday\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/date-filter-input.hbs"
    }
  });

  _exports.default = _default;
});