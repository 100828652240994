define("front-end/components/pricing-processes-performance-rate", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (this.isMobile.any) {
        this.set('size', 100);
      } else {
        this.set('size', 400);
      } // Doughnut plugin to allow adding a text in the middle. Based on:
      // https://github.com/chartjs/Chart.js/issues/78#issuecomment-289264197


      var plugin = {
        beforeDraw: function beforeDraw(chart) {
          if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx; //Get options from the center object in options

            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#272727';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2); //Start with a base font of 30px

            ctx.font = "30px " + fontStyle; //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated; // Find out how much the font can grow in width.

            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2; // Pick a new font size so it will not be larger than the height of label.

            var fontSizeToUse = Math.min(newFontSize, elementHeight); //Set font settings to draw it correctly.

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color; //Draw text in center

            ctx.fillText(txt, centerX, centerY);
          }
        }
      };
      var chartOptions = {
        data: {},
        type: 'doughnut',
        options: {
          responsive: true,
          legend: {
            display: true
          },
          animation: {
            duration: 0
          },
          hover: {
            animationDuration: 0
          },
          responsiveAnimationDuration: 0,
          elements: {
            center: {
              text: '',
              color: '',
              //Default black
              fontStyle: 'Helvetica',
              //Default Arial
              sidePadding: 15 //Default 20 (as a percentage)

            }
          }
        },
        plugins: [plugin]
      };
      this.set('chartOptions', chartOptions);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var estimatesSurveyTotal = this.estimatesSurveyTotal;
      var supervisedQuotesTotal = this.supervisedQuotesTotal;
      var labels = ['Pesquisa de orçamentos', 'Cotação Supervisionada'];
      var datasets = [{
        data: [estimatesSurveyTotal, supervisedQuotesTotal],
        backgroundColor: ['#BDE5F8', '#DFF2BF']
      }]; // TODO: Allow api to perform a get on pricingProcess

      var centerText = estimatesSurveyTotal + supervisedQuotesTotal;
      this.set('chartOptions.options.elements.center.text', centerText);
      var chartData = {
        datasets: datasets,
        labels: labels
      };
      this.set('chartOptions.data', chartData);
    },
    didRender: function didRender() {
      var ctx = document.getElementById("pricing-processes-performance-rate");
      this.set('chart', new _chart.default(ctx, this.chartOptions));
    }
  });

  _exports.default = _default;
});