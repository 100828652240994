define("front-end/components/textbox-save/textarea", ["exports", "front-end/mixins/component-textbox-save"], function (_exports, _componentTextboxSave) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentTextboxSave.default, {
    maxlength: '',
    placeholder: '',
    actions: {
      modelSave: function modelSave() {
        Ember.run.debounce(this, '_performSave', this.debounce);
      }
    }
  });

  _exports.default = _default;
});