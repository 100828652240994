define("front-end/models/actions/change-deadline-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    newDeadline: (0, _model.attr)('string'),
    estimateSurveys: (0, _model.hasMany)('estimate-survey', {
      inverse: null
    })
  });

  _exports.default = _default;
});