define("front-end/components/process-dates/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class={{concat "layout-column layout-align-space-between flex-20 batch-related-process " @processClass}}>
    <span class="text-center"><PaperIcon @icon={{@icon}} /><b> {{@processName}}</b></span>
    <div class="layout-column">
      <span><PaperIcon @icon="edit_calendar" /> Data Prevista: <b data-test-planned-date>{{formatted-date @plannedDate}}</b></span>
      <span><PaperIcon @icon="event_available" /> Data Efetiva: <b data-test-actual-date>{{formatted-date @actualDate}}</b></span>
    </div>
  </div>
  */
  {
    id: "mxZmsmIi",
    block: "{\"symbols\":[\"&attrs\",\"@processClass\",\"@icon\",\"@processName\",\"@plannedDate\",\"@actualDate\"],\"statements\":[[11,\"div\"],[17,1],[16,0,[30,[36,0],[\"layout-column layout-align-space-between flex-20 batch-related-process \",[32,2]],null]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-center\"],[12],[8,\"paper-icon\",[],[[\"@icon\"],[[32,3]]],null],[10,\"b\"],[12],[2,\" \"],[1,[32,4]],[13],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-column\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[8,\"paper-icon\",[],[[\"@icon\"],[\"edit_calendar\"]],null],[2,\" Data Prevista: \"],[10,\"b\"],[14,\"data-test-planned-date\",\"\"],[12],[1,[30,[36,1],[[32,5]],null]],[13],[13],[2,\"\\n    \"],[10,\"span\"],[12],[8,\"paper-icon\",[],[[\"@icon\"],[\"event_available\"]],null],[2,\" Data Efetiva: \"],[10,\"b\"],[14,\"data-test-actual-date\",\"\"],[12],[1,[30,[36,1],[[32,6]],null]],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"formatted-date\"]}",
    meta: {
      moduleName: "front-end/components/process-dates/card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});