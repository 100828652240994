define("front-end/models/commercial-proposal", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    session: Ember.inject.service(),
    version: (0, _model.attr)('number'),
    mainContactName: (0, _model.attr)('string'),
    contactEmails: (0, _model.attr)(),
    // Array
    note: (0, _model.attr)('string'),
    filename: (0, _model.attr)('string'),
    commercialProposalPdf: (0, _model.belongsTo)('files/commercial-proposal-pdf', {
      inverse: 'resource'
    }),
    project: (0, _model.belongsTo)('project'),
    creator: (0, _model.belongsTo)('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});