define("front-end/components/go-back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    actions: {
      goBack: function goBack() {
        this.router.transitionTo(this.previousRoute);
      }
    }
  });

  _exports.default = _default;
});