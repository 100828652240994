define("front-end/components/estimates-table-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statusSelected: null,
    sortEstimatesFilter: true,
    historyEstimatesFilter: true,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var statusSelected = this.availableEstimateStatus.find(function (option) {
        return option.value.toString() === _this.status.toString();
      });
      this.set('statusSelected', statusSelected);
    },
    inputSizeClass: Ember.computed('sortEstimatesFilter', function () {
      return this.sortEstimatesFilter ? 'flex-gt-sm-30' : 'flex-gt-sm-45';
    })
  });

  _exports.default = _default;
});