define("front-end/controllers/admin/job-offer-processes/index", ["exports", "front-end/mixins/controller-pagination-mixin", "front-end/constants"], function (_exports, _controllerPaginationMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    loadingModel: false,
    disableFormButton: false,
    jobOfferProcessItemStatus: '',
    companyId: '',
    myId: '',
    batchMyId: '',
    partInternalCode: '',
    statusSelected: '',
    showSelectManufacturersDialog: false,
    selectProcess: false,
    finished: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.jobOfferProcesses';
      this.selectedProcesses = [];
      this.availableJobOfferProcessStatus = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Processo sem PDF descaracterizado',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.MISSING_FILE
      }, {
        display: 'Processo em processo de criação/Pronto para ser oferecido',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_OFFER
      }, {
        display: 'Processo aguardando resposta dos parceiros',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.AVAILABLE
      }, {
        display: 'Pronto para selecionar parceiro',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.READY_TO_SELECT_WINNER
      }, {
        display: 'Processo Finalizado',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.COMPLETED
      }, {
        display: 'Processo Falhou',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.FAILED
      }, {
        display: 'Processo Cancelado',
        value: _constants.JOB_OFFER_PROCESS_ITEM_STATUS.CANCELED
      }];
      var queryParams = ['myId', 'batchMyId', 'partInternalCode', 'companyId', 'jobOfferProcessItemStatus', 'finished'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
    },
    filteredJobOfferProcesses: Ember.computed('model.jobOfferProcesses.[]', 'selectProcess', function () {
      if (this.selectProcess) {
        return this.model.jobOfferProcesses.filter(function (process) {
          return process.allowNewOffers;
        });
      } else {
        return this.model.jobOfferProcesses;
      }
    }),
    filteredJobOfferProcessesCount: Ember.computed('filteredJobOfferProcesses.[]', 'model.jobOfferProcesses.meta.count', 'selectProcess', function () {
      if (this.selectProcess) {
        return this.filteredJobOfferProcesses.length;
      } else {
        return this.model.jobOfferProcesses.meta.count;
      }
    }),
    selectProcessButtonText: Ember.computed('selectProcess', function () {
      if (this.selectProcess) {
        return "Cancelar seleção";
      } else {
        return "Selecionar processos para job board";
      }
    }),
    notSelectableProcesses: Ember.computed.filterBy('model.jobOfferProcesses', 'allowNewOffers', false),
    projectOwners: Ember.computed('selectedProcesses.[]', function () {
      var projectOwners = [];
      this.selectedProcesses.forEach(function (process) {
        if (!projectOwners.includes(process.get('company.id'))) {
          projectOwners.push(process.get('company.id'));
        }
      });
      return Ember.A(projectOwners);
    }),
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        myId: '',
        batchMyId: '',
        partInternalCode: '',
        companyId: '',
        jobOfferProcessItemStatus: '',
        statusSelected: '',
        selectProcess: false,
        finished: false,
        selectedProcesses: [],
        jobOfferProcesses: this.model.jobOfferProcesses,
        page: 1,
        size: this.defaultSizeValue,
        refreshedByFilter: true
      });
    },
    actions: {
      createJobOfferProcess: function createJobOfferProcess() {
        var _this = this;

        this.set('disableFormButton', true);
        this.store.createRecord('job-offer-process', {
          offerType: 'InterestBasedJobOffer'
        }).save().then(function (jobOfferProcess) {
          _this.set('disableFormButton', false);

          _this.transitionToRoute('admin.job-offer-processes.build', jobOfferProcess.get('id'));
        });
      },
      addProcess: function addProcess(process) {
        if (this.selectedProcesses.includes(process)) {
          this.selectedProcesses.removeObject(process);
        } else {
          this.selectedProcesses.pushObject(process);
        }
      },
      selectStatus: function selectStatus(option) {
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          jobOfferProcessItemStatus: option.value,
          statusSelected: option,
          refreshedByFilter: true
        });
      },
      filterByClient: function filterByClient(company) {
        var id = company ? company.get('id') : '';
        Ember.setProperties(this, {
          page: 1,
          size: this.defaultSizeValue,
          companyId: id,
          refreshedByFilter: true
        });
      },
      filterByMyId: function filterByMyId(myId) {
        Ember.setProperties(this, {
          page: 1,
          myId: myId,
          refreshedByFilter: true,
          size: this.defaultSizeValue
        });
      },
      filterByBatchMyId: function filterByBatchMyId(batchMyId) {
        Ember.setProperties(this, {
          page: 1,
          batchMyId: batchMyId,
          refreshedByFilter: true,
          size: this.defaultSizeValue
        });
      },
      filterByPartInternalCode: function filterByPartInternalCode(internalCode) {
        Ember.setProperties(this, {
          page: 1,
          partInternalCode: internalCode,
          refreshedByFilter: true,
          size: this.defaultSizeValue
        });
      },
      clearFilters: function clearFilters() {
        this._resetFilters();
      },
      cancelAction: function cancelAction() {
        this.set('showSelectManufacturersDialog', false);

        this._resetFilters();
      }
    }
  });

  _exports.default = _default;
});