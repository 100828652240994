define("front-end/services/application-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This service will be used to save the frontend
  // version given by the backend to check user application is outdated
  var _default = Ember.Service.extend({
    frontendVersion: ''
  });

  _exports.default = _default;
});