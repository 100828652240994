define("front-end/models/batch", ["exports", "@ember-data/model", "front-end/constants", "moment", "front-end/models/default-model"], function (_exports, _model, _constants, _moment, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    status: (0, _model.attr)('number'),
    message: (0, _model.attr)('string'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    previousStatus: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    note: (0, _model.attr)('string'),
    deliveryCity: (0, _model.attr)('string'),
    paymentMethod: (0, _model.attr)('string'),
    unitTargetPrice: (0, _model.attr)('pt-currency'),
    rawMaterialFormat: (0, _model.attr)('string'),
    providingFreight: (0, _model.attr)('boolean'),
    quotationDeadline: (0, _model.attr)('string'),
    providingRawMaterial: (0, _model.attr)('boolean'),
    completedAt: (0, _model.attr)('string'),
    project: (0, _model.belongsTo)('project', {
      inverse: 'batches'
    }),
    partRevision: (0, _model.belongsTo)('part-revision', {
      inverse: 'batches'
    }),
    context: (0, _model.belongsTo)('batch-context', {
      inverse: 'batch'
    }),
    quotation: (0, _model.belongsTo)('quotation', {
      inverse: 'batch'
    }),
    manufacturingJob: (0, _model.belongsTo)('manufacturing-job', {
      inverse: 'batch'
    }),
    purchaseOrderItem: (0, _model.belongsTo)('purchase-order-item', {
      inverse: 'batch'
    }),
    clientFormalization: (0, _model.belongsTo)('client-formalization', {
      inverse: 'batch'
    }),
    responsible: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    previous: (0, _model.belongsTo)('batch', {
      inverse: 'next'
    }),
    next: (0, _model.belongsTo)('batch', {
      inverse: 'previous'
    }),
    creator: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    // Denormalized relationships
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    validateForProjectErrors: Ember.computed('deadlineIsFuture', 'deliveryCity', 'paymentMethod', 'providingRawMaterial', 'quantity', 'quotationDeadline', 'rawMaterialFormat', function () {
      var errors = {};

      if (!this.quantity || this.quantity <= 0) {
        errors.quantity = 'Quantidade inválida';
      } else {
        delete errors.quantity;
      }

      if (!this.quotationDeadline || this.deadlineIsFuture) {
        errors.quotationDeadline = 'Prazo de cotação inválido';
      } else {
        delete errors.quotationDeadline;
      }

      if (!this.paymentMethod) {
        errors.paymentMethod = 'Método de pagamento inválido';
      } else {
        delete errors.paymentMethod;
      }

      if (!this.deliveryCity) {
        errors.deliveryCity = 'Cidade de entrega inválida';
      } else {
        delete errors.deliveryCity;
      }

      if (this.providingRawMaterial && !this.rawMaterialFormat) {
        errors.rawMaterialFormat = 'Formato da matéria prima inválido';
      } else {
        delete errors.rawMaterialFormat;
      }

      return errors;
    }),
    deadlineIsFuture: Ember.computed('quotationDeadline', function () {
      return (0, _moment.default)(this.quotationDeadline).isBefore(_moment.default.now());
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.BATCH_STATUS.CREATING:
          return 'warning';

        case _constants.BATCH_STATUS.QUOTING:
          return 'warning';

        case _constants.BATCH_STATUS.FORMALIZING:
          return 'warning';

        case _constants.BATCH_STATUS.DEFINING_MANUFACTURER:
          return 'warning';

        case _constants.BATCH_STATUS.MANUFACTURING:
          return 'info';

        case _constants.BATCH_STATUS.TRANSITING:
          return 'info';

        case _constants.BATCH_STATUS.COMPLETED:
          return 'success';

        case _constants.BATCH_STATUS.CANCELED:
          return 'error';

        case _constants.BATCH_STATUS.DEPRECATED:
          return 'error';

        case _constants.BATCH_STATUS.DENIED:
          return 'warning';

        default:
          return 'info';
      }
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.BATCH_STATUS.CREATING:
          return 'build';

        case _constants.BATCH_STATUS.QUOTING:
          return 'request_quote';

        case _constants.BATCH_STATUS.FORMALIZING:
          return 'grading';

        case _constants.BATCH_STATUS.DEFINING_MANUFACTURER:
          return 'connect_without_contact';

        case _constants.BATCH_STATUS.MANUFACTURING:
          return 'precision_manufacturing';

        case _constants.BATCH_STATUS.TRANSITING:
          return 'local_shipping';

        case _constants.BATCH_STATUS.COMPLETED:
          return 'thumb_up';

        case _constants.BATCH_STATUS.CANCELED:
          return 'thumb_down';

        case _constants.BATCH_STATUS.DEPRECATED:
          return 'cancel';

        case _constants.BATCH_STATUS.DENIED:
          return 'cancel';

        default:
          return '';
      }
    }),
    readableStatus: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.BATCH_STATUS.FORMALIZING:
          return 'Em Formalização';

        case _constants.BATCH_STATUS.MANUFACTURING:
          return 'Em Fabricação';

        case _constants.BATCH_STATUS.TRANSITING:
          return 'Em trânsito';

        case _constants.BATCH_STATUS.COMPLETED:
          return 'Finalizado';

        case _constants.BATCH_STATUS.CANCELED:
          return 'Cancelado';

        default:
          return '';
      }
    }),
    isManufacturing: Ember.computed('status', function () {
      return this.status == _constants.BATCH_STATUS.MANUFACTURING;
    }),
    isDeclined: Ember.computed('status', function () {
      return this.status == _constants.BATCH_STATUS.DENIED;
    }),
    hasBeenCanceled: Ember.computed('status', function () {
      var status = this.status;
      return status == _constants.BATCH_STATUS.DENIED || status == _constants.BATCH_STATUS.DEPRECATED || status == _constants.BATCH_STATUS.CANCELED;
    }),
    hasDefinedManufacturer: Ember.computed('status', function () {
      var status = this.status;
      return status >= _constants.BATCH_STATUS.MANUFACTURING && status <= _constants.BATCH_STATUS.COMPLETED;
    }),
    isLost: Ember.computed('status', function () {
      var canceled = this.status == _constants.BATCH_STATUS.CANCELED;
      var deprecated = this.status == _constants.BATCH_STATUS.DEPRECATED;
      return canceled || deprecated;
    }),
    hasPurchaseOrderItem: Ember.computed('purchaseOrderItem', function () {
      return this.belongsTo('purchaseOrderItem').id();
    }),
    setProvidingRawMaterial: function setProvidingRawMaterial(value) {
      this.set('providingRawMaterial', value);

      if (!this.providingRawMaterial) {
        this.set('rawMaterialFormat', null);
      }
    },
    humanizedFreightResponsible: Ember.computed('providingFreight', function () {
      var option = this.providingFreight;
      return this._translateOption(option);
    }),
    humanizedRawMaterialResponsible: Ember.computed('providingRawMaterial', function () {
      var option = this.providingRawMaterial;
      return this._translateOption(option);
    }),
    _translateOption: function _translateOption(option) {
      return option ? 'Cliente' : 'Fornecedor/Peerdustry';
    },
    route: function route() {
      var _this = this;

      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          return _this.adminRoute();
        } else {
          var projectId = _this.belongsTo('project').id(); // return not null elements of the below array


          return ['dashboard.projects.manage', companyId, projectId].filter(function (x) {
            return x;
          });
        }
      });
    },
    adminRoute: function adminRoute() {
      var projectId = this.belongsTo('project').id();
      return ['admin.projects.show.batch.analysis', projectId, this.id];
    }
  });

  _exports.default = _default;
});