define("front-end/mixins/route-websocket-handler-mixin", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    websockets: Ember.inject.service(),
    openFunction: null,
    closeFunction: null,
    messageFunction: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.socketRef = null; // Flag to define whether the route should try to reconnect again after
      // a closed connection

      this.reconnectWebSocket = false; // Default list of WS Channels for subscription

      this.websocketChannels = ['CompaniesChannel', 'UsersChannel', 'AdminsChannel']; // Flag to avoid that loading templates are shown on receiving WS messages

      this.refreshedByWebsocket = false; // Flag to execute the extendable method websocketMessageHandlerReady
      // after the proper registration of myMessageHandler

      this.messageHandlerReady = false; // The properties bellow aims at adding a new callback reference for each
      // route since registering a class function directly as the callback,
      // such as the `myMessageHandler`, leads to reference problems on
      // callback offloading. For instance, a route deactivate callback would
      // offload the callback for all other active routes undesirably

      this.set('openFunction', function (event) {
        _this.myOpenHandler(event);
      });
      this.set('messageFunction', function (event) {
        _this.myMessageHandler(event);
      });
      this.set('closeFunction', function (event) {
        _this.myCloseHandler(event);
      });
    },
    _connect: function _connect() {
      var access_token = this.session.data.authenticated.jwt;
      var url = _environment.default.ws + access_token;
      this.set('websocketUrl', url);
      var socket = this.websockets.socketFor(this.websocketUrl);
      socket.on('open', this.openFunction, this);
      socket.on('message', this.messageFunction, this);

      if (this.reconnectWebSocket) {
        socket.on('close', this.closeFunction, this);
      }

      this.set('socketRef', socket);
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      this._connect();
    },
    resetController: function resetController() {
      this._super.apply(this, arguments); // Resetting refreshedByWebsocket to the initial value
      // to show loader when the users are loading the route
      // in another way that is not a WS message


      this.set('refreshedByWebsocket', false);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments); // Tearing down `messageHandlerReady` on WS disconnection
      // to avoid that `websocketMessageHandlerReady` won't run
      // if the user had a logout without a browser reload and login again


      this.set('messageHandlerReady', false);
      this.socketRef.off('open', this.openFunction);
      this.socketRef.off('message', this.messageFunction);

      if (this.reconnectWebSocket) {
        this.socketRef.off('close', this.closeFunction);
      }
    },
    myCloseHandler: function myCloseHandler(err) {
      var _this2 = this;

      if (err.code == 1000) {
        /*
          code 1000 stands for unauthorized (ember-websockets)
        */
        if (this.session.isAuthenticated) {
          this._connect();
        } else {
          this.router.transitionTo('logout');
        }
      } else {
        Ember.run.later(this, function () {
          /*
            Try to reconnect to websocket every second
          */
          if (_this2.session.isAuthenticated) {
            _this2.socketRef.reconnect();
          } else {
            _this2.router.transitionTo('logout');
          }
        }, 1000);
      }
    },
    myOpenHandler: function myOpenHandler() {
      var _this3 = this;

      var msgs = [];
      this.websocketChannels.forEach(function (channel) {
        var msg = {
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: channel
          })
        };
        msgs.push(msg);
      });
      msgs.forEach(function (msg) {
        _this3.socketRef.send(msg, true);
      });
    },
    myMessageHandler: function myMessageHandler(event) {
      var data = JSON.parse(event.data);

      if (!this.messageHandlerReady) {
        if (data['type'] == 'ping') {
          // Set as true messageHandlerReady
          // after the first received ping from the backend
          this.set('messageHandlerReady', true);
          this.websocketMessageHandlerReady();
        }
      }

      if (!data['type']) {
        var payloadType = data.message.type;
        var payloadEvent = data.message.event; // Parsing the original data again since the data object may be modified
        // by pushPayload

        this.websocketRawHandler(JSON.parse(event.data).message.payload, payloadType, payloadEvent);

        if (payloadType == 'resource') {
          var payload = data.message.payload;
          var type = payload.data.type;
          var id = payload.data.id; // We cannot use the data object after calling pushPayload since it
          // changes the original object

          this.store.pushPayload(payload);
          var record = this.store.peekRecord(type.singularize(), id);
          this.websocketResourceHandler(record, type, payloadEvent);
        }
      }
    },
    // Override this method in extended components to handle the raw message
    // from websocket. Notice it does not send system-specific events data, such
    // as pings. This method receives the following arguments:
    // - payload: the payload
    // - payloadType: the type of the payload
    // - payloadEvent: the eventy of the payload
    websocketRawHandler: function websocketRawHandler() {},
    // Override this method in extended components to handle JSON-API based
    // messages. Such messages are usually related to the update or create of
    // model records. This method is called only when the payloadType is
    // 'resource'. It receives the following arguments:
    // - record: the DS model from the store with the latest data
    // - type: the json/api type in plural. Use the `singularize` method if
    //   necessary
    websocketResourceHandler: function websocketResourceHandler() {},
    // Override this method in extended routes when you want to make sure
    // that we had already registered `myMessageHandler` method
    // to guarantee that we are ready to send and receive messages
    websocketMessageHandlerReady: function websocketMessageHandlerReady() {},
    actions: {
      // Special action that allows us to control
      // when the loader template will be shown
      loading: function loading() {
        return !this.refreshedByWebsocket;
      },
      refreshRouteMixin: function refreshRouteMixin() {
        // Setting refreshedByWebsocket to true
        // to not show the loader template on receiving WS messages
        this.set('refreshedByWebsocket', true);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});