define("front-end/services/ajax", ["exports", "ember-ajax/services/ajax", "front-end/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.host,
    namespace: 'core/v1',
    headers: Ember.computed('session.data.authenticated.jwt', {
      get: function get() {
        var headers = {};
        var authToken = this.session.data.authenticated.jwt;

        if (authToken) {
          headers['API_KEY'] = _environment.default.API_KEY, headers['Authorization'] = "Bearer ".concat(authToken);
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});