define("front-end/templates/components/display-moderated-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PwL67SQ5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[35,0],[35,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"moderated-field-without-diff\"],[12],[2,\"\\n      \"],[18,1,[[35,2]]],[2,\"\\n\"],[6,[37,1],null,[[\"position\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"        Campo moderado sem alterações em relação ao original\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"original-field-with-diff\"],[12],[2,\"\\n      \"],[18,1,[[35,0]]],[2,\"\\n\"],[6,[37,1],null,[[\"position\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"        Campo original (Não é apresentado aos fornecedores)\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"moderated-field-with-diff\"],[12],[2,\"\\n      \"],[18,1,[[35,2]]],[2,\"\\n\"],[6,[37,1],null,[[\"position\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"        Campo moderado (Apresentado aos fornecedores)\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,\"data-test-no-moderated-field\",\"\"],[12],[18,1,[[35,0]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"originalField\",\"paper-tooltip\",\"moderatedField\",\"eq\",\"if\",\"showModerated\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/display-moderated-field.hbs"
    }
  });

  _exports.default = _default;
});