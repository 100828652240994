define("front-end/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      if (this.session.isAuthenticated) {
        return this.currentUser.load().then(function (user) {
          if (user.get('isAdmin')) {
            _this.transitionTo('admin');
          } else {
            return user.get('company').then(function (company) {
              return _this.transitionTo('dashboard', company.id);
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});