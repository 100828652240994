define("front-end/controllers/admin/statistics/users/user", ["exports", "front-end/mixins/controller-pagination-mixin"], function (_exports, _controllerPaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.paginatedModelName = 'model.users';
    }
  });

  _exports.default = _default;
});