define("front-end/controllers/admin/statistics/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    highlightUserButton: Ember.computed.equal('router.currentRouteName', 'admin.statistics.users.user'),
    highlightAdminButton: Ember.computed.equal('router.currentRouteName', 'admin.statistics.users.admin')
  });

  _exports.default = _default;
});