define("front-end/components/batch-information-for-manufacturer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    material: Ember.computed('model.material', function () {
      return this.model.material || 'Conforme desenho';
    }),
    rawMaterialIncluded: Ember.computed('model.rawMaterialResponsible', function () {
      var responsible = this.model.rawMaterialResponsible;
      return responsible != 'manufacturer';
    })
  });

  _exports.default = _default;
});