define("front-end/models/actions/register-user-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    cnpj: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    passwordConfirmation: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    })
  });

  _exports.default = _default;
});