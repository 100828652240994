define("front-end/models/tags/raw-material", ["exports", "@ember-data/model", "front-end/models/tags/application-tag"], function (_exports, _model, _applicationTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationTag.default.extend({
    manufacturerCharacterizations: (0, _model.hasMany)('manufacturerCharacterization', {
      inverse: 'recurringRawMaterials'
    })
  });

  _exports.default = _default;
});