define("front-end/controllers/dashboard/tasks/show/user-approval-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalsManager: Ember.inject.service(),
    actions: {
      processTask: function processTask() {
        var task = this.model.task;
        this.modalsManager.confirm({
          title: 'Você tem certeza que deseja aceitar essa solicitação?',
          body: 'Aceitar solicitação',
          footerComponent: 'custom-modals/default-actions'
        }).then(function () {
          task.set('action', 'process');
          task.save();
        }).catch(function () {});
      },
      cancelTask: function cancelTask() {
        var task = this.model.task;
        this.modalsManager.confirm({
          title: 'Recusar solicitação',
          body: 'Você tem certeza que deseja rejeitar essa solicitação?',
          footerComponent: 'custom-modals/default-actions'
        }).then(function () {
          task.set('action', 'cancel');
          task.save();
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});