define("front-end/templates/components/mechanism-option-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uY2trZyN",
    "block": "{\"symbols\":[\"controls\"],\"statements\":[[6,[37,6],null,[[\"class\",\"onClick\",\"data-test-initialized\"],[[30,[36,1],[[35,0,[\"level\"]],\" md-2-line mechanism-option option-initialized\"],null],[30,[36,5],[[32,0],[35,4]],null],true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[35,0,[\"icon\"]]],[[\"class\"],[[30,[36,1],[[35,0,[\"level\"]],\"-text md-avatar-icon round-icon\"],null]]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[10,\"b\"],[14,\"data-test-label\",\"\"],[12],[1,[34,3]],[13],[13],[2,\"\\n    \"],[10,\"p\"],[14,\"data-test-status-message\",\"\"],[12],[1,[35,0,[\"statusMessage\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"mechanism\",\"concat\",\"paper-icon\",\"label\",\"showAction\",\"action\",\"paper-item\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/mechanism-option-card.hbs"
    }
  });

  _exports.default = _default;
});