define("front-end/models/purchase-order", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    fullId: (0, _model.attr)('string'),
    identifier: (0, _model.attr)('string'),
    companyPrefix: (0, _model.attr)('string'),
    creating: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    itemsOrder: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    // Array
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    items: (0, _model.hasMany)('purchase-order-item', {
      inverse: 'purchaseOrder'
    }),
    purchaseOrderPdf: (0, _model.belongsTo)('files/purchase-order-pdf', {
      inverse: 'resource'
    })
  });

  _exports.default = _default;
});