define("front-end/components/ui/batch-responsible-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @batch.responsible}}
    <p data-test-batch-responsible>
      <PaperIcon @icon="how_to_reg" />
      {{@batch.responsible.displayName}}
    </p>
  {{/if}}
  
  */
  {
    id: "YmWTaZk+",
    block: "{\"symbols\":[\"@batch\"],\"statements\":[[6,[37,0],[[32,1,[\"responsible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,\"data-test-batch-responsible\",\"\"],[12],[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@icon\"],[\"how_to_reg\"]],null],[2,\"\\n    \"],[1,[32,1,[\"responsible\",\"displayName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    meta: {
      moduleName: "front-end/components/ui/batch-responsible-info.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});