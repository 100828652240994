define("front-end/routes/admin/companies/show", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.query('company', {
        filter: {
          id: params.company_id
        },
        include: 'users,company_configuration,client_profile,manufacturer_profile,companies_groups,manufacturer_characterization.jotform_response'
      }).then(function (company) {
        if (!company.length) {
          return Ember.RSVP.reject({
            errors: [{
              status: 404
            }]
          });
        }

        return company.get('firstObject');
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var clientProfile = model.clientProfile,
          manufacturerProfile = model.manufacturerProfile;

      if (clientProfile.isClient && manufacturerProfile.isManufacturer) {
        var becameClientAt = clientProfile.becameClientAt;
        var becameManufacturerAt = manufacturerProfile.becameManufacturerAt;
        var becameClientFirst = (0, _moment.default)(becameClientAt).isBefore(becameManufacturerAt);

        if (becameClientFirst) {
          this.transitionTo('admin.companies.show.client');
        } else {
          this.transitionTo('admin.companies.show.manufacturer');
        }
      } else if (!clientProfile.isClient && manufacturerProfile.isManufacturer) {
        this.transitionTo('admin.companies.show.manufacturer');
      } else {
        this.transitionTo('admin.companies.show.client');
      }
    }
  });

  _exports.default = _default;
});