define("front-end/templates/components/estimate-list-text/timeout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c0cx+ONM",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,0,[\"answeredAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"warn\"],[12],[2,\"Este orçamento foi reaberto\"],[13],[2,\"\\n\"],[6,[37,4],[[35,0,[\"unitPrice\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"position\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"b\"],[12],[2,\"Reposta Anterior\"],[13],[2,\"\\n        \"],[1,[30,[36,2],[[35,0,[\"unitPrice\"]]],null]],[2,\" p/ peça - \"],[1,[35,0,[\"daysAmount\"]]],[2,\" dias\\n        Preço Total: \"],[1,[30,[36,2],[[35,0,[\"price\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-created-at\",\"\"],[12],[2,\"Solicitado em \"],[1,[30,[36,1],[[35,0,[\"createdAt\"]],\"DD/MM/YY\"],null]],[13],[2,\"\\n\"],[6,[37,4],[[35,0,[\"answeredAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"data-test-answered-at\",\"\"],[12],[2,\" e Respondido em \"],[1,[30,[36,1],[[35,0,[\"answeredAt\"]],\"DD/MM/YY\"],null]],[10,\"small\"],[12],[2,\" (\"],[1,[35,0,[\"readableAnswerTime\"]]],[2,\")\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  Expirado em \"],[1,[30,[36,1],[[35,0,[\"timeoutDeadline\"]],\"DD/MM/YY | HH:mm\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"estimate\",\"moment-format\",\"format-currency\",\"paper-tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimate-list-text/timeout.hbs"
    }
  });

  _exports.default = _default;
});