define("front-end/controllers/admin/statistics/quotations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    showClientFilters: false,
    queryParams: ['clientCategory', 'clients', 'groupBy', 'upperBound', 'lowerBound'],
    init: function init() {
      this._super.apply(this, arguments);

      this.clients = [];
      this.clientCategory = undefined;
      this.upperBound = null;
      this.lowerBound = null;
      this.groupBySelected = null;
    },
    _resetTimeBounds: function _resetTimeBounds() {
      this.set('upperBound', null);
      this.set('lowerBound', null);
    },
    actions: {
      filterByClientCategory: function filterByClientCategory(value) {
        this.set('clientCategory', value);
      },
      filterByDate: function filterByDate(queryParamName, date) {
        this.set(queryParamName, date);
      },
      clearClientFilters: function clearClientFilters() {
        this.clients.clear();
        this.set('clientCategory', undefined);
      },
      clearDateFilters: function clearDateFilters() {
        this._resetTimeBounds();
      },
      clearFilters: function clearFilters() {
        this._resetTimeBounds();

        this.clients.clear();
        this.set('groupBy', this.groupBySelected.value);
        this.set('groupBySelected', this.groupByOptions[2]);
        this.set('clientCategory', undefined);
      },
      selectGroupBy: function selectGroupBy(option) {
        this.setProperties({
          groupBy: option.value,
          groupBySelected: option
        });
      },
      removeCompany: function removeCompany(company) {
        if (company) {
          this.clients.removeObject(company.get('id'));
        }
      },
      addCompany: function addCompany(company) {
        if (company) {
          this.clients.addObject(company.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});