define("front-end/components/change-deadline-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    newDeadline: null,
    actions: {
      changeDeadline: function changeDeadline() {
        var _this = this;

        var estimateSurveys = this.surveys;
        var newDeadline = this.newDeadline;
        var action = this.store.createRecord('actions/change-deadline-action', {
          estimateSurveys: estimateSurveys,
          newDeadline: newDeadline
        });
        action.save().then(function () {
          _this.toasterMessages.showSuccess(['Os prazos de expiração dos orçamentos foram modificados']);

          if (_this.afterSave) {
            _this.afterSave();
          }
        }).catch(function () {
          if (_this.onError) {
            _this.onError();
          }
        }).finally(function () {
          _this.onClose();
        });
      }
    }
  });

  _exports.default = _default;
});