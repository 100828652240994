define("front-end/controllers/admin/companies/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentRouteName: Ember.computed.reads('router.currentRouteName'),
    clientTabIcon: Ember.computed('model.clientProfile.isClient', function () {
      return this.model.clientProfile.isClient ? 'business' : 'domain_disabled';
    }),
    manufacturerTabIcon: Ember.computed('model.manufacturerProfile.isManufacturer', function () {
      return this.model.manufacturerProfile.isManufacturer ? 'factory' : 'domain_disabled';
    })
  });

  _exports.default = _default;
});