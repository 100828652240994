define("front-end/controllers/dashboard/quotations/index", ["exports", "front-end/mixins/controller-pagination-mixin"], function (_exports, _controllerPaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    store: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    loadingModel: false,
    showRenegotiationDialog: false,
    showDeclineQuotationDialog: false,
    showAcceptQuotationsDialog: false,
    declineMessage: '',
    myId: '',
    status: '',
    partName: '',
    deadlineGte: '',
    deadlineLte: '',
    projectName: '',
    internalCode: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedQuotations = [];
      this.selectedQuotation = null;
      this.paginatedModelName = 'model.quotations';
      var queryParams = ['projectName', 'internalCode', 'status', 'partName', 'myId', 'deadlineGte', 'deadlineLte'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
      this.statusOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Em cotação',
        value: 'quotation'
      }, {
        display: 'Em negociação',
        value: 'negotiation'
      }];
    },
    showInQuotationsTotal: Ember.computed.equal('status', 'quotation'),
    showInNegotiationTotal: Ember.computed.equal('status', 'negotiation'),
    hasActiveQuotations: Ember.computed.or('model.inNegotiationCount', 'model.inQuotationCount'),
    selectedBatches: Ember.computed.map('selectedQuotations', function (quotation) {
      return this.store.peekRecord('batch', quotation.belongsTo('batch').id());
    }),
    quotationsByProject: Ember.computed('model.quotations.[]', function () {
      var projects = {};
      var quotations = this.model.quotations;
      quotations.forEach(function (quotation) {
        var projectID = quotation.get('batch.project.id');

        if (!Object.prototype.hasOwnProperty.call(projects, projectID)) {
          projects[projectID] = {
            project: quotation.get('batch.project'),
            quotations: []
          };
        }

        projects[projectID].quotations.push(quotation);
      });
      var quotationsByProject = [];

      for (var item in projects) {
        quotationsByProject.push(projects[item]);
      }

      return quotationsByProject;
    }),
    notSelectableQuotations: Ember.computed.filterBy('model.quotations', 'isNegotiating', false),
    afterApplyFilters: function afterApplyFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: 10,
        refreshedByFilter: true
      });
    },
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        status: 'negotiation'
      });
    },
    actions: {
      clearFilters: function clearFilters() {
        this._resetFilters();
      },
      addQuotation: function addQuotation(quotation) {
        if (this.selectedQuotations.includes(quotation)) {
          this.selectedQuotations.removeObject(quotation);
        } else {
          this.selectedQuotations.pushObject(quotation);
        }
      },
      clearQuotations: function clearQuotations() {
        this.selectedQuotations.clear();
        this.set('refreshedByFilter', true);
        this.send('refreshRoute');
      },
      // Decline Quotation
      callDeclineQuotation: function callDeclineQuotation(quotation) {
        this.selectedQuotations.clear();
        this.set('selectedQuotation', quotation);
        this.set('showDeclineQuotationDialog', true);
      },
      // Renegotiate Quotation
      callRenegotiateQuotation: function callRenegotiateQuotation(quotation) {
        this.selectedQuotations.clear();
        this.selectedQuotations.pushObject(quotation);
        this.set('showRenegotiationDialog', true);
      },
      refresh: function refresh() {
        this.set('refreshedByFilter', true);
        this.send('refreshRoute');
      },
      closeDeclineDialog: function closeDeclineDialog() {
        // Close dialog before nullifying `selectedQuotation`
        this.set('showDeclineQuotationDialog', false);
        this.set('selectedQuotation', null);
      }
    }
  });

  _exports.default = _default;
});