define("front-end/routes/dashboard/tasks/show/user-approval-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('dashboard.tasks.show');
    }
  });

  _exports.default = _default;
});