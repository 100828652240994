define("front-end/components/custom-modals/rollback-manufacturing-batch-prompt-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperCard class="error" as |card|>
    <card.content>
      <PaperIcon @icon="warning" />
      <span class="warn"><b>Cuidado!</b> Você está prestes a reverter um lote em fabricação.</span>
    </card.content>
  </PaperCard>
  
  <p><b>Atenção</b> Em caso de pacote de lotes na oferta de trabalho, essa ação reverterá todos os lotes do pacote</p>
  
  <p>Com esta ação você irá retornar esses lotes de <b class="success-text">fabricando</b> para <b class="info-text">definindo fornecedor</b>, com isso serão movidos para a etapa do funil <b>Seleção de Fornecedores</b> cancelando a estratégia de definição anterior e habilitando a criação de uma nova.</p>
  
  <p>Você deve obrigatoriamente informar o motivo para a reversão deste lote. Esse motivo será enviado para o fornecedor selecionado.</p>
  
  <PaperForm class="layout-column" as |form|>
    <form.input data-test-input-reason
    @required      = {{true}}
    @label         = "Motivo para reverter o lote"
    @value         = {{@promptValue}}
    @onChange      = {{@updatePromptValue}}
    @errorMessages = {{hash required="Por favor, insira o motivo para reverter este lote"}} />
  </PaperForm>
  
  */
  {
    id: "YjtrrdL6",
    block: "{\"symbols\":[\"form\",\"card\",\"@promptValue\",\"@updatePromptValue\"],\"statements\":[[8,\"paper-card\",[[24,0,\"error\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,2,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@icon\"],[\"warning\"]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"warn\"],[12],[10,\"b\"],[12],[2,\"Cuidado!\"],[13],[2,\" Voc\xEA est\xE1 prestes a reverter um lote em fabrica\xE7\xE3o.\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[10,\"p\"],[12],[10,\"b\"],[12],[2,\"Aten\xE7\xE3o\"],[13],[2,\" Em caso de pacote de lotes na oferta de trabalho, essa a\xE7\xE3o reverter\xE1 todos os lotes do pacote\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"Com esta a\xE7\xE3o voc\xEA ir\xE1 retornar esses lotes de \"],[10,\"b\"],[14,0,\"success-text\"],[12],[2,\"fabricando\"],[13],[2,\" para \"],[10,\"b\"],[14,0,\"info-text\"],[12],[2,\"definindo fornecedor\"],[13],[2,\", com isso ser\xE3o movidos para a etapa do funil \"],[10,\"b\"],[12],[2,\"Sele\xE7\xE3o de Fornecedores\"],[13],[2,\" cancelando a estrat\xE9gia de defini\xE7\xE3o anterior e habilitando a cria\xE7\xE3o de uma nova.\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"Voc\xEA deve obrigatoriamente informar o motivo para a revers\xE3o deste lote. Esse motivo ser\xE1 enviado para o fornecedor selecionado.\"],[13],[2,\"\\n\\n\"],[8,\"paper-form\",[[24,0,\"layout-column\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"input\"]],[[24,\"data-test-input-reason\",\"\"]],[[\"@required\",\"@label\",\"@value\",\"@onChange\",\"@errorMessages\"],[true,\"Motivo para reverter o lote\",[32,3],[32,4],[30,[36,0],null,[[\"required\"],[\"Por favor, insira o motivo para reverter este lote\"]]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    meta: {
      moduleName: "front-end/components/custom-modals/rollback-manufacturing-batch-prompt-body.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});