define("front-end/components/custom-modals/cancel-defining-manufacturer-batches-prompt-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PaperCard class="error" as |card|>
    <card.content>
      <PaperIcon @icon="warning" />
      <span class="warn"><b>Cuidado!</b> Você está prestes a cancelar lotes em seleção de fornecedores.</span>
    </card.content>
  </PaperCard>
  
  <p>Com esta ação você irá passar esses lotes de <b class="success-text">definindo fornecedor</b> para <b class="info-text">cancelados</b>, e com isso eles não terão mais continuidade.</p>
  
  <p>Você deve obrigatoriamente informar o motivo para o cancelamento deses lotes. Esse motivo será enviado para o cliente do projeto.</p>
  
  <PaperForm class="layout-column" as |form|>
    <form.input data-test-input-reason
    @required      = {{true}}
    @label         = "Motivo para cancelar os lotes"
    @value         = {{@promptValue}}
    @onChange      = {{@updatePromptValue}}
    @errorMessages = {{hash required="Por favor, insira o motivo para cancelar estes lotes"}} />
  </PaperForm>
  
  */
  {
    id: "Uu6PTL9l",
    block: "{\"symbols\":[\"form\",\"card\",\"@promptValue\",\"@updatePromptValue\"],\"statements\":[[8,\"paper-card\",[[24,0,\"error\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,2,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@icon\"],[\"warning\"]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"warn\"],[12],[10,\"b\"],[12],[2,\"Cuidado!\"],[13],[2,\" Voc\xEA est\xE1 prestes a cancelar lotes em sele\xE7\xE3o de fornecedores.\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"Com esta a\xE7\xE3o voc\xEA ir\xE1 passar esses lotes de \"],[10,\"b\"],[14,0,\"success-text\"],[12],[2,\"definindo fornecedor\"],[13],[2,\" para \"],[10,\"b\"],[14,0,\"info-text\"],[12],[2,\"cancelados\"],[13],[2,\", e com isso eles n\xE3o ter\xE3o mais continuidade.\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"Voc\xEA deve obrigatoriamente informar o motivo para o cancelamento deses lotes. Esse motivo ser\xE1 enviado para o cliente do projeto.\"],[13],[2,\"\\n\\n\"],[8,\"paper-form\",[[24,0,\"layout-column\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"input\"]],[[24,\"data-test-input-reason\",\"\"]],[[\"@required\",\"@label\",\"@value\",\"@onChange\",\"@errorMessages\"],[true,\"Motivo para cancelar os lotes\",[32,3],[32,4],[30,[36,0],null,[[\"required\"],[\"Por favor, insira o motivo para cancelar estes lotes\"]]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    meta: {
      moduleName: "front-end/components/custom-modals/cancel-defining-manufacturer-batches-prompt-body.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});