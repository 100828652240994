define("front-end/models/actions/mark-notifications-as-read-action", ["exports", "front-end/models/default-model"], function (_exports, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({});

  _exports.default = _default;
});