define("front-end/components/textbox-save", ["exports", "moment", "front-end/mixins/component-masked-input", "front-end/mixins/component-textbox-save"], function (_exports, _moment, _componentMaskedInput, _componentTextboxSave) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentMaskedInput.default, _componentTextboxSave.default, {
    mask: '',
    attrName: '',
    dateShow: '',
    placeholder: '',
    select: false,
    isRequired: false,
    isDisabled: false,
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.date) {
        if (this.value) {
          this.set('dateShow', (0, _moment.default)(this.value).format('DD/MM/YYYY'));
        }
      }

      if (this.select) {
        var selectedValue = this.prop;
        var selectedObj = this.options.find(function (option) {
          return selectedValue == option.value;
        });
        this.set('selected', selectedObj);
      }
    },
    actions: {
      setDate: function setDate(date) {
        this.set('dateShow', date);
        this.set('value', (0, _moment.default)(date, "DD/MM/YYYY").format());
        Ember.run.debounce(this, '_performSave', this.debounce);
      },
      performSelection: function performSelection(option) {
        this.set('selected', option);
        this.set('value', option.value);
        Ember.run.debounce(this, '_performSave', this.debounce);
      },
      setValue: function setValue(value) {
        this.set('value', value);
        Ember.run.debounce(this, '_performSave', this.debounce);
      }
    }
  });

  _exports.default = _default;
});