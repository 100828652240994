define("front-end/routes/mail/resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.session.isAuthenticated) {
        var controller = this.controllerFor('index.signin', transition);
        controller.set('previousTransition', transition);
        this.transitionTo('index.signin');
      }
    },
    model: function model(params) {
      var id = params.resource_id;
      var type = params.resource_type.singularize();
      var resource = this.store.query(type, {
        filter: {
          id: id
        }
      }).then(function (resources) {
        return resources.get('firstObject');
      });
      var company = this.currentUser.load().then(function (user) {
        return user.get('company');
      });
      var route = Ember.RSVP.hash({
        resource: resource,
        company: company
      }).then(function (hash) {
        var companyId = hash.company ? hash.company.get('id') : null;
        return hash.resource.route(companyId);
      });
      return route;
    },
    afterModel: function afterModel(model) {
      this.transitionTo.apply(this, _toConsumableArray(model));
    }
  });

  _exports.default = _default;
});