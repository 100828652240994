define("front-end/components/paper-expansion-panel/collapsed", ["exports", "ember-paper-expansion-panel/components/paper-expansion-panel/collapsed"], function (_exports, _collapsed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _collapsed.default;
    }
  });
});