define("front-end/services/zoom-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    zoom: 0.8,
    factor: 0.1
  });

  _exports.default = _default;
});