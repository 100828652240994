define("front-end/models/actions/reopen-estimate-survey-with-changes-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    message: (0, _model.attr)('string'),
    freightStrategy: (0, _model.attr)('number'),
    material: (0, _model.attr)('string'),
    rawMaterialResponsible: (0, _model.attr)('string'),
    rawMaterialFormat: (0, _model.attr)('string'),
    paymentMethod: (0, _model.attr)('string'),
    note: (0, _model.attr)('string'),
    deliveryCity: (0, _model.attr)('string'),
    unitTargetPrice: (0, _model.attr)('number'),
    customDeadline: (0, _model.attr)('string'),
    estimateSurvey: (0, _model.belongsTo)('estimate-survey', {
      inverse: null
    })
  });

  _exports.default = _default;
});