define("front-end/models/tasks/part-revision-approval-task", ["exports", "@ember-data/model", "front-end/models/tasks/task"], function (_exports, _model, _task) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _task.default.extend({
    icon: 'settings',
    part: (0, _model.belongsTo)('part', {
      inverse: null
    }),
    partRevision: (0, _model.belongsTo)('partRevision', {
      inverse: null
    }),
    myRouteName: 'dashboard.tasks.show.part-revision-approval-task'
  });

  _exports.default = _default;
});