define("front-end/templates/components/custom-modals/default-prompt-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "brfX9ece",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[6,[37,8],[[35,1,[\"highlightedMessage\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"class\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[6,[37,6],null,[[\"class\",\"data-test-highlighted-message\"],[\"md-2-line\",true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"md-list-item-text\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[1,[35,1,[\"highlightedMessage\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"p\"],[14,\"data-test-body-content\",\"\"],[12],[1,[35,1,[\"body\"]]],[13],[2,\"\\n\"],[6,[37,9],null,[[\"class\"],[\"layout-column\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],[[30,[36,4],[[32,1,[\"input\"]],\"expected `form.input` to be a contextual component but found a string. Did you mean `(component form.input)`? ('front-end/templates/components/custom-modals/default-prompt-body.hbs' @ L12:C4) \"],null]],[[\"class\",\"max\",\"required\",\"value\",\"label\",\"type\",\"errorMessages\",\"onChange\",\"data-test-prompt-input\"],[\"flex\",[35,1,[\"maxAccepted\"]],true,[35,3],[35,1,[\"inputLabel\"]],[35,1,[\"type\"]],[30,[36,2],null,[[\"required\"],[[35,1,[\"errorMessage\"]]]]],[35,0],true]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"updatePromptValue\",\"options\",\"hash\",\"promptValue\",\"-assert-implicit-component-helper-argument\",\"component\",\"paper-item\",\"paper-list\",\"if\",\"paper-form\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/custom-modals/default-prompt-body.hbs"
    }
  });

  _exports.default = _default;
});