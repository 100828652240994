define("front-end/transforms/deadline", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized ? serialized : null;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        var date = (0, _moment.default)(deserialized);
        date = date.endOf("day");
        return date.format();
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});