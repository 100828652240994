define("front-end/models/manufacturing-process", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    mainType: (0, _model.attr)('string'),
    subType: (0, _model.attr)('string'),
    processName: (0, _model.attr)('string'),
    eligibleAsMainProcess: (0, _model.attr)('boolean'),
    boundingVolumeType: (0, _model.attr)('string'),
    partRevision: (0, _model.hasMany)('part-revision'),
    boundingBoxRecommended: Ember.computed('boundingVolumeType', function () {
      return this.boundingVolumeType == 'BoundingBox';
    }),
    boundingCylinderRecommended: Ember.computed('boundingVolumeType', function () {
      return this.boundingVolumeType == 'BoundingCylinder';
    })
  });

  _exports.default = _default;
});