define("front-end/initializers/reset-scroll-position", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /*application*/
  {
    Ember.Route.reopen({
      activate: function activate() {
        this._super.apply(this, arguments); // Checking environment to reset scroll position of body application or ember testing container


        var elementToScroll = _environment.default.environment == 'test' ? document.querySelector('#ember-testing-container') : window;
        elementToScroll.scroll(0, 0);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});