define("front-end/templates/components/filters/toggle-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pXUmMS2k",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],null,[[\"iconButton\",\"raised\",\"accent\",\"warn\",\"onClick\"],[true,true,[35,4],[35,3],[30,[36,2],[[32,0],[35,1],[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"property\",\"onClick\",\"action\",\"isWarn\",\"isAccent\",\"paper-button\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/filters/toggle-filter.hbs"
    }
  });

  _exports.default = _default;
});