define("front-end/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wIAUFDh0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tool-bar\",[],[[\"@user\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\n\"],[1,[32,0,[\"displayHeader\"]]],[2,\"\\n\\n\\n\"],[8,\"admin-sidenav\",[],[[\"@user\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"main\"],[14,1,\"main\"],[14,0,\"flex layout-column\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"displayHeader\"]]],null,[[\"default\"],[{\"statements\":[[2,\"       \"],[10,\"div\"],[14,\"data-test-dashboard-header\",\"\"],[14,0,\"dashboard-header\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"layout-column layout-align-center-start\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"date-today\"],[12],[1,[30,[36,1],[[35,0],\"LL\"],null]],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"welcome-text\"],[12],[2,\"Bem vindo à Plataforma da Peerdustry\"],[13],[2,\"\\n        \"],[13],[2,\" \\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"layout-column dashboard\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[34,6]]],\"hasEval\":false,\"upvars\":[\"nil\",\"moment-format\",\"if\",\"-outlet\",\"component\",\"footer-bar\",\"new-application-version-alert\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin.hbs"
    }
  });

  _exports.default = _default;
});