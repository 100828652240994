define("front-end/controllers/dashboard/machines/index", ["exports", "front-end/mixins/controller-pagination-mixin"], function (_exports, _controllerPaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_controllerPaginationMixin.default, {
    loadingModel: false,
    myId: '',
    brand: '',
    machineType: '',
    machineTypeSelected: '',
    machineModel: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.size = 9;
      this.sizeOptions = [3, 6, 9, 12, 30, 60];
      this.paginatedModelName = 'model.machines';
      var queryParams = ['myId', 'machineType', 'machineModel', 'brand'];
      this.queryParams = [].concat(_toConsumableArray(this.pageQueryParams), queryParams);
    },
    hasMachines: Ember.computed.gt('model.machinesCount', 0),
    afterApplyFilters: function afterApplyFilters() {
      Ember.setProperties(this, {
        page: 1,
        size: 10,
        refreshedByFilter: true
      });
    }
  });

  _exports.default = _default;
});