define("front-end/templates/components/date-range-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eiBJQQRP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,\"data-test-range-label\",\"\"],[14,0,\"range-label\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"layout-column layout-gt-xs-row layout-align-space-between\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"period\",\"class\",\"label\",\"queryParam\",\"queryParamName\",\"filterAction\",\"data-test-filter-initial-date\"],[\"start\",\"layout-row flex\",\"Data início\",[35,3],[35,2],[35,1],true]]]],[2,\"\\n\\n  \"],[1,[30,[36,4],null,[[\"minDate\",\"period\",\"class\",\"label\",\"queryParam\",\"queryParamName\",\"filterAction\",\"data-test-filter-final-date\"],[[35,7],\"end\",\"layout-row flex\",\"Data final\",[35,6],[35,5],[35,1],true]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"rangeLabel\",\"filterAction\",\"startQueryParamName\",\"startQueryParam\",\"date-filter-input\",\"endQueryParamName\",\"endQueryParam\",\"minDate\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/date-range-filter.hbs"
    }
  });

  _exports.default = _default;
});