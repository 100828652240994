define("front-end/models/jotform-response", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    version: (0, _model.attr)('string'),
    submissionUrl: (0, _model.attr)('string'),
    manufacturerCharacterization: (0, _model.belongsTo)('manufacturer-characterization', {
      inverse: 'jotformResponse'
    })
  });

  _exports.default = _default;
});