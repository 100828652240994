define("front-end/components/pipeline/pipeline-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    zoomService: Ember.inject.service(),
    zoom: Ember.computed.alias('zoomService.zoom'),
    factor: Ember.computed.reads('zoomService.factor'),
    didRender: function didRender() {
      this._applyZoomToElement();
    },
    _applyZoomToElement: function _applyZoomToElement() {
      var el = document.querySelector('.pipeline-stage-wrapper');
      el.style.transform = "scale(".concat(this.zoom, ")");
      el.style.maxHeight = "".concat(100 / this.zoom, "%");
    },
    actions: {
      zoomIn: function zoomIn() {
        var zoom = this.zoom;
        var factor = this.factor;

        if (zoom < 1.6) {
          zoom += factor;
          this.set('zoom', zoom);

          this._applyZoomToElement();
        }

        this.metrics.trackEvent('GoogleAnalytics', {
          label: 'projects.show',
          action: 'zoom-in',
          category: 'CSS'
        });
      },
      zoomOut: function zoomOut() {
        var zoom = this.zoom;
        var factor = this.factor;

        if (zoom > 0.4) {
          zoom -= factor;
          this.set('zoom', zoom);

          this._applyZoomToElement();
        }

        this.metrics.trackEvent('GoogleAnalytics', {
          label: 'projects.show',
          action: 'zoom-out',
          category: 'CSS'
        });
      }
    }
  });

  _exports.default = _default;
});