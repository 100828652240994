define("front-end/models/query-objects/quotations-summary-query", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    groupBy: (0, _model.attr)('string'),
    groupByClient: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    clientCategory: (0, _model.attr)('number'),
    upperBound: (0, _model.attr)('string'),
    lowerBound: (0, _model.attr)('string'),
    clients: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Array
    priceRef: (0, _model.attr)('string'),
    timeoutDateRef: (0, _model.attr)('string'),
    acceptedDateRef: (0, _model.attr)('string'),
    declinedDateRef: (0, _model.attr)('string'),
    negotiationDateRef: (0, _model.attr)('string'),
    stats: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Array
    init: function init() {
      this._super.apply(this, arguments);

      this.set('priceOptions', [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Preço total',
        value: 'price'
      }, {
        display: 'Preço unitário',
        value: 'unit_price'
      }]);
    }
  });

  _exports.default = _default;
});