define("front-end/templates/components/dynamic-page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iUJNKnlH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"sticked-header\"],[[30,[36,0],[\"dynamic-page-header/sticked-header\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"header\"],[[30,[36,0],[\"dynamic-page-header/header\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"stickedHeader\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/dynamic-page-header.hbs"
    }
  });

  _exports.default = _default;
});