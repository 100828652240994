define("front-end/components/average-ticket-chart", ["exports", "chart.js", "front-end/config/environment"], function (_exports, _chart, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chart: null,
    labels: null,
    datasets: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var chartOptions = {
        responsive: true,
        data: {},
        type: 'pie',
        options: {
          tooltips: {
            callbacks: {
              label: function label(tooltipItem) {
                return _this.stats[tooltipItem.index].name;
              }
            }
          },
          legend: {
            position: 'bottom',
            display: true
          }
        }
      };

      if (_environment.default.environment == 'test') {
        chartOptions.options.animation = {
          duration: 0
        };
      }

      this.set('chartOptions', chartOptions);
    },
    hasData: Ember.computed.bool('stats'),
    _randomColor: function _randomColor() {
      return Math.round(Math.random() * 100);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var stats = this.stats;

      if (!this.stats) {
        return;
      }

      this.set('labels', []);
      this.set('datasets', [{
        data: [],
        backgroundColor: []
      }]);
      stats.forEach(function (entry) {
        _this2.labels.push(entry.name);

        _this2.datasets[0]['data'].push(entry['ticket']);

        _this2.datasets[0]['backgroundColor'].push("rgba(".concat(_this2._randomColor(), ", ").concat(_this2._randomColor(), ", ").concat(_this2._randomColor(), ", 0.5)"));
      });

      if (this.chart) {
        this.set('chartOptions.data.labels', this.labels);
        this.set('chartOptions.data.datasets', this.datasets);
        this.chart.update();
      }
    },
    _insertChart: function _insertChart() {
      var ctx = document.getElementById("data-test-ticket-chart");
      this.set('chartOptions.data.labels', this.labels);
      this.set('chartOptions.data.datasets', this.datasets);
      this.set('chart', new _chart.default(ctx, this.chartOptions));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._insertChart();
    }
  });

  _exports.default = _default;
});