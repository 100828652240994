define("front-end/components/manufacturing-job-defining-mechanisms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    tagName: '',
    dialogTextToggleManufacturerProfile: Ember.computed('company.manufacturerProfile.disabled', function () {
      return this.company.manufacturerProfile.disabled ? 'Deseja disponibilizar sua fábrica para receber serviços da Peerdustry?' : 'Você realmente deseja parar de fornecer serviços para a Peerdustry?';
    }),
    actions: {
      saveDecision: function saveDecision() {
        var _this = this;

        this.modalsManager.confirm({
          title: 'Alterar disponibilidade para receber novos serviços',
          body: this.dialogTextToggleManufacturerProfile,
          footerComponent: 'custom-modals/default-actions'
        }).then(function () {
          var company = _this.company;
          var profile = company.manufacturerProfile;
          profile.set('disabled', !profile.get('disabled'));
          company.save().catch(function () {
            profile.rollbackAttributes();

            _this.toasterMessages.showErrors(['Não foi possível realizar esta ação, por favor tente novamente']);
          });
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});