define("front-end/models/estimate", ["exports", "@ember-data/model", "front-end/models/default-model", "front-end/models/helpers/util", "front-end/constants"], function (_exports, _model, _defaultModel, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    currentUser: Ember.inject.service(),
    myId: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    price: (0, _model.attr)('number'),
    unitPrice: (0, _model.attr)('number'),
    daysAmount: (0, _model.attr)('number'),
    timeoutDeadline: (0, _model.attr)('string'),
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    selected: (0, _model.attr)('boolean'),
    allowReopen: (0, _model.attr)('boolean'),
    answerMessage: (0, _model.attr)('string'),
    answeredAt: (0, _model.attr)('string'),
    answerTime: (0, _model.attr)('string'),
    concludedAt: (0, _model.attr)('string'),
    availableTime: (0, _model.attr)('string'),
    conclusionTime: (0, _model.attr)('string'),
    fromManufacturerFreightResponsible: (0, _model.attr)('string'),
    availableLogistic: (0, _model.attr)('boolean'),
    manufacturer: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    estimateSurvey: (0, _model.belongsTo)('estimate-survey', {
      inverse: 'estimates'
    }),
    estimateConfirmation: (0, _model.hasMany)('estimate-confirmation', {
      inverse: 'estimate'
    }),
    estimateChat: (0, _model.belongsTo)('chats/estimate-chat', {
      inverse: 'estimate'
    }),
    refusedEstimateMessage: (0, _model.belongsTo)('tags/refused-estimate-message', {
      inverse: null
    }),
    abortedEstimateAnswers: (0, _model.hasMany)('events/aborted-estimate-answer', {
      inverse: 'estimate'
    }),
    unconfirmedEstimateMessage: (0, _model.belongsTo)('tags/unconfirmed-estimate-message', {
      inverse: null
    }),
    previous: (0, _model.belongsTo)('estimate', {
      inverse: 'next'
    }),
    next: (0, _model.belongsTo)('estimate', {
      inverse: 'previous'
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.availableEstimateStatus = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Aceito',
        value: _constants.ESTIMATE_STATUS.ACCEPTED
      }, {
        display: 'Expirado',
        value: _constants.ESTIMATE_STATUS.TIMEOUT
      }, {
        display: 'Declinado',
        value: _constants.ESTIMATE_STATUS.DECLINED
      }, {
        display: 'Negociação',
        value: _constants.ESTIMATE_STATUS.NEGOTIATION
      }, {
        display: 'Pendentes',
        value: _constants.ESTIMATE_STATUS.NOT_ANSWERED
      }, {
        display: 'Perdido devido demora',
        value: _constants.ESTIMATE_STATUS.DELAYED
      }, {
        display: 'Recusado pelo fornecedor',
        value: _constants.ESTIMATE_STATUS.REFUSED
      }];
    },
    unitPriceNumber: Ember.computed('unitPrice', function () {
      var unitPrice = this.unitPrice;

      if (unitPrice && isNaN(unitPrice)) {
        unitPrice = parseFloat(unitPrice.replace(',', '.'));
      }

      return unitPrice;
    }),
    statusReference: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_STATUS.NOT_ANSWERED:
          return 'not_answered';

        case _constants.ESTIMATE_STATUS.REFUSED:
          return 'refused';

        case _constants.ESTIMATE_STATUS.NEGOTIATION:
          return 'negotiation';

        case _constants.ESTIMATE_STATUS.ACCEPTED:
          return 'accepted';

        case _constants.ESTIMATE_STATUS.DECLINED:
          return 'declined';

        case _constants.ESTIMATE_STATUS.TIMEOUT:
          return 'timeout';

        case _constants.ESTIMATE_STATUS.DELAYED:
          return 'delayed';

        case _constants.ESTIMATE_STATUS.CANCELED:
          return 'canceled';

        case _constants.ESTIMATE_STATUS.UNCONFIRMED:
          return 'unconfirmed';

        default:
          return '';
      }
    }),
    readableStatus: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_STATUS.NOT_ANSWERED:
          return 'Pendente';

        case _constants.ESTIMATE_STATUS.REFUSED:
          return 'Recusado pelo fornecedor';

        case _constants.ESTIMATE_STATUS.NEGOTIATION:
          return 'Negociação';

        case _constants.ESTIMATE_STATUS.ACCEPTED:
          return 'Aceito';

        case _constants.ESTIMATE_STATUS.DECLINED:
          return 'Recusado pelo Cliente';

        case _constants.ESTIMATE_STATUS.TIMEOUT:
          return 'Expirado';

        case _constants.ESTIMATE_STATUS.DELAYED:
          return 'Perdido devido demora da resposta';

        case _constants.ESTIMATE_STATUS.CANCELED:
          return 'Cancelado pelo admin';

        case _constants.ESTIMATE_STATUS.UNCONFIRMED:
          return 'Cancelado pelo fornecedor';

        default:
          return '';
      }
    }),
    accepted: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_STATUS.ACCEPTED;
    }),
    canceled: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_STATUS.CANCELED;
    }),
    pending: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_STATUS.NOT_ANSWERED;
    }),
    negotiation: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_STATUS.NEGOTIATION;
    }),
    refused: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_STATUS.REFUSED;
    }),
    canceledOrRefused: Ember.computed('status', function () {
      var statusList = [_constants.ESTIMATE_STATUS.REFUSED, _constants.ESTIMATE_STATUS.CANCELED];
      return statusList.includes(this.status);
    }),
    shouldShowPriceAndAnswerDate: Ember.computed('status', function () {
      var statusList = [_constants.ESTIMATE_STATUS.NEGOTIATION, _constants.ESTIMATE_STATUS.ACCEPTED, _constants.ESTIMATE_STATUS.DECLINED];
      return statusList.includes(this.status);
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_STATUS.NOT_ANSWERED:
          return 'timer';

        case _constants.ESTIMATE_STATUS.REFUSED:
          return 'clear';

        case _constants.ESTIMATE_STATUS.NEGOTIATION:
          return 'forum';

        case _constants.ESTIMATE_STATUS.ACCEPTED:
          return 'thumb_up';

        case _constants.ESTIMATE_STATUS.DECLINED:
          return 'thumb_down';

        case _constants.ESTIMATE_STATUS.TIMEOUT:
          return 'timer_off';

        case _constants.ESTIMATE_STATUS.DELAYED:
          return 'shutter_speed';

        case _constants.ESTIMATE_STATUS.CANCELED:
          return 'cancel';

        case _constants.ESTIMATE_STATUS.UNCONFIRMED:
          return 'thumb_down';

        default:
          return '';
      }
    }),
    level: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_STATUS.NOT_ANSWERED:
          return 'warning';

        case _constants.ESTIMATE_STATUS.REFUSED:
          return 'error';

        case _constants.ESTIMATE_STATUS.NEGOTIATION:
          return 'info';

        case _constants.ESTIMATE_STATUS.ACCEPTED:
          return 'success';

        case _constants.ESTIMATE_STATUS.DECLINED:
          return 'error';

        case _constants.ESTIMATE_STATUS.TIMEOUT:
          return 'error';

        case _constants.ESTIMATE_STATUS.DELAYED:
          return 'error';

        case _constants.ESTIMATE_STATUS.CANCELED:
          return 'error';

        case _constants.ESTIMATE_STATUS.UNCONFIRMED:
          return 'error';

        default:
          return '';
      }
    }),
    readableAnswerTime: Ember.computed('answerTime', function () {
      var answerTime = this.answerTime;
      return (0, _util.readableDuration)(answerTime);
    }),
    readableAvailableTime: Ember.computed('availableTime', function () {
      var availableTime = this.availableTime;
      return (0, _util.readableDuration)(availableTime);
    }),
    readableConclusionTime: Ember.computed('conclusionTime', function () {
      var conclusionTime = this.conclusionTime;
      return (0, _util.readableDuration)(conclusionTime);
    }),
    selectedFreightStrategy: Ember.computed('fromManufacturerFreightResponsible', function () {
      switch (this.fromManufacturerFreightResponsible) {
        case 'peerdustry':
          return 'FOB';

        case 'manufacturer':
          return 'CIF';

        default:
          return null;
      }
    }),
    route: function route() {
      var _this = this;

      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.currentUser.load().then(function (user) {
        if (user.get('isAdmin')) {
          return _this.estimateSurvey.then(function (estimateSurvey) {
            return estimateSurvey.route();
          });
        } else {
          // return not null elements of the below array
          return ['dashboard.estimates.show', companyId, _this.id].filter(function (x) {
            return x;
          });
        }
      });
    }
  });

  _exports.default = _default;
});