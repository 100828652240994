define("front-end/templates/components/all-estimates-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yRlMxJpu",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,5]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"summaries\",\"upperBound\",\"lowerBound\",\"height\"],[[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"height\",\"lowerBound\",\"upperBound\",\"summaries\",\"estimates-summaries-over-time-chart\",\"paper-progress-linear\",\"_fetchAllEstimatesSummaries\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/all-estimates-summary.hbs"
    }
  });

  _exports.default = _default;
});