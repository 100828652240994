define("front-end/templates/admin/projects/show/batch/manufacturing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rCt8sooa",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[10,\"div\"],[14,0,\"layout-column layout-align-center flex-100\"],[12],[2,\"\\n  \"],[8,\"paper-card\",[[16,0,[34,0,[\"level\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"content\"]],[[24,0,\"layout-column\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        Atualmente o lote se encontra no estágio \"],[10,\"b\"],[12],[1,[35,0,[\"statusMessage\"]]],[13],[2,\".\\n        Você encontra ao lado as informações consolidadas mais atualizadas.\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/projects/show/batch/manufacturing.hbs"
    }
  });

  _exports.default = _default;
});