define("front-end/templates/admin/statistics/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fUAkGfow",
    "block": "{\"symbols\":[\"card\"],\"statements\":[[10,\"div\"],[14,0,\"admin-panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[2,\"Estatísticas de Usuários\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,7],null,[[\"class\"],[\"statistics-users-header\"]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[32,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('front-end/templates/admin/statistics/users.hbs' @ L7:C7) \"],null]],[[\"class\"],[\"layout-row\"]],[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"raised\",\"accent\",\"href\",\"data-test-show-users\"],[true,[35,2],[30,[36,1],[\"admin.statistics.users.user\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"person\"],null]],[2,\"\\n        Clientes\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],null,[[\"raised\",\"accent\",\"href\",\"data-test-show-admins\"],[true,[35,4],[30,[36,1],[\"admin.statistics.users.admin\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"supervisor_account\"],null]],[2,\"\\n        Administradores\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n  \"],[1,[30,[36,6],[[30,[36,8],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"href-to\",\"highlightUserButton\",\"paper-button\",\"highlightAdminButton\",\"-assert-implicit-component-helper-argument\",\"component\",\"paper-card\",\"-outlet\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/statistics/users.hbs"
    }
  });

  _exports.default = _default;
});