define("front-end/models/events/aborted-estimate-answer", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    price: (0, _model.attr)('pt-currency'),
    daysAmount: (0, _model.attr)('number'),
    answerMessage: (0, _model.attr)('string'),
    unitPrice: (0, _model.attr)('pt-currency'),
    user: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    estimate: (0, _model.belongsTo)('estimate', {
      inverse: 'abortedEstimateAnswers'
    })
  });

  _exports.default = _default;
});