define("front-end/routes/dashboard/estimates/history", ["exports", "front-end/constants", "front-end/mixins/route-pagination-mixin"], function (_exports, _constants, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        timedOutEstimates: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;
      var filterQueryOptions = {
        filter: {
          'status.nin': [_constants.ESTIMATE_STATUS.CANCELED, _constants.ESTIMATE_STATUS.NEGOTIATION, _constants.ESTIMATE_STATUS.NOT_ANSWERED],
          'manufacturer': company.id
        },
        include: 'estimate-survey.part-revision.edited-pdf-design',
        fields: {
          'estimates': 'estimate_survey,my_id,timeout_deadline,price,status',
          'estimate-survey': 'quantity,raw_material_responsible,freight_responsible,delivery_city,payment_method,part_revision'
        }
      };

      if (params.status) {
        filterQueryOptions.filter['status.nin'] = [];
        filterQueryOptions.filter['status'] = params.status;
      } else if (params.timedOutEstimates) {
        filterQueryOptions.filter['status.nin'] = [];
        filterQueryOptions.filter['status.in'] = [_constants.ESTIMATE_STATUS.TIMEOUT, _constants.ESTIMATE_STATUS.DELAYED];
      }

      if (params.myId) {
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var estimates = this.store.query('estimate', queryOptions);
      var estimatesCount = this.store.query('estimate', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          'manufacturer': company.id,
          'status.nin': [_constants.ESTIMATE_STATUS.CANCELED, _constants.ESTIMATE_STATUS.NEGOTIATION, _constants.ESTIMATE_STATUS.NOT_ANSWERED]
        }
      }).then(function (estimates) {
        return estimates.get('meta.count');
      });
      return Ember.RSVP.hash({
        estimates: estimates,
        estimatesCount: estimatesCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});