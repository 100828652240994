define("front-end/templates/admin/projects/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mqeLHNzo",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"project\"],[[35,0,[\"project\"]]]]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"pipeline/pipeline-wrapper\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/projects/show.hbs"
    }
  });

  _exports.default = _default;
});