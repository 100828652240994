define("front-end/models/actions/execute-part-automation-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    repository: (0, _model.belongsTo)('default-model', {
      polymorphic: true,
      inverse: null
    })
  });

  _exports.default = _default;
});