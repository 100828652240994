define("front-end/components/job-offer-process-item-management-panel", ["exports", "front-end/utils/raw-material-delivery-dates-handler"], function (_exports, _rawMaterialDeliveryDatesHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    showDetails: false,
    showCostCalculator: false,
    rawMaterialFromClient: Ember.computed.equal('mechanism.rawMaterialResponsible', 'client'),
    rawMaterialFromPeer: Ember.computed.equal('mechanism.rawMaterialResponsible', 'peerdustry'),
    manufacturingDaysAmountLabel: Ember.computed('mechanism.rawMaterialProvided', function () {
      return this.mechanism.get('rawMaterialProvided') ? 'Nº de dias para fabricação após recebimento da MP' : 'Nº de dias para fabricação após recebimento do pedido de compra';
    }),
    computeDeliveryDate: Ember.computed('mechanism.{daysAmount,rawMaterialDeliveryDate,rawMaterialResponsible,trackRawMaterialDelivery,context.rawMaterialDelivery.plannedDate}', function () {
      return _rawMaterialDeliveryDatesHandler.default.computeDeliveryDate(this.mechanism.rawMaterialResponsible, this.mechanism.trackRawMaterialDelivery, this.mechanism.daysAmount, this.mechanism.rawMaterialDeliveryDate, this.mechanism.get('context.rawMaterialDelivery.plannedDate'));
    }),
    rawMaterialDeliveryTracked: Ember.computed.equal('mechanism.trackRawMaterialDelivery', true),
    showRawMaterialResponsible: Ember.computed.not('rawMaterialFromClient'),
    showDeliveryCity: Ember.computed.equal('mechanism.fromManufacturerFreightResponsible', 'manufacturer'),
    actions: {
      saveModel: function saveModel() {
        this.mechanism.save();
      },
      destroyModel: function destroyModel() {
        var _this = this;

        this.modalsManager.confirm({
          title: 'Cancelar Job Board',
          body: 'Você tem certeza que deseja cancelar esse Job Board?',
          footerComponent: 'custom-modals/default-actions'
        }).then(function () {
          var mechanism = _this.mechanism;
          mechanism.destroyRecord().then(function () {
            if (_this.onClose) {
              _this.onClose();
            }
          });
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});