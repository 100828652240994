define("front-end/components/notification-center-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['notification'],
    classNameBindings: ['item.notificationStatusClass'],
    notificationDate: Ember.computed('item.createdAt', function () {
      var dateTime = this.item.createdAt;
      return new Date(dateTime);
    })
  });

  _exports.default = _default;
});