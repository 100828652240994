define("front-end/templates/components/estimate-list-text/unconfirmed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9oqBODg3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[15,\"data-test-status\",[34,0,[\"statusReference\"]]],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-created-at\",\"\"],[12],[2,\"Solicitado em \"],[1,[30,[36,1],[[35,0,[\"createdAt\"]],\"DD/MM/YY\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,\"data-test-reason-reject\",\"\"],[12],[2,\"\\n  \"],[10,\"b\"],[14,0,\"warn\"],[12],[2,\"Motivo de não confirmação: \"],[13],[1,[35,0,[\"message\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"estimate\",\"moment-format\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimate-list-text/unconfirmed.hbs"
    }
  });

  _exports.default = _default;
});