define("front-end/templates/components/estimate-list-text/refused", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ae9dgWg3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-created-at\",\"\"],[12],[2,\"Solicitado em \"],[1,[30,[36,1],[[35,0,[\"createdAt\"]],\"DD/MM/YY\"],null]],[13],[2,\"\\n\"],[6,[37,2],[[35,0,[\"answeredAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"data-test-answered-at\",\"\"],[12],[2,\" e Respondido em \"],[1,[30,[36,1],[[35,0,[\"answeredAt\"]],\"DD/MM/YY\"],null]],[10,\"small\"],[12],[2,\" (\"],[1,[35,0,[\"readableAnswerTime\"]]],[2,\")\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,\"data-test-reason-reject\",\"\"],[12],[2,\"\\n  \"],[10,\"b\"],[14,0,\"warn\"],[12],[2,\"Motivo de recusa: \"],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"fullDescription\"],[[35,0,[\"message\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"estimate\",\"moment-format\",\"if\",\"handle-large-descriptions\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimate-list-text/refused.hbs"
    }
  });

  _exports.default = _default;
});