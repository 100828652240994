define("front-end/routes/mail/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    toasterMessages: Ember.inject.service(),
    model: function model(params) {
      return params.token;
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var confirmationToken = model;
      var action = this.store.createRecord('actions/confirm-account-action', {
        confirmationToken: confirmationToken
      });
      action.save().then(function () {
        _this.toasterMessages.showSuccess(['Sua conta foi ativada com sucesso, faça o login na aplicação']); // console.log('Sucesso!');

      }).catch(function () {// console.log('Erro');
      }).finally(function () {
        _this.transitionTo('index.signin');
      });
    }
  });

  _exports.default = _default;
});