define("front-end/templates/components/date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BNp+Ba36",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,8],null,[[\"label\",\"minDate\",\"value\",\"class\",\"required\",\"disabled\",\"format\",\"errorMessages\",\"passThru\",\"yearRange\",\"onChange\",\"data-test-pikaday\"],[[35,7],[35,6],[35,5],\"flex-100\",[35,4],[35,3],\"DD/MM/YYYY\",[35,2],[30,[36,1],null,[[\"autocomplete\",\"readonly\"],[\"off\",\"readonly\"]]],\"currentYear, 2050\",[30,[36,0],[[32,0],\"setValue\"],null],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"errorMessages\",\"isDisabled\",\"isRequired\",\"dateShow\",\"minDate\",\"label\",\"custom-paper-pikaday\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/date-input.hbs"
    }
  });

  _exports.default = _default;
});