define("front-end/templates/components/submenu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TN14EVUq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],null,[[\"onClick\",\"href\",\"class\"],[null,[35,3],[30,[36,2],[\"submenu-item\",[30,[36,1],[[35,0],\" active\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"active\",\"if\",\"concat\",\"href\",\"paper-item\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/submenu-item.hbs"
    }
  });

  _exports.default = _default;
});