define("front-end/mirage/factories/purchase-order-item", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name() {
      return "Number ".concat(Math.random());
    },
    customId: function customId() {
      return Math.random();
    },
    company: (0, _emberCliMirage.association)(),
    purchaseOrder: (0, _emberCliMirage.association)()
  });

  _exports.default = _default;
});