define("front-end/components/edit-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toasterMessages: Ember.inject.service(),
    actions: {
      saveUser: function saveUser() {
        var _this = this;

        var user = this.user;
        user.save().then(function () {
          _this.toasterMessages.showSuccess(['Registro alterado com sucesso']);

          if (_this.onSuccess) {
            _this.onSuccess(user);
          }
        }).catch(function () {
          _this.toasterMessages.showErrors(['Erro ao alterar o registro']);

          if (_this.onFailure) {
            _this.onFailure(user);
          }
        });
      },
      cancel: function cancel() {
        var user = this.user;
        user.rollbackAttributes();

        if (this.onCancel) {
          this.onCancel(user);
        }
      },
      activateDeactivateUser: function activateDeactivateUser() {
        var user = this.user;

        if (user.get('isActive')) {
          user.set('accepted', false);
          user.set('activatedAccount', false);
        } else {
          user.set('accepted', true);
          user.set('activatedAccount', true);
        }
      },
      changeUserRole: function changeUserRole() {
        var user = this.user;

        if (user.get('isCompanyAdmin')) {
          user.set('role', 'company_user');
        } else {
          user.set('role', 'company_admin');
        }
      }
    }
  });

  _exports.default = _default;
});