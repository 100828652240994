define("front-end/routes/admin/estimate-chats", ["exports", "front-end/mixins/route-pagination-mixin", "front-end/mixins/route-websocket-handler-mixin", "front-end/mixins/route-websocket-toaster-message"], function (_exports, _routePaginationMixin, _routeWebsocketHandlerMixin, _routeWebsocketToasterMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routeWebsocketHandlerMixin.default, _routeWebsocketToasterMessage.default, _routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        hasUnreadMessages: {
          refreshModel: true
        },
        partMyId: {
          refreshModel: true
        },
        projectName: {
          refreshModel: true
        },
        company: {
          refreshModel: true
        },
        estimateMyId: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      var queryOptions = _objectSpread({
        filter: {
          'active': true
        },
        include: 'estimate.estimate-survey.project,estimate.estimate-survey.part,company',
        sort: '-created-at',
        fields: {
          'company': 'name,fantasy_name,cnpj',
          'estimate': 'my_id,estimate_survey',
          'estimate-chats': 'company,estimate',
          'estimate_survey': 'project,part,final_status',
          'part': 'name',
          'project': 'name'
        }
      }, this.getPageQueryParams(params));

      if (params.company) {
        queryOptions.filter['company'] = params.company;
      }

      if (params.partMyId) {
        queryOptions.filter['part__myId'] = params.partMyId;
      }

      if (params.projectName) {
        queryOptions.filter['project__name'] = params.projectName;
      }

      if (params.hasUnreadMessages) {
        queryOptions.filter['has_unread_messages'] = 'company';
      }

      if (params.estimateMyId) {
        queryOptions.filter['estimate__myId'] = params.estimateMyId;
      }

      return this.store.query('chats/estimate-chat', queryOptions);
    },
    websocketResourceHandler: function websocketResourceHandler(record, type) {
      if (type == 'chats/company-estimate-chat-messages') {
        var model = this.controller.model;
        var existingChat = model.find(function (estimateChat) {
          return estimateChat.get('id') == record.get('estimateChat.id');
        });

        if (!existingChat) {
          // Action implemented by RouteWebsocketToasterMessage mixin
          this.send('showToasterForNewMessages', 'Você pode ter novos chats. Recarregue a página.');
        }
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller._clearFilters();
      }
    },
    actions: {
      websocketToasterRefresh: function websocketToasterRefresh() {
        this.send('refreshRouteMixin');
      }
    }
  });

  _exports.default = _default;
});