define("front-end/models/actions/clone-machine-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    machine: (0, _model.belongsTo)('machines/machine', {
      polymorphic: true,
      inverse: null
    }),
    newMachine: (0, _model.belongsTo)('machines/machine', {
      polymorphic: true,
      inverse: null
    })
  });

  _exports.default = _default;
});