define("front-end/routes/application", ["exports", "front-end/config/environment", "front-end/mixins/route-error-handler"], function (_exports, _environment, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    router: Ember.inject.service(),
    metrics: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var router = this.router;

      if (_environment.default.environment != 'test') {
        router.on('routeDidChange', function () {
          var page = router.currentURL;
          var title = router.currentRouteName || 'unknown';

          _this.metrics.trackPage('GoogleAnalytics', {
            page: page,
            title: title
          });
        });
      }
    }
  });

  _exports.default = _default;
});