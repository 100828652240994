define("front-end/models/files/pdf-design", ["exports", "@ember-data/model", "front-end/config/environment", "front-end/models/files/application-file"], function (_exports, _model, _environment, _applicationFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationFile.default.extend({
    label: 'Desenho PDF',
    md5Hash: (0, _model.attr)('string'),
    urlForThumb: (0, _model.attr)('string'),
    fileProp: 'pdfDesign',
    endpoint: "".concat(_environment.default.host, "/core/v1/files/pdf-designs"),
    resource: (0, _model.belongsTo)('part-revision', {
      inverse: 'pdfDesign'
    }),
    allowedExtensions: '.pdf',
    showThumb: true
  });

  _exports.default = _default;
});