define("front-end/controllers/admin/companies/show/manufacturer", ["exports", "front-end/mixins/controller-estimates-summary-chart-filters-mixin"], function (_exports, _controllerEstimatesSummaryChartFiltersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_controllerEstimatesSummaryChartFiltersMixin.default, {});

  _exports.default = _default;
});