define("front-end/routes/dashboard/jobs/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('batch', params.job_id, {
        include: 'context.part-revision.edited-pdf-design'
      });
    }
  });

  _exports.default = _default;
});