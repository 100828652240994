define("front-end/components/project-info", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modalsManager: Ember.inject.service(),
    toasterMessages: Ember.inject.service(),
    allowedToFinish: Ember.computed.lt('project.status', _constants.PROJECT_STATUS.FINISHED),
    actions: {
      finishProject: function finishProject() {
        var _this = this;

        this.modalsManager.confirm({
          title: 'Finalizar projeto',
          body: 'Você tem certeza que deseja concluir este projeto?',
          footerComponent: 'custom-modals/default-actions'
        }).then(function () {
          var project = _this.project;

          var action = _this.store.createRecord('actions/finish-project-action', {
            project: project
          });

          action.save().then(function () {
            _this.toasterMessages.showSuccess(['Projeto finalizado com sucesso']);
          });
        }).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});