define("front-end/models/files/purchase-order-pdf", ["exports", "@ember-data/model", "front-end/config/environment", "front-end/models/files/application-file"], function (_exports, _model, _environment, _applicationFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _applicationFile.default.extend({
    fileExt: 'pdf',
    allowedExtensions: 'pdf',
    label: 'Pedido de Compra',
    urlForThumb: (0, _model.attr)('string'),
    fileProp: 'purchaseOrderPdf',
    endpoint: "".concat(_environment.default.host, "/core/v1/files/purchase-order-pdfs"),
    resource: (0, _model.belongsTo)('purchase-order', {
      inverse: 'purchaseOrderPdf'
    })
  });

  _exports.default = _default;
});