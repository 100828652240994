define("front-end/templates/admin/projects/show/batch/defining-manufacturer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JGANxBiP",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"manufacturingJob\",\"definingMechanism\",\"batch\",\"class\"],[[35,0,[\"manufacturingJob\"]],[35,0,[\"manufacturingJob\",\"definingMechanism\"]],[35,0,[\"batch\"]],\"flex-100\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"manufacturing-job-management-panel\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/projects/show/batch/defining-manufacturer.hbs"
    }
  });

  _exports.default = _default;
});