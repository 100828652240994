define("front-end/models/actions/renegotiate-batch-with-changes-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    message: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    note: (0, _model.attr)('string'),
    deliveryCity: (0, _model.attr)('string'),
    paymentMethod: (0, _model.attr)('string'),
    providingFreight: (0, _model.attr)('boolean'),
    deadline: (0, _model.attr)('string'),
    rawMaterialFormat: (0, _model.attr)('string'),
    providingRawMaterial: (0, _model.attr)('boolean'),
    batch: (0, _model.belongsTo)('batch', {
      inverse: null
    }),
    newBatch: (0, _model.belongsTo)('batch', {
      inverse: null
    })
  });

  _exports.default = _default;
});