define("front-end/components/companies-group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    group: null,
    hoveredElement: false,
    groupRouteOpened: Ember.computed('router.currentURL', function () {
      var router = this.router,
          group = this.group;
      return router.get('currentURL') == "/admin/companies-groups/".concat(group.get('id'), "/show");
    }),
    showItemArrow: Ember.computed.or('hoveredElement', 'groupRouteOpened'),
    arrowIcon: Ember.computed('groupRouteOpened', function () {
      return this.groupRouteOpened ? 'arrow_back' : 'arrow_forward';
    }),
    groupCompaniesCount: Ember.computed('group.companies.[]', function () {
      return this.group.hasMany('companies').ids().length;
    }),
    actions: {
      hoverElement: function hoverElement(e) {
        this.toggleProperty('hoveredElement');
        e.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});