define("front-end/components/dynamic-page-header", ["exports", "front-end/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screenPercentage: 10,
    stickedHeader: false,
    shouldBeSticked: true,
    isRendered: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isTestEnv', _environment.default.environment == 'test');
      this.set('shouldReactToScroll', _environment.default.runOnTestEnv || !this.isTestEnv);
      var factor = this.screenPercentage / 100;
      var scrollY = this.isTestEnv ? 'scrollTop' : 'scrollY';
      this.set('factor', factor);
      this.set('scrollY', scrollY);
    },
    _defineParams: function _defineParams() {
      // Define scrollable element
      this.set('scrollableElement', this.isTestEnv ? document.querySelector('#ember-testing-container') : window); // Save onscroll reference for tear down purposes

      this.set('_onscroll', this.scrollableElement.onscroll); // Define limit position based on window height

      var heightProp = this.isTestEnv ? 'offsetHeight' : 'innerHeight';
      var height = this.scrollableElement[heightProp];
      var limitPosition = height * this.factor;
      this.set('limitPosition', limitPosition);
      this.set('isRendered', true);
    },
    _adjustContainerMarginTop: function _adjustContainerMarginTop() {
      var selector = this.isTestEnv ? '#ember-testing' : '.dashboard';
      var container = document.querySelector(selector);

      if (this.stickedHeader) {
        var stickedHeader = document.querySelector('.dynamic-header-sticked-header');
        var stickedHeaderHeight = stickedHeader ? stickedHeader.offsetHeight : 0;
        container.style.marginTop = "".concat(stickedHeaderHeight, "px");
      } else {
        container.style.marginTop = '';
      }
    },
    _tearDownBehaviors: function _tearDownBehaviors() {
      var selector = this.isTestEnv ? '#ember-testing' : '.dashboard';
      var container = document.querySelector(selector);
      container.style.marginTop = '';
      this.set('scrollableElement.onscroll', this._onscroll);
    },
    _handleStickedHeader: function _handleStickedHeader() {
      var _this = this;

      if (this.shouldReactToScroll && this.shouldBeSticked) {
        this.scrollableElement.onscroll = function () {
          var currentPosition = _this.scrollableElement[_this.scrollY];

          if (currentPosition > _this.limitPosition) {
            _this.set('stickedHeader', true);
          } else if (currentPosition == 0) {
            _this.set('stickedHeader', false);
          }

          _this._adjustContainerMarginTop();
        };
      } else {
        this.set('stickedHeader', false);

        this._adjustContainerMarginTop();

        this.set('scrollableElement.onscroll', this._onscroll);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._defineParams();

      this._handleStickedHeader();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.isRendered) {
        this._handleStickedHeader();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._tearDownBehaviors();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});