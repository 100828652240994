define("front-end/models/tax-profile", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    answered: (0, _model.attr)('boolean'),
    pis: (0, _model.attr)('number'),
    ipi: (0, _model.attr)('number'),
    icms: (0, _model.attr)('number'),
    cofins: (0, _model.attr)('number'),
    reducedBase: (0, _model.attr)('number'),
    taxSubstitution: (0, _model.attr)('boolean'),
    company: (0, _model.belongsTo)('company', {
      inverse: 'taxProfile'
    }),
    taxRegime: (0, _model.belongsTo)('tags/tax-regime', {
      inverse: null
    })
  });

  _exports.default = _default;
});