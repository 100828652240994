define("front-end/routes/admin/statistics/users/user", ["exports", "front-end/mixins/route-pagination-mixin", "front-end/mixins/route-websocket-handler-mixin"], function (_exports, _routePaginationMixin, _routeWebsocketHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, _routeWebsocketHandlerMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.queryParams = _objectSpread({}, this.pageQueryParams);
    },
    model: function model(params) {
      var users = this.store.query('user', _objectSpread(_objectSpread({}, this.getPageQueryParams(params)), {}, {
        filter: {
          'connections.gt': 0,
          'role.nin': ['admin', 'superadmin']
        },
        include: 'company',
        sort: 'name'
      }));
      var usersCount = users.then(function (users) {
        return users.get('meta.count');
      });
      return Ember.RSVP.hash({
        users: users,
        usersCount: usersCount
      });
    },
    websocketResourceHandler: function websocketResourceHandler(record, type, payloadEvent) {
      if (type == 'users' && payloadEvent == 'resource_updated') {
        var isAdmin = record.get('isAdmin');

        if (!isAdmin) {
          this.send('refreshRouteMixin');
        }
      }
    }
  });

  _exports.default = _default;
});