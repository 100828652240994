define("front-end/routes/dashboard/estimates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;

      if (!company.get('manufacturerProfile.isManufacturer')) {
        controller.send('goToProfile');
      }
    }
  });

  _exports.default = _default;
});