define("front-end/routes/dashboard/quotations/index", ["exports", "front-end/constants", "front-end/mixins/route-pagination-mixin"], function (_exports, _constants, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var querParams = {
        myId: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        partName: {
          refreshModel: true
        },
        deadlineGte: {
          refreshModel: true
        },
        deadlineLte: {
          refreshModel: true
        },
        projectName: {
          refreshModel: true
        },
        internalCode: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), querParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;

      var queryOptions = _objectSpread({
        filter: {
          batch__company_id: company.get('id')
        },
        sort: 'created-at',
        include: 'selected-pricing-process,batch.partRevision.part,batch.project,batch.partRevision.pdfDesign',
        fields: {
          'quotations': 'selected_pricing_process,my_id,status,unit_price,price,allow_renegotiation,batch',
          'batches': 'note,quantity,payment_method,part_revision,raw_material_responsible,freight_responsible,delivery_city,raw_material_format,quotation_deadline,project',
          'projects': 'name',
          'parts': 'name,internal_code'
        }
      }, this.getPageQueryParams(params));

      if (params.status == 'quotation') {
        queryOptions.filter['status.nin'] = [_constants.QUOTATION_STATUS.NEGOTIATION, _constants.QUOTATION_STATUS.ACCEPTED, _constants.QUOTATION_STATUS.DECLINED, _constants.QUOTATION_STATUS.CLOSED, _constants.QUOTATION_STATUS.TIMEOUT];
      } else {
        queryOptions.filter['status'] = _constants.QUOTATION_STATUS.NEGOTIATION;
      }

      if (params.myId) {
        queryOptions.filter['myId.match'] = params.myId;
      }

      if (params.partName) {
        queryOptions.filter['part__name.match'] = params.partName;
      }

      if (params.projectName) {
        queryOptions.filter['project__name.match'] = params.projectName;
      }

      if (params.deadlineLte) {
        queryOptions.filter['deadline.lte'] = params.deadlineLte;
      }

      if (params.deadlineGte) {
        queryOptions.filter['deadline.gte'] = params.deadlineGte;
      }

      if (params.internalCode) {
        queryOptions.filter['part__internalCode.match'] = params.internalCode;
      }

      var quotations = this.store.query('quotation', queryOptions);
      var inNegotiationCount = this.store.query('quotation', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          status: _constants.QUOTATION_STATUS.NEGOTIATION,
          batch__company_id: company.get('id')
        }
      }).then(function (quotations) {
        return quotations.get('meta.count');
      });
      var inQuotationCount = this.store.query('quotation', {
        page: {
          size: 1,
          number: 1
        },
        filter: {
          batch__company_id: company.get('id'),
          'status.nin': [_constants.QUOTATION_STATUS.NEGOTIATION, _constants.QUOTATION_STATUS.ACCEPTED, _constants.QUOTATION_STATUS.DECLINED, _constants.QUOTATION_STATUS.CLOSED, _constants.QUOTATION_STATUS.TIMEOUT]
        }
      }).then(function (quotations) {
        return quotations.get('meta.count');
      });
      return Ember.RSVP.hash({
        quotations: quotations,
        inNegotiationCount: inNegotiationCount,
        inQuotationCount: inQuotationCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);

      if (!controller.status) {
        controller.set('status', 'negotiation');
      }
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      controller.set('selectedQuotations', []);

      if (isExiting) {
        controller._resetFilters();

        controller.set('refreshedByFilter', false);
      }

      this._super.apply(this, arguments);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});