define("front-end/mixins/custom-body-scroll-lock", ["exports", "front-end/config/environment", "body-scroll-lock"], function (_exports, _environment, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    customDisableBodyScroll: function customDisableBodyScroll(target) {
      if (_environment.default.runOnTestEnv || _environment.default.environment != 'test') {
        (0, _bodyScrollLock.disableBodyScroll)(target, {
          reserveScrollBarGap: true
        });
      }
    },
    customEnableBodyScroll: function customEnableBodyScroll(target) {
      if (_environment.default.runOnTestEnv || _environment.default.environment != 'test') {
        (0, _bodyScrollLock.enableBodyScroll)(target);
      }
    }
  });

  _exports.default = _default;
});