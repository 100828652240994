define("front-end/models/estimate-survey", ["exports", "@ember-data/model", "front-end/models/pricing-process", "front-end/constants"], function (_exports, _model, _pricingProcess, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pricingProcess.default.extend({
    currentUser: Ember.inject.service(),
    type: 'estimate-survey',
    typeName: 'Pesquisa Orçamentos',
    allowNewEstimate: (0, _model.attr)('boolean'),
    allowDefineEstimate: (0, _model.attr)('boolean'),
    allowChangeDeadline: (0, _model.attr)('boolean'),
    moderatedFieldsCompleted: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('number'),
    statusName: (0, _model.attr)('string'),
    statusMessage: (0, _model.attr)('string'),
    videoAskUrl: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    deliveryCity: (0, _model.attr)('string'),
    paymentMethod: (0, _model.attr)('string'),
    rawMaterialFormat: (0, _model.attr)('string'),
    freightStrategy: (0, _model.attr)('number'),
    unitTargetPrice: (0, _model.attr)('pt-currency'),
    // Must be implemented by a child of PricingProcess Class
    quotation: (0, _model.belongsTo)('quotation'),
    estimates: (0, _model.hasMany)('estimate', {
      inverse: 'estimateSurvey'
    }),
    selectedEstimate: (0, _model.belongsTo)('estimate', {
      inverse: null
    }),
    declinedQuotationMessage: (0, _model.belongsTo)('tags/declined-quotation-message', {
      inverse: null
    }),
    competingEstimatesSummary: (0, _model.belongsTo)('computed-models/competing-estimates-summary', {
      inverse: 'estimateSurvey'
    }),
    statusReference: Ember.computed('status', 'isDefined', function () {
      if (this.isDefined) {
        return 'defined';
      } else {
        switch (this.status) {
          case _constants.ESTIMATE_SURVEY_STATUS.MISSING_FILE:
            return 'missing_file';

          case _constants.ESTIMATE_SURVEY_STATUS.READY_TO_ESTIMATE:
            return 'ready_to_estimate';

          case _constants.ESTIMATE_SURVEY_STATUS.ESTIMATING:
            return 'estimating';

          case _constants.ESTIMATE_SURVEY_STATUS.COMPLETED:
            return 'completed';

          case _constants.ESTIMATE_SURVEY_STATUS.CLOSED:
            return 'closed';

          default:
            return '';
        }
      }
    }),
    isDefined: Ember.computed('selectedEstimate.id', 'status', function () {
      return this.selectedEstimate.get('id') && this.status == _constants.ESTIMATE_SURVEY_STATUS.ESTIMATING;
    }),
    isCompleted: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_SURVEY_STATUS.COMPLETED;
    }),
    isClosed: Ember.computed('status', function () {
      return this.status == _constants.ESTIMATE_SURVEY_STATUS.CLOSED;
    }),
    hasBeenAvailable: Ember.computed('status', function () {
      return this.status >= _constants.ESTIMATE_SURVEY_STATUS.ESTIMATING;
    }),
    readableFreightStrategy: Ember.computed('freightStrategy', function () {
      switch (this.freightStrategy) {
        case _constants.FREIGHT_STRATEGY.CIF:
          return 'CIF';

        case _constants.FREIGHT_STRATEGY.FOB:
          return 'FOB';

        case _constants.FREIGHT_STRATEGY.BOTH_CIF_FOB:
          return 'CIF ou FOB';

        default:
          return '';
      }
    }),
    icon: Ember.computed('status', function () {
      switch (this.status) {
        case _constants.ESTIMATE_SURVEY_STATUS.MISSING_FILE:
          return 'cloud_upload';

        case _constants.ESTIMATE_SURVEY_STATUS.READY_TO_ESTIMATE:
          return 'notifications_active';

        case _constants.ESTIMATE_SURVEY_STATUS.ESTIMATING:
          return 'timer';

        case _constants.ESTIMATE_SURVEY_STATUS.COMPLETED:
          return 'check_circle';

        case _constants.ESTIMATE_SURVEY_STATUS.CLOSED:
          return 'cancel';

        default:
          return '';
      }
    }),
    level: Ember.computed('isDefined', 'status', function () {
      if (this.isDefined) {
        return 'info';
      } else {
        switch (this.status) {
          case _constants.ESTIMATE_SURVEY_STATUS.MISSING_FILE:
            return 'warning';

          case _constants.ESTIMATE_SURVEY_STATUS.READY_TO_ESTIMATE:
            return 'warning';

          case _constants.ESTIMATE_SURVEY_STATUS.ESTIMATING:
            return 'info';

          case _constants.ESTIMATE_SURVEY_STATUS.COMPLETED:
            return 'info';

          case _constants.ESTIMATE_SURVEY_STATUS.CLOSED:
            return 'error';

          default:
            return '';
        }
      }
    })
  });

  _exports.default = _default;
});