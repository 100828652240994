define("front-end/routes/admin/companies-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.websocketTypes = ['companies-groups'];
      this.queryParams = {
        groupName: {
          refreshModel: true
        }
      };
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var queryOptions = {
        filter: {}
      };
      var groups;

      if (params.groupName) {
        queryOptions.filter['name.match'] = params.groupName;
        groups = this.store.query('companies-group', queryOptions);
      } else {
        groups = this.store.findAll('companies-group');
      }

      var createdGroupsCount = this.store.query('companies-group', {
        page: {
          size: 1,
          number: 1
        }
      }).then(function (groups) {
        return groups.get('meta.count');
      });
      return Ember.RSVP.hash({
        groups: groups,
        createdGroupsCount: createdGroupsCount
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});