define("front-end/models/chats/estimate-chat", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    company: (0, _model.belongsTo)('company', {
      inverse: null
    }),
    estimate: (0, _model.belongsTo)('estimate', {
      inverse: 'estimateChat'
    }),
    estimateChatMessages: (0, _model.hasMany)('chats/estimate-chat-message', {
      inverse: 'estimateChat'
    })
  });

  _exports.default = _default;
});