define("front-end/controllers/dashboard/job-offers", ["exports", "front-end/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loadingModel: false,
    // This property is being set by filter-loader-mixin implemented by this *route*
    pageOffers: 1,
    pageConfirmations: 1,
    reloadOffers: true,
    reloadConfirmations: true,
    showGrid: true,
    size: 10,
    sortBy: '',
    myId: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.defaultPage = 1;
      var queryParams = ['myId', 'sortBy'];
      this.queryParams = [].concat(queryParams);
      this.jobOffersArray = [];
      this.estimateConfirmationsArray = [];
      this.sortOptions = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        display: 'Data de criação',
        value: 'created_at'
      }, {
        display: 'Data de expiração',
        value: 'timeout_deadline'
      }];
    },
    noMoreOffers: Ember.computed('model.jobOffers.meta.{page,page-count}', 'pageOffers', function () {
      return this.pageOffers >= this.model.jobOffers.meta['page-count'];
    }),
    showTour: Ember.computed.bool('hasOffers'),
    hasOffers: Ember.computed.notEmpty('jobOffersArray'),
    unansweredOffers: Ember.computed.filterBy('jobOffersArray', 'available'),
    hasConfirmations: Ember.computed.notEmpty('estimateConfirmationsArray'),
    unansweredConfirmations: Ember.computed.filterBy('estimateConfirmationsArray', 'status', _constants.ESTIMATE_CONFIRMATION_STATUS.AVAILABLE),
    noMoreConfirmations: Ember.computed.not('hasConfirmations'),
    // Manufacturer-offers tour should only be displayed if there are no estimate confirmations
    showManufacturerOffersTour: Ember.computed.and('noMoreConfirmations', 'hasOffers'),
    _resetFilters: function _resetFilters() {
      Ember.setProperties(this, {
        sortBy: '',
        myId: '',
        refreshedByFilter: true
      });
    },
    _clearInfiniteScroll: function _clearInfiniteScroll() {
      Ember.setProperties(this, {
        jobOffersArray: [],
        estimateConfirmationsArray: [],
        reloadOffers: true,
        reloadConfirmations: true,
        pageOffers: 1,
        pageConfirmations: 1,
        size: this.size
      });
    },
    actions: {
      loadMoreOffers: function loadMoreOffers() {
        var pageOffers = this.pageOffers;
        var count = this.model.jobOffers.meta['page-count'];

        if (pageOffers < count) {
          Ember.setProperties(this, {
            pageOffers: pageOffers + 1
          });
          this.set('reloadConfirmations', false);
          this.send('refreshRoute');
        }
      },
      loadMoreConfirmations: function loadMoreConfirmations() {
        var pageConfirmations = this.pageConfirmations;
        var count = this.model.estimateConfirmations.meta['page-count'];

        if (pageConfirmations < count) {
          Ember.setProperties(this, {
            pageConfirmations: pageConfirmations + 1
          });
          this.set('reloadOffers', false);
          this.send('refreshRoute');
        }
      },
      sortBy: function sortBy(option) {
        this._clearInfiniteScroll();

        Ember.setProperties(this, {
          sortBy: option.value,
          refreshedByFilter: true
        });
      },
      filterByMyId: function filterByMyId(myId) {
        this._clearInfiniteScroll();

        Ember.setProperties(this, {
          myId: myId,
          refreshedByFilter: true
        });
      },
      clearFilters: function clearFilters() {
        this._resetFilters();

        this._clearInfiniteScroll();
      },
      afterPerformJobActions: function afterPerformJobActions() {
        Ember.setProperties(this, {
          reloadOffers: false,
          reloadConfirmations: false
        });
        this.send('refreshRoute');
      }
    }
  });

  _exports.default = _default;
});