define("front-end/mirage/factories/files/purchase-order-pdf", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    purchaseOrder: (0, _emberCliMirage.association)(),
    stored: true,
    label: 'Pedido de Compra',
    originalFilename: 'sample.pdf',
    urlForFile: 'http://localhost:4200/assets/sample.pdf'
  });

  _exports.default = _default;
});