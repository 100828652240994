define("front-end/routes/dashboard/projects/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      this._super.apply(this, arguments);

      var company = this.modelFor('dashboard').company;
      var project = this.store.query('project', {
        include: 'batches',
        filter: {
          id: params.project_id
        }
      }).then(function (query) {
        return query.get('firstObject');
      });
      var batches = project.then(function (project) {
        return project.get('batches').toArray();
      });
      return Ember.RSVP.hash({
        company: company,
        project: project,
        batches: batches
      });
    },
    afterModel: function afterModel(model) {
      var project = model.project;

      if (project.get('creating')) {
        this.transitionTo('dashboard.projects.build', project.get('id'));
      }
    }
  });

  _exports.default = _default;
});