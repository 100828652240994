define("front-end/templates/components/is-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jdoy5z8H",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"value\",\"disabled\",\"onChange\",\"data-test-checkbox\"],[[35,3],[35,2],[30,[36,1],[[32,0],[35,0]],null],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onChangeAction\",\"action\",\"isDisabled\",\"isSelected\",\"paper-checkbox\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/is-selected.hbs"
    }
  });

  _exports.default = _default;
});