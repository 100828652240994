define("front-end/routes/dashboard/estimates/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var estimate = this.store.query('estimate', {
        filter: {
          id: params.estimate_id
        },
        include: 'estimateSurvey.partRevision.editedPdfDesign,estimateChat'
      }).then(function (query) {
        if (!query.length) {
          return Ember.RSVP.reject({
            errors: [{
              status: 404
            }]
          });
        }

        return query.get('firstObject');
      });
      var estimateConfirmations = this.store.query('estimate-confirmation', {
        filter: {
          'estimate_id': params.estimate_id
        },
        include: 'batch'
      });
      return Ember.RSVP.hash({
        estimate: estimate,
        company: this.modelFor('dashboard').company,
        estimateSurvey: estimate.then(function (estimate) {
          return estimate.get('estimateSurvey');
        }),
        batch: estimateConfirmations.then(function (estimateConfirmations) {
          return estimateConfirmations.get('firstObject.batch');
        })
      });
    },
    resetController: function resetController(controller) {
      // Caution: This block of code is executed on route refreshing
      controller.set('showChat', false);

      this._super.apply(this, arguments);
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});