define("front-end/templates/components/manufacturers-engagement-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pJEqitM/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"canvas\"],[14,1,\"manufacturers-engagement-chart\"],[14,\"width\",\"400\"],[14,\"height\",\"400\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "front-end/templates/components/manufacturers-engagement-chart.hbs"
    }
  });

  _exports.default = _default;
});