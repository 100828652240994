define("front-end/templates/components/help-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J+vGLejl",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[8,\"paper-button\",[[24,\"data-test-open-help-information\",\"\"]],[[\"@mini\",\"@iconButton\",\"@onClick\"],[[32,0,[\"mini\"]],true,[30,[36,0],[[32,0],\"toggleHelpInformation\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[[24,\"data-test-help-icon\",\"\"]],[[\"@icon\",\"@class\"],[[32,0,[\"icon\"]],[32,0,[\"iconClass\"]]]],null],[2,\"\\n\\n    \"],[8,\"paper-tooltip\",[[24,\"data-test-tooltip-button\",\"\"]],[[\"@position\"],[[32,0,[\"position\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"tooltipText\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"ember-popover\",[[24,\"data-test-help\",\"\"]],[[\"@event\",\"@side\",\"@isShown\",\"@tooltipClass\"],[\"none\",[32,0,[\"side\"]],[32,0,[\"showHelpInformation\"]],\"popover-wrapper\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"layout-column popover-content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"layout-row layout-align-space-between-center\"],[12],[2,\"\\n        \"],[10,\"h3\"],[14,\"data-test-help-title\",\"\"],[14,0,\"popover-title\"],[12],[1,[32,2]],[13],[2,\"\\n\\n        \"],[8,\"paper-button\",[[24,\"data-test-close-help-information\",\"\"]],[[\"@warn\",\"@iconButton\",\"@class\",\"@onClick\"],[true,true,\"close-popover\",[30,[36,0],[[32,0],\"toggleHelpInformation\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"paper-icon\",[[16,\"size\",15]],[[\"@icon\"],[\"close\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"paper-divider\",[],[[],[]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,\"data-test-help-content\",\"\"],[12],[18,3,null],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/help-button.hbs"
    }
  });

  _exports.default = _default;
});