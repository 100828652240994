define("front-end/templates/components/manage-manufacturer-block-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CH2A4t5r",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3,[\"manufacturerProfile\",\"blockedByAdmin\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],null,[[\"primary\",\"raised\",\"onClick\",\"data-test-unblock-manufacturer-button\"],[true,[35,1],[30,[36,0],[[32,0],\"unblockManufacturerProfile\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n    Desbloquear\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],null,[[\"warn\",\"raised\",\"onClick\",\"data-test-block-manufacturer-button\"],[true,[35,1],[30,[36,0],[[32,0],\"blockManufacturerProfile\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n    Bloquear\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"raised\",\"paper-button\",\"company\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/manage-manufacturer-block-state.hbs"
    }
  });

  _exports.default = _default;
});