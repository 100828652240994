define("front-end/models/actions/add-purchase-order-for-batches-action", ["exports", "@ember-data/model", "front-end/models/default-model"], function (_exports, _model, _defaultModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _defaultModel.default.extend({
    identifier: (0, _model.attr)('string'),
    batches: (0, _model.hasMany)('batch', {
      inverse: null
    }),
    purchaseOrder: (0, _model.belongsTo)('purchase-order', {
      inverse: null
    })
  });

  _exports.default = _default;
});