define("front-end/templates/components/display-field-diff-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nB3fDPNB",
    "block": "{\"symbols\":[\"row\",\"&default\"],\"statements\":[[6,[37,7],[[30,[36,6],[[35,1],[35,0]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[35,5,[\"row\"]],\"expected `body.row` to be a contextual component but found a string. Did you mean `(component body.row)`? ('front-end/templates/components/display-field-diff-table-row.hbs' @ L2:C5) \"],null]],[[\"data-test-diff-attribute\"],[[35,2]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1,[\"cell\"]],\"expected `row.cell` to be a contextual component but found a string. Did you mean `(component row.cell)`? ('front-end/templates/components/display-field-diff-table-row.hbs' @ L3:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[32,1,[\"cell\"]],\"expected `row.cell` to be a contextual component but found a string. Did you mean `(component row.cell)`? ('front-end/templates/components/display-field-diff-table-row.hbs' @ L6:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"accent\"],[12],[2,\"\\n        \"],[18,2,[[35,1]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[32,1,[\"cell\"]],\"expected `row.cell` to be a contextual component but found a string. Did you mean `(component row.cell)`? ('front-end/templates/components/display-field-diff-table-row.hbs' @ L11:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"warn\"],[12],[2,\"\\n        \"],[18,2,[[35,0]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"secondField\",\"firstField\",\"label\",\"-assert-implicit-component-helper-argument\",\"component\",\"body\",\"not-eq\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/display-field-diff-table-row.hbs"
    }
  });

  _exports.default = _default;
});