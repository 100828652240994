define("front-end/components/brazilian-states-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchText: '',
    selectedBrazilianState: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.brazilianStates = [{
        display: 'Selecione uma opção...',
        value: ''
      }, {
        value: 'AC',
        display: 'Acre'
      }, {
        value: 'AL',
        display: 'Alagoas'
      }, {
        value: 'AP',
        display: 'Amapá'
      }, {
        value: 'AM',
        display: 'Amazonas'
      }, {
        value: 'BA',
        display: 'Bahia'
      }, {
        value: 'CE',
        display: 'Ceará'
      }, {
        value: 'DF',
        display: 'Distrito Federal'
      }, {
        value: 'ES',
        display: 'Espírito Santo'
      }, {
        value: 'GO',
        display: 'Goiás'
      }, {
        value: 'MA',
        display: 'Maranhão'
      }, {
        value: 'MT',
        display: 'Mato Grosso'
      }, {
        value: 'MS',
        display: 'Mato Grosso do Sul'
      }, {
        value: 'MG',
        display: 'Minas Gerais'
      }, {
        value: 'PA',
        display: 'Pará'
      }, {
        value: 'PB',
        display: 'Paraíba'
      }, {
        value: 'PR',
        display: 'Paraná'
      }, {
        value: 'PE',
        display: 'Pernambuco'
      }, {
        value: 'PI',
        display: 'Piauí'
      }, {
        value: 'RJ',
        display: 'Rio de Janeiro'
      }, {
        value: 'RN',
        display: 'Rio Grande do Norte'
      }, {
        value: 'RS',
        display: 'Rio Grande do Sul'
      }, {
        value: 'RO',
        display: 'Rondônia'
      }, {
        value: 'RR',
        display: 'Roraima'
      }, {
        value: 'SC',
        display: 'Santa Catarina'
      }, {
        value: 'SP',
        display: 'São Paulo'
      }, {
        value: 'TO',
        display: 'Tocantins'
      }];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var selectedState = this.brazilianStates.find(function (state) {
        return state.value == _this.prop;
      });
      this.set('selectedBrazilianState', selectedState);
    },
    actions: {
      changeSearchText: function changeSearchText(searchText) {
        this.set('selectedBrazilianState', null);
        this.set('searchText', searchText);
      },
      setCompanyState: function setCompanyState(state) {
        if (state) {
          this.set('selectedBrazilianState', state);
          this.set('prop', state.value);
        }
      }
    }
  });

  _exports.default = _default;
});