define("front-end/initializers/handle-page-scroll-to-fixed-elements", ["exports", "ember-paper/components/paper-dialog", "front-end/mixins/component-block-page-scroll", "front-end/mixins/custom-body-scroll-lock", "ember-basic-dropdown/components/basic-dropdown", "ember-paper/components/paper-sidenav"], function (_exports, _paperDialog, _componentBlockPageScroll, _customBodyScrollLock, _basicDropdown, _paperSidenav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /*application*/
  {
    // Reopening `BasicDropdownComponent` and overrides `toggle` and `close` actions to block
    // and unblock page scrolling at `paper-menu` and `paper-select` components
    _basicDropdown.default.reopen(_customBodyScrollLock.default, {
      scrollDisabled: false,
      toggle: function toggle() {
        this._super.apply(this, arguments); // Using publicAPI native of basic dropdown to avoid that
        // toggle action is triggered after run close action on the
        // test environment, we notice that using ember-test-helper `click` it
        // triggers `mousedown` event and this cause that the situation above occurs


        if (this.publicAPI.isOpen) {
          this.customDisableBodyScroll();
          this.set('scrollDisabled', true);
        }
      },
      close: function close() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this._super.apply(this, arguments);

        this.customEnableBodyScroll();
        this.set('scrollDisabled', false);
      },
      willDestroyElement: function willDestroyElement() {
        if (this.scrollDisabled) {
          this.customEnableBodyScroll();
          this.set('scrollDisabled', false);
        }

        (true && !(!this.scrollDisabled) && Ember.assert('Body page scroll is disabled', !this.scrollDisabled));

        this._super.apply(this, arguments);
      }
    });

    _paperDialog.default.reopen(_componentBlockPageScroll.default, {
      escapeToClose: false
    });

    _paperSidenav.default.reopen(_customBodyScrollLock.default, {
      actions: {
        onToggle: function onToggle() {
          this.customDisableBodyScroll(); // This property will be true when
          // we click on a menu link and change the current route
          // So we need to enable body scroll
          // since onBackdropTap action will not be performed

          if (!this.closed) {
            this.customEnableBodyScroll();
          }

          this._super.apply(this, arguments);
        },
        onBackdropTap: function onBackdropTap() {
          // Enable body scroll on click outside sidenav area
          this.customEnableBodyScroll();

          this._super.apply(this, arguments);
        }
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});