define("front-end/templates/components/estimate-list-text/declined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CJGdU6W5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,0,[\"unitPrice\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[35,0,[\"unitPrice\"]]],null]],[2,\" p/ peça\\n\"],[6,[37,4],null,[[\"position\"],[\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"        Preço Total: \"],[1,[30,[36,3],[[35,0,[\"price\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\" - \"],[1,[35,0,[\"daysAmount\"]]],[2,\" dias\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"Preço e prazo não informados\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-created-at\",\"\"],[12],[2,\"Solicitado em \"],[1,[30,[36,2],[[35,0,[\"createdAt\"]],\"DD/MM/YY\"],null]],[13],[2,\"\\n\"],[6,[37,5],[[35,0,[\"answeredAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,\"data-test-answered-at\",\"\"],[12],[2,\" e Respondido em \"],[1,[30,[36,2],[[35,0,[\"answeredAt\"]],\"DD/MM/YY\"],null]],[10,\"small\"],[12],[2,\" (\"],[1,[35,0,[\"readableAnswerTime\"]]],[2,\")\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,0,[\"answerMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    \"],[10,\"b\"],[14,0,\"accent\"],[12],[2,\"Obs:\"],[13],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"fullDescription\",\"data-test-estimate-additional-information\"],[[35,0,[\"answerMessage\"]],true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"estimate\",\"handle-large-descriptions\",\"moment-format\",\"format-currency\",\"paper-tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimate-list-text/declined.hbs"
    }
  });

  _exports.default = _default;
});