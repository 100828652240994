define("front-end/templates/components/estimate-chat-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i+yLyrbH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"data-test-chat-message-wrapper\",\"\"],[15,0,[31,[[34,2]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"data-test-chat-message-ballon\",\"\"],[14,0,\"talk-bubble\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"talktext\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,\"data-test-chat-message-text\",\"\"],[14,0,\"layout-column\"],[12],[2,\"\\n        \"],[1,[35,3,[\"message\"]]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"layout-row layout-align-end-center send-status\"],[12],[2,\"\\n          \"],[1,[30,[36,4],[[35,3,[\"createdAt\"]],\"DD/MM | HH:mm\"],[[\"class\"],[\"time\"]]]],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[35,0]],[[\"size\",\"class\",\"data-test-chat-message-read\"],[\"18\",\"accent icon\",true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"readIcon\",\"paper-icon\",\"customClassNames\",\"chatMessage\",\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/estimate-chat-message.hbs"
    }
  });

  _exports.default = _default;
});