define("front-end/templates/admin/job-offer-processes/build/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1azt7tIq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-fill\"],[12],[2,\"\\n  \"],[8,\"job-offer-process-item-info\",[],[[\"@item\"],[[34,0,[\"item\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "front-end/templates/admin/job-offer-processes/build/item.hbs"
    }
  });

  _exports.default = _default;
});