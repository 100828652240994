define("front-end/routes/mail/notification", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.session.isAuthenticated) {
        var controller = this.controllerFor('index.signin', transition);
        controller.set('previousTransition', transition);
        this.transitionTo('index.signin');
      }
    },
    model: function model(params) {
      return this.store.query('notification', {
        filter: {
          'id': params.notification_id
        },
        include: 'notifiable,recipient.company'
      }).then(function (notifications) {
        if (!notifications.length) {
          return Ember.RSVP.reject({
            errors: [{
              status: 404
            }]
          });
        }

        return notifications.get('firstObject');
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      var notification = model;
      var notifiable = model.get('notifiable');
      var companyId = model.get('recipient.company.id');
      var route = notifiable.then(function (notifiable) {
        return notifiable.route(companyId);
      });
      return route.then(function (route) {
        notification.set('readAt', (0, _moment.default)().format());
        notification.save().then(function () {
          _this.transitionTo.apply(_this, _toConsumableArray(route));
        });
      });
    }
  });

  _exports.default = _default;
});