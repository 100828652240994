define("front-end/routes/admin/projects/index", ["exports", "front-end/constants", "front-end/mixins/route-pagination-mixin"], function (_exports, _constants, _routePaginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_routePaginationMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var queryParams = {
        myId: {
          refreshModel: true
        },
        status: {
          refreshModel: true
        },
        company: {
          refreshModel: true
        },
        partName: {
          refreshModel: true
        },
        searchText: {
          refreshModel: true
        },
        partInternalCode: {
          refreshModel: true
        },
        withPendingQuotations: {
          refreshModel: true
        },
        sortBy: {
          refreshModel: true
        }
      };
      this.queryParams = _objectSpread(_objectSpread({}, this.pageQueryParams), queryParams);
    },
    model: function model(params) {
      this._super.apply(this, arguments);

      var filterQueryOptions = {
        filter: {
          'status.nin': [_constants.PROJECT_STATUS.CREATING]
        },
        sort: '-created-at',
        include: 'batches,company,creator,project-summary',
        fields: {
          'batches': 'id',
          'users': 'name,email',
          'companies': 'name,fantasy_name,cnpj',
          'projects': 'my_id,name,company,batches,creator,project_summary,created_at,status'
        }
      };

      if (params.company) {
        filterQueryOptions.filter['company'] = params.company;
      }

      if (params.myId) {
        filterQueryOptions.filter['myId.match'] = params.myId;
      }

      if (params.status) {
        filterQueryOptions.filter['status'] = params.status;
      }

      if (params.searchText) {
        filterQueryOptions.filter['name.match'] = params.searchText;
      }

      if (params.partName) {
        filterQueryOptions.filter['parts__name.match'] = params.partName;
      }

      if (params.withPendingQuotations) {
        filterQueryOptions.filter['quotations__status'] = _constants.QUOTATION_STATUS.NEGOTIATION;
      }

      if (params.partInternalCode) {
        filterQueryOptions.filter['parts__internalCode.match'] = params.partInternalCode;
      }

      if (params.sortBy) {
        filterQueryOptions['sort'] = params.sortBy;
      }

      var queryOptions = _objectSpread(_objectSpread({}, filterQueryOptions), this.getPageQueryParams(params));

      var projects = this.store.query('project', queryOptions);
      var projectsCount = this.store.query('project', {
        filter: {
          'status.nin': [_constants.PROJECT_STATUS.CREATING]
        },
        page: {
          size: 1,
          number: 1
        }
      }).then(function (projects) {
        return projects.get('meta.count');
      });
      return Ember.RSVP.hash({
        projects: projects,
        projectsCount: projectsCount
      });
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller._resetFilters();

        controller.set('refreshedByFilter', false);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('refreshedByFilter', false);
    }
  });

  _exports.default = _default;
});