define("front-end/components/date-hour-input", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    minDate: '',
    maxDate: '',
    isRequired: false,
    defaultHour: '17',
    enableTime: true,
    dateFormat: 'd/m/Y H:i',
    disabled: false,
    disableMobile: false,
    isTouched: false,
    errorMessage: '',
    placeholder: '',
    fallbackHour: 17,
    fallBackminute: 0,
    shouldFallback: true,
    date: Ember.computed('prop', function () {
      return this.prop ? new Date(this.prop) : null;
    }),
    showClearInput: Ember.computed('disabled', 'date', function () {
      return !this.disabled && this.date;
    }),
    containerClass: Ember.computed('date', 'isTouched', 'errorMessage', function () {
      var cssClass = '';

      if (this.date) {
        cssClass += 'md-input-has-value ';
      }

      if (this.isTouched && this.errorMessage) {
        cssClass += 'md-input-invalid ';
      }

      return cssClass;
    }),
    showPlaceholder: Ember.computed.and('placeholder', 'date'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var minDate = this.minDate,
          maxDate = this.maxDate;
      this.checkAndFormatDateObject(minDate, 'minDateObject');
      this.checkAndFormatDateObject(maxDate, 'maxDateObject');
    },
    checkAndFormatDateObject: function checkAndFormatDateObject(date, prop) {
      if (date) {
        // Only accept a valid string as some params, such as
        // maxDate and minDate, since ember-flatpicker has
        // unexpected behavior dealing with native Date Object
        // and Moment's .toDate()
        (true && !(typeof date == "string") && Ember.assert("".concat(prop, " Must be a String"), typeof date == "string"));
        (true && !((0, _moment.default)(date).isValid()) && Ember.assert("".concat(prop, " Must be a valid date string"), (0, _moment.default)(date).isValid()));
        this.set(prop, new Date(date));
      }
    },
    actions: {
      touchElement: function touchElement() {
        this.set('isTouched', true);
      },
      setDate: function setDate(dateObjects) {
        var newDate = null; // ember-flatpickr will populate dateObjects with
        // now value if user leaves without selection

        if (this.minDate) {
          if (dateObjects[0] && (0, _moment.default)(dateObjects[0]).isAfter(this.minDate)) {
            newDate = (0, _moment.default)(dateObjects[0]).format();
          }
        } else {
          newDate = (0, _moment.default)(dateObjects[0]).format();
        }

        this.set('prop', newDate);

        if (this.onChangeAction) {
          this.onChangeAction(newDate);
        }
      },
      clearField: function clearField() {
        this.set('prop', null);

        if (this.afterClear) {
          this.afterClear();
        }
      }
    }
  });

  _exports.default = _default;
});