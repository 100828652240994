define("front-end/components/date-filter-input", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    period: '',
    queryParam: '',
    queryParamName: '',
    minDateRaw: Ember.computed('minDate', function () {
      var minDate = this.minDate;
      return minDate ? new Date(minDate) : null;
    }),
    dateShow: Ember.computed('queryParam', function () {
      var queryParam = this.queryParam;
      return queryParam ? (0, _moment.default)(queryParam).format('DD/MM/YYYY') : null;
    }),
    actions: {
      setDate: function setDate(dateString) {
        if (dateString) {
          var date = (0, _moment.default)(dateString, "DD/MM/YYYY");

          if (this.period == 'start') {
            date.startOf('day');
          } else if (this.period == 'end') {
            date.endOf('day');
          }

          this.filterAction(this.queryParamName, date.format());
        }
      }
    }
  });

  _exports.default = _default;
});