define("front-end/templates/components/custom-side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0bkGM6f",
    "block": "{\"symbols\":[\"card\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"layout-row layout-align-center-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"layout-row layout-align-end\"],[12],[2,\"\\n\"],[6,[37,9],null,[[\"class\",\"iconButton\",\"onClick\",\"data-test-toggle-side-bar\"],[\"toggle-hide\",true,[30,[36,8],[[32,0],[30,[36,7],[\"show\",[32,0]],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],[[35,4]],null]],[2,\"\\n\"],[6,[37,6],null,[[\"position\"],[\"left\"]],[[\"default\"],[{\"statements\":[[2,\"        Informações do projeto\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,10],null,[[\"class\"],[\"item\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"content\"]],\"expected `card.content` to be a contextual component but found a string. Did you mean `(component card.content)`? ('front-end/templates/components/custom-side-bar.hbs' @ L14:C7) \"],null]],[[\"class\"],[\"item-content\"]],[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],[[\"class\"],[\"item-content-yield\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"show\",\"liquid-if\",\"-assert-implicit-component-helper-argument\",\"component\",\"arrowIcon\",\"paper-icon\",\"paper-tooltip\",\"toggle\",\"action\",\"paper-button\",\"paper-card\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/custom-side-bar.hbs"
    }
  });

  _exports.default = _default;
});