define("front-end/templates/components/handle-large-descriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "neSE0nQr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"data-test-short-description\",\"\"],[14,0,\"handle-large-descriptions\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,9]],[13],[2,\"\\n\"],[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"b\"],[24,\"data-test-see-more\",\"\"],[24,0,\"info-text see-more\"],[4,[38,2],[[32,0],[30,[36,1],[\"toggleFullDescription\",[32,0]],null]],null],[12],[2,\"\\n      \"],[1,[34,5]],[2,\"\\n\"],[6,[37,8],null,[[\"event\",\"side\",\"isShown\",\"data-test-popover-description\"],[\"none\",[35,7],[35,6],true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"layout-column description-content\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"layout-row layout-align-end\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"iconButton\",\"onClick\",\"data-test-close-full-description\"],[true,[30,[36,2],[[32,0],[30,[36,1],[\"toggleFullDescription\",[32,0]],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"              \"],[1,[30,[36,0],[\"close\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n          \"],[10,\"span\"],[14,\"data-test-full-description\",\"\"],[14,0,\"description-text\"],[12],[1,[34,4]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"paper-icon\",\"toggle\",\"action\",\"paper-button\",\"fullDescription\",\"openPopoverLabel\",\"toggleFullDescription\",\"side\",\"ember-popover\",\"shortDescription\",\"descriptionSplited\",\"if\"]}",
    "meta": {
      "moduleName": "front-end/templates/components/handle-large-descriptions.hbs"
    }
  });

  _exports.default = _default;
});