define("front-end/components/masked-paper-input", ["exports", "front-end/mixins/component-masked-input"], function (_exports, _componentMaskedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentMaskedInput.default, {
    classNames: ['layout-row'],
    customValidations: Ember.A([])
  });

  _exports.default = _default;
});