define("front-end/components/company-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showDialog: false,
    selectCompanies: false,
    showCompaniesGroupsList: false
  });

  _exports.default = _default;
});