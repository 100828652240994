define("front-end/models/tasks/user-approval-task", ["exports", "@ember-data/model", "front-end/models/tasks/task"], function (_exports, _model, _task) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _task.default.extend({
    icon: 'person_add',
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    myRouteName: 'dashboard.tasks.show.user-approval-task',
    route: function route() {
      var companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // return not null elements of the below array
      return Ember.RSVP.resolve([this.myRouteName, companyId, this.id].filter(function (x) {
        return x;
      }));
    }
  });

  _exports.default = _default;
});