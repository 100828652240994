define("front-end/components/countdown-clock", ["exports", "moment", "front-end/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loop: null,
    display: '',
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      (true && !(this.date) && Ember.assert("date can't be blank", this.date));

      this._setTimer();

      if (_environment.default.environment != 'test') {
        this._updateTimer();
      }
    },
    _setTimer: function _setTimer() {
      var date = (0, _moment.default)(this.date);
      var now = (0, _moment.default)().valueOf();
      var distance = date - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      var seconds = Math.floor(distance % (1000 * 60) / 1000);
      this.set('display', "".concat(days, " dia(s), ").concat(hours, "h ").concat(minutes, "m ").concat(seconds, "s"));
    },
    _updateTimer: function _updateTimer() {
      var loop = Ember.run.later(this, function () {
        this._setTimer();

        this._updateTimer();
      }, 1000);
      this.set('loop', loop);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.loop);
    }
  });

  _exports.default = _default;
});