define("front-end/components/manufacturers-engagement-chart", ["exports", "chart.js"], function (_exports, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments); // Doughnut plugin to allow adding a text in the middle. Based on:
      // https://github.com/chartjs/Chart.js/issues/78#issuecomment-289264197


      var plugin = {
        beforeDraw: function beforeDraw(chart) {
          if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx; //Get options from the center object in options

            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2); //Start with a base font of 30px

            ctx.font = "30px " + fontStyle; //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated; // Find out how much the font can grow in width.

            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2; // Pick a new font size so it will not be larger than the height of label.

            var fontSizeToUse = Math.min(newFontSize, elementHeight); //Set font settings to draw it correctly.

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color; //Draw text in center

            ctx.fillText(txt, centerX, centerY);
          }
        }
      };
      var chartOptions = {
        data: {},
        type: 'doughnut',
        options: {
          responsive: true,
          legend: {
            display: false
          },
          animation: {
            duration: 0
          },
          hover: {
            animationDuration: 0
          },
          responsiveAnimationDuration: 0,
          elements: {
            center: {
              text: '',
              color: '',
              //Default black
              fontStyle: 'Helvetica',
              //Default Arial
              sidePadding: 15 //Default 20 (as a percentage)

            }
          }
        },
        plugins: [plugin]
      };
      this.set('chartOptions', chartOptions);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var summary = this.summary;
      var engaged = summary.get('refused') + summary.get('negotiation') + summary.get('accepted') + summary.get('declined');
      var missed = summary.get('delayed') + summary.get('timeout');
      var labels = ['Orçamentos Avaliados', 'Orçamentos Perdidos'];
      var datasets = [{
        data: [engaged, missed],
        backgroundColor: ['#DFF2BF', '#FFD2D2'],
        borderColor: ['#4F8A10', '#D8000C']
      }];
      var answerRate = Number((summary.get('answerRate') * 100).toFixed(0));
      var centerTextColor;

      if (answerRate >= 75) {
        centerTextColor = '#4F8A10';
      } else if (answerRate >= 50) {
        centerTextColor = '#9F6000';
      } else {
        centerTextColor = '#D8000C';
      }

      var centerText = answerRate.toString() + "%";
      this.set('chartOptions.options.elements.center.text', centerText);
      this.set('chartOptions.options.elements.center.color', centerTextColor);
      var chartData = {
        datasets: datasets,
        labels: labels
      };
      this.set('chartOptions.data', chartData);
    },
    didRender: function didRender() {
      var ctx = document.getElementById("manufacturers-engagement-chart");
      this.set('chart', new _chart.default(ctx, this.chartOptions));
    }
  });

  _exports.default = _default;
});