define("front-end/components/toaster-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toasterMessages: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.messages = [];
    },
    didInsertElement: function didInsertElement() {
      this.set('messages', this.toasterMessages.messages);
    },
    actions: {
      clearToaster: function clearToaster() {
        var _this = this;

        this.toasterMessages.toasters.forEach(function (toaster) {
          return _this.toasterMessages.paperToaster.cancelToast(toaster);
        });
        this.toasterMessages.toasters.clear();
      }
    }
  });

  _exports.default = _default;
});